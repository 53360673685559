<template>
  <a
    @click.prevent="handleDownloadFile(globals.backendHost + file.link + '?token=' + globals.authToken, file)"
    :href="globals.backendHost + file.link + '?token=' + globals.authToken"
    target="_blank"
  >
    <div class="file-icon">
      <i
        :class="fileClass"
        class="fa"
      ></i>
    </div>
    <div class="file-info">
      <span class="file-name ">
        {{ file.name }}
      </span>
      <span class="file-size ">
        {{ $format.formatFileSize(file.size) }}
      </span>
    </div>
  </a>
</template>

<script>
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'FileIconLink',
  props: ['file'],
  inject: ['handleDownloadFile'],
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore()
    }
  },
  computed: {
    fileExt: function () {
      return this.$format.formatFileType(this.file.mime)
    },
    fileClass: function () {
      return {
        'icon-pdf': this.fileExt === 'pdf',
        'icon-doc': this.fileExt === 'doc' || this.fileExt === 'docx' || this.fileExt === 'odt' || this.fileExt === 'rtf',
        'icon-excel': this.fileExt === 'xls' || this.fileExt === 'xlsx' || this.fileExt === 'ods',
        'icon-archive': this.fileExt === 'zip' || this.fileExt === 'rar',
        'icon-doc-1': this.fileExt === 'jpg' || this.fileExt === 'jpeg' || this.fileExt === 'png' || this.fileExt === 'gif'
      }
    }
  },
  methods:
  {
  }
}
</script>
<style lang="scss" scoped>
.file-icon {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 8px 15px;
  i {
    font-size: 30px;
    line-height: 50px;
    &.icon-doc {
      color: var(--color-primary);
    }
    &.icon-excel {
      color: var(--color-green);
    }
    &.icon-pdf{
      color: var(--color-red);
    }
    &.icon-archive {
      color: #BB6BD9;
    }
    &.icon-doc1 {
      color: var(--color-yellow);
    }
  }
}
</style>
