<script setup>

// UploadModal

import { ref, computed, onMounted, onUnmounted } from 'vue'
import UploadManager from '@/components/utils/UploadManager.js'
import { TenderiaHttp as $http } from '@/services/TenderiaHttp'
import { useStatsStore } from '@/storePinia/statsStore'


const props = defineProps({ files: Array, title: String, titles: Array, onDone: Function, onError: Function, fields: Object, auth: String, options: Object })
const $emit = defineEmits(['vuedals:close'])
const statsStore = useStatsStore()

const titleText = ref('')
const titleIndex = ref(0)
const waitTimer = ref(null)
const progress = ref(null)
const totalPercent = computed(() =>
{
  if (progress.value) return Math.round(((progress.value.currentIndex - 1) + (progress.value.currentPercent / 100)) / progress.value.total * 100)
  else                return 0
})
const hasPremiumPackages = computed(() =>
{
  return statsStore.premiumPackage?.state?.valid
})

const progressUpload = function(state)
{
  progress.value = state
}

const errorUpload = function(error)
{
  $emit('vuedals:close', null)
  props.onError(error)
}

const finishUpload = function(result)
{
  $emit('vuedals:close', null)
  props.onDone(result)
}

const initUpload = function()
{
  const uploader = new UploadManager(finishUpload, errorUpload, props.fields, $http, props.auth, progressUpload)
  uploader.startUpload(props.files, props.options)
}

const timeUpdate = function()
{
  if (!hasPremiumPackages.value)
  { // non premium users
    if (Array.isArray(props.titles) && props.titles.length > 0)
    {
      waitTimer.value = setTimeout(timeUpdate, 4500)
      let idx = titleIndex.value
      titleText.value = props.titles[idx]
      idx++
      if (idx >= props.titles.length) idx = 0
      titleIndex.value = idx
    }
  }
}

onMounted(() =>
{
  titleText.value = props.title
  titleIndex.value = 0
  initUpload()
  timeUpdate()
})

onUnmounted(async () =>
{
  if (waitTimer.value)  clearTimeout(waitTimer.value)
})

</script>

<template>
  <div class="card-block my-2">
    <div class="container">
      <div class="text-center mb-3 mt-2">
        <div class="row">
          <div class="col-md-12">
            <h3 class="text-semibold">{{ titleText }}</h3>
          </div>
        </div>
      </div>
      <div class="mb-1 text-dark overflow-hidden">
        <div class="row" v-if="hasPremiumPackages">
          <div class="col-md-1 d-none d-md-block">
            <i class="icon-dupllicate-1 drag-hide font-size-35 align-content-center tw-text-pink-800"></i>
          </div>
          <div class="col-md-11">
            <div class="d-flex justify-content-between">
              <span class="text-bold pr-2">{{ totalPercent }}%</span>
              <span v-if="progress">Celkovo: {{ progress.currentIndex }} z {{ progress.total }}</span>
            </div>
            <div>
              <div class="progress" style="height: 0.5rem;">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" :style="{ width: totalPercent + '%' }" :aria-valuenow="totalPercent" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4" v-if="hasPremiumPackages">
          <div class="col-md-1 d-none d-md-block">
            <i class="icon-upload-cloud drag-hide font-size-35 align-content-center tw-text-sky-700"></i>
          </div>
          <div class="col-md-11">
            <div class="d-flex justify-content-between">
              <span class="text-bold pr-3">{{ progress?.currentPercent || 0 }}%</span>
              <span>{{ progress?.currentName || '...' }}</span>
            </div>
            <div>
              <div class="progress" style="height: 0.5rem;">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" :style="{ width: (progress?.currentPercent || 0) + '%' }" :aria-valuenow="progress?.currentPercent || 0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4" v-if="!hasPremiumPackages">
          <div class="col-md-12">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-info" role="status"><span class="sr-only">Prosím čakajte...</span></div>
            </div>
          </div>
        </div>
        <div v-if="!hasPremiumPackages" class="row mt-5 text-center">
          <div class="col-md-12">
            <div class="alert alert-warning text-semibold text-dark text-justify">
              <u class="text-primary text-underline">Informácia</u>
              <div>Rýchlosť nahrávania súborov je v bezplatnej verzii <strong>obmedzená</strong>. Získajte plnú rýchlosť nahrávania súborov a prehľad o stave nahrávania súborov s naším prémiovým balíčkom služieb...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.progress-bar
{
    transition: width 0.25s ease-out;
}
</style>
