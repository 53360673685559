<template>
  <div
    id="order-settings-add-auction"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        Vytvoriť novú aukciu k zákazke
      </h4>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label
              class="control-label"
              for="auction-name"
            >
              Názov aukcie <small>*</small>
            </label>
            <input
              id="subject"
              v-model.lazy="auction.name"
              type="text"
              class="form-control"
              :class="{ error: form.name.error, valid: form.name.valid }"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.name.error"
              class="error"
              for="auction-name"
            >
              Názov aukcie musí byť vyplnený
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <button
          class="btn btn-tenderia-grey-blue btn-inverse"
          @click="goBack"
        >
          <i class="icon-arrow-left" /> Späť
        </button>
        <button
          class="btn btn-success"
          @click="addAuction"
        >
          <i class="icon-save" /> Uložiť
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'
export default {
  name: 'AdminAuctionsAdd',
  data () {
    return {
      orderStore: useOrderStore(),
      auction: {
        name: ''
      },
      form: {
        name: {
          error: false,
          valid: false
        }
      }
    }
  },
  watch: {
    'auction.name' () {
      this.validateName()
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  },
  methods: {
    validateName () {
      var status = this.form.name
      status.error = false
      status.valid = false
      if (this.auction.name) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    goBack () {
      window.history.go(-1)
    },
    async addAuction () {
      this.validateName()
      if (this.form.name.error) {
        return
      }
      this.ShowLoading()
      var data = {
        order: {
          id: this.$route.params.partId ? this.$route.params.partId : this.$route.params.orderId
        },
        auction: this.auction
      }
      try {
        await this.$http.post('/auction/set', data)
        await this.orderStore.getOrder(this.order.id)
        this.CloseLoading()
        this.OkAlert({ text: 'Aukcia bola vytvorená' })
        this.goBack()
      } catch (error) {
        console.log(error)
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba' }, error)
      }
    }
  }
}
</script>
