
import { getCurrentInstance } from 'vue'
import { TenderiaHttp as $http } from '@/services/TenderiaHttp'
const insertGroupingRegexp = /(\d+)(\d{3})/

function insertGrouping(s, grouping)
{
  const times = Math.floor(s.length / 3)
  for (var i = 0; i < times; i++) {
    s = s.replace(insertGroupingRegexp, '$1' + grouping + '$2')
  }
  return s
}

export const formatNumber = function(n, options)
{
  // Set default options
  options = options || {}
  options.fractionDigits = typeof options.fractionDigits !== 'undefined' ? options.fractionDigits : 2
  options.useGrouping = typeof options.useGrouping !== 'undefined' ? options.useGrouping : true
  options.symbols = typeof options.symbols !== 'undefined' ? options.symbols : { grouping: ',', decimal: '.' }
  const parts = n.toFixed(options.fractionDigits).split('.')
  let integerPart = parts[0]
  const decimalPart = parts[1]
  if (options.useGrouping) {
    integerPart = insertGrouping(integerPart, options.symbols.grouping)
  }
  return integerPart + (options.fractionDigits > 0 ? options.symbols.decimal + decimalPart : '')
}

/// check if some elements in arr2 exist in arr1
export const checkIfSomeElementsExistInArray = (arr1 = [], arr2 = []) => arr1.some((el) => arr2.includes(el))

export const copyToClipboard = async (val) => {
  await navigator.clipboard.writeText(val)
}

export const useRoute = () =>
{
  const { proxy } = getCurrentInstance()
  return proxy.$route
}


export const initializeErrorHandler = function(vue)
{
  const errorCache = new Map()
  const cacheDuration = 60000

  function shouldReport(code)
  {
    const now = Date.now()
    const lastReportedTime = errorCache.get(code)
    if (lastReportedTime && now - lastReportedTime < cacheDuration) return false
    errorCache.set(code, now)
    for (const [key, timestamp] of errorCache)
    {
      if (now - timestamp > cacheDuration)  errorCache.delete(key)
    }
    return true
  }

  function reportError(details)
  {
    const code = `${details.type}:${details.message}:${details.stack || ''}`
    if (!shouldReport(code)) return
    const payload = { timestamp: new Date().toISOString(), ...details }
    $http.post('/system/error/log', payload).catch(_err => ({ data: null }))
  }

  vue.config.errorHandler = function (err, vm, info)
  {
    console.error('ERR:V', err)
    reportError({ type: 'vue', message: err.message, stack: err.stack, info, })
  }

  window.addEventListener('error', function (event)
  {
    console.error('ERR:G', event)
    reportError({ type: 'global', message: event.message, source: event.filename, lineno: event.lineno, colno: event.colno, stack: event.error?.stack })
  })

  window.addEventListener('unhandledrejection', function (event)
  {
    console.error('ERR:P', event)
    reportError({ type: 'promise', message: event.reason?.message || 'Unhandled promise rejection', stack: event.reason?.stack })
  })
}
