<template>
  <div>
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Identifikácia dodávateľa
        </h4>
        <div class="actions">
          <button
            class="btn btn-tenderia-blue"
            @click.prevent="fillApplicantDetails()"
          >
            Prebrať údaje z účtu
          </button>
        </div>
      </div>
      <div class="card-block">
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="businessName"
          >
            <span class="text-dark text-bold">
              Obchodné meno alebo názov uchádzača <small>*</small>
            </span><br>úplné oficiálne obchodné meno alebo názov uchádzača
          </label>
          <div class="col-md-7">
            <input
              id="businessName"
              v-model="offer.applicantDetails.businessName"
              type="text"
              :class="{ error: form.businessName.error, valid: form.businessName.valid }"
              class="form-control"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.businessName.error"
              class="error"
              for="businessName"
            >
              Obchodné meno musí byť vyplnené.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="address"
          >
            <span class="text-dark text-bold">
              Sídlo alebo miesto podnikania uchádzača <small>*</small>
            </span><br>úplná adresa sídla alebo miesta podnikania uchádzača
          </label>
          <div class="col-md-7">
            <textarea
              id="address"
              v-model="offer.applicantDetails.address"
              :class="{ error: form.address.error, valid: form.address.valid }"
              class="form-control"
            />
            Počet zostávajúcich znakov: {{ addressTextRemaining }}<br>
            <label
              v-if="form.address.error"
              class="error"
              for="address"
            >
              Miesto podnikania uchádzača musí byť vyplnené.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-md-5 control-label">
            <span class="text-dark text-bold">
              Platca DPH <small>*</small>
            </span>
          </label>
          <div class="col-md-7">
            <div class="radio radio-inline">
              <input
                id="vat-yes"
                v-model="offer.applicantDetails.vatPayer"
                type="radio"
                value="yes"
              ><label for="vat-yes">
                Áno
              </label>
            </div>
            <div class="radio radio-inline">
              <input
                id="vat-no"
                v-model="offer.applicantDetails.vatPayer"
                type="radio"
                value="no"
              ><label for="vat-no">
                Nie
              </label>
            </div>
            <label
              v-if="form.vatPayer.error"
              class="error"
            >
              Musíte zadať či ste platcom DPH.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="ico"
          >
            <span class="text-dark text-bold">
              IČO <small>*</small>
            </span>
          </label>
          <div class="col-md-7">
            <input
              v-model="offer.applicantDetails.ico"
              type="text"
              :class="{ error: form.ico.error, valid: form.ico.valid }"
              class="form-control"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.ico.error"
              class="error"
            >
              Musíte vyplniť platné IČO.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="country"
          >
            <span class="text-dark text-bold">
              Štát <small>*</small>
            </span><br>názov štátu, podľa právneho poriadku ktorého bol uchádzač založený
          </label>
          <div class="col-md-7">
            <country-select
              v-model="offer.applicantDetails.country"
              :value="offer.applicantDetails.country"
            />
            <label
              v-if="form.country.error"
              class="error"
              for="country"
            >
              Štát musí byť vyplnený.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-5">
            <span class="text-dark text-bold">
              Kontaktné údaje uchádzača
            </span><br>pre potreby komunikácie s uchádzačom počas verejného obstarávania
          </div>
          <div class="col-md-5" />
        </div>
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="contactPerson"
          >
            <span class="text-dark text-bold">
              Meno a priezvisko kontaktnej osoby <small>*</small>
            </span><br>
          </label>
          <div class="col-md-7">
            <input
              id="contactPerson"
              v-model="offer.applicantDetails.contactPerson"
              type="text"
              :class="{ error: form.contactPerson.error, valid: form.contactPerson.valid }"
              class="form-control"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.contactPerson.error"
              class="error"
              for="contactPerson"
            >
              Meno a priezvisko kontaktnej osoby musia byť vyplnené.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="phone"
          >
            <span class="text-dark text-bold">
              Telefón <small>*</small>
            </span>
          </label>
          <div class="col-md-7">
            <input
              id="phone"
              v-model="offer.applicantDetails.phone"
              type="text"
              :class="{ error: form.phone.error, valid: form.phone.valid }"
              class="form-control"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.phone.error"
              class="error"
              for="phone"
            >
              Telefónne čislo musí byť uvedené v tvare  napr. +421123456789.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="fax"
          >
            <span class="text-dark text-bold">
              Fax
            </span>
          </label>
          <div class="col-md-7">
            <input
              id="fax"
              v-model="offer.applicantDetails.fax"
              type="text"
              :class="{ error: form.fax.error, valid: form.fax.valid }"
              class="form-control"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.fax.error"
              class="error"
              for="fax"
            >
              Telefónne čislo pre fax ak ste ho uviedli musí byť v tvare  napr. +421123456789.
            </label>
          </div>
        </div>
        <div class="row form-group">
          <label
            class="col-md-5 control-label"
            for="email"
          >
            <span class="text-dark text-bold">
              Email <small>*</small>
            </span>
          </label>
          <div class="col-md-7">
            <input
              id="email"
              v-model="offer.applicantDetails.email"
              type="text"
              :class="{ error: form.email.error, valid: form.email.valid }"
              class="form-control"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.email.error"
              class="error"
              for="email"
            >
              Email musí byť uvedený v tvare  napr. meno@domena.sk.
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Doklady vyžadované výzvou
        </h4>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <label
              for="file"
              class="text-bold text-dark"
            >
              Prílohy
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span style="display: none">
              <input
                id="file"
                type="file"
                :multiple="statsStore.premiumActive"
                @change="setFiles"
              >
            </span>
            <div
              id="dropTarget"
              class="drop-area"
            >
              <i class="icon-upload-cloud drag-hide" />
              <span class="drag-visible">
                Súbory vložte sem
              </span>
              <span class="drag-hide">
                Pretiahnite sem dokumenty z plochy alebo priečinkov, ktoré chcete nahrať alebo
                <label for="file">
                  kliknite sem a vyberte súbory
                </label> na nahratie.
                <span>(Maximálna veľkosť jedného súboru je 2GB.)</span>
              </span>
            </div>
            <div v-if="messages.length > 0">
              <ul>
                <li
                  v-for="(message, index) in messages"
                  :key="`warning-message-${index}`"
                  class="text-danger"
                >
                  {{ message }}
                </li>
              </ul>
            </div>
            <div v-if="!statsStore.premiumActive" class="alert tw-bg-red-100" role="alert">
              <div class="d-flex">
                <div class="px-2">
                  <div class="tw-text-red-900 tw-font-semibold">UPOZORNENIE</div>
                  <div class="tw-text-red-700">Bez prémiových služieb môžete nahrávať súbory len jednotlivo. Celkový počet súborov však nie je obmedzený. Pre možnosť hromadného výberu súborov pre nahrávanie si aktivujte <a href="#" role="button" @click.prevent="premiumServices">Prémové služby.</a></div>
                </div>
              </div>
            </div>
            <table class="table">
              <tr>
                <th>Názov súboru</th>
                <th>Veľkosť</th>
                <th>Typ</th>
                <th />
              </tr>
              <tr
                v-for="(file, index) in offer.attachments"
                :key="file.id"
              >
                <td>{{ file.name }}</td>
                <td>{{ $format.formatFileSize(file.size) }}</td>
                <td>{{ $format.formatFileType(file.type) }}</td>
                <td>
                  <a
                    href=""
                    @click.prevent="removeSavedFileModal(index, offer.attachments)"
                  >
                    <i class="icon-delete font-size-25 text-danger" />
                  </a>
                </td>
              </tr>
              <tr
                v-for="(file, index) in files"
                :key="index + file.name"
              >
                <td>{{ file.name }}</td>
                <td>{{ $format.formatFileSize(file.size) }}</td>
                <td>{{ $format.formatFileType(file.type) }}</td>
                <td>
                  <a
                    href=""
                    @click.prevent="removeFile(index)"
                  >
                    <i class="icon-delete font-size-25 text-danger" />
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click="goBack"
      >
        <i class="icon-arrow-left" /> Späť
      </button>
      <button
        class="btn btn-tenderia-blue"
        :disabled="!canSaveApplication"
        @click="saveOffer(null)"
      >
        <i class="icon-save" /> Uložiť žiadosť
      </button>
      <button
        class="btn btn-success"
        :disabled="!canSendApplication"
        @click="saveOffer(sendOffer)"
      >
        <i class="icon-send" /> Uložiť a odoslať žiadosť
      </button>
    </div>
  </div>
</template>

<script>
import validator from '@/components/validator.js'
import BaseUserOfferForm from '@/components/order/common/components/BaseUserOfferForm'
import { useGlobalStore } from '@/storePinia/globalStore'
import { useStatsStore } from '@/storePinia/statsStore'

var dragDrop = require('drag-drop')

export default {
  name: 'UsUserApplicationsAdd',
  extends: BaseUserOfferForm,
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      form: {
        ico: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    canSaveApplication () {
      return this.order.state === 'active' && (!this.offer.state || this.offer.state === 'new')
    },
    canSendApplication () {
      return this.order.state === 'active' && (!this.offer.state || this.offer.state === 'new')
    }
  },
  watch: {
    'offer.applicantDetails.ico': function () {
      this.validateIco()
    }
  },
  methods: {
    validateOffer () {
      this.validateIco()
      this.validateBusinessName()
      this.validateAddress()
      this.validateCountry()
      this.validateContactPerson()
      this.validatePhone()
      this.validateFax()
      this.validateEmail()
      this.validateVatPayer()

      return this.validateCriteria() && this.form.businessName.valid && this.form.ico.valid && this.form.address.valid && this.form.country.valid && this.form.contactPerson.valid && this.form.phone.valid && this.form.email.valid && this.form.vatPayer.valid
    },
    validateIco () {
      var status = this.form.ico
      status.error = status.valid = false
      if (validator.validateIco(this.offer.applicantDetails.ico)) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    validateBusinessName () {
      var status = this.form.businessName
      status.error = status.valid = false
      var length = this.offer.applicantDetails.businessName.length
      if (length > 0 && length <= 256) {
        status.valid = true
      } else {
        status.error = true
      }
    },
    fillApplicantDetails () {
      const user = this.globals.user
      const supplier = this.globals.userOrganization

      this.offer.applicantDetails.email = user.email
      this.offer.applicantDetails.phone = user.phone
      this.offer.applicantDetails.contactPerson = `${user.title1} ${user.firstname} ${user.lastname} ${user.title2}`
      this.offer.applicantDetails.ico = supplier.ico
      this.offer.applicantDetails.businessName = supplier.name
      this.offer.applicantDetails.country = supplier.country?.toUpperCase() || ''
      this.offer.applicantDetails.address = supplier.address + ' ' + supplier.address2 + '\n' + supplier.city + '\n' + supplier.zip
      this.offer.applicantDetails.vatPayer = supplier.icdph ? 'yes' : 'no'
    },
    premiumServices () {
      this.showPurchaseModal({ title: 'Prémiové služby' })
    },
  },
  async mounted () {
    this.fillApplicantDetails()
    let vm = this

    if (document.querySelector('#dropTarget')) {
      dragDrop('#dropTarget', function (files) {
        for (let file of files) {
          vm.files.push(file)
        }
      })
    }

    try {
      let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.order.id } })

      if (offer.id) {
        this.offer = offer
        this.offer.applicantDetails.ico = offer.applicantDetails.ico[0]
      }

      this.ready = true

      if (this.$route.params.action === 'send') {
        this.sendOffer()
      }
    } catch (error) {
      console.log(error)
    }
  }
}
</script>
