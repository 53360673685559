<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title">
        <span v-if="part">
          Časť {{ part.part }} -
        </span>Vyhodnotenie ponúk
      </h4>
      <div
        v-if="order._canEdit"
        class="actions"
      >
        <button
          :disabled="loading"
          class="btn btn-success"
          @click.prevent="downloadProtocolTemplate()"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm text-light mr-2"
            role="status"
          />
          <i
            v-else
            class="icon-doc-1"
          />Zápisnica z vyhodnotenia
        </button>
      </div>
    </div>
    <v-table
      :items="offers"
      :columns="tableColumns"
      item-key="id"
      class="light"
    >
      <template #header="{headersStyles}">
        <thead>
          <col>
          <col>
          <colgroup :span="part ? part.criteria.length * 2 : order.criteria.length * 2" />
          <col>
          <col>
          <tr>
            <th
              :style="headersStyles[4]"
              rowspan="2"
              class="valign-middle text-center"
            >
              Por. č.
            </th>
            <th
              :style="headersStyles[1]"
              rowspan="2"
              class="valign-middle text-center"
            >
              Uchádzač
            </th>
            <th
              :colspan="part ? part.criteria.length * 2 : order.criteria.length * 2"
              scope="colgroup"
              class="text-center border right"
            >
              Návrhy na plnenie kritérií na vyhodnotenie ponúk
            </th>
            <th
              :style="headersStyles[3]"
              rowspan="2"
              class="valign-middle text-center"
            >
              Celkový počet bodov
            </th>
            <th
              :style="headersStyles[4]"
              rowspan="2"
              class="valign-middle text-center"
            >
              Poradie
            </th>
          </tr>
          <tr>
            <template v-if="part">
              <th
                v-for="criterion in part.criteria"
                :key="criterion.id"
                :style="headersStyles[2]"
                scope="col"
                colspan="2"
                class="text-center second-row"
              >
                <div class="two-row-header">
                  <div>
                    {{ criterion.name }} <span v-if="criterion.type === 'price'">
                      (EUR)
                    </span><span v-else>
                      ({{ criterion.unit }})
                    </span>
                  </div>
                  <span class="text-secondary">
                    Max. počet bodov: {{ criterion.maximumPoints }}
                  </span>
                </div>
              </th>
            </template>
            <template v-else>
              <th
                v-for="criterion in order.criteria"
                :key="criterion.id"
                :style="headersStyles[2]"
                scope="col"
                colspan="2"
                class="text-center second-row"
              >
                <div class="two-row-header">
                  <div>
                    {{ criterion.name }} <span v-if="criterion.type === 'price'">
                      (EUR)
                    </span><span v-else>
                      ({{ criterion.unit }})
                    </span>
                  </div>
                  <span class="text-secondary">
                    Max. počet bodov: {{ criterion.maximumPoints }}
                  </span>
                </div>
              </th>
            </template>
          </tr>
        </thead>
      </template>
      <template #body>
        <tbody>
          <tr
            v-for="offer in offers"
            :key="offer.id"
          >
            <td>{{ offer.rankByDate }}.</td>
            <td>
              <span v-if="openingEnabled && globals.user.type === 'observer' && offer.stage === 'unopened'">
                {{ $format.formatApplicantName(offer) }}
              </span>
              <router-link
                v-else-if="part"
                :to="{ name: `${orderStore.routePrefix}admin-offers-view-parts`, params: {partId: part.id ,orderId: order.id, offerId: offer.id}}"
              >
                {{ $format.formatApplicantName(offer) }}
              </router-link>
              <router-link
                v-else
                :to="{ name: `${orderStore.routePrefix}admin-offers-view`, params: {orderId: order.id, offerId: offer.id}}"
              >
                {{ $format.formatApplicantName(offer) }}
              </router-link>
            </td>
            <template v-if="part">
              <template v-for="criterion in part.criteria">
                <td
                  :key="`${offer.id}-${criterion._id}-value`"
                  class="text-right"
                >
                  <span v-if="proposals[offer.id][criterion._id]">
                    {{ formatProposalValue(proposals[offer.id][criterion._id]) }}
                  </span>
                </td>
                <td
                  :key="`${offer.id}-${criterion._id}-points`"
                  class="text-right"
                >
                  <span v-if="proposals[offer.id][criterion._id]">
                    {{ $format.numFixed2(proposals[offer.id][criterion._id].points) }}
                  </span>
                </td>
              </template>
            </template>
            <template v-else>
              <template v-for="criterion in order.criteria">
                <td
                  :key="`${offer.id}-${criterion._id}-value`"
                  class="text-right"
                >
                  <span v-if="proposals[offer.id][criterion._id]">
                    {{ formatProposalValue(proposals[offer.id][criterion._id]) }}
                  </span>
                </td>
                <td
                  :key="`${offer.id}-${criterion._id}-points`"
                  class="text-right"
                >
                  <span v-if="proposals[offer.id][criterion._id]">
                    {{ $format.numFixed2(proposals[offer.id][criterion._id].points) }}
                  </span>
                </td>
              </template>
            </template>
            <td class="text-center">
              {{ $format.numFixed2(offer.totalPoints) }}
            </td>
            <td class="text-center">
              {{ offer.rankByPoints }}.
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </div>
</template>

<script>
import saveAs from 'file-saver'
import VTable from '@/components/ui/Table.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'
let sFormatNumber = require('simple-format-number')

export default {
  name: 'OffersEvaluationWidget',
  components: { VTable },
  props: {
    part: {
      type: Object,
      default: null
    },
    order: {
      type: Object,
      required: true
    },
    round: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data () {
    return {
      orderStore: useOrderStore(),
      statsStore: useStatsStore(),
      globals: useGlobalStore(),
      offers: [],
      openingDate: '',
      openingDateStr: '',
      proposals: {},
      criteria: {},
      loading: false,
    }
  },
  computed: {
    serverTime () {
      return new Date(this.statsStore.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    tableColumns () {
      return [
        {
          key: 'rankByDate'
        },
        {
          key: 'applicant'
        },
        {
          key: 'criteria'
        },
        {
          key: 'totalPoints'
        },
        {
          key: 'rankByPoints'
        }
      ]
    }
  },
  watch: {
    round () {
      this.loadOffers()
    }
  },
  created () {
    this.loadOffers()
  },
  methods: {
    formatProposalValue (proposal) {
      return proposal
        ? sFormatNumber(proposal.value, { fractionDigits: this.criteria[proposal.criterion]?.decimalCount, symbols: { decimal: ',', grouping: ' ' } })
        : ''
    },
    prepareOfferProposals (offer) {
      let proposals = {}
      offer.proposals.forEach(proposal => {
        proposals[proposal.criterion] = proposal
      })
      this.proposals[offer.id] = proposals
    },
    prepareCriteria () {
      if (this.part) {
        this.part.criteria.forEach(criterion => {
          this.criteria[criterion._id] = criterion
        })
      } else {
        this.order.criteria.forEach(criterion => {
          this.criteria[criterion._id] = criterion
        })
      }
    },
    async loadOffers () {
      this.prepareCriteria()
      this.offers = []
      let offersArray = []
      if (this.part) {
        this.openingDateStr = this.round === 1 ? this.part.bidsOpenAt : this.part.bidsOpenAt2
        this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
        let { data: { offers } } = await this.$http.post('/offer/list', { order: this.part.id, limit: 100, sort: 'sentAt', stage: '!excluded' })
        offersArray = offers
      } else {
        this.openingDateStr = this.round === 1 ? this.order.bidsOpenAt : this.order.bidsOpenAt2
        this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
        let { data: { offers } } = await this.$http.post('/offer/list', { order: this.order.id, limit: 100, sort: 'sentAt', stage: '!excluded' })
        offersArray = offers
      }
      offersArray.forEach(offer => {
        if (this.round === 1 || (this.round === 2 && offer.stage !== 'excluded')) {
          this.offers.push(offer)
          this.prepareOfferProposals(offer)
        }
      })
    },
    async downloadProtocolTemplate () {
      this.loading = true
      try {
        let url =''
        if (this.part) {
          url = '/template/@bids-evaluate?order=' + this.part.id
        } else {
          url = '/template/@bids-evaluate?order=' + this.order.id
        }
        let { data } = await this.$http({ method: 'GET', url, responseType: 'blob' })
        const blob = new Blob([ data ])
        saveAs(blob, 'Zápisnica z vyhodnotenia ponúk.docx')
      } catch (error) {
        this.ErrorAlert({ text: 'Pri sťahovaní zápisnice nastala chyba.' }, error)
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.second-row {
  border-right: 1px solid #E0E0E0;
  .two-row-header {
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;
  }
}
</style>
