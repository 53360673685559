<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: 'ptk-admin-settings', params: {orderId: $route.params.orderId}}">
        Nastavenia
      </router-link>
      <router-link :to="{ name: 'ptk-admin-documents', params: {orderId: $route.params.orderId}}">
        Dokumenty
      </router-link>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PtkAdminHome'
}
</script>
