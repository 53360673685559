<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title">
        <span class="text-bold">
          {{ $format.formatDocumentType(order.attachments[attachmentIndex].type) }}
        </span> - {{ order.attachments[attachmentIndex].name }}
      </h4>
    </div>
    <div class="card-block">
      <h3>
        Prílohy
      </h3>
      <table class="table">
        <tr>
          <th>Názov súboru</th>
          <th>Veľkosť</th>
          <th>Typ</th>
        </tr>
        <tr
          v-for="file in files"
          :key="file.id"
        >
          <td>
            <a
              :href="downloadUrl(file.link)"
              target="_blank"
            >
              {{ file.name }}
            </a>
          </td>
          <td>{{ $format.formatFileSize(file.size) }}</td>
          <td>{{ $format.formatFileType(file.mime) }}</td>
        </tr>
      </table>
    </div>
    <div class="card-footer border top text-right">
      <button
        class="btn btn-default"
        @click="goBack"
      >
        <i class="icon-arrow-left"></i> Späť
      </button>
      <button
        class="btn btn-tenderia-grey-blue"
        @click.prevent="downloadDocument()"
      >
        <i class="icon-download"></i> Stiahnuť všetky súbory
      </button>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'PublicMarketReserachDocument',
  data () {
    return {
      globals: useGlobalStore(),
      orderStore: useOrderStore(),
      attachmentIndex: null,
      files: []
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  },
  async created () {
    this.attachmentIndex = this.$route.params.attachmentindex
    let { data: { files } } = await this.$http.post('/file/catalog', { parent: this.$route.params.documentid, mode: 'document', limit: 1000 })
    this.files = files
  },
  methods: {
    goBack () {
      window.history.go(-1)
    },
    downloadUrl (link) {
      return `${this.globals.backendHost}${link}?token=${this.globals.authToken}`
    },
    async downloadDocument () {
      this.ShowLoading({ title: 'Pripravujem súbory na stiahnutie' })
      var data = {
        file: {
          id: this.$route.params.documentid
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    }
  }
}
</script>
