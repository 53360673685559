<template>
  <button
    class="btn btn-success interested"
    :disabled="!canInterest && $route.name !== 'freeOrderHome'"
    @click="setInterestedModal"
  >
    Vstúpiť do zákazky
  </button>
</template>

<script>
import InterestedModal from '@/components/order/common/components/InterestedModal.vue'
import { permissions } from '@/router/permissions.js'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'InterestedButton',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      orderStore: useOrderStore(),
      showClass: false
    }
  },
  computed: {
    canInterest () {
      return this.order.state === 'active' && !this.order.isInterested
    }
  },
  methods: {
    setInterestedModal () {
      let userType = null
      if (this.globals.user) {
        if (this.globals.user.permissions && this.globals.user.permissions.includes(permissions.ORDER_ORDER_LIST_GET_1) && this.order.user.d === this.globals.user.id) {
          userType = 'admin'
        } else if (this.globals.user.permissions && this.globals.user.permissions.includes(permissions.ORDER_ORDER_LIST_GET_3) && this.order.userHome === this.globals.userOrganization.id) {
          userType = 'admin'
        } else if (this.globals.user.permissions && this.globals.user.permissions.includes(permissions.ORDER_ORDER_LIST_GET_6) && this.order.organization.ico === this.globals.userOrganization.ico) {
          userType = 'admin'
        } else if (this.globals.user.permissions && this.globals.user.permissions.includes(permissions.ORDER_OFFER_SET)) {
          userType = 'user'
        }
      }
      if (this.globals.user && this.$route.name === 'freeOrderHome') {
        if (userType) {
          this.$router.push({ name: `${this.orderStore.routePrefix}${userType}-overview`, params: { orderId: this.order.id } })
        } else {
          this.$router.push({ name: 'errorPage', params: { code: 403 } })
        }
      }
      if (!this.globals.user && this.$route.name === 'freeOrderHome') {
        this.InfoAlert({ title: 'Prihláste sa', text: 'Pre vstup do zákazky je potrebné prihlásiť sa' })
      }
      if (userType === 'user' && this.canInterest) {
        this.$emit('vuedals:new', {
          name: 'set-as-interested',
          escapable: true,
          title: 'Zaradiť do zoznamu záujemcov?',
          props: {
            order: this.order
          },
          component: InterestedModal,
          onClose: async (data) => {
            if (data === 'update') {
              await this.orderStore.readOrder(this.order.id)
            }
          }
        })
      }
    }
  }
}
</script>
