<template>
  <div>
    <div class="section-title row no-gutters justify-content-between">
      <span>
        <span class="text-primary">
          Zoznam
        </span>vyhlásených zákaziek
      </span>
      <button
        @click="showFilter = !showFilter"
        class="btn btn-tenderia-blue filter-btn"
      >
        <i class="icon-filter"></i><span>{{showFilter ? 'Skryť' : 'Zobraziť'}} filter</span>
      </button>
    </div>
    <v-collapsible>
      <div v-if="showFilter">
        <public-order-filters v-model="filter" />
      </div>
    </v-collapsible>
    <div class="card dark">
      <div class="card-heading row">
        <h1 class="card-title">
          Verejné obstarávania tenderia<span class="text-primary">
            .
          </span>
        </h1>
      </div>
      <v-table
        :items="contracts.orders"
        :columns="contractsColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="dark contracts-table"
      >
        <template #cell(name)="{ item }">
          <order-link
            :user-type="'user'"
            :order="item"
          />
        </template>
        <template #cell(organizationName)="{ item }">
          <router-link :to="`/public/organization/${item.organization.country.toLowerCase()}/${item.organization.ico}`">
            {{ item.organization.name }}
          </router-link>
        </template>
        <template #cell(journalAnnouncementDate)="{ item }">
          {{ $format.formatDateTime(item.journalAnnouncementDate) }}
        </template>
        <template #cell(procedureType)="{ item }">
          {{ $format.formatProcedureType(item) }}
        </template>
        <template #cell(bidsOpenAt)="{ item }">
          {{ $format.formatDateTime(item.bidsOpenAt) }}
        </template>
        <template #cell(state)="{ item }">
          <order-state
            :state="item.state"
            display="text"
          ></order-state>
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="contracts.total"
          class="dark"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="contracts.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import OrderLink from '@/components/order/OrderLink.vue'
import OrderState from '@/components/OrderState.vue'
import VTable from '@/components/ui/Table.vue'
import VCollapsible from '@/components/ui/Collapsible.vue'
import VPagination from '@/components/ui/Pagination.vue'
import PublicOrderFilters from '@/components/PublicOrderFilters.vue'

export default {
  name: 'OrdersPage',
  components: {
    OrderState,
    OrderLink,
    VTable,
    VCollapsible,
    VPagination,
    PublicOrderFilters
  },
  data () {
    return {
      contracts: {},
      showFilter: true,
      tableSort: {
        sortBy: 'journalAnnouncementDate',
        sort: 'desc'
      },
      filter: {
        fulltext: '',
        ico: '',
        cpvCode: '',
        updatedAt: '',
        biddingMethod: '',
        fundingByEu: '',
        type: '',
        subjectType: ''
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    contractsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov zákazky',
          sortable: true
        },
        {
          key: 'organizationName',
          label: 'Názov obstarávateľa',
          sortable: true
        },
        {
          key: 'journalAnnouncementDate',
          label: 'Dátum vyhlásenia súťaže',
          sortable: true
        },
        {
          key: 'bidsOpenAt',
          label: 'Dátum otvárania ponúk',
          sortable: true
        },
        {
          key: 'procedureType',
          label: 'Druh postupu',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    updatedAtValue () {
      const { updatedAt } = this.filter
      return updatedAt && updatedAt.code && moment().subtract(parseInt(updatedAt.code), 'd')
    },
    fetchParams () {
      const { fulltext, ico, cpvCode, updatedAt, biddingMethod, fundingByEu, type, subjectType } = this.filter
      return {
        limit: this.perPage,
        page: this.page,
        offset: this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        filter: fulltext,
        ico: ico,
        cpvCode: cpvCode,
        updatedFrom: this.updatedAtValue && this.updatedAtValue.toISOString(),
        updatedTo: updatedAt && updatedAt.code && moment().toISOString(),
        biddingMethod: biddingMethod && biddingMethod.code,
        fundingByEu: fundingByEu && fundingByEu.code,
        type: type && type.code,
        subjectType: subjectType && subjectType.code
      }
    }
  },
  methods: {
    orderState (value) {
      var orderStates = {
        planned: 'V príprave',
        active: 'Prebiehajúce',
        finished: 'Ukončené',
        cancelled: 'Zrušené'
      }
      return orderStates[value]
    },
    async fetchContracts () {
      try {
        const { data } = await this.$http.post('/order/catalog', this.fetchParams)
        this.contracts = data
      } catch (error) {
        this.contracts = { orders: [], total: 0 }
      }
    }
  },
  mounted () {
    this.fetchContracts()
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    }
  }
}
</script>
<style lang="scss" scoped>
.filters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px 10px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}
:deep(.contracts-table) {
  tbody {
    tr {
      &:hover {
        background-color: #3E97EF;
        td a {
          color: #fff;
        }
      }
    }
  }
}
</style>
