.<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Vyhľadať dodávateľa
        </h4>
      </div>
      <div class="card-block">
        <div>
          <p>
            <input
              v-model="fulltext"
              placeholder="Začnite písať názov spoločnosti alebo IČO"
              class="form-control"
            >
          </p>
        </div>
      </div>
      <v-table
        :items="suppliers.data"
        :columns="suppliersColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
        @row-click="toggleRow"
      >
        <template #cell(name)="{ item }">
          <div class="checkbox">
            <input
              v-model="checkedRows"
              class="mr-2 checkbox"
              type="checkbox"
              :value="item"
            >
            <label>
              {{ item.name }}<span class="font-size-10">
                {{ item.residence }}
              </span>
            </label>
          </div>
        </template>
      </v-table>
      <div />
      <div class="card-footer">
        <v-pagination
          v-if="suppliers.count"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="suppliers.count"
        />
        <div class="row">
          <button
            class="btn btn-tenderia-grey-blue btn-inverse"
            @click.prevent="goBack"
          >
            <i class="icon-arrow-left" /> Späť
          </button>
          <button
            v-if="checkedRows.length"
            class="btn btn-danger"
            @click="resetCheckedRows"
          >
            Zrušiť výber ({{ checkedRows.length }})
          </button>
          <button
            class="btn btn-success"
            :disabled="!checkedRows.length"
            @click="addSuppliers"
          >
            <i class="icon-add font-size-14" /> Pridať do prieskumu <span v-if="checkedRows.length">
              ({{ checkedRows.length }})
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'SupplierSearch',
  components: { VPagination, VTable },
  data () {
    return {
      orderStore: useOrderStore(),
      suppliers: {},
      fulltext: '',
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      },

      checkedRows: [],
      columns: ['name', 'ico']
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    suppliersColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'ico',
          label: 'IČO',
          sortable: true
        }
      ]
    },
    fetchParams () {
      return {
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        'filter': this.fulltext,
        sort: this.$format.sortString(this.tableSort)

      }
    }
  },
  watch: {
    fetchParams () {
      this.fetchSuppliers()
    }
  },
  mounted () {
    this.fetchSuppliers()
  },
  methods: {
    toggleRow (val) {
      const index = this.checkedRows.findIndex(row => row._id === val._id)
      if (index === -1) {
        this.checkedRows.push(val)
      } else {
        this.checkedRows.splice(index, 1)
      }
    },
    async orderAddSupplier (supplier) {
      let data = {
        order: {
          id: this.order.id
        },
        supplier: supplier
      }
      await this.$http.post('/order/supplier/set', data)
    },
    async addSuppliers () {
      this.ShowLoading()
      try {
        for (let supplier of this.checkedRows) {
          await this.orderAddSupplier(supplier)
        }

        let message = 'Dodávateľ bol priradený do zákazky.'
        if (this.order.state === 'active') {
          message += ' Pozvánka bola automaticky odoslaná na kontaktný email dodávateľa.'
        } else {
          message += ' Pozvánka bude odoslaná na kontaktný email dodávateľa po spustení zákazky.'
        }
        if (this.checkedRows.length > 1) {
          message = 'Dodávatelia boli priradení do zákazky.'
          if (this.order.state === 'active') {
            message += ' Pozvánky im boli automaticky odoslané na ich e-mail.'
          } else {
            message += ' Pozvánky im budú odoslané automaticky po spustení zákazky na ich e-mail.'
          }
        }
        await this.orderStore.getOrder(this.order.id)
        this.CloseLoading()
        this.OkAlert({ text: message })
        this.goBack()
      } catch (error) {
        let message = 'Nastala neočakávaná chyba. Skúste to znovu.'
        console.log(error)
        if (error?.response?.data.error && error?.response?.data.error.code === 410) {
          message = 'Nie je možné pridávať dodávateľov do zákazky po lehote na predkladanie ponúk.'
        }
        this.CloseLoading()
        this.ErrorAlert({ text: message }, error)
      }
      this.checkedRows = []
    },
    goBack () {
      this.$router.push({ name: `${this.orderStore.routePrefix}admin-suppliers` })
    },
    resetCheckedRows () {
      this.checkedRows = []
    },
    async fetchSuppliers () {
      const { data } = await this.$http.post('/organization/supplier/list', this.fetchParams)
      let { suppliers, total } = data
      suppliers = suppliers.filter(supplier =>
        !this.order.suppliers.some(orderSupplier =>
          orderSupplier.organization === supplier.organization
        )
      )
      suppliers = suppliers.map(supplier => {
        supplier.residence = `${supplier.address} ${supplier.address2}, ${supplier.zip} ${supplier.city}, ${supplier.country}`
        return supplier
      })
      this.suppliers = { data: suppliers, count: total }
    }
  }
}
</script>
