
import sk from '@/locales/sk'

const messages = { sk }
const locale = 'sk'

const format = function(text, params = {})
{
  if (!text) return ''
  if (Array.isArray(params))
  { // params as array
    return text.replace(/\{(\d+)\}/g, (match, index) =>
    {
      return params[index] !== undefined ? params[index] : match
    })
  }
  else
  { // params as object
    return text.replace(/\{([^}]+)\}/g, (match, key) =>
    {
      return params[key] !== undefined ? params[key] : match
    })
  }
}

const TEXT = (key, params = {}) =>
{
  const text = messages[locale][key]
  if (!text)  return format(key, params)
  else        return format(text, params)
}

export { TEXT }
