<template>
  <form class="p-2">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label
            for="nazov-zakazky"
            class="text-dark text-bold"
          >
            Názov novej výzvy <small>*</small>
          </label>
          <input
            id="nazov-zakazky"
            v-model="contractTitle"
            type="text"
            :class="{ error: form.contractTitle.error, valid: form.contractTitle.valid }"
            class="form-control"
            required="required"
            aria-required="true"
          >
          <label
            v-if="form.contractTitle.error"
            class="error"
            for="nazov-zakazky"
          >
            Toto pole je povinné. Môžete zadať max. 200 znakov
          </label>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close"
      >
        Zrušiť
      </button>
      <button
        class="btn btn-success"
        @click.prevent="createContract"
      >
        Vytvoriť výzvu
      </button>
    </div>
  </form>
</template>

<script>

export default {
  name: 'NewTenderModal',
  props: {
    parent: Object
  },
  data () {
    return {
      contractTitle: '',
      divided: false,
      form: {
        contractTitle: {
          error: false,
          valid: false
        }
      },
      validators: [
        this.validateContractTitle
      ]
    }
  },
  watch: {
    contractTitle () {
      this.validateContractTitle()
    }
  },
  methods: {
    close () {
      this.$emit('vuedals:close', null)
    },
    async createContract () {
      if (!this.validate()) {
        return
      }
      try {
        this.ShowLoading({ title: 'Vytváram výzvu' })
        let id = await this.$tenderiaService.createContract({
          order: {
            name: this.contractTitle,
            organization: this.parent.organization.id,
            parent: this.parent.id,
            type: 'dns',
            subtype: 'vyz'
          }
        })
        this.CloseLoading()
        this.PopupAlert({ title: 'Výzva bola vytvorená.' })
        this.close()
        this.$openOrder(id, 'dns', 'vyz')
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ title: 'Chyba', text: 'Výzvu sa nepodarilo vytvoriť' }, error)
      }
    },
    validate () {
      return this.validators.reduce((accumulator, validator) => validator() && accumulator, true)
    },
    validateContractTitle () {
      let titleLength = this.contractTitle.replace(/\s/g, '').length
      let state = this.form.contractTitle
      state.valid = state.error = false
      if (titleLength > 0 && titleLength <= 200) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    }
  }
}
</script>
