<template>
  <div class="order-actions ml-1 mt-1">
    <a
      href=""
      class="dropdown-switch"
      data-toggle="dropdown"
    >
      <i class="icon-option font-size-18"></i>
    </a>
    <ul class="dropdown-menu">
      <template v-if="order.procedureType !== 'dns' && (order.state === 'cancelled' || order.state === 'finished')">
        <li>
          <a @click.prevent="eventBus.$emit('open-order-modal', { type: 'order', action: 'clone', data: { order: item } })">
            <div class="row no-gutters align-items-center">
              <i class="icon-dupllicate-1 font-size-20"></i>
              <span>Klonovať</span>
            </div>
          </a>
        </li>
      </template>
      <template v-else-if="order.state === 'planned'">
        <li>
          <router-link :to="{ name: `${$orderRoutePrefix(order.type, order.subtype)}admin-settings`, params: { orderId: order.id, action: 'publish' } }">
            <div class="row no-gutters align-items-center">
              <i class="icon-send font-size-20"></i>
              <span>Vyhlásiť</span>
            </div>
          </router-link>
          <router-link :to="{ name: `${$orderRoutePrefix(order.type, order.subtype)}admin-settings`, params: { orderId: order.id } }">
            <div class="row no-gutters align-items-center">
              <i class="icon-pencil font-size-20"></i>
              <span>Upraviť</span>
            </div>
          </router-link>
          <router-link :to="{ name: `${$orderRoutePrefix(order.type, order.subtype)}admin-settings`, params: { orderId: order.id, action: 'remove' } }">
            <div class="row no-gutters align-items-center">
              <i class="icon-delete font-size-20"></i>
              <span>Vymazať</span>
            </div>
          </router-link>
        </li>
      </template>
      <template v-else-if="order.state === 'active'">
        <li>
          <router-link :to="{ name: `${$orderRoutePrefix(order.type, order.subtype)}admin-settings`, params: { orderId: order.id, action: 'finish' } }">
            <div class="row no-gutters align-items-center">
              <i class="icon-check font-size-20"></i>
              <span>Ukončiť</span>
            </div>
          </router-link>
        </li>
      </template>
      <template v-else>
        <li>
          <div class="row no-gutters align-items-center py-2 px-3">
            <span>Žiadne akcie</span>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OrderActionsMenu',
  props: {
    order: {
      type: Object,
      required: true
    },
  }
}
</script>
<style lang="scss" scoped>
.order-actions {
  a > i {
    color: #2B3442 !important;
    max-height: 42px;
  }
}
</style>
