<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Verejný obstarávateľ
            </h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-4">
                  <b>Názov</b>
                </div>
                <div class="col-md-8">
                  <b>{{ order.organization.name }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b>IČO</b>
                </div>
                <div class="col-md-8">
                  {{ order.organization.ico }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <b>Adresa</b>
                </div>
                <div class="col-md-8">
                  {{ order.organization.address }} {{ order.organization.address2 }}<br>
                  {{ order.organization.zip }} {{ order.organization.city }}<br>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zodpovedná osoba</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.title1 }} {{ order.user.firstname }} {{ order.user.lastname }} {{ order.user.title2 }}<br>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>E-mail</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.email }}<br>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefónne číslo</b>
                </div>
                <div class="col-md-7">
                  {{ order.user.phone }}<br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <order-overview-charts :order="order" />
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Informácie o zákazke
            </h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Názov</b>
                </div>
                <div class="col-md-7">
                  <b>{{ order.name }}</b>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Číslo zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.identifier }}
                </div>
              </div>
              <div
                v-if="order.announcementUrl"
                class="row"
              >
                <div class="col-md-5">
                  <b>URL v tenderia</b>
                </div>
                <div class="col-md-7">
                  <a
                    :href="order.announcementUrl"
                    target="_blank"
                  >
                    {{ order.announcementUrl }}
                  </a>
                </div>
              </div>
              <div
                v-if="order.journal"
                class="row"
              >
                <div class="col-md-5">
                  <b>Číslo vestníka</b>
                </div>
                <div class="col-md-7">
                  {{ order.journal }}
                </div>
              </div>
              <div
                v-if="order.announcement"
                class="row"
              >
                <div class="col-md-5">
                  <b>Číslo oznámenia/výzvy</b>
                </div>
                <div class="col-md-7">
                  {{ order.announcement }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Opis predmetu zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ order.description }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Podmienky účasti</b>
                </div>
                <div class="col-md-7">
                  {{ order.participationConditions }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Druh zákazky</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatOrderSubject(order.subjectType) }}
                </div>
              </div>
              <div
                v-if="order.mainDeliveryPlace"
                class="row"
              >
                <div class="col-md-5">
                  <b>Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác</b>
                </div>
                <div class="col-md-7">
                  {{ order.mainDeliveryPlace }}
                </div>
              </div>
              <div
                v-if="order.cpvCodeText"
                class="row"
              >
                <div class="col-md-5">
                  <b>Hlavný CPV kód</b>
                </div>
                <div class="col-md-7">
                  {{ order.cpvCodeText.texts.SK }} ({{ order.cpvCodeText.code }})
                </div>
              </div>
              <div
                v-if="order.cpvCodesText && order.cpvCodesText.length > 0"
                class="row"
              >
                <div class="col-md-5">
                  <b>Doplnkové CPV kódy</b>
                </div>
                <div class="col-md-7">
                  <template v-for="cpvCode in order.cpvCodesText">
                    <span :key="cpvCode.code">
                      {{ cpvCode.texts.SK }} ({{ cpvCode.code }})<br>
                    </span>
                  </template>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav</b>
                </div>
                <div class="col-md-7">
                  <order-state
                    :state="order.state"
                    display="text"
                  />
                </div>
              </div>
              <div
                v-if="order.estimatedPrice != '0'"
                class="row"
              >
                <div class="col-md-5">
                  <b>Predpokladaná hodnota v &euro; bez DPH</b>
                </div>
                <div class="col-md-7">
                  {{ $format.price(order.estimatedPrice) }}
                </div>
              </div>
              <div
                v-if="order.fundingByEu"
                class="row"
              >
                <div class="col-md-5">
                  <b>Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatYesNo(order.fundingByEu) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum vytvorenia</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDate(order.createdAt) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum aktualizácie</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDate(order.updatedAt) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum vyhlásenia súťaže</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDate(order.journalAnnouncementDate) }}
                </div>
              </div>
              <div
                v-if="order.bidsLimitTo"
                class="row"
              >
                <div class="col-md-5">
                  <b>Koniec lehoty na predkladanie ponúk</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDateTime(order.bidsLimitTo) }}
                </div>
              </div>
              <div
                v-if="!order.divided && order.bidsOpenAt"
                class="row"
              >
                <div class="col-md-5">
                  <b>
                    Dátum otvárania ponúk <span v-if="order.procedureType === 'tste'">
                      (ostatené)
                    </span>
                  </b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDateTime(order.bidsOpenAt) }}
                </div>
              </div>
              <div
                v-if="!order.divided && order.bidsOpenAt2"
                class="row"
              >
                <div class="col-md-5">
                  <b>Dátum otvárania ponúk (kritériá)</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDateTime(order.bidsOpenAt2) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Postup</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatProcedureType(order) }}
                </div>
              </div>
              <div
                v-if="!order.divided"
                class="row"
              >
                <div class="col-md-5">
                  <b>Aukcia</b>
                </div>
                <div class="col-md-7">
                  {{ order.auction ? 'Áno' : 'Nie' }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zákazka sa delí na časti</b>
                </div>
                <div class="col-md-7">
                  {{ order.divided ? 'Áno' : 'Nie' }}
                </div>
              </div>
              <template v-if="order.divided">
                <div class="row">
                  <div class="col-md-5">
                    <b>Počet častí</b>
                  </div>
                  <div class="col-md-7">
                    {{ order.parts }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b>Ponuky možno predložiť na</b>
                  </div>
                  <div class="col-md-7">
                    {{ $format.formatPartsBidding(order.partDetails.partsBidding) }}
                  </div>
                </div>
                <div
                  v-if="order.partDetails.partsBidding === 'several'"
                  class="row"
                >
                  <div class="col-md-5">
                    <b>Maximálny počet častí na ktoré môže uchádzač vložiť ponuku</b>
                  </div>
                  <div class="col-md-7">
                    {{ order.partDetails.maxPartsBidding }}
                  </div>
                </div>
                <div
                  v-if="order.type === 'nz' && order.partDetails.partsBidding === 'several'"
                  class="row"
                >
                  <div class="col-md-5">
                    <b>Maximálny počet častí, ktoré možno zadať jednému uchádzačovi</b>
                  </div>
                  <div class="col-md-7">
                    {{ order.partDetails.maxPartsAssign }}
                  </div>
                </div>
                <div
                  v-if="order.type === 'nz' && order.partDetails.partsBidding !== 'one'"
                  class="row"
                >
                  <div class="col-md-5">
                    <b>Verejný obstarávateľ si vyhradzuje právo prideliť zákazky kombináciou týchto častí alebo skupín častí</b>
                  </div>
                  <div class="col-md-7">
                    {{ order.partDetails.combinationAssign ? 'Áno' : 'Nie' }}
                  </div>
                </div>
                <div
                  v-if="order.partDetails.combinationAssign"
                  class="row"
                >
                  <div class="col-md-5">
                    <b>Ktoré časti alebo skupiny častí sa dajú kombinovať</b>
                  </div>
                  <div class="col-md-7">
                    {{ order.partDetails.combinationInfo }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <parts-list
      v-if="order.divided"
      :order="order"
    />
    <div class="row">
      <div class="col-md-12">
        <user-documents-table :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderState from '@/components/OrderState.vue'
import PartsList from '@/components/order/common/components/PartsList.vue'
import UserDocumentsTable from '@/components/order/common/components/UserDocumentsTable.vue'
import OrderOverviewCharts from '@/components/order/common/components/OrderOverviewCharts.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'UserOverviewSsseDivided',
  components: { OrderState, PartsList, UserDocumentsTable, OrderOverviewCharts },
  data () {
    return {
      orderStore: useOrderStore()
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'date',
          label: 'Dátum'
        }
      ]
    }
  }
}
</script>
