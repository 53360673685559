import Swal from 'sweetalert2'
import { watch } from 'vue'
import PurchaseModal from '@/components/PurchaseModal.vue'
let FileSaver = require('file-saver')

const b64EncodeUnicode = (str) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode('0x' + p1),
    ),
  )
}
export default {
  methods: {
    YesNoAlert (settings) {
      let defaultSettings = {
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'Nie',
        confirmButtonText: 'Áno'
      }
      return Swal.fire(Object.assign(defaultSettings, settings))
    },
    PopupAlert (settings) {
      let defaultSettings = {
        position: 'top-end',
        icon: 'success',
        toast: true,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
          timerProgressBar: 'bg-primary'
        },
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer
          toast.onmouseleave = Swal.resumeTimer

        }
      }
      Swal.fire(Object.assign(defaultSettings, settings))
    },
    ShowLoading (settings) {
      let defaultSettings = {
        title: 'Načítavam',
        showConfirmButton: false,
        customClass: {
          loader: 'text-primary font-size-25'
        },
        willOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick: () => !Swal.isLoading()
      }
      Swal.fire(Object.assign(defaultSettings, settings))
    },
    CloseLoading () {
      Swal.close()
    },
    BasicAlert (settings) {
      return Swal.fire(settings)
    },
    OkAlert (settings)
    {
      let defaultSettings = {
        icon: 'success'
      }
      return this.BasicAlert(Object.assign(defaultSettings, settings))
    },
    ErrorAlert (settings, error)
    {
      const e = error?.response?.data?.error
      const statusCode = error?.statusCode || error?.response?.status || 0
      const display = e?.display || error?.display
      if (statusCode === 402)
      { // premium pack needed
        const defaultSettings = { text: display || 'Pre túto operáciu sa vyžadujú platené prémiové služby' }
        return this.PremiumAlert(defaultSettings)
      }
      else if (display)
      { // custom message from response
        const defaultSettings = { icon: 'error', title: 'Chyba', text: display }
        return this.BasicAlert(defaultSettings)
      }
      else
      { // default message
        const defaultSettings = { icon: 'error' }
        return this.BasicAlert(Object.assign(defaultSettings, settings))
      }
    },
    ErrorAlertReport (settings, exception, error) {
      let defaultSettings = {}
      if (error && error.display) {
        defaultSettings = {
          type: 'error',
          confirmButtonText: 'Zavrieť',
          cancelButtonText: 'Stiahnuť log',
          showCancelButton: true,
          title: 'Chyba',
          text: error.display
        }
        return this.BasicAlert(defaultSettings)
      } else {
        defaultSettings = {
          type: 'error',
          confirmButtonText: 'Zavrieť',
          cancelButtonText: 'Stiahnuť log',
          showCancelButton: true,
          title: 'Chyba',
          text: 'Chcete odoslať hlásenie ?'
        }
      }
      console.log(JSON.stringify(exception))
      const encodedString = b64EncodeUnicode(JSON.stringify(exception, Object.getOwnPropertyNames(exception)))
      let blob = new Blob([encodedString], { type: 'text/plain;charset=utf-8' })
      this.YesNoAlert(Object.assign(defaultSettings, settings)).then((result) => { if (!result.value) { FileSaver.saveAs(blob, 'report.log') } })
    },
    InfoAlert (settings) {
      let defaultSettings = {
        icon: 'info'
      }
      return this.BasicAlert(Object.assign(defaultSettings, settings))
    },
    PremiumAlert (settings)
    {
      this.$emit('vuedals:new',
        {
          name: 'purchase-modal',
          closeOnBackdrop: false,
          dismissable: true,
          title: settings.title || '',
          component: PurchaseModal,
          props: { text: settings.text },
          onDismiss: async () => { }
        })
    },
    UploadProgressAlert(settings, statsStore) {
      let defaultSettings = {
        html: `
        <p>Celkovo <span id="currentIndex">0</span> z <span id="total">0</span></p>
        <div class="progress" style="height: 16px;">
          <div
            class="progress-bar bg-info"
            id="totalProgressBar"
            role="progressbar"
            style="width: 0%;"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span></span>
          </div>
        </div>
        <p>Nahrávam <span id="currentName"></span></p>
        <div class="progress" style="height: 16px;">
          <div
            class="progress-bar bg-info"
            id="currentProgressBar"
            role="progressbar"
            style="width: 0%;"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span></span>
          </div>
        </div>
      `,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
        // Watch for changes in the status within the Pinia store
          const uploadStatus = statsStore.uploadStatus
          watch(
            () => uploadStatus,
            (status) => {
              const totalPercent = Math.round(((status.currentIndex - 1) + (status.currentPercent / 100)) / status.total * 100)
              // Update the HTML elements with the new status
              let el = document.getElementById('currentIndex')
              if (el) el.innerText = status.currentIndex
              el = document.getElementById('total')
              if (el) el.innerText = status.total
              el = document.getElementById('currentName')
              if (el) el.innerText = status.currentName
              el = document.getElementById('totalProgressBar')
              if (el) el.style.width = `${totalPercent}%`
              if (el) el.setAttribute('aria-valuenow', `${totalPercent}`)
              if (el) el.innerText = `${totalPercent}%`
              el = document.getElementById('currentProgressBar')
              if (el) el.style.width = `${status.currentPercent}%`
              if (el) el.setAttribute('aria-valuenow', `${status.currentPercent}`)
              if (el) el.innerText = `${status.currentPercent}%`

              // Close the alert if the upload is complete
              if (status.currentIndex > status.total) {
                Swal.close()
              }
            },
            { immediate: true, deep: true }
          )
        }
      }

      // Show the SweetAlert popup with the default settings
      return Swal.fire(Object.assign(defaultSettings, settings))
    }
  }
}
