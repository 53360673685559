import { defineStore } from 'pinia'
//import { TenderiaHttp as http } from '@/services/TenderiaHttp.js'

export const useGlobalStore = defineStore('global',
  {
    state: () => ({
      user: null,
      userOrganization: null,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      backendHost: process.env.VUE_APP_BACKEND_HOST,
      env: process.env.NODE_ENV,
    }),
    actions: {
      setUser(user)
      {
        this.user = user
      },
      setUserOrganization(org)
      {
        this.userOrganization = org
      },
    }
  }
)
