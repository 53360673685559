<template>
  <div>
    <!-- completed -->
    <div v-if="olinkDocument">
      <div class="card-block">
        <div class="column">
          <div class="col-md-12 text-center">
            <div class="card-body">
              <p>Súťažné podklady sú zverejnené</p>

              <div class="d-flex justify-content-center">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Button group"
                >
                  <a
                    :href="olinkDocument.fileUrl"
                    target="_blank"
                    class="btn btn-primary mx-1"
                  >
                    <i class="icon-download" /> Stiahnuť
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- error -->
    <div v-else-if="isError">
      <div class="card-block my-5">
        <div class="column">
          <div class="col-md-12 text-center">
            <!--<div><i class="icon-warning" style="font-size: 4rem; color: #500"></i></div>-->
            <h4 style="color: #300;">
              Nepodarilo sa dokončiť operáciu
            </h4>
            <div style="color: #500">
              {{ progressText }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <div v-else>
      <div class="card-block my-5">
        <div class="column">
          <div class="col-md-12 text-center">
            <div>{{ progressText }}</div>
            <div
              v-if="isLoading"
              class="progress"
              style="height: 8px;"
            >
              <div
                class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                role="progressbar"
                :style="{ width: progressValue + '%' }"
                :aria-valuenow="progressValue"
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default
{
  name: 'OlinkModal',
  data ()
  {
    return {
      isLoading: false,
      isError: false,
      isCompleted: false,
      olinkDocument: null,
      progressJob: null,
      progressValue: 0,
      progressText: 'Čakajte prosím...'
    }
  },
  beforeDestroy ()
  {
    this.stopCheckingProgress()
  },
  mounted ()
  {
    this.executeAction('get-olink')
  },
  methods:
  {
    showLoading ()
    {
      $('.vuedal').LoadingOverlay('show')
    },
    hideLoading ()
    {
      $('.vuedal').LoadingOverlay('hide')
    },
    close ()
    {
      this.$emit('vuedals:close', null)
    },
    async executeAction (act)
    {
      this.isError = false
      this.isCompleted = false
      this.showLoading()
      try
      {
        const params = { order: this.$route.params.orderId, action: act }
        const { data } = await this.$http.post('/order/service/execute', params)
        this.hideLoading()
        if (data.jobId)
        {
          this.olinkDocument = null
          this.progressJob = data.jobId
          if (this.progressJob) this.startCheckingProgress()
        }
        else if (data.documentId)
        {
          this.isCompleted = true
          this.olinkDocument = data
        }
        else
        {
          this.executeAction('publish-olink')
        }
      }
      catch (error)
      {
        this.hideLoading()
        this.showError('Nepodarilo sa načítať dáta...')
      }
    },
    startCheckingProgress ()
    {
      if (this.isLoading) return
      this.isLoading = true
      this.checkProgress()
    },
    async checkProgress ()
    {
      try
      {
        const params = { order: this.$route.params.orderId, job: this.progressJob }
        const { data } = await this.$http.post('/order/service/status', params)
        const isRunning = data.running
        const progress = data.progress
        const details = data.details
        if (progress === -1) this.showError()
        this.progressValue = progress
        this.progressText = details
        if (isRunning) setTimeout(() => { this.checkProgress() }, 2500)
        else this.stopCheckingProgress()
        if (this.progressValue === 100) this.executeAction('get-olink')
      }
      catch (error)
      {
        this.showError('Nepodarilo sa načítať dáta zo systému...')
      }
    },
    stopCheckingProgress ()
    {
      this.isLoading = false
      if (this.progressValue !== 100) this.showError('Chyba pri spracovaní požiadavky')
    },
    showError (msg)
    {
      this.isLoading = false
      this.isError = true
      if (msg) this.progressText = msg
      this.stopCheckingProgress()
    }
  }
}
</script>
