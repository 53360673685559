<script setup>

// PurchaseModal

import { ref, reactive, computed, onMounted, onUnmounted } from 'vue'
import { TenderiaHttp as $http } from '@/services/TenderiaHttp'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'

const props = defineProps({ text: String, organization: String, viewMode: String })
const $emit = defineEmits(['vuedals:close'])
const globals = useGlobalStore()
const statsStore = useStatsStore()
const isError = ref(false)
const isLoading = ref(false)
const mode = ref('loading')
const packages = ref([])
const selectedId = ref(null)
const selectedPackage = computed(() =>(packages.value?.packages || []).find(p => p.id === selectedId.value))
const form = reactive({ consent: false, autorenew: false, email: '' })
const rules = { email: { required, email } }
const vuelidate = useVuelidate(rules, form, { $lazy: true, $autoDirty: true })
const v$ = reactive(vuelidate.value)

const changeMode = async function(newMode)
{
  if (newMode === 'list')  await loadPackages()
  mode.value = newMode
}

const loadPackages = async function()
{
  if (!packages.value || packages.value.length === 0)
  {
    isLoading.value = true
    const params = {}
    const { data } = await $http.post('/organization/package/list', params).catch(_err => ({ data: null }))
    isLoading.value = false
    packages.value = data || []
  }
}

const orderPackage = async function(packageId)
{
  selectedId.value = packageId
  await changeMode('checkout')
}

const cancelSubscription = async function()
{
  const paymentId = statsStore.premiumPackage?.state?.payment
  isLoading.value = true
  const params = { payment: paymentId, action: 'unsubscribe' }
  const { data } = await $http.post('/organization/package/confirm', params).catch(_err => ({ data: null }))
  if (!data)  isError.value = true
  isLoading.value = false
  if (isError.value)
  {
    await changeMode('cancel')
  }
  else
  {
    await changeMode('unsubscribed')
    await statsStore.updatePremiumPackageState()
  }
}

const checkoutPayment = async function(packageId, email, autorenew)
{
  const correct = await v$.$validate()
  if (correct)
  {
    const subscription = selectedPackage.value?.settings?.autorenew ? autorenew : false
    isLoading.value = true
    const params = { package: packageId, email: email, autorenew: subscription }
    const { data } = await $http.post('/organization/package/checkout', params).catch(_err => ({ data: null }))
    if (!data)  isError.value = true
    isLoading.value = false
    const paymentId = data?.payment
    await confirmPayment(paymentId)
  }
}

const confirmPayment = async function(paymentId)
{
  isLoading.value = true
  const params = { payment: paymentId, action: 'purchase' }
  const { data } = await $http.post('/organization/package/confirm', params).catch(_err => ({ data: null }))
  if (!data)  isError.value = true
  isLoading.value = false
  if (isError.value)
  {
    await changeMode('checkout')
  }
  else
  {
    await changeMode('confirm')
    await statsStore.updatePremiumPackageState()
  }
}

const closeModal = function()
{
  $emit('vuedals:close', null)
}

function formatPrice(value, currency)
{
  const formatter = new Intl.NumberFormat('sk-SK',
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  return formatter.format(value)
}

onMounted(async () =>
{
  form.consent = false
  form.autorenew = true
  form.email = ''
  changeMode(props.viewMode || 'list')
})

onUnmounted(async () =>
{
})

</script>

<template>
  <div>
    <transition name="fade" mode="out-in">
      <!-- loading -->
      <div v-if="isLoading" key="loading">
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <div class="spinner-border text-info" role="status"><span class="sr-only">Prosím čakajte...</span></div>
            </div>
          </div>
        </div>
      </div>

      <!-- error -->
      <div v-else-if="isError" key="error">
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <h4 style="color: #300;">
                Chyba pri spracovaní požiadavky
              </h4>
              <div style="color: #500">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- message dialog -->
      <div v-else-if="mode === 'message'" key="message">
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <i class="icon-warning font-size-60"></i>
            </div>
            <div class="col-md-12 text-center">
              <p>{{ props.text }}</p>
            </div>
            <div class="col-md-12 text-center d-flex justify-content-center">
              <button type="button" class="btn btn-lg btn-danger" @click="mode = 'list'">OBJEDNAŤ</button>
              <button type="button" class="btn btn-lg btn-secondary" @click="closeModal">ZAVRIEŤ</button>
            </div>
          </div>
        </div>
      </div>

      <!-- package options -->
      <div v-else-if="mode === 'list'" key="list">
        <div class="card-body">
          <div class="text-center">
            <div v-if="(packages.packages || []).length > 0">
              <div class="row justify-content-center mb-2 p-2 text-semibold font-size-15 text-info">
                <div class="col">{{ props.text }}</div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-6" v-for="item in (packages.packages || [])" :key="item.id">
                  <button class="button-purchase card box-shadow p-0 w-100" :style="{background: item.style || '#F8F8F8' }" @click="orderPackage(item.id)">
                    <div class="card-header p-1 w-100 no-border">
                      <h5 class="my-0 font-weight-normal"><strong :style="{color: item.color || '#000' }">{{ item.title }}</strong></h5>
                    </div>
                    <div class="card-body w-100 px-4 py-1">
                      <small :style="{color: item.color || '#000' }">{{ item.description }}</small>
                      <!--<h2 class="mt-2">
                        <strong :style="{color: item.color || '#000' }">
                          <s v-if="item.oldPrice" class="bg-danger text-white px-2">{{ formatPrice(item.oldPrice, item.currency) }}</s>
                          <b v-else>&nbsp;</b>
                        </strong>
                      </h2>-->
                      <h1 class="mt-1">
                        <span :style="{color: item.color || '#000' }" style="font-size: 67%;">
                          <s v-if="item.oldPrice" class="text-danger pr-2">{{ formatPrice(item.oldPrice, item.currency) }}</s>
                          <b v-else>&nbsp;</b>
                        </span>
                        <strong :style="{color: item.color || '#000' }">{{ formatPrice(item.price, item.currency) }}</strong>
                      </h1>
                      <!--<ul class="list-unstyled mt-3 mb-4">
                        <li v-for="short in (item.shorts || [])" :key="short">{{ short }}</li>
                      </ul>-->
                      <!--<button type="button" class="btn btn-lg btn-primary" style="min-width: 0" @click="orderPackage(item.id)">OBJEDNAŤ</button>-->
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="card-block my-5">
                <div class="column">
                  <div class="col-md-12 text-center">
                    <h5 style="color: #300;">
                      Aktuálne nie sú dostupné žiadne položky na zakúpenie
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- checkout -->
      <div v-else-if="mode === 'checkout' && selectedPackage" key="checkout">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="p-3 p-md-4">
              <!--<h6 class="text-bold mb-1" id="head1" :style="{color: selectedPackage.color || '#000' }">{{ selectedPackage.title }}</h6>-->
              <div class="text-secondary">{{ globals.userOrganization.name }}</div>
              <div>{{ selectedPackage.description }}</div>
              <h2 class="text-bold mb-3 text-primary">{{ formatPrice(selectedPackage.price, selectedPackage.currency) }}</h2>
              <div class="mb-3">
                <label class="form-check-label" for="app-modal-purchase-email">Fakturačný email</label><br>
                <input v-model="form.email" type="email" class="form-control" id="app-modal-purchase-email" placeholder="Uveďte prosím kontaktný email pre zasielanie faktúr">
                <div v-if="v$.email.$error" class="text-danger">{{ v$.email.$errors[0].$uid == 'email-email' ? 'Nesprávny formát emailovej adresy' : v$.email.$errors[0].$uid == 'email-required' ? 'Toto pole je povinné' : 'Nesprávny email' }}</div>
              </div>
              <div class="form-check mb-1" v-if="selectedPackage?.settings?.autorenew">
                <input v-model="form.autorenew" type="checkbox" class="form-check-input" id="app-modal-purchase-autorenew">
                <label class="form-check-label" for="app-modal-purchase-autorenew">Automaticky predlžovať platnosť prémiových služieb po skončení platnosti</label>
              </div>
              <div class="form-check mb-1 d-flex align-items-center">
                <input v-model="form.consent" type="checkbox" class="form-check-input" id="app-modal-purchase-consent" style="width: 1.1rem; height: 1.1rem;">
                <label class="form-check-label" for="app-modal-purchase-consent">Súhlasím s <a href="/vop_podnikatelia.pdf" target="_blank">obchodnými podmienkami</a> a záväzne objednávam prémiové služby</label>
              </div>
              <div class="d-flex mt-3">
                <button :disabled="!form.consent" class="btn btn-lg btn-success" @click="checkoutPayment(selectedPackage.id, form.email, form.autorenew)">OBJEDNAŤ</button>
                <button type="button" class="btn btn-lg btn-light" @click="mode = 'list'">NÁVRAT</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- confirm payment -->
      <div v-else-if="mode === 'confirm' && selectedPackage" key="confirm">
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <h5 style="color: #300;">
                Objednávka prebehla úspešne. Balík prémiových služieb máte k dispozícii.
              </h5>
              <div class="col-md-12 text-center d-flex justify-content-center">
                <button type="button" class="btn btn-lg btn-primary" @click="closeModal">POKRAČOVAŤ</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- cancel subscription -->
      <div v-else-if="mode === 'cancel'" key="cancel">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="p-3 p-md-4 mt-4">
              <h5 class="mb-3">Ste si istý že chcete zrušiť aktívne prémiové služby?</h5>
              <div class="d-flex mt-3">
                <button type="button" class="btn btn-lg btn-danger" @click="cancelSubscription">ÁNO</button>
                <button type="button" class="btn btn-lg btn-dark" @click="closeModal">NIE</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- cancelled subscription -->
      <div v-else-if="mode === 'unsubscribed'" key="unsubscribed">
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <h5 style="color: #300;">Predplatné balíka prémiových služieb bolo zrušené</h5>
              <div class="col-md-12 text-center d-flex justify-content-center">
                <button type="button" class="btn btn-lg btn-primary" @click="closeModal">POKRAČOVAŤ</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- fallback -->
      <div v-else key="fallback">
        <div class="card-block my-5">
          <div class="column">
            <div class="col-md-12 text-center">
              <div class="spinner-border text-info" role="status"><span class="sr-only">Prosím čakajte...</span></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
  .fade-enter-active, .fade-leave-active
  {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to
  {
    opacity: 0;
  }

  .button-purchase:hover
  {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: 0.3s;
  }

  .form-check input[type="checkbox"]
  {
    width: 1.1rem;
    height: 1.1rem;
  }

  .form-check label
  {
     margin-left: 0.5rem;
  }

</style>
