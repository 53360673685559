<template>
  <div class="tw-w-full">
    <user-offers-opening-widget :order="order" :part="part" />
    <user-offers-evaluation-widget
      v-if="evaluationEnabled"
      :order="order"
      :part="part"
    />
  </div>
</template>

<script>
import UserOffersOpeningWidget from '@/components/order/common/components/UserOffersOpeningWidget.vue'
import UserOffersEvaluationWidget from '@/components/order/common/components/UserOffersEvaluationWidget.vue'
import { useStatsStore } from '@/storePinia/statsStore'

export default {
  name: 'UserOffersParts',
  components: { UserOffersOpeningWidget, UserOffersEvaluationWidget },
  props: {
    part: {
      type: Object,
      default: null
    },
    order: Object,
    round: Number
  },
  data () {
    return {
      statsStore: useStatsStore(),
      openingDateStr: '',
      openingDate: null,
      dateNotBefore: process.env.VUE_APP_ENBL_OFFER_EVAL_FROM,
    }
  },
  computed: {
    serverTime () {
      return new Date(this.statsStore.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    evaluationEnabled () {
      if (this.part) {
        return this.openingEnabled && this.part.biddingMethod !== 'costEffectiveness' && !this.part.divided && this.orderCreatedAt > this.evaluationEnabledFrom
      } else {
        return this.openingEnabled && this.order.biddingMethod !== 'costEffectiveness' && !this.order.divided && this.orderCreatedAt > this.evaluationEnabledFrom
      }
    },
    orderCreatedAt () {
      if (this.part) {
        return new Date(this.part.createdAt)
      } else {
        return new Date(this.order.createdAt)
      }
    },
    evaluationEnabledFrom () {
      return new Date(this.dateNotBefore)
    }
  },
  created () {
    if (this.part) {
      this.openingDateStr = this.round === 1 ? this.part.bidsOpenAt : this.part.bidsOpenAt2
      this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
    } else {
      this.openingDateStr = this.round === 1 ? this.order.bidsOpenAt : this.order.bidsOpenAt2
      this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
    }
  }
}
</script>
