<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <template v-if="order.divided">
          <organizations-list
            v-for="part in order._parts"
            :key="part.id"
            :organizations="part.interested"
            :order="part"
            :title="`Zoznam záujemcov - ${part.name}`"
          />
        </template>
        <template v-else>
          <organizations-list
            :organizations="order.interested"
            :order="order"
            title="Zoznam záujemcov"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationsList from '@/components/order/common/components/OrganizationsList.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminInterested',
  components: { OrganizationsList },
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  }
}
</script>
