<template>
  <div
    class="container-fluid"
    id="settings-account-edit"
  >
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          {{formMode}}
        </h4>
      </div>
      <div class="card-block">
        <form
          novalidate="novalidate"
          v-if="this.user.mode !== 'edit' || this.dataLoaded"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label
                  class="text-bold text-dark"
                  for="email"
                >
                  Email <small>*</small>
                </label>
                <input
                  type="text"
                  v-model.lazy="user.email"
                  class="form-control"
                  placeholder="Email"
                  :class="{ error: v$.user.email.$error }"
                  :disabled="this.user.mode === 'edit'"
                />
                <label
                  v-if="v$.user.email.$error"
                  class="error"
                >
                  {{ vuelidateErrors.email }}
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label
                  class="text-bold text-dark"
                  for="phone"
                >
                  Role v organizácii <small>*</small>
                </label>
                <roles-select
                  v-model="user.roles"
                  :value="user.roles"
                  :error="v$.user.roles.$error"
                />
                <label
                  v-if="v$.user.roles.$error"
                  class="error"
                >
                  {{ vuelidateErrors.roles }}
                </label>
              </div>
            </div>
          </div>
          <template v-if="user.mode === 'invite'">
            <info-box
              icon="icon-info"
              color="primary"
              text="Používateľ s týmto emailom už v systéme existuje. Pozvite používateľa do organizácie."
            />
          </template>
          <template v-if="!editOnlyRoles">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="firstname"
                    class="text-bold text-dark"
                  >
                    Meno <small>*</small>
                  </label>
                  <input
                    type="text"
                    v-model="user.firstname"
                    class="form-control"
                    placeholder="Meno"
                    :class="{ error: v$.user.firstname.$error }"
                  />
                  <label
                    v-if="v$.user.firstname.$error"
                    class="error"
                  >
                    {{  vuelidateErrors.firstname }}
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="lastname"
                    class="text-bold text-dark"
                  >
                    Priezvisko <small>*</small>
                  </label>
                  <input
                    type="text"
                    v-model="user.lastname"
                    class="form-control"
                    placeholder="Priezvisko"
                    :class="{ error: v$.user.lastname.$error }"
                  />
                  <label
                    v-if="v$.user.lastname.$error"
                    class="error"
                  >
                    {{  vuelidateErrors.lastname }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    class="text-bold text-dark"
                    for="title1"
                  >
                    Titul pred menom
                  </label>
                  <input
                    type="text"
                    v-model=" user.title1"
                    class="form-control"
                    placeholder="Titul"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    class="text-bold text-dark"
                    for="title2"
                  >
                    Titul za menom
                  </label>
                  <input
                    type="text"
                    v-model=" user.title2"
                    class="form-control"
                    placeholder="Titul"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    class="text-bold text-dark"
                    for="phone"
                  >
                    Teleónne čislo <small>*</small>
                  </label>
                  <input
                    type="text"
                    v-model="user.phone"
                    class="form-control"
                    placeholder="Zadávajte v tvare +421 123 456 789"
                    :class="{ error: v$.user.phone.$error }"
                  />
                  <label
                    v-if="v$.user.phone.$error"
                    class="error"
                  >
                    {{  vuelidateErrors.phone }}
                  </label>
                </div>
              </div>
            </div>
          </template>
        </form>
        <div class="card-footer">
          <div class="row">
            <button
              class="btn btn-tenderia-grey-blue btn-inverse"
              @click.prevent="goBack"
            >
              <i class="icon-arrow-left"></i> Späť
            </button>
            <button
              class="btn btn-success"
              @click.prevent="save"
              :disabled="editOnlyRoles ? v$.user.roles.$error : v$.user.$errors.length > 0"
            >
              <i class="icon-save"></i> Uložiť
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RolesSelect from '@/components/forms/RolesSelect.vue'
import InfoBox from '@/components/ui/InfoBox.vue'
import { useVuelidate } from '@vuelidate/core'
import {
  useVuelidateValidationErrors,
  validateRequired,
  validatePhone,
  validateEmail,
  validateUniqueEmail
} from '@/components/validator'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserSettingsAddAccount',
  components: { RolesSelect, InfoBox },
  inject: ['blockNonPremiumUser'],
  setup () {
    const v$ = useVuelidate({
      $lazy: true,
      $autoDirty: true,
    })
    return { v$ }
  },
  validations () {
    return {
      user: {
        firstname: { validateRequired },
        lastname: { validateRequired },
        phone: { validateRequired, validatePhone },
        email: { validateEmail, validateUniqueEmail: validateUniqueEmail(this.user), validateRequired },
        roles: { validateRequired }
      },
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      userHomeOrganization: '',
      dataLoaded: false,
      user: {
        firstname: '',
        lastname: '',
        title1: '',
        title2: '',
        email: '',
        phone: '',
        roles: [],
        mode: 'create',
      },
    }
  },
  computed: {
    vuelidateErrors () {
      return useVuelidateValidationErrors(this.v$.$errors)
    },
    editOnlyRoles () {
      return this.user.mode === 'invite' || (this.user.mode === 'edit' && this.userHomeOrganization !== this.globals.userOrganization.id)
    },
    formMode () {
      if (this.user.mode === 'create') {
        return 'Vytvoriť nového používateľa'
      } else if (this.user.mode === 'invite') {
        return 'Pozvať nového používateľa'
      } else {
        return 'Upraviť používateľa'
      }
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'settingsAccounts' })
    },
    async save () {
      let isFormCorrect = false
      if (this.editOnlyRoles) {
        isFormCorrect = await this.v$.user.roles.$validate()
      } else {
        isFormCorrect = await this.v$.user.$validate()
      }
      if (!isFormCorrect) return
      if (await this.blockNonPremiumUser() === true) return
      this.ShowLoading()
      let userData = {
        organization: {
          id: this.globals.userOrganization.id
        },
        user: this.user,
        relation: {
          roles: this.user.roles
        }
      }
      if (this.$route.params.userid) {
        userData.user.id = this.$route.params.userid
      }
      try {
        if (this.user.mode === 'invite') {
          await this.$http.post('/organization/user/invite', userData)
        } else {
          await this.$http.post('/organization/user/set', userData)
        }
        let message = ''
        if (this.user.mode === 'create') {
          message = 'Nový používateľ bol vytvorený. Prihlasovacie údaje mu boli odoslané na zadaný e-mail.'
        } else if (this.user.mode === 'invite') {
          message = 'Nový používateľ bol pridaný.'
        } else {
          message = 'Používateľ bol upravený.'
        }
        this.CloseLoading()
        this.OkAlert({ title: 'Hotovo', text: message })
        this.goBack()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa uložiť informácie o používateľovi.' }, error)
      }
    }
  },
  async mounted () {
    if (!this.$route.params.userid) {
      return
    }
    this.user.mode = 'edit'
    this.ShowLoading()
    try {
      this.dataLoaded = false
      const { data } = await this.$http.post('/organization/user/get', {
        user: { id: this.$route.params.userid },
        organization: { id: this.globals.userOrganization.id }
      })
      this.user.firstname = data.firstname
      this.user.lastname = data.lastname
      this.user.title1 = data.title1
      this.user.title2 = data.title2
      this.user.email = data.email
      this.user.phone = data.phone
      this.user.roles = data.roles
      this.userHomeOrganization = data.homeOrg
      this.dataLoaded = true
      this.CloseLoading()
    } catch (error) {
      this.CloseLoading()
      this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o používateľovi.' }, error)
    }
  }
}
</script>
