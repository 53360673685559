<script setup>

// PurchaseWaitModal

import { ref, inject, onMounted, onUnmounted } from 'vue'

const $emit = defineEmits(['vuedals:close'])
const TEXT = inject('TEXT')
const waitPercent = ref(0)
const waitSecs = ref(5)
const waitTimer = ref(null)
const waitCompleted = ref(false)

const closeModal = function(action)
{
  $emit('vuedals:close', action)
}

const waitStep = function()
{
  waitTimer.value = null
  waitPercent.value += 20
  if (waitSecs.value > 0) waitSecs.value -= 1
  if (waitPercent.value < 100)  waitTimer.value = setTimeout(waitStep, 1000)
  else waitCompleted.value = true
}

onMounted(async () =>
{
  waitSecs.value = 6
  waitPercent.value = -20
  waitCompleted.value = false
  waitTimer.value = null
  waitStep()
})

onUnmounted(async () =>
{
  if (waitTimer.value)  clearTimeout(waitTimer.value)
})

</script>

<template>
  <div>
    <transition name="fade" mode="out-in">
      <!-- wait counter -->
      <div key="counter">
        <div class="card-block mt-5 mb-4">
          <div class="column">
            <div class="col-md-12 text-center">
              <div class="row">
                <div class="col-md-12" v-if="!waitCompleted">
                  <div class="d-flex justify-content-center">
                    <h4 class="text-semibold text-danger">Prosím čakajte ({{waitSecs}} s)</h4>
                  </div>
                  <!--<div>
                    <div class="progress" style="height: 0.5rem;">
                      <div class="progress-bar progress-bar-striped progress-bar-animated bg-secondary" role="progressbar" :style="{ width: waitPercent + '%' }" :aria-valuenow="waitPercent" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>-->
                </div>
                <div class="col-md-12">
                  <h5 class="my-4" v-html="TEXT('STR_PREMIUM_PROMO')"></h5>
                </div>
                <div class="mt-2 col-md-12 text-center d-flex justify-content-center">
                  <button type="button" class="btn btn-lg btn-success" @click="closeModal(true)"><i class="icon-check"></i>MÁM ZÁUJEM</button>
                  <button :disabled="!waitCompleted" type="button" class="btn btn-lg btn-dark" @click="closeModal(false)">POKRAČOVAŤ BEZPLATNE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
