<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          {{ $route.params.id ? 'Upraviť dodávateľa' : 'Pridať dodávateľa' }}
        </h4>
        <div class="actions">
          <v-badge
            v-if="form.manualMode"
            color="primary"
            glow
          >
            <small>Manuálne zadané údaje</small>
          </v-badge>
        </div>
      </div>
      <div class="card-block">
        <div id="new-supplier-form">
          <div class="row">
            <div class="col-md-8 form-group">
              <label
                class="control-label"
                form="name"
              >
                Názov spoločnosti<small>*</small>
              </label>
              <rpo-search
                placeholder="Názov"
                :value="form.manualMode ? supplier.name : form.organization"
                :manual-mode="form.manualMode"
                :error="v$.supplier.name.$error"
                @manualModeChange="onManualModeChange"
                @input="updateOrganizationName"
              >
                <slot slot="prepend">
                  <span />
                </slot>
              </rpo-search>
              <label
                v-if="v$.supplier.name.$error"
                class="error"
              >
                {{ vuelidateErrors.name }}
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label
                class="control-label"
                form="ic"
              >
                IČO <small>*</small>
              </label>
              <rpo-search
                placeholder="IČO"
                :value="form.manualMode ? supplier.ico : form.organization"
                :error="v$.supplier.ico.$error"
                item-text="cin"
                :manual-mode="form.manualMode"
                @manualModeChange="onManualModeChange"
                @input="updateOrganizationCin"
              >
                <slot slot="prepend">
                  <span />
                </slot>
              </rpo-search>
              <label
                v-if="v$.supplier.ico.$error"
                class="error"
              >
                {{ vuelidateErrors.ico }}
              </label>
            </div>

            <div class="col-md-2 form-group">
              <label class="text-dark text-bold">
                IČ DPH
              </label>
              <input
                v-model="supplier.icdph"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.icdph.$error }"
              >
              <label
                v-if="v$.supplier.icdph.$error"
                class="error"
              >
                {{ vuelidateErrors.icdph }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 form-group">
              <label class="text-dark text-bold">
                Ulica <small>*</small>
              </label>
              <input
                v-model="supplier.address"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.address.$error }"
              >
              <label
                v-if="v$.supplier.address.$error"
                class="error"
              >
                {{ vuelidateErrors.address }}
              </label>
            </div>
            <div class="col-md-3 form-group">
              <label class="text-dark text-bold">
                Číslo domu <small>*</small>
              </label>
              <input
                v-model="supplier.address2"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.address2.$error }"
              >
              <label
                v-if="v$.supplier.address2.$error"
                class="error"
              >
                {{ vuelidateErrors.address2 }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 form-group">
              <label class="text-dark text-bold">
                PSČ <small>*</small>
              </label>
              <input
                v-model="supplier.zip"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.zip.$error }"
              >
              <label
                v-if="v$.supplier.zip.$error"
                class="error"
              >
                {{ vuelidateErrors.zip }}
              </label>
            </div>
            <div class="col-md-5 form-group">
              <label class="text-dark text-bold">
                Mesto <small>*</small>
              </label>
              <input
                v-model="supplier.city"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.city.$error }"
              >
              <label
                v-if="v$.supplier.city.$error"
                class="error"
              >
                {{ vuelidateErrors.city }}
              </label>
            </div>
            <div class="col-md-4 form-group">
              <label class="text-dark text-bold">
                Krajina <small>*</small>
              </label>
              <country-select
                v-model="supplier.country"
                :value="supplier.country"
              />
              <label
                v-if="v$.supplier.country.$error"
                class="error"
              >
                {{ vuelidateErrors.country }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">
                Email <small>*</small>
              </label>
              <input
                v-model="supplier.email"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.email.$error }"
              >
              <label
                v-if="v$.supplier.email.$error"
                class="error"
              >
                {{ vuelidateErrors.email }}
              </label>
            </div>
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">
                Telefón
              </label>
              <input
                v-model="supplier.phone"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.phone.$error }"
              >
              <label
                v-if="v$.supplier.phone.$error"
                class="error"
              >
                {{ vuelidateErrors.phone }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="text-dark text-bold">
                Kontaktná osoba
              </label>
              <input
                v-model="supplier.contactPerson"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.contactPerson.$error }"
              >
              <label
                v-if="v$.supplier.contactPerson.$error"
                class="error"
              >
                {{ vuelidateErrors.contactPerson }}
              </label>
            </div>
          </div>
          <div
            v-for="(supplierContact, index) in supplier.contacts"
            :key="'suppContact'+index"
            class="row"
          >
            <div class="col-md-4 form-group">
              <label class="text-dark text-bold">
                Email <small>*</small>
              </label>
              <input
                v-model="supplierContact.email"
                type="text"
                class="form-control"
                :class="{ error: v$.supplier.contacts.$each?.$response?.$data[index].email.$error }"
              >
              <label
                v-if="v$.supplier.contacts.$each?.$response?.$data[index].email.$error"
                class="error"
              >
                {{ vuelidateErrors.contacts[index][0] }}
              </label>
            </div>
            <div class="col-md-4 form-group">
              <label class="text-dark text-bold">
                Telefón
              </label>
              <input
                v-model="supplierContact.phone"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-md-3 form-group">
              <label class="text-dark text-bold">
                Kontaktná osoba
              </label>
              <input
                v-model="supplierContact.name"
                type="text"
                class="form-control"
              >
            </div>
            <div class="row align-items-center no-gutters form-group">
              <a
                href=""
                class="text-danger font-size-27"
                title="Zmazať"
                @click.prevent="removeContactPerson(index)"
              >
                <i class="icon-delete" />
              </a>
            </div>
          </div>
          <div class="row no-gutters justify-content-end">
            <button
              class="btn btn-tenderia-blue"
              @click="addContactPerson"
            >
              <i class="icon-add font-size-14" /> Pridať kotaktnú osobu
            </button>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <button
            class="btn btn-tenderia-grey-blue btn-inverse"
            @click.prevent="goBack"
          >
            <i class="icon-arrow-left" /> Späť
          </button>
          <button
            class="btn btn-success"
            :disabled="v$.supplier.$errors.length > 0"
            @click="save"
          >
            <i class="icon-save" /> Uložiť
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from '@/components/forms/CountrySelect.vue'
import RpoSearch from '@/components/ui/RpoSearch.vue'
import VBadge from '@/components/ui/Badge.vue'
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import {
  useVuelidateValidationErrors,
  validateRequired,
} from '@/components/validator'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'SupplierForm',
  components: { CountrySelect, RpoSearch, VBadge },
  setup () {
    const v$ = useVuelidate({
      $lazy: true,
      $autoDirty: true,
    })
    return { v$ }
  },
  validations () {
    return {
      supplier: {
        name: { validateRequired },
        ico: { validateRequired },
        icdph: {},
        address: { validateRequired },
        address2: { validateRequired },
        city: { validateRequired },
        country: { validateRequired },
        zip: { validateRequired },
        email: { validateRequired },
        contactPerson: {},
        phone: {},
        contacts: {
          $each: helpers.forEach({
            email: { validateRequired },
          })
        }
      }
    }
  },
  data () {
    return {
      orderStore: useOrderStore(),
      form: {
        organization: {},
        manualMode: false
      },
      supplier: {
        id: '',
        name: '',
        ico: '',
        icdph: '',
        address: '',
        address2: '',
        city: '',
        country: 'SK', // default value
        zip: '',
        email: '',
        contactPerson: '',
        phone: '',
        contacts: []
      },
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    vuelidateErrors () {
      return useVuelidateValidationErrors(this.v$.$errors)
    },
  },
  watch: {
    'form.organization' (value) {
      if (value && value.cin && !this.form.manualMode) {
        this.supplier.address = value.address.street
        this.supplier.address2 = value.address.building_number
        this.supplier.city = value.address.municipality
        this.supplier.zip = value.address.postal_code?.replace(/\s+/g, '')
        this.supplier.country = value.address.country.toUpperCase()
        this.supplier.name = value.name
        this.supplier.ico = value.cin
      }
    }
  },
  async created () {
    if (!this.$route.params.id) {
      return
    }
    this.showLoading()

    try {
      if (this.order) {
        const supplier = this.order.suppliers.find(element => element._id === this.$route.params.id)
        Object.assign(this.supplier, supplier)
        this.$set(this.form.organization, 'name', supplier.name)
        this.$set(this.form.organization, 'cin', supplier.ico)
      } else {
        let { data } = await this.$http.post('/organization/supplier/get', { supplier: { id: this.$route.params.id } })
        Object.assign(this.supplier, data.supplier)
        this.$set(this.form.organization, 'name', data.supplier.name)
        this.$set(this.form.organization, 'cin', data.supplier.ico)
      }
      this.hideLoading()
    } catch (error) {
      this.hideLoading()
      this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o dodávateľovi.' }, error)
    }
  },
  methods: {
    updateOrganizationName (val) {
      if (this.form.manualMode) {
        this.supplier.name = val
      } else {
        this.form.organization = val
      }
    },
    updateOrganizationCin (val) {
      if (this.form.manualMode) {
        this.supplier.ico = val
      } else {
        this.form.organization = val
      }
    },
    onManualModeChange (val) {
      this.form.manualMode = val
      this.form.organization = null
      this.supplier.name = ''
      this.supplier.ico = ''
    },
    addContactPerson () {
      this.supplier.contacts.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    removeContactPerson (index) {
      this.supplier.contacts.splice(index, 1)
    },
    goBack () {
      if (this.orderStore.routePrefix) {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-suppliers` })
      } else {
        this.$router.push({ name: 'settingsSuppliers' })
      }
    },
    showLoading () {
      $('#new-supplier-form').LoadingOverlay('show')
    },
    hideLoading () {
      $('#new-supplier-form').LoadingOverlay('hide')
    },
    async createOrderSupplier (supplier) {
      this.showLoading()
      let data = {
        supplier: supplier
      }
      try {
        let { data: { organization } } = await this.$http.post('/organization/supplier/set', data)
        let formData = {
          order: {
            id: this.order.id
          },
          supplier: Object.assign({ organization: organization }, supplier)
        }
        await this.$http.post('/order/supplier/set', formData)
        await this.orderStore.getOrder(this.order.id)
        let message = `Dodávateľ  ${supplier.name} (${supplier.ico}) bol priradený do zákazky.`
        if (this.order.state === 'active') {
          message += ' Pozvánka bola automaticky odoslaná na kontaktný email dodávateľa.'
        } else {
          message += ' Pozvánka bude odoslaná na kontaktný email dodávateľa po spustení zákazky.'
        }
        this.OkAlert({ text: message })
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-suppliers` })
      } catch (error) {
        let message = 'Nastala neočakávaná chyba. Skúste to znovu.'
        console.log(error)
        if (error?.response?.data.error && error?.response?.data.error.code === 410) {
          message = 'Nie je možné pridávať dodávateľov do zákazky po lehote na predkladanie ponúk.'
        }
        this.ErrorAlert({ text: message }, error)
      }
      this.hideLoading()
    },
    async editOrderSupplier (supplier) {
      this.showLoading()
      let data = {
        order: {
          id: this.order.id
        },
        supplier: Object.assign({ organization: this.supplier.organization }, supplier)
      }
      try {
        await this.$http.post('/order/supplier/set', data)
        await this.orderStore.getOrder(this.order.id)
        this.OkAlert({ text: `Dodávateľ ${supplier.name} (${supplier.ico}) bol úspešne aktualizovaný.` })
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-suppliers` })
      } catch (error) {
        this.ErrorAlert({ text: 'Nastala neočakávaná chyba. Skúste to znovu.' }, error)
      }
      this.hideLoading()
    },
    async saveOrganizationSupplier (supplier) {
      this.showLoading()
      let data = {
        supplier: supplier
      }
      try {
        await this.$http.post('/organization/supplier/set', data)
        let operation = this.$route.params.id ? 'aktualizovaný' : 'pridaný'
        this.OkAlert({ text: `Dodávateľ ${supplier.name} (${supplier.ico}) bol úspešne ${operation}.` })
        this.$router.push({ name: 'settingsSuppliers' })
      } catch (error) {
        this.ErrorAlert({ text: 'Nastala neočakávaná chyba. Skúste to znovu.' }, error)
      }
      this.hideLoading()
    },
    async save () {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      try {
        if (this.order) {
          this.supplier.organization ? this.editOrderSupplier(this.supplier) : this.createOrderSupplier(this.supplier)
        } else {
          this.saveOrganizationSupplier(this.supplier)
        }
      } catch (error) {
        if (error.code && error.code === 422) {
          this.supplier.$validate()
        } else {
          this.ErrorAlert({ text: 'Nastala neočakávaná chyba. Skúste to znovu.' }, error)
          console.log(error)
        }
      }
    }
  }
}
</script>
