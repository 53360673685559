import axios from 'axios'
import router from '@/router'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'
import Alerts from '@/components/mixins/Alerts'

const TenderiaHttp = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_HOST,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

const token = localStorage.getItem('token')

if (token) {
  TenderiaHttp.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

TenderiaHttp.interceptors.request.use(function (config) {
  config.url = process.env.VUE_APP_BACKEND_URL + config.url
  return config
}, function (error) {
  return Promise.reject(error)
})

TenderiaHttp.interceptors.response.use(function (response)
{
  const statsStore = useStatsStore()
  const serverTime = parseInt(response.headers['x-server-time'])
  statsStore.setCurrentTime(serverTime)
  return response
},
function (error)
{
  if (error?.response?.status === 401)
  {
    const statsStore = useStatsStore()
    const globals = useGlobalStore()
    statsStore.clearMessagesInterval()
    Alerts.methods.InfoAlert({ title: 'Vaše prihlásenie vypršalo', text: 'Budete presmerovaný na prihlásenie.', allowOutsideClick: false }).then((result) => {
      if (result.value) {
        localStorage.clear()
        router.push({ path: `/login?redirect=${router.currentRoute.fullPath}&org=${globals.userOrganization.id}` })
        window.location.reload()
      }
    })
  }
  return Promise.reject(error)
})

export { TenderiaHttp }
