<template>
  <li
    :class="{'unread': !seen, 'active': active}"
    class="email-item"
  >
    <div class="email-tick">
      <div class="checkbox">
        <input
          type="checkbox"
          :id="'email-' + message.id"
          :value="message.id"
          @change="onSelectedChange"
          name="selected-messages"
        />
        <label :for="'email-' + message.id"></label>
      </div>
    </div>
    <div
      class="open-mail"
      @click.prevent="$emit('item-clicked', message.id)"
    >
      <div class="email-detail">
        <p
          class="from"
          v-if="message.organization && message.organization.id === globals.userOrganization.id"
        >
          <i class="icon-send font-size-22"></i>&nbsp;{{ message.to }}
        </p>
        <p
          v-else
          class="from"
        >
          <i class="icon-delivered font-size-22"></i>&nbsp;{{ message.from }}
        </p>
        <p class="subject">
          {{ message.subject }}
        </p>
        <p class="type">
          {{ $format.formatMessageType(message.type) }}
        </p>
        <span class="datetime">
          {{ $format.formatDateTime(message.sentAt || message.createdAt) }}
        </span>
        <span
          v-if="message.attachments.length > 0"
          class="attachments"
        >
          <i class="icon-attach"></i>&nbsp;{{ message.attachments.length }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import BaseItem from './BaseItem.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'ItemJunk',
  extends: BaseItem,
  data () {
    return {
      globals: useGlobalStore()
    }
  }
}
</script>
