<template>
  <organizations-list
    :organizations="order.applicants"
    :order="order"
    title="Uchádzači"
  />
</template>

<script>
import OrganizationsList from '@/components/order/common/components/OrganizationsList.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminApplicants',
  components: { OrganizationsList },
  data () {
    return {
      orderStore: useOrderStore()
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  }
}
</script>
