<template>
  <div>
    <order-settings-log-detail :row="row" />
    <div class="row">
      <div class="col-md-4 control-label">
        Predmet:
      </div>
      <div class="col">
        {{ row.data.request.subject }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Odosielateľ:
      </div>
      <div class="col">
        {{ `${row.data.request.from.firstname} ${row.data.request.from.lastname} <${row.data.request.from.email}>` }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Adresát:
      </div>
      <div class="col">
        {{ to }}
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close()"
      >
        <i class="icon-close" /> Zavrieť
      </button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOrderCommunication',
  components: { OrderSettingsLogDetail },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      to: ''
    }
  },
  created () {
    let to = ''
    if (this.row.data.request.to.length !== 0) {
      to = `${this.row.data.request.to[0].firstname} ${this.row.data.request.to[0].firstname} <${this.row.data.request.to[0].email}>`
    } else {
      this.row.data.request.bcc.forEach(recipient => {
        to += `${recipient.firstname} ${recipient.lastname} <${recipient.email}>, `
      })
      to = to.substring(0, to.length - 2)
    }
    this.to = to
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    }
  }
}
</script>

<style>

</style>
