<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: `${orderStore.routePrefix}admin-suppliers-search` }">
        Vyhľadať dodávateľa
      </router-link>
      <router-link :to="{ name: `${orderStore.routePrefix}admin-suppliers-add` }">
        Pridať dodávateľa
      </router-link>
    </div>
    <div class="row mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminSupplierInvite',
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
}
</script>
