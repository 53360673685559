<template>
  <offer-widget :order="order" />
</template>

<script>
import OfferWidget from '@/components/order/common/components/OfferWidget.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'UserOffer',
  components: { OfferWidget },
  data () {
    return {
      orderStore: useOrderStore()
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  }
}
</script>
