<template>
  <div class="layout free-order">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <div class="public-form-container p-3">
          <div class="registration-header">
            <span class="registration-title">
              <span class="text-primary">
                Prihláste sa do
              </span> tenderia<span class="text-primary">.</span>
            </span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <form @submit.prevent="login">
                <div class="login-form">
                  <div class="form-group">
                    <label
                      for="email"
                      class="control-label text-secondary"
                    >
                      E-mail
                    </label>
                    <input
                      id="email"
                      type="text"
                      v-model="user"
                      class="form-control"
                      placeholder="Email"
                      :class="{ error: error }"
                      autocomplete="email"
                    >
                  </div>
                  <div class="form-group">
                    <label
                      for="password"
                      class="control-label text-secondary"
                    >
                      Heslo
                    </label>
                    <input
                      id="password"
                      type="password"
                      v-model="password"
                      class="form-control"
                      placeholder="Heslo"
                      :class="{ error: error }"
                    >
                    <label
                      v-if="error"
                      id="password-error"
                      class="error"
                      for="password"
                    >
                      Email alebo heslo je nesprávne.
                    </label>
                  </div>
                  <button
                    class="btn btn-tenderia-blue m-0"
                    type="submit"
                  >
                    <span v-if="!loading">
                      {{ TEXT('STR_LOGIN_BUTTON') }}
                    </span>
                    <div
                      v-if="loading"
                      class="spinner-border spinner-border-sm text-light"
                      role="status"
                    >
                      <span class="sr-only">
                        Prihlasujem...
                      </span>
                    </div>
                  </button>
                  <a
                    :href="eIdLoginUrl"
                    class="btn btn-tenderia-blue btn-inverse m-0 mt-2 d-none d-sm-flex"
                  >
                    {{ TEXT('STR_LOGIN_BUTTON_EID') }}
                  </a>
                  <div class="mt-sm-2 mb-sm-0 mt-3 mb-2 text-center">
                    <span class="text-secondary">
                      Zabudli ste heslo? <router-link
                        to="/password-reset"
                        class=""
                      >
                        Obnova hesla.
                      </router-link>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
//import { startAuthentication } from '@simplewebauthn/browser'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import ActiveOrganizationSelect from './ActiveOrganizationSelect.vue'
import { useGlobalStore } from '@/storePinia/globalStore'
import { useStatsStore } from '@/storePinia/statsStore'

export default {
  name: 'AccountActivation',
  components: {
    AppLoginHeader,
    AppFooter
  },
  inject: [ 'TEXT' ],
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      message: 'Zadajte Vaše meno a heslo pre prihlásenie',
      user: null,
      password: null,
      error: false,
      loading: false,
      eIdLoginUrl: process.env.VUE_APP_BACKEND_URL + '/auth/eid/login/start?a=login'
    }
  },
  methods: {
    async login (options = null)
    {
      if (options === null)
      {
        if (this.user === null || this.password === null)
        {
          this.error = true
          return
        }
      }
      this.error = false
      this.loading = true
      let data = {
        login: this.user.trim(),
        password: this.password
      }

      if (this.$route.params.supplierToken) data.supplierToken = this.$route.params.supplierToken
      if (this.$route.params.inviteToken)   data.inviteToken = this.$route.params.inviteToken
      if (options)
      {
        if (options.webauthn)       data.webauthn = options.webauthn
        if (options.webauthnToken)  data.webauthnToken = options.webauthnToken
      }

      try
      {
        const response = await this.$http.post('/auth/login', data)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('sessionType', response.data.sessionType)
        this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
        this.globals.sessionType = response.data.sessionType
        this.globals.authToken = response.data.token
        this.globals.user = response.data.user
        this.globals.user.authLevel = response.data.authLevel
        this.globals.user.relations = response.data.relations
        this.statsStore.resetPremiumPackageState()
        this.$tenderiaService.syncCountries()
        this.$tenderiaService.syncCpvCodes()
        localStorage.setItem('user', JSON.stringify(this.globals.user))
        if (response.data.user.type === 'observer') {
          this.$router.push({ name: `${this.$orderRoutePrefix(response.data.order.type, response.data.order.subtype)}admin-overview`, params: { orderId: response.data.order.id } })
        }
        else {
          this.globals.user.organizations = response.data.organizations
          if (this.$route.query.redirect && this.$route.query.org && this.globals.user.organizations.some(org => org.id === this.$route.query.org)) {
            this.setUserDefaultOrganization(this.$route.query.org)
            localStorage.setItem('user', JSON.stringify(this.globals.user))
            this.$router.push({ path: this.$route.query.redirect })
          }
          else {
            this.setUserDefaultOrganization(response.data.organization)
            if (this.globals.user.organizations.length > 1 || !this.globals.userOrganization) {
              this.openOrganizationSelect()
            }
            localStorage.setItem('user', JSON.stringify(this.globals.user))
            if (!this.order) {
              this.$router.push({ name: 'dashboard' })
            }
          }
        }
        await this.statsStore.updatePremiumPackageState()
      }
      catch (error)
      {
        console.log(error)
        this.error = true
        this.loading = false
      }
    },
    setUserDefaultOrganization (organizationId) {
      console.log('setting default organization', organizationId)
      const selectedOrganization = this.globals.user.organizations.find(org => org.id === organizationId)
      if (selectedOrganization) {
        const selectedRelation = this.globals.user.relations.find(relation => relation.organization === organizationId)
        if (selectedRelation) {
          this.globals.user = {
            ...this.globals.user,
            roles: selectedRelation.roles,
            permissions: selectedRelation.permissions.map(permission => `${permission.group}/${permission.object}/${permission.action}/${permission.level}`)
          }
          this.globals.userOrganization = selectedOrganization
          localStorage.setItem('userOrganization', JSON.stringify(selectedOrganization))
        }
      }
    },
    openOrganizationSelect () {
      this.$emit('vuedals:new', {
        name: 'organization-select-modal',
        escapable: true,
        title: 'Výber aktívnej organizácie',
        props: {
          organizations: this.globals.user.organizations,
          relations: this.globals.user.relations
        },
        component: ActiveOrganizationSelect
      })
    }
    /*async webauthnLogin ()
    {
      try
      {
        const response = await this.$http.post('/auth/webauthn/signin')
        const options = response?.data?.options
        const token = response?.data?.token
        const auth = await startAuthentication({ optionsJSON: options, useAutofill: true })
        await this.login({ webauthn: auth, webauthnToken: token })
      }
      catch (error)
      {
        console.error(error)
        this.ErrorAlert({ title: '', text: 'Prihlásenie zlyhalo' }, error)
      }
    }*/
  },
  async mounted () {
    if (this.globals.authToken) {
      await this.$http.post('/auth/logout')
    }
    localStorage.clear()
    this.globals.sessionType = null
    this.globals.authToken = null
    this.globals.user = null
    this.globals.userOrganization = null
  }
}
</script>
<style scoped lang="scss">
.login-form {
    padding: 15px 20px 10px 20px;
    min-width: 250px;
    .btn {
      width: 100%;
    }
    .form-group {
      margin-bottom: 10px;
      .control-label {
        margin-bottom: 2px;
      }
    }
  }
</style>
