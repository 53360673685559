<template>
  <div class="column">
    <div class="row">
      <div class="col-md-12">
        <email-client
          v-if="order.id"
          class="px-0"
          :order="order"
        >
          <template #append>
            <router-link
              class="btn btn-success m-0 ml-auto"
              :to="{name: `${orderStore.routePrefix}user-communication-add`,params: {orderId: order.id}}"
            >
              <i class="icon-messages" /> Nová správa
            </router-link>
          </template>
        </email-client>
      </div>
    </div>
  </div>
</template>

<script>
import EmailClient from '@/components/emailClient/EmailClient.vue'
import { useOrderStore } from '@/storePinia/orderStore'
export default {
  name: 'UserCommunication',
  components: { EmailClient },
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  }
}
</script>
