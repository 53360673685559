<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Rozpočty
        </h4>
        <div class="actions">
          <router-link
            class="btn btn-tenderia-blue"
            to="/app/settings/budgets/add"
          >
            <i class="icon-add font-size-14"></i> Pridať rozpočet
          </router-link>
        </div>
      </div>
      <v-table
        :items="budgets.data"
        :columns="budgetsColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template #cell(validFrom)="{item}">
          {{ $format.formatDate(item.validFrom) }}
        </template>
        <template #cell(validTo)="{item}">
          {{ $format.formatDate(item.validTo) }}
        </template>
        <template #cell(value)="{item}">
          {{ $format.shortCurrencyFixed2(item.value) }}
        </template>
        <template #cell(actions)="{ item }">
          <router-link
            :to="'/app/settings/budgets/edit/' + item.id"

            title="Upraviť rozpočet"
          >
            <i class="icon-pencil"></i>
          </router-link>
          <a
            @click.stop="removeBudgetModal(item)"
            class="text-danger"

            title="Zmazať"
          >
            <i class="icon-delete"></i>
          </a>
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="budgets.count"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="budgets.count"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'

export default {
  name: 'BudgetSettingsAccounts',
  components: { VTable, VPagination },
  data: function () {
    return {
      budgets: {},
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    budgetsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'validFrom',
          label: 'Dátum od',
          sortable: true
        },
        {
          key: 'validTo',
          label: 'Dátum do',
          sortable: true
        },
        {
          key: 'value',
          label: 'Výška',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    },
    fetchParams () {
      return {
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort)

      }
    }
  },
  methods: {
    async fetchBudgets () {
      try {
        const { data } = await this.$http.post('/budget/list', this.fetchParams)
        this.budgets = { data: data.budgets, count: data.total }
      } catch (error) {
        this.ErrorAlert({ text: 'Nastala chyba.' }, error)
      }
    },
    removeBudgetModal (budget) {
      this.YesNoAlert({
        title: 'Zmazať rozpočet',
        text: `Skutočne chcete zmazať rozpočet ${budget.name}?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeBudget(budget)
        }
      })
    },
    async removeBudget (budget) {
      try {
        let data = {
          budget: {
            id: budget.id
          }
        }
        await this.$http.post('/budget/delete', data)
        this.OkAlert({ text: `Rozpočet ${budget.name} bol zmazaný.` })
        this.fetchBudgets()
      } catch (error) {
        this.ErrorAlert({ text: 'Rozpočet sa nepodarilo zmazať.' }, error)
      }
    }
  },
  mounted () {
    this.fetchBudgets()
  },
  watch: {
    fetchParams () {
      this.fetchBudgets()
    }
  }
}
</script>
