<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Informácie o žiadanke</h4>
          </div>
          <div class="card-block">
            <div class="list-table row no-gutters">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-5"><b>Názov</b></div>
                  <div class="col-md-7">{{ request.name }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Stav</b></div>
                  <div class="col-md-7">
                    <request-state
                      display="text"
                      :state="request.state"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Schvaľovatelia</b></div>
                  <div class="col-md-7 column"><span>{{ requestApprovers }}</span></div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Rozpočet</b></div>
                  <div class="col-md-7"><span>{{ requestBudgets }}</span></div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Celková predp. hodnota (PHZ) bez DPH</b></div>
                  <div class="col-md-7">{{ $format.price(request.value) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Celková predp. hodnota (PHZ) s DPH</b></div>
                  <div class="col-md-7">{{ $format.price(request.valueWithVat) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Typ položky</b></div>
                  <div class="col-md-7">{{ $format.formatOrderSubject(request.subjectType) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Kategória (CPV)</b></div>
                  <div class="col-md-7">{{ request.cpvCode }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Opis predmetu zákazky</b></div>
                  <div class="col-md-7">{{ request.description }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Odôvodnenie potreby</b></div>
                  <div class="col-md-7">{{ request.justification }}</div>
                </div>
              </div>
              <div class="col-md-6 border-left">
                <div class="row">
                  <div class="col-md-5"><b>Dátum dodania</b></div>
                  <div class="col-md-7">{{ $format.formatDate(request.deliveryDate) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Dátum odoslania na schválenie</b></div>
                  <div class="col-md-7">{{ $format.formatDate(request.submittedAt) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Dátum rozhodnutia</b></div>
                  <div class="col-md-7">{{ $format.formatDate(request.decidedAt) }}</div>
                </div>
                <div class="row">
                  <div class="col-md-5"><b>Poznámka</b></div>
                  <div class="col-md-7">{{ request.note }}</div>
                </div>
                <div
                  class="row"
                  v-if="request.budgets.length > 0"
                >
                  <div class="col-md-12"><b>Hodnota žiadanky voči rozpočtu</b></div>
                  <div class="col-md-12">
                    <bar-chart-echart
                      :data="requestChartData"
                      :series="requestChartsSeries"
                      :formatLabel="$format.shortCurrencyFixed2"
                      :yFormat="$format.shortCurrency"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <request-suppliers-list
      :editable="false"
      :request="request"
    />
    <items-list-widget
      :editable="false"
      :request="request"
    />
    <request-approvers-list v-if="loaded && request.state !== 'planned'" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">Prílohy</h4>
            <div class="actions">
              <button
                v-if="request.attachments && request.attachments.length > 0"
                class="btn btn-tenderia-grey-blue"
                @click="downloadAttachments"
              >
                <i class="icon-download"></i>Stiahnuť všetky prílohy
              </button>
            </div>
          </div>
          <v-table
            :items="request.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template #cell(name)="{ item }">
              <a
                :href="globals.backendHost + item.link + '?token=' + globals.authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template #cell(mime)="{ item }">
              {{ $format.formatFileType(item.mime) }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemsListWidget from '@/components/requests/components/ItemsListWidget.vue'
import VTable from '@/components/ui/Table.vue'
import RequestState from '@/components/requests/components/RequestState.vue'
import RequestApproversList from '@/components/requests/components/RequestApproversList.vue'
import RequestSuppliersList from '../components/RequestSuppliersList.vue'
import BarChartEchart from '@/components/ui/BarChartEchart.vue'
import { useRequestStore } from '@/storePinia/requestStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'RequestEmailOverview',
  components: { ItemsListWidget, VTable, RequestState, RequestApproversList, RequestSuppliersList, BarChartEchart },
  data () {
    return {
      globals: useGlobalStore(),
      requestStore: useRequestStore(),
      loaded: false
    }
  },
  computed: {
    request () {
      return this.requestStore.request
    },
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    },
    requestApprovers () {
      if (this.request.approvers.length > 0) {
        return this.request.approvers.map(approver => {
          return `${approver.firstname} ${approver.lastname}`
        }).join(', ')
      } else {
        return 'Žiadni schvaľovatelia.'
      }
    },
    requestBudgets () {
      if (this.request.budgets.length > 0) {
        return this.request.budgets.map(budget => {
          return `${budget.name}`
        }).join(', ')
      } else {
        return 'Žiadne rozpočty.'
      }
    },
    requestChartsSeries () {
      return {
        budgetValue: {
          color: '#3E97EF',
          label: 'Rozpočet',
        },
        requestValue: {
          color: '#FF9D3B',
          label: 'PHZ bez DPH',
        },
      }
    },
    requestChartData () {
      if (this.request.budgets.length > 0) {
        const xAxis = ['Rozpočet / PHZ bez DPH']

        const data = {
          budgetValue: [
            { xAxis, value: this.request.budgets[0].value }
          ],
          requestValue: [
            { xAxis, value: this.request.value }
          ],
        }

        return data
      } else {
        return 'Žiadne rozpočty.'
      }
    },
  },
  methods: {
    logout () {
      this.$http.post('/auth/logout')
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.globals.authToken
    },
    async loadRequest () {
      this.loaded = false
      this.ShowLoading()
      try {
        this.requestStore.getRequest(this.$route.params.requestId)
        this.loaded = true
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o žiadanke.' }, error)
      }
    },
    async downloadAttachments () {
      this.ShowLoading({ title: 'Pripravujem dokumenty na stiahnutie' })
      var data = {
        request: {
          id: this.request.id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    }
  },
  mounted () {
    window.addEventListener('beforeunload', this.logout)
    if (this.$route.params.requestId && this.$route.query.token) {
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$route.query.token
      this.loadRequest()
    }
  },
  destroyed () {
    this.logout()
  }
}
</script>
