<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title">
        <span v-if="part">
          Časť {{ part.part }} -
        </span>Otváranie ponúk
      </h4>
      <div
        v-if="order._canEdit && openingEnabled"
        class="actions"
      >
        <button
          :disabled="loading"
          class="btn btn-success"
          @click.prevent="downloadProtocolTemplate()"
        >
          <div
            v-if="loading"
            class="spinner-border spinner-border-sm text-light mr-2"
            role="status"
          />
          <i
            v-else
            class="icon-doc-1"
          />Zápisnica z otvárania
        </button>
      </div>
    </div>
    <div class="card-block">
      <p class="py-3">
        Termín otvárania ponúk: <span
          v-if="openingDate"
          class="text-bold"
        >
          {{ $format.formatDateTime(openingDateStr) }}
        </span><span
          v-else
          class="text-bold"
        >
          Termín nebol stanovený
        </span>
      </p>
      <p
        v-if="openingDate && !openingEnabled"
        class="py-3 text-center"
      >
        <countdown
          :deadline="openingDate"
          :server-time="serverTime"
        />
      </p>
    </div>
    <v-table
      :items="offers"
      :columns="tableColumns"
      item-key="id"
      class="light"
    >
      <template #cell(index)="{ _item, row }">
        {{ row + 1 }}.
      </template>
      <template #cell(applicant)="{ item }">
        <span v-if="openingEnabled && globals.user.type === 'observer' && item.stage === 'unopened'">
          {{ $format.formatApplicantName(item) }}
        </span>
        <router-link
          v-else-if="openingEnabled && part"
          :to="{ name: `${orderStore.routePrefix}admin-offers-view-parts`, params: {partId: part.id ,orderId: order.id, offerId: item.id}}"
        >
          {{ $format.formatApplicantName(item) }}
        </router-link>
        <router-link
          v-else-if="openingEnabled"
          :to="{ name: `${orderStore.routePrefix}admin-offers-view`, params: {orderId: order.id, offerId: item.id}}"
        >
          {{ $format.formatApplicantName(item) }}
        </router-link>
        <span v-else>
          {{ $format.formatApplicantName(item) }}
        </span>
      </template>
      <template #cell(sentAt)="{ item }">
        {{ $format.formatDateTime(item.sentAt) }}
      </template>
      <template #cell(stage)="{ item }">
        {{ formatStage(item.stage) }}
      </template>
    </v-table>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import saveAs from 'file-saver'
import VTable from '@/components/ui/Table.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'OffersOpeningWidget',
  components: { Countdown, VTable },
  props: {
    part: {
      type: Object,
      default: null
    },
    order: {
      type: Object,
      required: true
    },
    round: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data () {
    return {
      orderStore: useOrderStore(),
      statsStore: useStatsStore(),
      globals: useGlobalStore(),
      offers: [],
      openingDate: '',
      openingDateStr: '',
      loading: false
    }
  },
  computed: {
    serverTime () {
      return new Date(this.statsStore.currentTime)
    },
    openingEnabled () {
      return this.serverTime > this.openingDate
    },
    tableColumns () {
      return [
        {
          key: 'index',
          label: 'Por. č.'
        },
        {
          key: 'applicant',
          label: 'Uchádzač'
        },
        {
          key: 'sentAt',
          label: 'Dátum doručenia'
        },
        {
          key: 'stage',
          label: 'Stav'
        }
      ]
    }
  },
  watch: {
    round () {
      this.loadOffers()
    }
  },
  mounted () {
    this.loadOffers()
  },
  methods: {
    formatStage (value) {
      var stages = {
        unopened: 'Neotvorená',
        opened: (this.round === 1 ? 'Otvorená' : 'Neotvorená'),
        opened2: 'Otvorená',
        excluded: 'Vylúčená'
      }
      return stages[value]
    },
    async loadOffers () {
      if (!this.order.id) {
        return
      }
      this.offers = []
      let offersArray = []
      if (this.part) {
        this.openingDateStr = this.round === 1 ? this.part.bidsOpenAt : this.part.bidsOpenAt2
        this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
        let { data: { offers } } = await this.$http.post('/offer/list', { order: this.part.id, limit: 100, sort: 'sentAt' })
        offersArray = offers
      } else {
        this.openingDateStr = this.round === 1 ? this.order.bidsOpenAt : this.order.bidsOpenAt2
        this.openingDate = (this.openingDateStr ? new Date(this.openingDateStr) : null)
        let { data: { offers } } = await this.$http.post('/offer/list', { order: this.order.id, limit: 100, sort: 'sentAt' })
        offersArray = offers
      }
      offersArray.forEach(offer => {
        if (this.round === 1 || (this.round === 2 && offer.stage !== 'excluded')) {
          this.offers.push(offer)
        }
      })
    },
    async downloadProtocolTemplate () {
      this.loading = true
      try {
        let url =''
        if (this.part) {
          url = '/template/@bids-open?order=' + this.part.id
        } else {
          url = '/template/@bids-open?order=' + this.order.id
        }
        let { data } = await this.$http({ method: 'GET', url, responseType: 'blob' })
        const blob = new Blob([ data ])
        saveAs(blob, 'Zápisnica z otvárania ponúk.docx')
      } catch (error) {
        this.ErrorAlert({ text: 'Pri sťahovaní zápisnice nastala chyba.' }, error)
      }
      this.loading = false
    }
  }
}
</script>
