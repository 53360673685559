<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h1
          v-if="$route.params.state === 'all'"
          class="card-title"
        >
          Všetky žiadanky
        </h1>
        <h1
          v-else
          class="card-title"
        >
          {{requestState($route.params.state)}}
        </h1>
        <div class="actions">
          <button
            @click="showFilter = !showFilter"
            class="btn btn-success"
          >
            <i class="icon-filter"></i><span>{{showFilter ? 'Skryť' : 'Zobraziť'}} filter</span>
          </button>
          <button
            @click="fetchRequestsXlsx"
            class="btn btn-success"
          >
            <i class="icon-excel"></i><span>Exportovať do excelu</span>
          </button>
        </div>
      </div>
      <v-collapsible>
        <div
          v-if="showFilter"
          class="card-block"
        >
          <div class="row gutters-sm align-items-baseline tabel-filters">
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Hľadaj v názve alebo popise:
              </label>
              <input
                v-model="fulltext"
                class="form-control"
              >
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Dátum vytvorenia od:
              </label>
              <date-input
                name="date"
                v-model="dateFrom"
                :max-date="new Date()"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Dátum vytvorenia do:
              </label>
              <date-input
                name="date-to"
                v-model="dateTo"
                :max-date="new Date()"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                CPV kód:
              </label>
              <main-cpv-code-select
                v-model="cpvCode"
                placeholder=""
              />
            </div>
            <div
              v-if="$route.params.state === 'all'"
              class="col-md-4 form-group"
            >
              <label class="text-bold text-dark">
                Stav:
              </label>
              <multiselect
                v-model="state"
                :options="statesOptions"
                :multiple="true"
                :preserve-search="true"
                track-by="value"
                label="label"
                openDirection="bottom"
                :clear-on-select="false"
                :close-on-select="false"
                :searchable="true"
                :taggable="true"
                :show-labels="false"
                placeholder=""
              >
              </multiselect>
            </div>
            <div
              v-if="$route.name === 'requests'"
              class="col-md-4 form-group"
            >
              <label class="text-bold text-dark">
                Žiadateľ:
              </label>
              <user-select
                v-model="users"
                placeholder=""
                aria-required="true"
                includeAll
              />
            </div>
          </div>
        </div>
      </v-collapsible>
      <v-table
        :items="requests.requests"
        :columns="requestsColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
        :selected="selectedRow"
        @row-click="toggleRowDetail"
      >
        <template #cell(user)="{ item }">
          {{ `${item.user.firstname} ${item.user.lastname} (${item.user.email})` }}
        </template>
        <template #cell(name)="{ item }">
          <router-link :to="`/app/requests/${item.id}/overview`">
            {{ item.name }}
          </router-link>
        </template>
        <template #cell(approvers)="{ item }">
          <span>
            {{ requestApprovers(item) }}
          </span>
        </template>
        <template #cell(createdAt)="{ item }">
          {{ $format.formatDateTime(item.createdAt) }}
        </template>
        <template #cell(value)="{ item }">
          {{ $format.shortCurrencyFixed2(item.value) }}
        </template>
        <template #cell(state)="{ item }">
          <request-state :state="item.state" />
        </template>
        <template #item-detail="{ item }">
          <div class="table-detail">
            <div class="info m-2">
              <label>Rozpočet</label>
              <span>{{ item.budgets[0].name }} - {{ $format.price(item.budgets[0].value) }}</span>
              <label>PHZ bez DPH</label>
              <span>{{ $format.price(item.value) }}</span>
              <label>PHZ s DPH</label>
              <span>{{ $format.price(item.valueWithVat) }}</span>
              <label>Kategória (CPV)</label>
              <span>{{ item.cpvCode }}</span>
              <label>Dátum odoslania na schválenie</label>
              <span> {{ $format.formatDate(item.submittedAt) }}</span>
              <label>Dátum rozhodnutia</label>
              <span> {{ $format.formatDate(item.decidedAt) }}</span>
              <label>Dátum dodania</label>
              <span> {{ $format.formatDate(item.deliveryDate) }}</span>
              <label>Odôvodnenie potreby</label>
              <span>{{ item.justification }}</span>
            </div>
            <div class="description column m-2">
              <label class="title">
                Hodnota žiadanky voči rozpočtu
              </label>
              <bar-chart-echart
                :data="requestChartData(item)"
                :series="requestChartsSeries"
                :formatLabel="$format.shortCurrencyFixed2"
                :yFormat="$format.shortCurrency"
                :minHeight="175"
              />
              <router-link
                class="btn btn-tenderia-blue mt-3 mb-0"
                :to="`/app/requests/${item.id}/overview`"
              >
                Zobraziť viac
              </router-link>
            </div>
          </div>
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="requests.total"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="requests.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import saveAs from 'file-saver'
import VTable from './ui/Table.vue'
import VCollapsible from './ui/Collapsible.vue'
import VPagination from './ui/Pagination.vue'
import DateInput from '@/components/forms/DateInput.vue'
import RequestState from './requests/components/RequestState.vue'
import Multiselect from 'vue-multiselect'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import UserSelect from '@/components/forms/UserSelect.vue'
import BarChartEchart from '@/components/ui/BarChartEchart.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'RequestsPage',
  components: {
    VTable,
    VCollapsible,
    VPagination,
    DateInput,
    RequestState,
    Multiselect,
    MainCpvCodeSelect,
    UserSelect,
    BarChartEchart
  },
  data () {
    return {
      globals: useGlobalStore(),
      selectedRow: null,
      requests: {
        requests: [],
        total: 0
      },
      showFilter: true,
      fulltext: '',
      dateFrom: '',
      dateTo: '',
      state: [],
      cpvCode: '',
      users: [],
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    requestChartsSeries () {
      return {
        budgetValue: {
          color: '#3E97EF',
          label: 'Rozpočet',
        },
        requestValue: {
          color: '#FF9D3B',
          label: 'PHZ bez DPH',
        },
      }
    },
    statesOptions () {
      const items = [
        { value: 'approving', label: 'Čakajúce' },
        { value: 'approved', label: 'Schválené' },
        { value: 'rejected', label: 'Odmietnuté' },
      ]
      if (this.$route.name === 'my-request') {
        items.push({ value: 'planned', label: 'V príprave' })
      }
      return items
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    requestsColumns () {
      const columns = [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia',
          sortable: true
        },
        {
          key: 'approvers',
          label: 'Schvaľovatelia',
          sortable: true
        },
        {
          key: 'value',
          label: 'Hodnota',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav',
          sortable: true
        }
      ]

      if (this.$route.name === 'requests') {
        columns.splice(1, 0, {
          key: 'user',
          label: 'Žiadateľ',
          sortable: true
        })
      }

      return columns
    },
    fetchParams () {
      const paramsMap = {
        all: '',
        planned: 'planned',
        approving: 'approving',
        approved: 'approved',
        rejected: 'rejected',
        archived: 'archived,'
      }

      const params = {
        state: paramsMap[this.$router.currentRoute.params.state] || this.state.map(state => state.value),
        limit: this.perPage,
        page: this.page,
        offset: this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        filter: this.fulltext,
        user: this.users,
        cpvCode: this.cpvCode
      }

      if (this.$route.name === 'my-requests') {
        params.user = [this.globals.user.id]
      }
      if (this.$route.name === 'requests') {
        params.approver = true
      }

      return params
    }
  },
  methods: {
    requestChartData (item) {
      const xAxis = ['Rozpočet / PHZ bez DPH']

      const data = {
        budgetValue: [
          { xAxis, value: item.budgets[0].value }
        ],
        requestValue: [
          { xAxis, value: item.value }
        ],
      }

      return data
    },
    toggleRowDetail (item) {
      this.selectedRow = this.selectedRow === item.id ? null : item.id
    },
    requestApprovers (item) {
      if (item.approvers.length > 0) {
        return item.approvers.map(approver => {
          return `${approver.firstname} ${approver.lastname}`
        }).join(', ')
      }
    },
    requestState (value) {
      var requestStates = {
        planned: 'V príprave',
        approving: 'Čakajúce',
        approved: 'Schválené',
        rejected: 'Odmietnuté',
        archived: 'Archivované'
      }
      return requestStates[value]
    },
    async fetchRequests () {
      const { data } = await this.$http.post('/request/list', this.fetchParams)
      this.requests = data
    },
    async fetchRequestsXlsx () {
      const url = '/request/list'
      let { data } = await this.$http({ method: 'POST', url, data: { ...this.fetchParams, format: 'xlsx' }, responseType: 'blob' })
      const blob = new Blob([ data ])
      saveAs(blob, 'Žiadanky.xlsx')
    }
  },
  mounted () {
    this.fetchRequests()
  },
  watch: {
    fetchParams () {
      this.fetchRequests()
    }
  }
}
</script>
<style lang="scss" scoped>
.table-detail {
  border-top: 1px solid #E0E0E0;
  width: 100%;
  display: grid;
  grid-template-columns: 55% 45%;
  @media(max-width: 600px) {
    grid-template-columns: 1fr;
  }
  .info {
    display: grid;
    grid-template-columns: 45% 55%;
    background-color: #E0E0E0;
    border: 1px solid #E0E0E0;
    overflow: hidden;
    row-gap: 1px;
    > * {
      padding: 4px 8px;
      background-color: #EDF1F5;
    }
    label {
      font-weight: bold;
      min-height: 30px;
      margin-bottom: 0;
      &::after {
        content: ":";
      }
    }
  }
  .description {
    .title {
      font-weight: bold;
    }
    .btn {
      flex-shrink: 0;
      align-self: flex-end;
    }
  }
}
</style>
