<template>
  <div class="email-content">
    <div
      class="email-content-wrapper"
      v-if="message.id"
    >
      <div class="email-content-tools">
        <ul class="tools">
          <a
            class="back d-md-none"
            href=""
            title="Späť"
            @click.prevent="$emit('back-to-list')"
          >
            <i class="icon-arrow-left font-size-30"></i>
          </a>
          <span class="time text-secondary">
            {{ $format.formatDateTime(message.sentAt || message.createdAt) }}
          </span>
          <div class="actions">
            <li v-if="mode !== 'draft-order' && this.canEdit">
              <a
                href=""
                title="Odpovedať"
                @click.prevent="mailAction('reply')"
              >
                <i class="icon-reply"></i>
              </a>
            </li>

            <li
              v-if="mode === 'draft-order' && this.canEdit"
              class="d-none d-sm-block"
            >
              <a
                href=""
                title="Pokračovať"
                @click.prevent="mailAction('draft')"
              >
                <i class="icon-messages"></i>
              </a>
            </li>

            <li class="d-none d-sm-block">
              <a
                href=""
                title="Vytlačiť"
                @click.prevent="print()"
              >
                <i class="icon-print"></i>
              </a>
            </li>
            <li v-if="mode === 'junk' && this.canEdit">
              <a
                href=""
                title="Obnoviť"
                @click.prevent="$emit('undelete-message')"
              >
                <i class="icon-synch"></i>
              </a>
            </li>
          </div>
        </ul>
        <div class="title">
          <span class="subject text-bold">
            {{ message.subject }}
          </span>
          <span
            v-if="from === 'Portál tenderia <spravca@tenderia.sk>'"
            class="from"
          >
            Portál tenderia
          </span>
          <span
            v-else
            class="from"
          >
            {{ from }}
          </span>
        </div>
      </div>
      <div class="email-content-detail">
        <div class="detail-head">
          <ul class="list-unstyled list-info">
            <li>
              <div>
                <div class="info">
                  <span class="sub-title text-dark">
                    <span class="text-bold">
                      Typ správy:
                    </span> {{ $format.formatMessageType(message.type) }}<br />
                    <span
                      v-if="to"
                      class="text-bold"
                    >
                      Pre:
                    </span> {{ to }}<br />
                    <span class="text-bold">
                      Zákazka:
                    </span> <a
                      href=""
                      @click.prevent="openMessageOrder()"
                      class="text-semibold"
                    >
                      {{ message.order.name }}
                    </a><br />
                    <span class="text-bold">
                      Obstarávateľ:
                    </span> {{ message.order.organization.name}}
                    <template v-if="message.validTo">
                      <br />Nutné odpovedať do: <span class="text-bold">
                        {{ $format.formatDateTime(message.validTo) }}
                      </span>
                    </template>
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <button
            v-if="mode === 'draft-order' && this.canEdit"
            class="btn btn-success m-0 ml-auto"
            @click.prevent="mailAction('draft')"
          >
            <i class="icon-messages"></i> Pokračovať
          </button>
        </div>
        <div class="detail-body text-dark">
          <div v-html="message.body"></div>
          <template v-if="message.validTo">
            <p class="mrg-top-15">
              <span class="text-dark">
                Upozorňujeme Vás, že na túto správu ste povinní doručiť odpoveď do
              </span> <span class="text-danger">
                {{ $format.formatDateTime(message.validTo) }}
              </span>
            </p>
          </template>
        </div>
        <div class="detail-foot">
          <span
            class="text-bold"
            v-if="message.attachments && message.attachments.length > 0"
          >
            Prílohy:
          </span>
          <span class="float-right">
            <a
              href=""
              title="Stiahnuť všetky prílohy"
              @click.prevent="downloadAttachments()"
              v-if="message.attachments && message.attachments.length > 1"
            >
              <i class="icon-download"></i> Stiahnuť všetky
            </a>
          </span>
          <ul class="attachments">
            <li
              v-for="attachment in message.attachments"
              :key="attachment.id"
              :class="attachmentExtension(attachment.mime)"
            >
              <file-icon-link :file="attachment" />
            </li>
          </ul>
          <div
            class="mrg-top-20 pdd-top-10"
            v-if="mode === 'sent-order' && userStats.length > 0"
          >
            <p class="no-mrg font-weight-light">
              Stav prečítania
            </p>
            <message-state-table
              :message="message"
              :user-stats="userStats"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../router'
import FileIconLink from '../utils/FileIconLink.vue'
import EmailHelper from '../utils/EmailHelper.js'
import MessageStateTable from './MessageStateTable.vue'
import { permissions } from '../../router/permissions'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'EmailContentOrder',
  components: { FileIconLink, MessageStateTable },
  props: ['currentMessage', 'mode', 'canEdit'],
  data () {
    return {
      globals: useGlobalStore(),
      message: {},
      userStats: [],
      from: '',
      to: '',
      authToken: localStorage.getItem('token')
    }
  },
  computed: {
    isAdmin () {
      return this.message.order.organization.ico === this.globals.userOrganization.ico && this.globals.user.permissions && this.globals.user.permissions.includes(permissions.ORDER_ORDER_CREATE_0)
    }
  },
  methods: {
    loadMessage () {
      let emailHelper = new EmailHelper(this.currentMessage, this.mode)
      let messageBuild = emailHelper.buildMetadata()

      this.message = emailHelper.message
      this.from = messageBuild.from
      this.to = messageBuild.to.slice(0, -2)

      if (this.currentMessage.userStats && this.currentMessage.userStats.length > 0) {
        this.userStats = this.currentMessage.userStats
      }
      if (this.currentMessage.parentOrder && this.currentMessage.parentOrder.parentId) {
        if (this.currentMessage.order.subtype === 'vyz') {
          this.message.order.id = this.currentMessage.order.id
        } else {
          this.message.order.id = this.currentMessage.parentOrder.parentId
        }
        this.message.order.name = `${this.currentMessage.parentOrder.parentName} / ${this.currentMessage.order.name}`
      }
    },
    mailAction (action) {
      if (this.isAdmin) {
        router.push({ name: `${this.$orderRoutePrefix(this.message.order.type, this.message.order.subtype)}admin-communication-add`, params: { orderId: this.message.order.id, action: action, message: this.message } })
      } else {
        router.push({ name: `${this.$orderRoutePrefix(this.message.order.type, this.message.order.subtype)}user-communication-add`, params: { orderId: this.message.order.id, action: action, message: this.message } })
      }
    },
    openMessageOrder () {
      if (this.isAdmin) {
        router.push({ name: `${this.$orderRoutePrefix(this.message.order.type, this.message.order.subtype)}admin-overview`, params: { orderId: this.message.order.id } })
      } else {
        router.push({ name: `${this.$orderRoutePrefix(this.message.order.type, this.message.order.subtype)}user-overview`, params: { orderId: this.message.order.id } })
      }
    },
    attachmentExtension (mime) {
      return mime.split('/')[1]
    },
    print () {
      let routeData = this.$router.resolve({ name: 'printMessage', params: { messageid: this.message.id, mode: this.mode } })
      window.open(routeData.href, '_blank')
    },
    async downloadAttachments () {
      this.ShowLoading({ title: 'Pripravujem prílohy na stiahnutie' })
      var data = {
        message: {
          id: this.message.id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    }
  },
  mounted () {
    this.loadMessage()
  }
}
</script>
