import { permissions } from './permissions'
import { useGlobalStore } from '@/storePinia/globalStore'

export const orderRoutePrefix = (type, subtype) => {
  // Mapping of type-subtype combinations to route prefixes
  let map = {
    'nh-nh': 'mr-', // 'Prieskum trhu'
    'pz-vs': 'pt-', // 'Podlimitná zákazka'
    'nz-vs': 'pt-', // 'Nadlimitná zákazka - verejná súťaž'
    'nz-us': 'us-', // 'Nadlimitná zákazka - užšia súťaž'
    'nk-us': 'us-', // 'Nadlimitná koncesia - užšia súťaž'
    'ob-us': 'us-', // 'Zákazka v oblasti obrany a bezpečnosti - užšia súťaž'
    'us-vyz': 'usv-', // 'Užšia súťaž - výzva'
    'ob-kd': 'kd-', // 'Zákazka v oblasti obrany a bezpečnosti - koncesný dialóg'
    'nk-kd': 'kd-', // 'Nadlimitná koncesia - koncesný dialóg'
    'kd-vyz': 'kdv-', // 'Koncesný dialóg - výzva'
    'nz-rkz': 'rkz-', // 'Nadlimitná zákazka - rokovacie konanie so zverejnením'
    'nk-rkz': 'rkz-', // 'Nadlimitná koncesia - rokovacie konanie so zverejnením'
    'ob-rkz': 'rkz-', // 'Zákazka v oblasti obrany a bezpečnosti - rokovacie konanie so zverejnením'
    'rkz-vyz': 'rkzv-',// 'Rokovacie konanie so zverejnením - výzva'
    'nz-prk': 'prk-', // 'Priame rokovacie konanie'
    'ob-prk': 'prk-', // 'Zákazka v oblasti obrany a bezpečnosti - priame rokovacie konanie'
    'nz-ip': 'ip-', // 'Nadlimitná zákazka - inovatívne partnerstvo'
    'nk-vs': 'pt-', // 'Nadlimitná koncesia - verejná súťaž'
    'dns-dns': 'dns-', // 'Dynamický nákupný systém'
    'dns-vyz': 'dnsv-',// 'Dynamický nákupný systém - výzva'
    'ptk-ptk': 'ptk-', // 'Predbežné trhové konzultácie'
    'nz-sd': 'sd-', // 'Nadlimitná zákazka - súťažný dialóg'
    'nk-sd': 'sd-', // 'Nadlimitná koncesia - súťažný dialóg'
    'sd-vyz': 'sdv-', // 'Súťažný dialóg - výzva'
    'nk-ovs': 'ovs-', // 'Nadlimitná koncesia - obchodná verejná súťaž'
    'sn-sn': 'sn-', // 'Súťaž návrhov'
    'pk-pk': 'pk-' // 'Podlimitná koncesia'
  }
  return map[`${type}-${subtype}`]
}

const TenderiaRouterPlugin = {
  install (Vue) {
    Vue.prototype.$openOrder = function (id, type, subtype)
    {
      const globals = useGlobalStore()
      let userType = globals.user.permissions && globals.user.permissions.includes(permissions.ORDER_ORDER_CREATE_0) ? 'admin' : 'user'
      let routePrefix = this.$orderRoutePrefix(type, subtype)
      if (routePrefix) {
        this.$router.push({ name: `${routePrefix}${userType}-overview`, params: { orderId: id } })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    }

    Vue.prototype.$orderRoutePrefix = orderRoutePrefix
  }
}

export default TenderiaRouterPlugin
