<template>
  <div class="filters">
    <div class="form-group">
      <label class="control-label">
        Hľadaj v názve alebo popise:
      </label>
      <input
        v-model.lazy="value.fulltext"
        class="form-control"
      >
    </div>
    <div class="form-group">
      <label class="control-label">
        IČO obstarávateľa:
      </label>
      <input
        v-model.lazy="value.ico"
        class="form-control"
      >
    </div>
    <div class="form-group">
      <label class="control-label">
        Hlavný CPV kód:
      </label>
      <main-cpv-code-select
        v-model="value.cpvCode"
        placeholder=""
      />
      <!-- <input v-model="value.cpvCode" class="form-control"> -->
    </div>
    <div class="form-group">
      <label class="control-label">
        Dátum aktualizície zákazky:
      </label>
      <multiselect
        v-model="value.updatedAt"
        :options="updatedAtOptions"
        track-by="code"
        label="text"
        openDirection="bottom"
        :clear-on-select="false"
        :close-on-select="true"
        :searchable="true"
        :show-labels="false"
        placeholder=""
      />
    </div>
    <div class="form-group">
      <label class="control-label">
        Kritéria vyhodnotenia:
      </label>
      <multiselect
        v-model="value.biddingMethod"
        :options="biddingMethodOptions"
        track-by="code"
        label="text"
        openDirection="bottom"
        :clear-on-select="false"
        :close-on-select="true"
        :searchable="true"
        :show-labels="false"
        placeholder=""
      />
    </div>
    <div class="form-group">
      <label class="control-label">
        Spolufinancovanie z EÚ:
      </label>
      <multiselect
        v-model="value.fundingByEu"
        :options="fundingByEuOptions"
        track-by="code"
        label="text"
        openDirection="bottom"
        :clear-on-select="false"
        :close-on-select="true"
        :searchable="true"
        :show-labels="false"
        placeholder=""
      />
    </div>
    <div class="form-group">
      <label class="control-label">
        Finančný limit:
      </label>
      <multiselect
        v-model="value.type"
        :options="typeOptions"
        track-by="code"
        label="text"
        openDirection="bottom"
        :clear-on-select="false"
        :close-on-select="true"
        :searchable="true"
        :show-labels="false"
        placeholder=""
      />
    </div>
    <div class="form-group">
      <label class="control-label">
        Druh zákazky:
      </label>
      <multiselect
        v-model="value.subjectType"
        :options="subjectTypeOptions"
        track-by="code"
        label="text"
        openDirection="bottom"
        :clear-on-select="false"
        :close-on-select="true"
        :searchable="true"
        :show-labels="false"
        placeholder=""
      />
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
export default {
  components: {
    Multiselect,
    MainCpvCodeSelect
  },
  props: {
    value: Object
  },
  computed: {
    biddingMethodOptions () {
      return [
        { code: null, text: 'Všetky' },
        { code: 'lowestPrice', text: 'Najnižšej ceny' },
        { code: 'priceQualityRatio', text: 'Najlepšieho pomeru ceny a kvality' },
        { code: 'costEffectiveness', text: 'Nákladov použitím prístupu nákladovej efektívnosti najmä nákladov počas životného cyklu' }
      ]
    },
    fundingByEuOptions () {
      return [
        { code: null, text: 'Všetky' },
        { code: 'yes', text: 'Áno' },
        { code: 'no', text: 'Nie' }
      ]
    },
    subjectTypeOptions () {
      return [
        { code: null, text: 'Všetky' },
        { code: 'goods', text: 'Tovary' },
        { code: 'services', text: 'Služby' },
        { code: 'work', text: 'Práce' }
      ]
    },
    typeOptions () {
      return [
        { code: null, text: 'Všetky' },
        { code: 'nz', text: 'Nadlimitná zákazka' },
        { code: 'pz', text: 'Podlimitná zákazka' },
        { code: 'nh', text: 'ZsNH/Prieskum trhu' }
      ]
    },
    updatedAtOptions () {
      return [
        { code: null, text: 'Všetky' },
        { code: '7', text: 'Posledných 7 dní' },
        { code: '31', text: 'Posledných 31 dní' },
        { code: '365', text: 'Posledných 365 dní' }
      ]
    }
  }
}

</script>
<style lang="scss" scoped>
.filters {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px 10px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}
</style>
