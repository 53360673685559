<template>
  <div class="row">
    <div class="col-md-12">
      <div
        v-for="part in order._parts"
        :key="part.id"
        class="card"
      >
        <div class="card-heading">
          <h4 class="card-title">
            Časť {{ part.part }} - {{ part.name }}
          </h4>
        </div>
        <div class="card-block">
          <div class="list-table">
            <div class="row">
              <div class="col-md-3">
                <b>Opis predmetu zákazky</b>
              </div>
              <div class="col-md-9">
                {{ part.description }}
              </div>
            </div>
            <div
              v-if="part.estimatedPrice > 0"
              class="row"
            >
              <div class="col-md-3">
                <b>Predpokladaná hodnota v &euro; bez DPH</b>
              </div>
              <div class="col-md-9">
                {{ $format.price(part.estimatedPrice) }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b>Druh zákazky</b>
              </div>
              <div class="col-md-9">
                {{ $format.formatOrderSubject(part.subjectType) }}
              </div>
            </div>
            <div
              v-if="part.cpvCodeText"
              class="row"
            >
              <div class="col-md-3">
                <b>Hlavný CPV kód</b>
              </div>
              <div class="col-md-9">
                {{ part.cpvCodeText.texts.SK }} ({{ part.cpvCodeText.code }})
              </div>
            </div>
            <div
              v-if="part.cpvCodesText && part.cpvCodesText.length > 0"
              class="row"
            >
              <div class="col-md-3">
                <b>Doplnkové CPV kódy</b>
              </div>
              <div class="col-md-9">
                <ul class="list-unstyled">
                  <li
                    v-for="cpvCode in part.cpvCodesText"
                    :key="`${part.id}-${cpvCode.code}`"
                  >
                    {{ cpvCode.texts.SK }} ({{ cpvCode.code }})
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-if="part.bidsOpenAt"
              class="row"
            >
              <div class="col-md-3">
                <b>
                  Dátum otvárania ponúk <span v-if="order.procedureType === 'tste'">
                    (ostatené)
                  </span>
                </b>
              </div>
              <div class="col-md-9">
                {{ $format.formatDateTime(part.bidsOpenAt) }}
              </div>
            </div>
            <div
              v-if="part.bidsOpenAt2"
              class="row"
            >
              <div class="col-md-3">
                <b>Dátum otvárania ponúk (kritériá)</b>
              </div>
              <div class="col-md-9">
                {{ $format.formatDateTime(part.bidsOpenAt2) }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <b>Aukcia</b>
              </div>
              <div class="col-md-9">
                {{ part.auction ? 'Áno' : 'Nie' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'
export default {
  name: 'PartsList',
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  }
}
</script>
