<template>
  <div
    class="card"
    id="my-offer"
  >
    <div class="card-heading">
      <h4 class="card-title">
        <span v-if="order.parent && order.part">
          Časť {{ order.part}} -
        </span>{{ order.name }}
      </h4>
      <div class="actions">
        <button
          v-if="canCreateOffer && !canRevokeOffer"
          :disabled="!canCreateOffer"
          class="btn btn-tenderia-blue"
          @click="addOffer()"
        >
          <template v-if="offer.id">
            <i class="icon-pencil" /> Upraviť ponuku
          </template><template v-else>
            <i class="icon-add font-size-14" /> Vytvoriť ponuku
          </template>
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <p class="py-3">
            Koniec lehoty na predkladanie ponúk: <span
              v-if="order.bidsLimitTo"
              class="text-bold"
            >
              {{ $format.formatDateTime(order.bidsLimitTo) }}
            </span><span
              class="text-bold"
              v-else
            >
              Termín nebol stanovený
            </span>
          </p>
          <p
            class="text-dark text-center pdd-vertical-25"
            v-if="canCreateOffer"
          >
            <countdown
              :deadline="bidsLimitTo"
              :server-time="serverTime"
            ></countdown>
          </p>
        </div>
      </div>
    </div>
    <v-table
      v-if="offer.id"
      :items="[offer]"
      :columns="offerColumns"
      item-key="id"
      class="light"
    >
      <template #cell(createdAt)="{ item }">
        {{ $format.formatDateTime(item.createdAt) }}
      </template>
      <template #cell(updatedAt)="{ item }">
        {{ $format.formatDateTime(item.updatedAt) }}
      </template>
      <template #cell(sentAt)="{ item }">
        <span v-if="item.state !== 'new'">
          {{ $format.formatDateTime(item.sentAt) }}
        </span>
      </template>
      <template #cell(state)="{ item }">
        <v-badge
          class="m-0"
          :color="item.state !== 'new' ? 'green' : 'red'"
          glow
        >
          {{$format.formatOfferState(item.state)}}
        </v-badge>
      </template>
    </v-table>
    <p
      class="text-bold text-dark text-center pdd-vertical-50"
      v-else
    >
      Nemáte vytvorenú žiadnu ponuku
    </p>
    <div class="card-footer">
      <div class="row">
        <button
          v-if="offer.id"
          class="btn btn-tenderia-blue"
          @click="openOffer()"
        >
          <span>Zobraziť ponuku</span>
        </button>
        <button
          v-if="canSendOffer"
          class="btn btn-success"
          @click="sendOffer()"
        >
          <i class="icon-send" /> <span>Odoslať ponuku</span>
        </button>
        <button
          v-if="offer.id && offer.state === 'new'"
          class="btn btn-danger"
          @click="removeOfferModal()"
        >
          <i class="icon-delete" /> <span>Zmazať ponuku</span>
        </button>
        <button
          v-if="canRevokeOffer"
          class="btn btn-warning"
          @click="revokeOfferModal()"
        >
          <i class="icon-close" /> Odvolať ponuku
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import VTable from '@/components/ui/Table.vue'
import VBadge from '@/components/ui/Badge.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'PublicMarketReserachOffer',
  components: { Countdown, VTable, VBadge },
  data () {
    return {
      orderStore: useOrderStore(),
      statsStore: useStatsStore(),
      globalStore: useGlobalStore(),
      bidsLimitTo: false,
      offer: {
        id: null
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    offerColumns () {
      return [
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia'
        },
        {
          key: 'updatedAt',
          label: 'Dátum poslednej aktualizácie'
        },
        {
          key: 'sentAt',
          label: 'Dátum odoslania ponuky'
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    serverTime: function () {
      return new Date(this.statsStore.currentTime)
    },
    canSendOffer () {
      return this.offer.id && this.offer.state === 'new' && this.canCreateOffer
    },
    canCreateOffer: function () {
      return this.bidsLimitTo && this.serverTime < this.bidsLimitTo
    },
    canRevokeOffer: function () {
      return this.bidsLimitTo && this.offer.id && this.offer.state === 'sent' && this.serverTime < this.bidsLimitTo
    }
  },
  watch: {
    'order.id' (val) {
      if (val) {
        this.getOffer()
      }
    }
  },
  methods: {
    getOffer () {
      var vm = this
      this.bidsLimitTo = this.order.bidsLimitTo ? new Date(this.order.bidsLimitTo) : false
      this.$http.post('/offer/get', { offer: { order: this.order.id } }).then(function (offerResponse) {
        vm.bidsLimitTo = vm.order.bidsLimitTo ? new Date(vm.order.bidsLimitTo) : false
        if (offerResponse.data.offer.id) {
          vm.offer = offerResponse.data.offer
        }
      })
    },
    addOffer () {
      this.$router.push({ name: 'publicMarketResearchOfferForm', params: { orderId: this.order.id, token: this.$route.params.token } })
    },
    openOffer () {
      this.$router.push({ name: 'publicMarketResearchOfferView', params: { orderId: this.order.id, token: this.$route.params.token } })
    },
    async sendOffer () {
      this.$router.push({ name: 'publicMarketResearchOfferForm', params: { orderId: this.order.id, token: this.$route.params.token, action: 'send' } })
    },
    async revokeOfferModal () {
      this.YesNoAlert({
        text: 'Skutočne chcete odvolať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odvolať'
      }).then((result) => {
        if (result.value) {
          this.revokeOffer()
        }
      })
    },
    async revokeOffer () {
      this.ShowLoading({ title: 'Odvolávam ponuku' })
      try {
        await this.$http.post('/offer/set', { offer: { organization: this.globalStore.userOrganization.id, order: this.order.id, id: this.offer.id, state: 'new' } })
        this.offer.state = 'new'
        await this.orderStore.readOrder(this.orderStore.order.id)
        this.CloseLoading()
      } catch (error) {
        console.error(error)
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    },
    async removeOfferModal () {
      this.YesNoAlert({
        text: 'Skutočne chcete zmazať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeOffer()
        }
      })
    },
    async removeOffer () {
      this.ShowLoading({ title: 'Ponuka sa odstraňuje' })
      try {
        await this.$http.post('/offer/remove', { offer: { order: this.order.id } })
        this.offer = {
          id: null
        }
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    }
  },
  mounted () {
    if (this.order.id) {
      this.getOffer()
    }
  }
}
</script>
