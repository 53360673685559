<template>
  <div
    v-if="!loading || order.id"
    class="container-fluid"
  >
    <ul class="order-main-menu mb-3">
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-overview`, params: {orderId: $route.params.orderId}}">
          Prehľad
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-communication`, params: {orderId: $route.params.orderId}}">
          Komunikácia
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-offers`, params: {orderId: $route.params.orderId}}">
          Moja ponuka
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-offers-opening`, params: {orderId: $route.params.orderId}}">
          Otváranie ponúk
        </router-link>
      </li>
      <!-- <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-auctions`, params: {orderId: $route.params.orderId}}">
          Aukcie
        </router-link>
      </li> -->
    </ul>
    <router-view />
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'
export default {
  name: 'User',
  data () {
    return {
      orderStore: useOrderStore(),
      loading: false,
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    canInterest () {
      if (this.order.divided) {
        return this.order.state === 'active' && !this.order._parts.reduce((accumulator, part) => part.isInterested && accumulator, true)
      } else {
        return this.order.state === 'active' && !this.order.isInterested
      }
    }
  },
  mounted () {
    this.loadOrder()
  },
  methods: {
    async loadOrder () {
      try {
        this.ShowLoading()
        this.loading = true
        await this.orderStore.readOrder(this.$route.params.orderId)
        this.loading = false
        this.CloseLoading()
      } catch (error) {
        console.log(error)
        this.loading = false
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať zákazku' }, error)
      }
    }
  }
}
</script>
