<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div
          class="card"
          id="request-settings-general"
        >
          <div class="card-heading">
            <h4 class="card-title">
              Základné údaje žiadanky
            </h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Názov žiadanky<small>*</small>
                </label>
                <input
                  type="text"
                  placeholder="Názov"
                  v-model="request.name"
                  class="form-control"
                  :class="{ error: v$.request.name.$error }"
                />
                <label
                  v-if="v$.request.name.$error"
                  class="error"
                >
                  {{ vuelidateErrors.name }}
                </label>
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Schvaľovatelia<small>*</small>
                </label>
                <div
                  v-if="Array.isArray(budgetApprovers) && budgetApprovers.length > 0"
                  class="column mb-2"
                >
                  <span
                    v-for="(approver, index) in budgetApprovers"
                    :key="index"
                  >
                    {{ approver.firstname }} {{ approver.lastname }} ({{ approver.email }})
                  </span>
                </div>
                <request-approvers-select
                  v-model="request.approvers"
                  :class="{ error: v$.request.approvers.$error }"
                  aria-required="true"
                />
                <label
                  v-if="v$.request.approvers.$error"
                  class="error"
                >
                  {{ vuelidateErrors.approvers }}
                </label>
              </div>

              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Druh zákazky<small>*</small>
                </label>
                <div>
                  <div class="radio radio-inline">
                    <input
                      id="subject-type-goods"
                      type="radio"
                      name="request-subject-type"
                      v-model="request.subjectType"
                      value="goods"
                    />
                    <label for="subject-type-goods">
                      Tovary
                    </label>
                  </div>
                  <div class="radio radio-inline">
                    <input
                      id="subject-type-services"
                      type="radio"
                      name="request-subject-type"
                      v-model="request.subjectType"
                      value="services"
                    />
                    <label for="subject-type-services">
                      Služby
                    </label>
                  </div>
                  <div class="radio radio-inline">
                    <input
                      id="subject-type-work"
                      type="radio"
                      name="request-subject-type"
                      v-model="request.subjectType"
                      value="work"
                    />
                    <label for="subject-type-work">
                      Práce
                    </label>
                  </div>
                </div>

                <label
                  v-if="v$.request.subjectType.$error"
                  class="error"
                >
                  {{ vuelidateErrors.subjectType }}
                </label>
              </div>

              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Kategória (CPV)<small>*</small>
                </label>
                <main-cpv-code-select
                  v-model="request.cpvCode"
                  :class="{ error: v$.request.cpvCode.$error }"
                  aria-required="true"
                />
                <label
                  v-if="v$.request.cpvCode.$error"
                  class="error"
                >
                  {{ vuelidateErrors.cpvCode }}
                </label>
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Rozpočet
                </label>
                <budget-select
                  v-model="request.budgets"
                  :class="{ error: v$.request.budgets.$error }"
                  :multiple="false"
                  aria-required="true"
                />
                <label
                  v-if="v$.request.budgets.$error"
                  class="error"
                >
                  {{ vuelidateErrors.budgets }}
                </label>
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">
                  PHZ bez DPH<small>*</small>
                </label>
                <vue-numeric
                  :disabled="disableValue"
                  class="form-control"
                  currency="€"
                  separator="space"
                  decimal-separator=","
                  :precision="2"
                  :empty-value="0"
                  v-model="request.value"
                  :class="{ error: v$.request.value.$error }"
                />
                <label
                  v-if="v$.request.value.$error"
                  class="error"
                >
                  {{ vuelidateErrors.value }}
                </label>
                <label>
                  Po pridaní položiek budu sumy automaticky prepočítané
                </label>
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">
                  PHZ s DPH<small>*</small>
                </label>
                <vue-numeric
                  :disabled="disableValue"
                  class="form-control"
                  currency="€"
                  separator="space"
                  decimal-separator=","
                  :precision="2"
                  :empty-value="0"
                  v-model="request.valueWithVat"
                  :class="{ error: v$.request.valueWithVat.$error }"
                />
                <label
                  v-if="v$.request.valueWithVat.$error"
                  class="error"
                >
                  {{ vuelidateErrors.valueWithVat }}
                </label>
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Dátum dodania<small>*</small>
                </label>
                <date-input
                  name="deliveryDate"
                  v-model="request.deliveryDate"
                  placeholder="Dátum dodania"
                  :error="v$.request.deliveryDate.$error"
                ></date-input>
                <label
                  v-if="v$.request.deliveryDate.$error"
                  class="error"
                >
                  {{ vuelidateErrors.deliveryDate }}
                </label>
              </div>
              <div class="col-md-12 form-group">
                <label class="text-dark text-bold">
                  Opis predmetu zákazky
                </label>
                <textarea
                  id="description"
                  v-model="request.description"
                  class="form-control"
                  :class="{ error: v$.request.description.$error }"
                ></textarea>
                Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br />
                <label
                  v-if="v$.request.description.$error"
                  class="error"
                >
                  {{ vuelidateErrors.description }}
                </label>
              </div>
              <div class="col-md-12 form-group">
                <label class="text-dark text-bold">
                  Odôvodnenie potreby
                </label>
                <textarea
                  id="description"
                  v-model="request.justification"
                  class="form-control"
                  :class="{ error: v$.request.justification.$error }"
                ></textarea>
                Počet zostávajúcich znakov: {{ justificationTextRemaining }}<br />
                <label
                  v-if="v$.request.justification.$error"
                  class="error"
                >
                  {{ vuelidateErrors.justification }}
                </label>
              </div>
              <div class="col-md-12 form-group">
                <label class="text-dark text-bold">
                  Poznámka
                </label>
                <textarea
                  id="description"
                  v-model="request.note"
                  class="form-control"
                  :class="{ error: v$.request.note.$error }"
                ></textarea>
                Počet zostávajúcich znakov: {{ noteTextRemaining }}<br />
                <label
                  v-if="v$.request.note.$error"
                  class="error"
                >
                  {{ vuelidateErrors.note }}
                </label>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label
                    class="control-label"
                    for="file-upload"
                  >
                    Súbory
                  </label>
                  <file-upload-element
                    v-model="files"
                    :attachments="request.attachments"
                    @remove-saved="removeSavedFile"
                  ></file-upload-element>
                  <!-- <label class="error" for="file-upload">Musíte pridať aspoň jeden súbor.</label> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <request-suppliers-list :request="request" />
    <items-list-widget :request="request" />
    <div class="row no-gutters justify-content-end">
      <div v-if="$route.params.requestId">
        <button
          class="btn btn-danger"
          @click="removeRequestModal()"
          href=""
        >
          <i class="icon-delete"></i>Zmazať žiadanku
        </button>
      </div>
      <div>
        <button
          class="btn btn-success"
          @click="saveSettings()"
          href=""
        >
          <i class="icon-save"></i>Uložiť
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/forms/DateInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'
import ItemsListWidget from '@/components/requests/components/ItemsListWidget.vue'
import FileUploadElement from '@/components/forms/FileUploadElement.vue'
import RequestApproversSelect from '@/components/forms/RequestApproversSelect.vue'
import BudgetSelect from '@/components/forms/BudgetSelect.vue'
//import UploadManager from '@/components/utils/UploadManager.js'
import RequestSuppliersList from '../components/RequestSuppliersList.vue'
import { useVuelidate } from '@vuelidate/core'
import {
  useVuelidateValidationErrors,
  validateRequired,
  validateRequiredIf,
  validateMaxLength
} from '@/components/validator'
import { useRequestStore } from '@/storePinia/requestStore'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'RequestSettings',
  components: {
    DateInput,
    MainCpvCodeSelect,
    VueNumeric,
    ItemsListWidget,
    FileUploadElement,
    RequestApproversSelect,
    BudgetSelect,
    RequestSuppliersList
  },
  inject: ['UploadProgressModal'],
  setup () {
    const v$ = useVuelidate({
      $lazy: true,
      $autoDirty: true,
    })
    return { v$ }
  },
  validations () {
    return {
      request: {
        name: { validateRequired },
        approvers: { validateRequiredIf: validateRequiredIf(Array.isArray(this.budgetApprovers) && this.budgetApprovers.length === 0) },
        subjectType: { validateRequired },
        cpvCode: { validateRequired },
        value: { validateRequired },
        valueWithVat: { validateRequired },
        deliveryDate: { validateRequired },
        budgets: { validateRequired },
        justification: { validateMaxLength: validateMaxLength(4000) },
        description: { validateMaxLength: validateMaxLength(4000) },
        note: { validateMaxLength: validateMaxLength(4000)
        }
      }
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      requestStore: useRequestStore(),
      statsStore: useStatsStore(),
      files: [],
      budgetApprovers: [],
      requestId: this.$route.params.requestId
    }
  },
  watch: {
    requestItems: {
      handler (items) {
        this.request.value = items.reduce((total, item) => total + (item.estimatedPrice || 0), 0)
        this.request.valueWithVat = items.reduce((total, item) => total + (item.estimatedPrice + (item.estimatedPrice * (item.vat / 100)) || 0), 0)
      }
    },
    requestBudget: {
      handler (val) {
        if (val) {
          this.fetchBudgetApprovers(val)
        } else {
          this.budgetApprovers = []
        }
      }
    }
  },
  computed: {
    request () {
      return this.requestStore.request
    },
    vuelidateErrors () {
      return useVuelidateValidationErrors(this.v$.$errors)
    },
    disableValue () {
      return Array.isArray(this.request.items) && this.request.items.length > 0
    },
    requestItems () {
      return this.request.items || []
    },
    requestBudget () {
      return Array.isArray(this.request.budgets) && this.request.budgets.length > 0 ? this.request.budgets[0].id : null
    },
    budgetFetchParams () {
      return {
        organization: {
          id: this.globals.userOrganization.id
        },
        budget: this.requestBudget
      }
    },
    descriptionTextRemaining () {
      return (this.request.description && 4000 - this.request.description.length) || 4000
    },
    justificationTextRemaining () {
      return (this.request.justification && 4000 - this.request.justification.length) || 4000
    },
    noteTextRemaining () {
      return (this.request.note && 4000 - this.request.note.length) || 4000
    },
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
  methods: {
    async fetchBudgetApprovers () {
      let { data } = await this.$http.post('/organization/approver/list', this.budgetFetchParams)
      this.budgetApprovers = data.approvers
    },
    onError (error) {
      this.CloseLoading()
      this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error)
    },
    finishSave () {
      let message = 'Nová žiadanka bola vytvorená.'
      if (this.$route.params.requestId) {
        message = 'Údaje boli uložené.'
      }
      this.$router.push({ name: 'request-overview', params: { requestId: this.requestId } })
      setTimeout(() => {
        this.PopupAlert({ title: message })
      }, 1000)
    },
    async addAttachemnts (attachments) {
      let data = {
        request: {
          id: this.requestId
        },
        attachment: {
          id: attachments
        }
      }
      try {
        await this.$http.post('/request/attachment/add', data)
        this.finishSave()
      } catch (error) {
        this.onError(error)
      }
    },
    async saveSettings () {
      const isFormCorrect = await this.v$.request.$validate()
      if (!isFormCorrect) return
      this.ShowLoading({ title: 'Ukladám nastavenia žiadanky' })
      const data = {
        request: {
          name: this.request.name,
          description: this.request.description,
          value: this.request.value,
          valueWithVat: this.request.valueWithVat,
          subjectType: this.request.subjectType,
          cpvCode: this.request.cpvCode,
          justification: this.request.justification,
          deliveryDate: this.request.deliveryDate,
          note: this.request.note,
          suppliers: this.request.suppliers && this.request.suppliers.map(approver => approver._id),
          items: this.request.items,
          id: this.request.id,
          approvers: this.request.approvers && this.request.approvers.map(approver => approver.id),
          budgets: this.request.budgets && this.request.budgets.map(budget => budget.id)
        }
      }
      try {
        const response = await this.$http.post('/request/set', data)
        this.requestId = response.data.id
        if (this.files.length > 0) {
          const titles = ['Ukladám prílohy žiadanky', 'Prosím čakajte', 'Zvážte aktiváciu Prémiových služieb', 'Získate zobrazenie priebehu nahrávania', 'Získate plnú rýchlosť nahrávania súborov', 'Získate mnoho ďalších funkcií']
          await this.UploadProgressModal({ files: this.files, title: 'Ukladám prílohy žiadanky', titles, onDone: this.addAttachemnts, onError: this.onError, fields: { request: response.data.id } })
          //this.UploadProgressAlert({ title: 'Ukladám prílohy žiadanky' }, this.statsStore)
          //const uploader = new UploadManager(this.addAttachemnts, this.onError, { request: response.data.id }, this.$http)
          //uploader.startUpload(this.files, { offset: 0, totalCount: this.files.length })
        } else {
          this.finishSave()
        }
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error)
      }
    },
    removeRequestModal () {
      this.YesNoAlert({
        title: 'Skutočne chcete zmazať túto žiadanku?',
        text: 'žiadanka sa po zmazaní už nebude dať obnoviť.',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then(async (result) => {
        if (result.value) {
          this.ShowLoading()
          try {
            await this.$http.post('/request/delete', { request: { id: this.$route.params.requestId } })
            this.CloseLoading()
            this.OkAlert({ text: 'Žiadanka bola zmazaná.' })
            if (this.request.user === this.globals.user.id) {
              this.$router.push({ path: '/app/my-requests/all' })
            } else {
              this.$router.push({ path: '/app/requests/all' })
            }
          } catch (error) {
            this.CloseLoading()
            this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error)
          }
        }
      })
    },
    removeSavedFile (value) {
      var data = {
        request: {
          id: this.request.id
        },
        attachment: {
          id: value.fileList[value.index].id
        }
      }
      try {
        this.$http.post('/request/attachment/remove', data)
        this.request.attachments.splice(value.index, 1)
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error)
      }
    }
  }
}
</script>
