import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import Admin from '../views/Admin'
import AdminHome from '../views/AdminHome'
import AdminInterested from '../views/AdminInterested'
import AdminOverview from '../views/AdminOverview'
import AdminSettings from '../views/AdminSettings'
import User from '../views/User'
import UserOverview from '../views/UserOverview'
import { permissions } from '@/router/permissions.js'

export default [
  {
    path: 'ptk/:orderId',
    name: 'ptk-admin',
    redirect: { name: 'ptk-admin-overview' },
    component: Admin,
    children: [
      {
        path: 'home',
        name: 'ptk-admin-home',
        redirect: { name: 'ptk-admin-overview' },
        component: AdminHome,
        children: [
          {
            path: 'overview',
            name: 'ptk-admin-overview',
            component: AdminOverview,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          },
          {
            path: 'settings',
            name: 'ptk-admin-settings',
            component: AdminSettings,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          },
          {
            path: 'documents',
            name: 'ptk-admin-documents',
            component: AdminDocuments,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          },
          {
            path: 'documents/add',
            name: 'ptk-admin-documents-add',
            component: AdminDocumentsAdd,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          },
          {
            path: 'documents/edit/:id',
            name: 'ptk-admin-documents-edit',
            component: AdminDocumentsAdd,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          },
        ]
      },
      {
        path: 'communication',
        name: 'ptk-admin-communication',
        component: AdminCommunication,
        meta: {
          viewRights: [permissions.ORDER_ORDER_CREATE_0]
        },
      },
      {
        path: 'communication/add',
        name: 'ptk-admin-communication-add',
        component: AdminCommunicationAdd,
        meta: {
          viewRights: [permissions.ORDER_ORDER_CREATE_0]
        },
      },
      {
        path: 'interested',
        name: 'ptk-admin-interested',
        component: AdminInterested,
        meta: {
          viewRights: [permissions.ORDER_ORDER_CREATE_0]
        },
      }
    ]
  },
  {
    path: 'ptk/user/:orderId',
    name: 'ptk-user',
    redirect: { name: 'ptk-user-overview' },
    component: User,
    children: [
      {
        path: 'overview',
        name: 'ptk-user-overview',
        component: UserOverview,
        meta: {
          viewRights: [permissions.ORDER_OFFER_SET]
        },
      },
      {
        path: 'communication',
        name: 'ptk-user-communication',
        component: UserCommunication,
        meta: {
          viewRights: [permissions.ORDER_OFFER_SET]
        },
      },
      {
        path: 'communication/add',
        name: 'ptk-user-communication-add',
        component: UserCommunicationAdd,
        meta: {
          viewRights: [permissions.ORDER_OFFER_SET]
        },
      }
    ]
  }
]
