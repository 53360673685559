<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div
          class="card"
          id="invoice-settings-general"
        >
          <div class="card-heading">
            <h4 class="card-title">
              Základné údaje zmluvy
            </h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Názov zmluvy<small>*</small>
                </label>
                <input
                  type="text"
                  v-model="invoice.name"
                  class="form-control"
                />
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Zákazka<small>*</small>
                </label>
                <input
                  type="text"
                  v-model="invoice.order"
                  class="form-control"
                />
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Stručný opis<small>*</small>
                </label>
                <input
                  type="textarea"
                  v-model="invoice.description"
                  class="form-control"
                />
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">
                  Zmluvná cena bez DPH<small>*</small>
                </label>
                <vue-numeric
                  :disabled="disableValue"
                  class="form-control"
                  currency="€"
                  separator="space"
                  decimal-separator=","
                  :precision="2"
                  :empty-value="0"
                  v-model="invoice.value"
                />
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">
                  Zmluvná cena s DPH<small>*</small>
                </label>
                <vue-numeric
                  :disabled="disableValue"
                  class="form-control"
                  currency="€"
                  separator="space"
                  decimal-separator=","
                  :precision="2"
                  :empty-value="0"
                  v-model="invoice.valueWithVat"
                />
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">
                  Dátum trvania od<small>*</small>
                </label>
                <date-input
                  name="deliveryDate"
                  v-model="invoice.dateFrom"
                  placeholder="Dátum dodania"
                ></date-input>
              </div>
              <div class="col-md-3 form-group">
                <label class="text-dark text-bold">
                  Dátum trvania do<small>*</small>
                </label>
                <date-input
                  name="deliveryDate"
                  v-model="invoice.dateTo"
                  placeholder="Dátum dodania"
                ></date-input>
              </div>
              <div class="col-md-6 form-group">
                <label class="text-dark text-bold">
                  Upozorniť pred koncom zmluvy (T)<small>*</small>
                </label>
                <input
                  type="number"
                  v-model="invoice.ending"
                  class="form-control"
                />
              </div>
              <div class="col-md-6 form-group">
                <!-- <label class="text-dark text-bold">Uve<small>*</small></label> -->
                <div class="checkbox">
                  <input
                    type="checkbox"
                    :id="'invoiceId'"
                    :value="invoice.post"
                    name="invoice-post"
                  />
                  <label :for="'invoiceId'">
                    Uverejniť zmluvu?
                  </label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label
                    class="control-label"
                    for="file-upload"
                  >
                    Súbory
                  </label>
                  <file-upload-element
                    v-model="files"
                    :attachments="invoice.attachments"
                  ></file-upload-element>
                  <!-- <label class="error" for="file-upload">Musíte pridať aspoň jeden súbor.</label> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <invoice-suppliers-list :invoice="invoice" />
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/forms/DateInput.vue'
import VueNumeric from 'vue-numeric'
import FileUploadElement from '@/components/forms/FileUploadElement.vue'
import InvoiceSuppliersList from '@/components/invoices/components/InvoiceSuppliersList.vue'

export default {
  name: 'InvoiceSettings',
  components: { DateInput, VueNumeric, InvoiceSuppliersList, FileUploadElement },
  inject: ['invoice'],
  computed: {

  },
  data () {
    return {
      files: []
    }
  },
  watch: {

  },
  methods: {

  },
  mounted () {

  },
  created () {

  }
}
</script>
