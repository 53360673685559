<template>
  <table class="table-sm width-100 mrg-btm-20 mrg-top-10 font-size-12">
    <thead class="border bottom">
      <tr>
        <th>Organizácia</th>
        <th>Stav</th>
        <th>Odoslaná dňa</th>
        <th>Čas odoslania</th>
        <th>Čas doručenia</th>
        <th>Prečítaná dňa</th>
        <th>Čas prečítania</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(user, index) in userStats"
        :key="`user-stat-${index}`"
      >
        <td>{{user.organization}}</td>
        <td>
          <template v-if="user.readFlag">
            Prečítané
          </template><template v-else>
            Neprečítané
          </template>
        </td>
        <td>{{ $format.formatDate(message.sentAt || message.createdAt) }}</td>
        <td>{{ $format.formatTime(message.sentAt || message.createdAt) }}</td>
        <td>{{ $format.formatTime(message.sentAt || message.createdAt) }}</td>
        <td>
          <template v-if="user.readFlag">
            {{ $format.formatDate(user.readAt) }}
          </template>
        </td>
        <td>
          <template v-if="user.readFlag">
            {{ $format.formatTime(user.readAt) }}
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'MessageStateTable',
  props: ['message', 'userStats']
}
</script>
