<template>
  <div
    v-if="order.divided"
    class="row"
  >
    <div class="col-md-12">
      <offer-widget
        v-for="part in order._parts"
        :key="part.id"
        :order="part"
      />
    </div>
  </div>
  <div
    v-else
    class="row"
  >
    <div class="col-md-12">
      <offer-widget :order="order" />
    </div>
  </div>
</template>

<script>
import OfferWidget from '@/components/order/common/components/OfferWidget.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'UserOffer',
  components: { OfferWidget },
  data () {
    return {
      orderStore: useOrderStore()
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  }
}
</script>
