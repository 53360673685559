<template>
  <multiselect
    v-model="country"
    :options="countries"
    track-by="code"
    label="text"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="true"
    :show-labels="false"
    :disabled="disabled"
    placeholder="Vyberte si krajinu"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'CountrySelect',
  components: { Multiselect },
  props: {
    value: String,
    disabled: Boolean
  },
  data () {
    return {
      countries: [],
      loading: false
    }
  },
  computed: {
    country: {
      get () {
        return this.countries.find(country => country?.code === this.value)
      },
      set (val) {
        this.$emit('input', val ? val.code : null)
      }
    }
  },
  async mounted () {
    this.loading = true
    var countries = JSON.parse(localStorage.getItem('COUNTRIES'))
    if (countries != null) {
      this.countries = countries
      this.loading = false
    } else {
      const response = await this.$http.post('/enum/countries/list', { limit: 10000 })
      localStorage.setItem('COUNTRIES', JSON.stringify(response.data.enum))
      this.loading = false
      this.countries = response.data.enum
    }
  }
}
</script>
