<template>
  <multiselect
    v-model="selectedBudgets"
    :options="budgets"
    track-by="id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="!multiple"
    :searchable="true"
    :show-labels="false"
    :multiple="multiple"
    placeholder="Vyberte si rozpočty"
    :loading="loading"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'BudgetSelect',
  components: { Multiselect },
  props: {
    value: [Array, String],
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      budgets: [],
      loading: false
    }
  },
  computed: {
    fetchParams () {
      return {
        id: this.globals.userOrganization.id
      }
    },
    selectedBudgets: {
      get() {
        return this.budgets.filter(budget => this.value.find(val => val.id === budget.id))
      },
      set(val) {
        this.$emit('input', this.multiple ? val : [val])
      }
    }
  },
  async mounted () {
    this.loading = true
    let { data } = await this.$http.post('/budget/list', this.fetchParams)
    this.loading = false
    this.budgets = data.budgets
  }
}
</script>
