<template>
  <div
    id="order-add-criterion"
    class="card"
  >
    <div class="card-heading">
      <h4
        v-if="!isNaN($route.params.index)"
        class="card-title"
      >
        Upraviť kritérium
      </h4>
      <h4
        v-else
        class="card-title"
      >
        Pridať nové kritérium
      </h4>
    </div>
    <div class="card-block">
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="form-group">
            <label
              for="criterion-name"
              class="text-dark text-bold"
            >
              Názov <small>*</small>
            </label>
            <input
              id="criterion-name"
              v-model.lazy="criterion.name"
              type="text"
              class="form-control"
              :class="{ error: form.name.error, valid: form.name.valid }"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.name.error"
              class="error"
              for="criterion-name"
            >
              Názov kritéria musí byť vyplnený a môže obsahovať max. 250 znakov.
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label
              for="criterion-min"
              class="text-dark text-bold"
            >
              Minimálna hodnota
            </label>
            <input
              id="criterion-min"
              v-model.lazy="criterion.minimalValue"
              type="number"
              class="form-control"
              :class="{ error: form.min.error, valid: form.min.valid }"
            >
            <label
              v-if="form.min.error"
              class="error"
              for="criterion-min"
            >
              Minimálna hodnota musí byť číslo.
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label
              for="criterion-max"
              class="text-dark text-bold"
            >
              Maximálna hodnota
            </label>
            <input
              id="criterion-max"
              v-model.lazy="criterion.maximalValue"
              type="number"
              class="form-control"
              :class="{ error: form.max.error, valid: form.max.valid }"
            >
            <label
              v-if="form.max.error"
              class="error"
              for="criterion-max"
            >
              Maximálna hodnota musí byť číslo.
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label
              for="decimal-count"
              class="text-dark text-bold"
            >
              Počet desatinných miest
            </label>
            <input
              id="decimal-count"
              v-model.lazy="criterion.decimalCount"
              type="number"
              min="0"
              max="5"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label
              for="maximum-points"
              class="text-dark text-bold"
            >
              Maximálny počet bodov
            </label>
            <input
              id="maximum-points"
              v-model.lazy="criterion.maximumPoints"
              type="number"
              min="0"
              max="100"
              class="form-control"
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="text-dark text-bold">
              Povaha <small>*</small>
            </label><br>
            <div class="radio radio-inline">
              <input
                id="nature-ascending"
                v-model="criterion.nature"
                type="radio"
                value="ascending"
              > <label for="nature-ascending">
                Stúpajúca (najlepšia je najvyššia hodnota)
              </label>
            </div>
            <div class="radio radio-inline">
              <input
                id="nature-descending"
                v-model="criterion.nature"
                type="radio"
                value="descending"
              > <label for="nature-descending">
                Klesajúca (najlepšia je najnižšia hodnota)
              </label>
            </div>
            <label
              v-if="form.nature.error"
              class="error"
              for="criterion-min"
            >
              Povaha musí byť vyplnená.
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <label class="text-dark text-bold">
            Typ: <small>*</small>
          </label><br>
          <div class="radio radio-inline">
            <input
              id="type-price"
              v-model="criterion.type"
              type="radio"
              value="price"
            > <label for="type-price">
              Cenové
            </label>
          </div>
          <div class="radio radio-inline">
            <input
              id="type-noprice"
              v-model="criterion.type"
              type="radio"
              value="noprice"
            > <label for="type-noprice">
              Necenové
            </label>
          </div>
        </div>
        <div class="col-md-3">
          <template v-if="criterion.type === 'price'">
            <label class="text-dark text-bold">
              Hodnotená bude: <small>*</small>
            </label><br>
            <div class="radio radio-inline">
              <input
                id="tax-inclusion-yes"
                v-model="criterion.taxInclusion"
                type="radio"
                :value="true"
              > <label for="tax-inclusion-yes">
                Cena s DPH
              </label>
            </div>
            <div class="radio radio-inline">
              <input
                id="tax-inclusion-no"
                v-model="criterion.taxInclusion"
                type="radio"
                :value="false"
              > <label for="tax-inclusion-no">
                Cena bez DPH
              </label>
            </div>
          </template>
          <template v-else>
            <div class="form-group">
              <label
                for="units"
                class="text-dark text-bold"
              >
                Merná jednotka
              </label>
              <input
                id="units"
                v-model.lazy="criterion.unit"
                type="text"
                class="form-control"
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <button
          class="btn btn-tenderia-grey-blue btn-inverse"
          @click="goBack"
        >
          <i class="icon-arrow-left" /> Späť
        </button>
        <button
          class="btn btn-success"
          :disabled="saveDisabled"
          @click="addCriterium"
        >
          <i class="icon-save" /> Uložiť
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import validator from '@/components/validator.js'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminCriteriaAdd',
  data () {
    return {
      orderStore: useOrderStore(),
      criterion: {
        type: 'noprice',
        name: '',
        nature: '',
        minimalValue: '',
        maximalValue: '',
        decimalCount: 0,
        taxInclusion: false,
        maximumPoints: 100,
        unit: ''
      },
      form: {
        name: {
          error: false,
          valid: false
        },
        min: {
          error: false,
          valid: false
        },
        max: {
          error: false,
          valid: false
        },
        nature: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    saveDisabled () {
      var valid = this.form.name.valid
      if (this.criterion.minimalValue) {
        valid = valid && this.form.min.valid
      }
      if (this.criterion.maximalValue) {
        valid = valid && this.form.max.valid
      }
      if (this.criterion.minimalValue) {
        valid = valid && this.form.min.valid
      }
      return !valid
    }
  },
  watch: {
    'criterion.name': function (value) {
      var state = this.form.name
      state.error = state.valid = false
      if (validator.validateString(value, 250, 1)) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    'criterion.type': function (value) {
      if (value === 'noprice') {
        this.criterion.nature = ''
      } else if (value === 'price') {
        this.criterion.nature = 'descending'
      }
    },
    'criterion.minimalValue': function (value) {
      var state = this.form.min
      state.error = state.valid = false
      if (!value) {
        return
      }
      if (validator.validateNumeric(value)) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    'criterion.maximalValue': function (value) {
      var state = this.form.max
      state.error = state.valid = false
      if (!value) {
        return
      }
      if (validator.validateNumeric(value)) {
        state.valid = true
      } else {
        state.error = true
      }
    }
  },
  mounted () {
    this.loadCriterion()
  },
  methods: {
    validateNature () {
      var state = this.form.nature
      state.error = state.valid = false
      if (this.criterion.nature) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    goBack () {
      window.history.go(-1)
    },
    async addCriterium () {
      this.validateNature()
      if (this.form.nature.error) {
        return
      }
      this.ShowLoading()
      var data = {
        order: {
          id: this.$route.params.partId ? this.$route.params.partId : this.$route.params.orderId
        },
        criterion: {
          name: this.criterion.name,
          type: this.criterion.type,
          nature: this.criterion.nature,
          unit: this.criterion.unit,
          taxInclusion: this.criterion.taxInclusion,
          minimalValue: this.criterion.minimalValue.toString().replaceAll(' ', '').replaceAll(',', '.') || Number.MIN_SAFE_INTEGER,
          maximalValue: this.criterion.maximalValue.toString().replaceAll(' ', '').replaceAll(',', '.') || Number.MAX_SAFE_INTEGER,
          decimalCount: this.criterion.decimalCount,
          maximumPoints: this.criterion.maximumPoints
        }
      }
      if (!isNaN(this.$route.params.index)) {
        data.criterion.id = this.criterion.id
      }
      try {
        await this.$http.post('/order/criterion/set', data)
        await this.orderStore.getOrder(this.order.id)
        this.CloseLoading()
        this.goBack()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    },
    setCriterionData (criterion) {
      this.criterion = {
        name: criterion.name,
        type: criterion.type,
        nature: criterion.nature,
        unit: criterion.unit,
        taxInclusion: criterion.taxInclusion,
        minimalValue: criterion.minimalValue === Number.MIN_SAFE_INTEGER ? '' : criterion.minimalValue.toString().replaceAll('.', ','),
        maximalValue: criterion.maximalValue === Number.MAX_SAFE_INTEGER ? '' : criterion.maximalValue.toString().replaceAll('.', ','),
        decimalCount: criterion.decimalCount,
        maximumPoints: criterion.maximumPoints,
        id: criterion._id
      }
    },
    async loadCriterion () {
      this.ShowLoading()
      if (this.order.divided && this.$route.params.partId) {
        const response = await this.$http.post('/order/get', { order: { id: this.$route.params.partId } })
        this.part = response.data.order
        if (!isNaN(this.$route.params.index)) {
          this.setCriterionData(this.part.criteria[this.$route.params.index])
        }
        this.CloseLoading()
      } else {
        if (!isNaN(this.$route.params.index)) {
          this.setCriterionData(this.order.criteria[this.$route.params.index])
        }
        this.CloseLoading()
      }
    }
  }
}
</script>
