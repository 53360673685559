<template>
  <div class="mt-3">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Osobné údaje
        </h4>
      </div>
      <div class="card-block">
        <form novalidate="novalidate">
          <div class="row">
            <div class="col-md-6 form-group">
              <label
                for="firstname"
                class="text-dark text-bold"
              >
                Meno <small>*</small>
              </label>
              <input
                id="firstname"
                type="text"
                v-model="user.firstname"
                class="form-control"
                placeholder="Meno"
                :class="{ error: v$.user.firstname.$error }"
              />
              <label
                v-if="v$.user.firstname.$error"
                class="error"
              >
                {{ vuelidateErrors.firstname }}
              </label>
            </div>
            <div class="col-md-6 form-group">
              <label
                for="lastname"
                class="text-dark text-bold"
              >
                Priezvisko <small>*</small>
              </label>
              <input
                id="lastname"
                type="text"
                v-model="user.lastname"
                class="form-control"
                placeholder="Priezvisko"
                :class="{ error: v$.user.lastname.$error }"
              />
              <label
                v-if="v$.user.lastname.$error"
                class="error"
              >
                {{ vuelidateErrors.lastname }}
              </label>
            </div>
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">
                Titul pred menom
              </label>
              <input
                id="title1"
                type="text"
                v-model="user.title1"
                class="form-control"
                placeholder="Titul"
              />
            </div>
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">
                Titul za menom
              </label>
              <input
                id="title2"
                type="text"
                v-model="user.title2"
                class="form-control"
                placeholder="Titul"
              />
            </div>
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">
                Email <small>*</small>
              </label>
              <input
                id="email"
                type="text"
                v-model.lazy="user.email"
                class="form-control"
                placeholder="Email"
                disabled
              />
            </div>
            <div class="col-md-6 form-group">
              <label class="text-dark text-bold">
                Teleónne čislo <small>*</small>
              </label>
              <input
                id="phone"
                type="text"
                v-model="user.phone"
                class="form-control"
                placeholder="Zadávajte v tvare +421 123 456 789"
                :class="{ error: v$.user.phone.$error }"
              />
              <label
                v-if="v$.user.phone.$error"
                class="error"
              >
                {{ vuelidateErrors.phone }}
              </label>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row justify-content-end">
          <button
            id="save-profile"
            @click.prevent="updateProfile"
            class="btn btn-success"
            :disabled="v$.user.$errors.length > 0"
          >
            <i class="icon-save"></i> Aktualizovať profil
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Prihlasovacie údaje / zmena hesla
        </h4>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="text-dark text-bold">
                Prihlasovacie meno / email
              </label>
              <input
                type="email"
                v-model.lazy="globals.user.email"
                placeholder="Email"
                class="form-control"
                disabled
              >
            </div>
          </div>
        </div>
        <div class="row align-items-baseline">
          <div class="col-6">
            <div class="form-group">
              <label class="text-dark text-bold">
                Nové heslo <small>*</small>
              </label>
              <password
                id="password1"
                type="password"
                placeholder="Heslo"
                defaultClass="form-control"
                v-model="userPassword.password1"
                :toggle="true"
                :badge="false"
                required="required"
                aria-required="true"
              />
              <label
                v-if="v$.userPassword.password1.$error"
                class="error"
                for="password1"
              >
                {{ vuelidateErrors.password1 }}
              </label>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="text-dark text-bold">
                Zopakujte nové heslo <small>*</small>
              </label>
              <password
                id="password2"
                type="password"
                placeholder="Zopakujte heslo"
                defaultClass="form-control"
                v-model="userPassword.password2"
                :toggle="true"
                :badge="false"
                :showStrengthMeter="false"
                required="required"
                aria-required="true"
              />
              <label
                v-if="v$.userPassword.password2.$error"
                class="error"
                for="password2"
              >
                {{ vuelidateErrors.password2 }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row align-items-center justify-content-between">
          <div class="eid-state">
            <label class="text-dark text-bold mr-2">
              eID:
            </label>
            <div
              v-if="globals.user.eidLink"
              class="active"
            >
              <i class="icon-check"></i> Aktívne
            </div>
            <div
              v-else
              class="canceled"
            >
              <i class="icon-close"></i> Neaktívne
            </div>
          </div>
          <div class="row justify-content-end">
            <a
              :href="eIdJoinUrl"
              class="btn btn-tenderia-blue btn-inverse"
              :title="`${globals.user.eidLink ? 'Zmeniť' : 'Aktivovať'} prihlásenie pomocou občianskeho preukazu s čipom`"
            >
              {{ globals.user.eidLink ? 'Zmeniť' : 'Aktivovať' }} eID
            </a>
            <button
              id="save-password"
              @click.prevent="checkOldPassword"
              class="btn btn-success"
            >
              <i class="icon-save"></i> Aktualizovať heslo
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="card" v-if="webAuthnSupported">
      <div class="card-heading">
        <h4 class="card-title">
          Prihlásenie bez hesla
        </h4>
      </div>
      <div class="card-block pb-0">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="text-dark text-bold">Prihlasovacie meno</label>
              <input type="email" v-model.lazy="globals.user.email" placeholder="Email" class="form-control" disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row align-items-center justify-content-between">
          <div></div>
          <div class="row justify-content-end">
            <button @click.prevent="registerWebauthn" class="btn btn-success">Aktivovať prihlásenie bez hesla</button>
          </div>
        </div>
      </div>
    </div>-->

    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Prihlasovacie údaje IS EPVO
        </h4>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="text-dark text-bold">
                Prihlasovacie meno
              </label>
              <input
                type="email"
                v-model.lazy="isepvo.login"
                placeholder="Prihlasovacie meno"
                class="form-control"
                :disabled="!isepvo.edit"
              >
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="text-dark text-bold">
                Heslo <small>*</small>
              </label>
              <password
                :disabled="!isepvo.edit"
                id="isepvoPassword"
                type="password"
                placeholder="Heslo"
                defaultClass="form-control"
                v-model="isepvo.password"
                :toggle="true"
                :badge="false"
                :showStrengthMeter="false"
                required="required"
                aria-required="true"
              />
              <!-- <label v-if="form.password2.error" class="error" for="password2">Heslá sa musia zhodovať.</label> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <template v-if="isepvo.edit">
            <button
              class="btn btn-tenderia-grey-blue btn-inverse"
              @click="isepvo.edit = false"
            >
              Zrušiť
            </button>
            <button
              id="save-password"
              @click.prevent="saveIsepvoLogin"
              class="btn btn-success"
            >
              <i class="icon-save"></i> Uložiť
            </button>
          </template>
          <template v-else>
            <button
              id="save-password"
              @click.prevent="removeIsepvoLogin"
              class="btn btn-danger"
            >
              <i class="icon-delete"></i> Zmazať údaje
            </button>
            <button
              class="btn btn-tenderia-blue"
              @click="isepvo.edit = true"
            >
              <i class="icon-pencil"></i> Upraviť
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import { browserSupportsWebAuthn, startRegistration } from '@simplewebauthn/browser'
import Password from 'vue-password-strength-meter'
import Swal from 'sweetalert2'
import { useVuelidate } from '@vuelidate/core'
import {
  useVuelidateValidationErrors,
  validateRequired,
  validatePhone,
  validatePasswordStrength,
  validateSameAsPassword
} from '@/components/validator'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserProfile',
  components: {
    Password
  },
  props: {
    operation: String
  },
  setup () {
    const v$ = useVuelidate({
      $lazy: true,
      $autoDirty: true,
    })
    return { v$ }
  },
  validations () {
    return {
      user: {
        firstname: { validateRequired },
        lastname: { validateRequired },
        phone: { validateRequired, validatePhone },
      },
      userPassword: {
        password1: { validatePasswordStrength },
        password2: { validateRequired, validateSameAsPassword: validateSameAsPassword(this.userPassword.password1) }
      }
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      eIdJoinUrl: process.env.VUE_APP_BACKEND_URL + '/auth/eid/login/start?a=join&token=' + useGlobalStore().authToken,
      isepvo: {
        login: (useGlobalStore().user?.credentials?.isepvo?.login) || '',
        password: '',
        edit: false
      },
      user: {
        firstname: useGlobalStore().user?.firstname,
        lastname: useGlobalStore().user?.lastname,
        title1: useGlobalStore().user?.title1,
        title2: useGlobalStore().user?.title2,
        email: useGlobalStore().user?.email,
        phone: useGlobalStore().user?.phone,
        mode: 'profile',
      },
      userPassword: {
        password1: '',
        password2: ''
      },
    }
  },
  computed: {
    vuelidateErrors () {
      return useVuelidateValidationErrors(this.v$.$errors)
    }
    /*webAuthnSupported ()
    {
      return browserSupportsWebAuthn()
    }*/
  },
  methods: {
    async checkOldPassword () {
      const isFormCorrect = await this.v$.userPassword.$validate()
      if (!isFormCorrect) return
      const { value: oldPassword } = await Swal.fire({
        title: 'Zadajte aktuálne heslo',
        input: 'password',
        inputPlaceholder: 'Zadajte aktuálne heslo',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      })
      if (oldPassword) {
        this.updatePassword(oldPassword)
      }
    },
    async saveIsepvoLogin () {
      this.ShowLoading({ type: 'info', title: 'Prosím počkajte', text: 'Overujú sa prihlasovacie údaje' })
      try {
        await this.$http.post('/user/set', {
          user: {
            id: this.globals.user.id
          },
          credentials: {
            isepvo: {
              login: this.isepvo.login,
              password: this.isepvo.password
            }
          }
        })
        this.CloseLoading()
        this.OkAlert({ text: 'Údaje IS EPVO boli zmenené.' })
        this.getUserData()
        this.isepvo.edit = false
        this.isepvo.password = ''
      } catch (error) {
        this.CloseLoading()
        if (error?.response?.data.error.code === 400 && error?.response?.data.error.message === 'REQUEST_ERROR_INVALID_CREDENTIALS') {
          this.ErrorAlert({ title: 'Nesprávne prihlasovacie meno alebo heslo', text: 'Aktualizujte prihlasovacie údaje a skúste znovu.' }, error)
        } else {
          this.ErrorAlert({ text: 'Pri ukladaní nastala chyba.' }, error)
        }
      }
    },
    removeIsepvoLogin () {
      this.YesNoAlert({
        title: 'Zmazať údaje pre prihlásenie do IS EPVO?',
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Potvrdiť',
        focusCancel: true
      }).then(async (result) => {
        if (result.value) {
          try {
            await this.$http.post('/user/set', {
              user: {
                id: this.globals.user.id
              },
              credentials: {
                isepvo: {
                  login: null
                }
              }
            })
            this.OkAlert({ title: 'Údaje IS EPVO boli zmazané.' })
            this.isepvo.login = ''
            this.isepvo.password = ''
            this.getUserData()
          } catch (error) {
            this.ErrorAlert({ text: 'Pri ukladaní profilu nastala chyba.' }, error)
          }
        }
      })
    },
    async updatePassword (oldPassword) {
      if (!(this.checkPasswordStrength() && this.checkPasswordSameness())) {
        return
      }
      try {
        await this.$http.post('/user/set', { user: { id: this.globals.user.id, oldPassword: oldPassword, password: this.password1 } })
        this.OkAlert({ text: 'Údaje vo vašom profile boli zmenené.' })
      } catch (error) {
        this.ErrorAlert({ text: 'Pri ukladaní profilu nastala chyba.' }, error)
      }
    },
    async updateProfile () {
      const isFormCorrect = await this.v$.userPassword.$validate()
      if (!isFormCorrect) return
      let data = {
        user: {
          id: this.globals.user.id,
          firstname: this.useVuelidate.firstname,
          lastname: this.useVuelidate.lastname,
          title1: this.useVuelidate.title1,
          title2: this.useVuelidate.title2,
          email: this.useVuelidate.email,
          phone: this.useVuelidate.phone
        }
      }
      try {
        await this.$http.post('/user/set', data)
        this.getUserData()
        this.OkAlert({ title: 'Hotovo', text: 'Údaje vo vašom profile boli zmenené.' })
      } catch (error) {
        this.ErrorAlert({ title: 'Chyba', text: 'Nepodarilo sa aktualizovať údaje' }, error)
      }
    },
    async getUserData () {
      let data = {
        user: {
          id: this.globals.user.id
        }
      }
      try {
        const response = await this.$http.post('/user/get', data)
        localStorage.setItem('user', JSON.stringify(Object.assign(this.globals.user, response.data.user)))
      } catch (error) {
        this.ErrorAlert({ title: 'Chyba', text: 'Nepodarilo sa aktualizovať údaje' }, error)
      }
    }
    /*async registerWebauthn()
    {
      try
      {
        const response = await this.$http.post('/auth/webauthn/init')
        const options = response?.data?.options
        if (!options) throw new Error('Invalid options')
        console.log(options)
        const regResponse = await startRegistration({ optionsJSON: options })
        if (!regResponse) throw new Error('Invalid response')
        const result = await this.$http.post('/auth/webauthn/register', regResponse)
        const success = result?.data?.success
        if (success)  this.OkAlert({ title: 'Hotovo', text: 'Bezpečný prístup bez hesla bol aktivovaný' })
        else          throw new Error('Verification failed')
      }
      catch (error)
      {
        console.error(error)
        if (error.name === 'InvalidStateError')
        {
          this.ErrorAlert({ title: '', text: 'Prístup bez hesla je už aktívny' }, error)
        }
        else
        {
          this.ErrorAlert({ title: '', text: 'Nepodarilo sa aktivovať prístup bez hesla' }, error)
        }
      }
    }*/
  },
  mounted () {
    this.getUserData()
    if (this.operation === 'eid') {
      if (this.$route.params.operationStatus === 'ok') {
        this.globals.user.eidLink = true
        this.OkAlert({ title: 'Hotovo', text: 'Údaje vo vašom profile boli zmenené.' })
      } else {
        this.globals.user.eidLink = false
        this.ErrorAlert({ title: 'Chyba', text: 'Pri spracovaní vašej požiadavky nastala chyba' })
      }
      localStorage.setItem('user', JSON.stringify(this.globals.user))
      this.$router.replace({ name: 'user-profile' })
    }
  }
}
</script>
<style lang="scss" scoped>
i {
  font-size: 18px;
  line-height: 14px;
}

:deep(.Password) {
  max-width: unset;
  .Password__strength-meter {
    margin: 10px auto 2px;
  }
}
.eid-state {
  display: flex;
  align-items: baseline;
  div {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 5px 8px 5px 5px;

    &.active {
      color: #1FCB7C;
      background-color: lighten(#1FCB7C, 53%);
      border: 1px solid #1FCB7C;
      border-radius: 5px;
    }
    &.canceled {
      color: #EB5757;
      background-color: lighten(#EB5757, 35%);
      border: 1px solid #EB5757;
      border-radius: 5px;
    }
  }
}
</style>
