// // The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import './assets/css/tailwind.css'
import 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'popper.js'
import 'lodash'
import 'gasparesganga-jquery-loading-overlay'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'bootstrap-datetime-picker'
import 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css'
import 'bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.sk.js'
import 'simple-format-number'
import 'countdown'
import 'filesize'
import 'drag-drop'
import 'vue-numeric'
import { default as Vuedals } from 'vuedals'
import Vue from 'vue'
import 'tinymce/tinymce'
import 'tinymce-i18n/langs/sk'
import 'tinymce/themes/modern/theme'
import 'tinymce/skins/lightgray/skin.min.css'
import 'tinymce/plugins/print'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/table'
import 'tinymce/plugins/paste'
import App from './App'
import router from './router'
import './assets/css/ei-icon.css'
import './assets/css/tenderia-icon.css'
import './assets/sass/tenderia.styles.scss'
import Alerts from './components/mixins/Alerts.js'
import { TenderiaHttp } from './services/TenderiaHttp.js'
import TenderiaService from './services/TenderiaService'
import formats from '@/utils/format'
import AOS from 'aos'
import 'aos/dist/aos.css'
import PortalVue from 'portal-vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'
import { initializeErrorHandler } from '@/utils/helpers.js'
import { TEXT } from '@/utils/i18n.js'

/* eslint-disable no-new */
Vue.use(Vuedals)
Vue.use(PortalVue)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.prototype.$http = TenderiaHttp
Vue.prototype.$tenderiaService = TenderiaService
Vue.config.productionTip = false

Vue.mixin(Alerts)

/*
export const globalStore = new Vue({
  data: {
    user: null,
    userOrganization: null,
    backendUrl: process.env.VUE_APP_BACKEND_URL,
    backendHost: process.env.VUE_APP_BACKEND_HOST,
    env: process.env.NODE_ENV,
    backPath: '/app/dashboard'
  },
  watch: {
    user:
    {
      handler(newValue)
      {
        useGlobalStore().setUser(newValue)
      },
      deep: true
    },
    userOrganization:
    {
      handler(newValue)
      {
        useGlobalStore().setUserOrganization(newValue)
      },
      deep: true
    }
  }
})
Vue.prototype.$globals = globalStore

const user = JSON.parse(localStorage.getItem('user'))
if (user) {
  Vue.prototype.globals.user = user
}

const userOrganization = JSON.parse(localStorage.getItem('userOrganization'))
if (userOrganization) {
  Vue.prototype.globals.userOrganization = userOrganization
}

Vue.prototype.$globals.authToken = localStorage.getItem('token')
Vue.prototype.$globals.sessionType = localStorage.getItem('sessionType')
*/

Vue.prototype.$format = formats

initializeErrorHandler(Vue)

new Vue({
  el: '#app',
  router,
  pinia,
  components: { App },
  provide () {
    return {
      eventBus: new Vue(),
      TEXT: TEXT
    }
  },
  created ()
  {
    if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
      window.addEventListener('hashchange', () => {
        var currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    }
    AOS.init()

    const user = JSON.parse(localStorage.getItem('user'))
    if (user)  useGlobalStore().user = user

    const userOrganization = JSON.parse(localStorage.getItem('userOrganization'))
    if (userOrganization) useGlobalStore().userOrganization = userOrganization

    useGlobalStore().authToken = localStorage.getItem('token')
    useGlobalStore().sessionType = localStorage.getItem('sessionType')
  },
  template: '<App/>'
})

const statsStore = useStatsStore()
window.setInterval(function () {
  let time = statsStore.currentTime
  statsStore.setCurrentTime(time + 1000)
}, 1000)
