<template>
  <div
    id="order-settings-interested"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        {{ title }}
      </h4>
      <div class="actions">
        <button
          v-if="rows.length > 0"
          class="btn btn-success"
          @click.prevent="exportToXsl()"
        >
          <i class="icon-excel" />Exportovať do excelu
        </button>
      </div>
    </div>
    <v-table
      :items="rowsItems"
      :columns="rowsColumns"
      item-key="id"
      class="light"
    >
      <template #cell(date)="{ item }">
        {{ $format.formatDate(item.date) }}
      </template>
    </v-table>
    <div class="card-footer">
      <v-pagination
        v-if="rowsTotal"
        :url-navigation="false"
        :page.sync="page"
        :per-page.sync="perPage"
        :per-page-options="pageSizeOptions"
        :total-elements="rowsTotal"
      />
    </div>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import moment from 'moment'

export default {
  name: 'OrganizationsList',
  components: {
    VTable,
    VPagination
  },
  props: {
    organizations: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      rows: [],
      rowsTotal: 0,
      page: 1,
      perPage: 10
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    rowsColumns () {
      return [
        {
          key: 'organizationName',
          label: 'Obchodné meno'
        },
        {
          key: 'organizationResidency',
          label: 'Sídlo'
        },
        {
          key: 'userName',
          label: 'Meno používateľa'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'contact',
          label: 'Kontakty'
        },
        {
          key: 'date',
          label: this.$route.name.includes('applicants') ? 'Dátum predloženia ponuky' : 'Dátum zaradenia'
        }
      ]
    },
    rowsItems () {
      const from = (this.page - 1) * this.perPage
      const to = this.page * this.perPage
      return this.rows && this.rows.slice(from, to)
    }
  },
  mounted () {
    this.rows = []
    if (this.organizations) {
      this.organizations.forEach((item) => {
        this.rows.push({
          organizationName: item.organization.name,
          organizationResidency: `${item.organization.address} ${item.organization.address2}, ${item.organization.zip} ${item.organization.city}`,
          userName: `${item.user.title1} ${item.user.firstname} ${item.user.lastname} ${item.user.title2}`,
          email: item.user.email,
          contact: item.user.phone,
          date: item.addedAt
        })
      })
    }
    this.rowsTotal = this.rows.length
  },
  methods: {
    exportToXsl () {
      let interested = XLSX.utils.json_to_sheet(this.rows.map(function (item) {
        item.date = moment(item.date).format('DD. MM. YYYY')
        return item
      }))
      delete interested['A1'].w; interested['A1'].v = 'Obchodné meno'
      delete interested['B1'].w; interested['B1'].v = 'Sídlo'
      delete interested['C1'].w; interested['C1'].v = 'Meno používateľa'
      delete interested['D1'].w; interested['D1'].v = 'Email'
      delete interested['E1'].w; interested['E1'].v = 'Kontakty'
      delete interested['F1'].w; interested['F1'].v = 'Dátum predloženia ponuky'
      interested['!cols'] = [
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 150 },
        { wpx: 100 },
        { wpx: 100 }
      ]
      let document = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(document, interested, this.title.substring(0, 31))
      XLSX.writeFile(document, 'zoznam.xlsx')
    }
  }
}
</script>
