<template>
  <div class="tenderia-submenu">
    <router-link :to="{ name: `${orderStore.routePrefix}admin-offers`, params: $route.params}">
      1. kolo (ostatné)
    </router-link>
    <router-link :to="{ name: `${orderStore.routePrefix}admin-offers-round2`, params: $route.params}">
      2. kolo (kritériá)
    </router-link>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'
export default {
  name: 'AdminOffersSubmenu',
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  }
}
</script>
