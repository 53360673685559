<script>
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

var sFormatNumber = require('simple-format-number')
export default {
  name: 'BaseOffer',
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      orderStore: useOrderStore(),
      ready: false,
      decimalCount: 0,
      criteria: [],
      offer: {
        id: null,
        state: null,
        stage: null,
        applicantDetails: {}
      },
      authToken: useGlobalStore().authToken
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    serverTime () {
      return new Date(this.statsStore.currentTime)
    },
    authLevelComplied () {
      return this.globals.user.authLevel >= this.order.requiredAuthLevel
    }
  },
  methods: {
    formatNumber (value, decimalCount) {
      return (value ? sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } }) : '')
    },
    goBack () {
      window.history.go(-1)
    },
    findPriceCriterion () {
      for (let criterion of this.criteria) {
        if (criterion.type === 'price') {
          return criterion
        }
      }
    },
    async downloadAttachments (source) {
      this.ShowLoading({ title: 'Pripravujem prílohy na stiahnutie' })
      var data = {
        offer: {
          id: this.offer.id,
          source: source
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    }
  }
}
</script>
