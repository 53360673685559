<template>
  <div>
    <order-settings-log-detail :row="row" />
    <div class="row no-gutters justify-content-end">
      <button class="btn btn-tenderia-grey-blue btn-inverse" @click.prevent="close()">
        <i class="icon-close" /> Zavrieť
      </button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOrderUnknown',
  components: { OrderSettingsLogDetail },
  inject: ['closeModal'],
  props:
  {
    row: { type: Object, required: true }
  },
  methods:
  {
    close: function ()
    {
      this.closeModal()
    }
  }
}
</script>

<style>

</style>
