<template>
  <div>
    <v-autocomplete
      v-if="!manualMode"
      ref="autocomplete"
      class="rpo-search"
      :color="color"
      :value="modelValueLocal"
      :items="task.data"
      :multi="multi"
      :item-text="itemText"
      showIfItemsIsEmpty
      :error="error"
      highlight-fields="name,cin"
      v-bind="$attrs"
      v-on="$listeners"
      @input="onInput"
      @text:update="onTextChange"
      @clear="clear"
      @blur="onBlur"
      @close="onClosePopup"
      @append-item:click="switchToManualMode"
    >
      <template #item="{ item, html }">
        <div class="rpo-item">
          <div class="grow">
            <div
              class="title"
              v-html="html.name"
            />
            <div class="country">
              <img
                class="mr-2"
                :src="flags[item.country].img"
                :alt="flags[item.country].text"
              >
              <small
                class="text--secondary"
                v-text="item.municipality"
              />
            </div>
          </div>
          <div class="cin-col mx-2">
            <div
              class="cin text-right"
              v-html="html.cin"
            />
            <v-badge
              v-if="item.terminated_on"
              color="red"
              glow
            >
              <small>Zrušená</small>
            </v-badge>
            <v-badge
              v-if="isNew(item)"
              color="green"
              glow
            >
              <small>Nová</small>
            </v-badge>
          </div>
        </div>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
      <template #append>
        <div
          v-if="task.pending"
          class="spinner-border spinner-border-sm mr-2"
        />
        <i
          v-else-if="task.error"
          class="icon-ended text-danger mx-2"
        ></i>
        <slot name="append" />
      </template>
      <template #append-item>
        <span
          class="text-bold"
          v-text="'Zadať manuálne'"
        />
      </template>
    </v-autocomplete>
    <div
      v-else
      class="input-group"
    >
      <input
        id="name"
        type="text"
        v-model="modelValueLocal"
        class="form-control"
        :class="{ error }"
      />
      <button
        class="input-group-addon clear"
        @click="switchToFetchMode"
        title="Zrušiť manuálne zadávanie"
      >
        <i class="icon-close font-size-18"></i>
      </button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import moment from 'moment'

import VBadge from '@/components/ui/Badge.vue'
import VAutocomplete from '@/components/ui/Autocomplete.vue'
import { colorVars } from '@/components/ui/utils/colors'
import searchServ from '@/services/search'
import { isEmpty } from 'lodash'

export default {
  name: 'RpoSearch',
  components: {
    VBadge, VAutocomplete
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    country: {
      type: String,
      default: null
    },
    multi: Boolean,
    searchField: Boolean,
    value: {
      type: [Object, String],
      default: null
    },
    error: Boolean,
    manualMode: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      task: {
        data: [],
        error: false,
        pending: false
      },
      text: '',
    }
  },
  computed: {
    modelValueLocal: {
      get () { return this.value } ,
      set (value) {
        this.$emit('input', value)
      }
    },
    colorVars () {
      return colorVars(this.color)
    },
    flags () {
      return {
        sk: {
          text: this.$format.country('SK'),
          img: require('@/assets/flag-sk.svg')
        },
        cz: {
          text: this.$format.country('CZ'),
          img: require('@/assets/flag-cz.svg')
        }
      }
    }
  },
  methods: {
    clear () {
      this.task = {
        data: [],
        error: false,
        pending: false
      }
    },
    _updateResults: debounce(function (text, country) {
      if (text.length > 2) {
        this.loadResults(text, country)
      } else {
        this.clear()
      }
    }, 350),
    onTextChange (text) {
      if (text === '') {
        this.clear()
        this.$emit('input', null)
      }
      this.text = text
      this._updateResults(text, this.country)
    },
    onInput (item) {
      if (item) {
        this.onTextChange(item[this.itemText])
      }
      if (this.searchField && item) {
        setTimeout(() => this.$router.push(`/organization/${item.country}/${item.cin}/profile`))
      }
      if (this.multi) {
        this.clear()
      }
    },
    async loadResults (query) {
      if (this.task.pending) {
        this.cancelRequest()
      }
      const req = searchServ.search(query)
      const task = await req.then(resp => {
        if (resp) { // if not canceled
          const attrs = ['cin', 'name', 'municipality', 'terminated_on', 'country', 'established_on', 'address']
          return { data: resp.data.hits.hits.map(i => pick(i._source, attrs)) }
        }
      })
      this.cancelRequest = req.cancelRequest
      this.task.data = task.data
    },
    onClosePopup () {
      if (this.value && !isEmpty(this.value)) {
        this.onTextChange(this.value[this.itemText])
      }
    },
    onBlur () {
      this.clear()
    },
    isNew (item) {
      const today = moment(new Date())
      const establishedOn = moment(new Date(item.established_on))
      return today.diff(establishedOn, 'months') <= 3
    },
    switchToManualMode() {
      this.clear()
      this.$emit('manualModeChange', true)
    },
    switchToFetchMode() {
      this.clear()
      this.$emit('manualModeChange', false)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .title, .cin {
    strong {
      color: var(--color);
    }
  }
}
.rpo-item {
  font-size: 14px;
  display: flex;
  flex-grow: 1;
  .grow {
    flex-grow: 1;
  }
  .country {
    display: flex;
    align-items: center;
  }
  .title {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;

    /* single line */
    // white-space: nowrap;

    /* multiple lines */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .cin-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .badge {
    margin: 2px 0;
    padding: 3px 4px;
  }
}
</style>
