<template>
  <multiselect
    v-model="computedPerPage"
    :options="items"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    placeholder=""
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'PerPageSelect',
  components: { Multiselect },
  props: {
    value: {
      type: Number,
      default: 10
    },
    items: {
      type: Array,
      default: () => [5, 10, 20, 30]
    }
  },
  computed: {
    computedPerPage: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
