<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title">
        <span v-if="part && part.parent && part.part">
          Časť {{ part.part }} -
        </span>Otváranie ponúk
      </h4>
    </div>
    <template v-if="openingEnabledBySettings">
      <div class="card-block">
        <p class="text-dark text-center pdd-vertical-25">
          Termín otvárania ponúk: <span
            v-if="openingDate"
            class="text-bold"
          >
            {{ $format.formatDateTime(openingDate) }}
          </span><span
            v-else
            class="text-bold"
          >
            Termín nebol stanovený
          </span>
        </p>
        <p
          v-if="openingDate && !openingEnabled"
          class="text-dark text-center pdd-vertical-25"
        >
          <countdown
            :deadline="openingDate"
            :server-time="serverTime"
          />
        </p>
      </div>
      <v-table
        :items="offers"
        :columns="tableColumns"
        item-key="id"
        class="light"
      >
        <template #cell(applicant)="{ item }">
          <span>{{ item.offer.applicantDetails.businessName }}</span>
        </template>
        <template #cell(criteria)="{ item }">
          <div
            v-for="criterion in criteria"
            :key="criterion._id"
          >
            <label class="text-bold text-dark">
              {{ criterion.name }}:
            </label>
            {{ formatNumber(item.proposals[criterion._id], criterion.decimalCount) }}
            <template v-if="criterion.type === 'price'">
              &euro;
            </template>
            <template v-else>
              {{ criterion.unit }}
            </template>
          </div>
        </template>
      </v-table>
    </template>
    <div
      v-else
      class="card-block"
    >
      <p class="text-bold text-center pdd-vertical-25 font-size-20">
        Otváranie ponúk je neverejné.
      </p>
    </div>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import VTable from '@/components/ui/Table.vue'
import { useStatsStore } from '@/storePinia/statsStore'
var sFormatNumber = require('simple-format-number')

export default {
  name: 'UserOffersOpeningWidget',
  components: { Countdown, VTable },
  props: {
    order: {
      type: Object,
      default: null
    },
    part: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      statsStore: useStatsStore(),
      criteria: [],
      offers: [],
      openingDate: false,
      openingEnabledBySettings: false
    }
  },
  computed: {
    serverTime: function () {
      return new Date(this.statsStore.currentTime)
    },
    openingEnabled: function () {
      return this.openingEnabledBySettings && this.order.isApplicant && this.openingDate && this.serverTime > this.openingDate
    },
    tableColumns () {
      return [
        {
          key: 'applicant',
          label: 'Uchádzač'
        },
        {
          key: 'criteria',
          label: 'Plnenie kritérií'
        }
      ]
    }
  },
  watch: {
    openingEnabled: function (value) {
      if (value) {
        this.loadOffers()
      }
    }
  },
  created () {
    if (this.part) {
      if (this.part.procedureType === 'tste' && this.part.bidsOpenAt2) {
        this.openingDate = new Date(this.part.bidsOpenAt2)
      }
      if (this.part.procedureType === 'ssse' && this.part.bidsOpenAt) {
        this.openingDate = new Date(this.part.bidsOpenAt)
      }
      for (let i = 0; i < this.part.criteria.length; i++) {
        this.criteria.push(Object.assign({}, this.part.criteria[i], { value: '' }))
      }
      if (!this.part.auction && this.part.offerOpeningType === 'distant') {
        this.openingEnabledBySettings = true
      }
    } else {
      if (this.order.procedureType === 'tste' && this.order.bidsOpenAt2) {
        this.openingDate = new Date(this.order.bidsOpenAt2)
      }
      if (this.order.procedureType === 'ssse' && this.order.bidsOpenAt) {
        this.openingDate = new Date(this.order.bidsOpenAt)
      }
      for (let i = 0; i < this.order.criteria.length; i++) {
        this.criteria.push(Object.assign({}, this.order.criteria[i], { value: '' }))
      }
      if (!this.order.auction && this.order.offerOpeningType === 'distant') {
        this.openingEnabledBySettings = true
      }
    }
  },
  methods: {
    formatNumber: function (value, decimalCount) {
      return sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } })
    },
    async loadOffers (retryCount = 3) {
      this.offers = []
      let offersArray = []
      try {
        if (this.part) {
          let { data: { offers } } = await this.$http.post('/offer/catalog', { order: this.part.id, sort: '-sentAt' })
          offersArray = offers
        } else {
          let { data: { offers } } = await this.$http.post('/offer/catalog', { order: this.order.id, sort: '-sentAt' })
          offersArray = offers
        }
        this.offers = offersArray.map((offer) => {
          let data = {
            offer: offer,
            proposals: {}
          }
          offer.proposals.forEach(function (proposal) {
            data.proposals[proposal.criterion] = proposal.value ? proposal.value : ''
          })
          return data
        })
      } catch (error) {
        if (error.response && error?.response?.status === 423 && retryCount > 0) {
          await new Promise(resolve => setTimeout(resolve, 500)) // wait for 500ms
          return this.loadOffers(retryCount - 1) // retry the function
        } else {
          this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }
      }
    }
  }
}
</script>
