<template>
  <multiselect
    v-model="selectedCpvCodes"
    :disabled="disabled"
    :options="cpvCodes"
    :multiple="true"
    :preserve-search="true"
    track-by="code"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :custom-label="customLabel"
    :taggable="true"
    :show-labels="false"
    placeholder="Vyberte CPV kód"
    :loading="loading"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'SupplCpvCodeSelect',
  components: { Multiselect },
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      cpvCodes: []
    }
  },
  computed: {
    selectedCpvCodes: {
      get() {
        return this.cpvCodes.filter(cpvCode => this.value.includes(cpvCode.code))
      },
      set(val) {
        this.$emit('input', val.map(cpvCode => cpvCode.code))
      }
    }
  },
  methods: {
    customLabel({ text, code }) {
      return `${text} - (${code})`
    }
  },
  async mounted() {
    this.loading = true
    var codes = JSON.parse(localStorage.getItem('CPV_CODES_MAIN'))
    if (codes != null) {
      this.cpvCodes = codes
      this.loading = false
    } else {
      const response = await this.$http.post('/enum/cpvmain' + '/list', { limit: 10000 })
      localStorage.setItem('CPV_CODES_MAIN', JSON.stringify(response.data.enum))
      this.cpvCodes = response.data.enum
      this.loading = false
    }
  }
}
</script>
