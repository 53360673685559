<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <organizations-list
          :organizations="order.interested"
          :order="order"
          title="Zoznam záujemcov"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationsList from '@/components/order/common/components/OrganizationsList.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'PtkAdminInterested',
  components: { OrganizationsList },
  data () {
    return {
      orderStore: useOrderStore()
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  }
}
</script>
