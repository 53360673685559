<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-heading">
          <h4 class="card-title ">
            Kritéria - {{ order.name }}
          </h4>
          <div
            v-if="order.biddingMethod !== 'lowestPrice' && order.biddingMethod !== 'n/a' && canEdit "
            class="actions"
          >
            <button
              class="btn btn-success"
              @click.prevent="addCriterion()"
            >
              <i class="icon-add font-size-14" />Pridať nové kritérium
            </button>
          </div>
        </div>
        <v-table
          :items="order.criteria"
          :columns="criteriaColumns"
          item-key="id"
          class="light"
        >
          <template #cell(name)="{ item }">
            {{ item.name }} {{ item.type === 'price' && item.taxInclusion ? 's DPH' : item.type === 'price' && !item.taxInclusion ? 'bez DPH' : '' }}
          </template>
          <template #cell(type)="{ item }">
            {{ $format.formatCriterionType(item.type) }}
          </template>
          <template #cell(nature)="{ item }">
            {{ $format.formatNature(item.nature) }}
          </template>
          <template #cell(interval)="{ item }">
            &lt;
            {{ item.minimalValue === Number.MIN_SAFE_INTEGER ? "-&infin;" : formatNumber(item.minimalValue, item.decimalCount) }}
            ;
            {{ item.maximalValue === Number.MAX_SAFE_INTEGER ? "&infin;" : formatNumber(item.maximalValue, item.decimalCount) }}
            &gt;
          </template>
          <template #cell(actions)="{ _item, row }">
            <a
              v-if="canEdit"
              href=""
              title="Upraviť"
              @click.prevent="updateCriterion(row)"
            >
              <i class="icon-pencil" />
            </a>
            <a
              v-if="order.biddingMethod !== 'lowestPrice' && canEdit"
              href=""
              title="Zmazať"
              @click.prevent="removeCriterionModal(row)"
            >
              <i class="icon-delete text-danger" />
            </a>
          </template>
        </v-table>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import { useOrderStore } from '@/storePinia/orderStore'
var sFormatNumber = require('simple-format-number')

export default {
  name: 'CriteriaListWidget',
  components: { VTable },
  props: {
    order: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      orderStore: useOrderStore(),
      canEdit: this.parent ? this.parent._canEdit : this.order._canEdit
    }
  },
  computed: {
    criteriaColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'nature',
          label: 'Povaha'
        },
        {
          key: 'interval',
          label: 'Interval'
        },
        {
          key: 'maximumPoints',
          label: 'Maximálny počet bodov'
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  methods: {
    showLoading: function () {
      $('#order-criteria .card-block').LoadingOverlay('show')
    },
    hideLoading: function () {
      $('#order-criteria .card-block').LoadingOverlay('hide')
    },
    formatNumber: function (value, decimalCount) {
      return sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } })
    },
    addCriterion: function () {
      if (this.parent) {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-criteria-add-part`, params: { orderId: this.parent.id, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-criteria-add`, params: { orderId: this.order.id } })
      }
    },
    updateCriterion: function (index) {
      if (this.parent) {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-criteria-update-part`, params: { orderId: this.parent.id, partId: this.order.id, index: index } })
      } else {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-criteria-update`, params: { orderId: this.order.id, index: index } })
      }
    },
    removeCriterionModal: function (index) {
      this.YesNoAlert({
        title: 'Skutočne chcete odstrániť kritérium?',
        text: 'Kritérium ' + this.order.criteria[index].name + ' sa po zmazaní už nebude dať obnoviť.',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeCriterion(index)
        }
      })
    },
    removeCriterion: function (index) {
      this.showLoading()
      var data = {
        order: {
          id: this.order.id
        },
        criterion: {
          id: this.order.criteria[index]._id
        }
      }
      var vm = this
      this.$http.post('/order/criterion/remove', data).then(function () {
        vm.order.criteria.splice(index, 1)
        vm.hideLoading()
        setTimeout(() => {
          vm.OkAlert({ text: '' })
        }, 500)
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(() => {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }, 500)
      })
    }
  }
}
</script>
