<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div
          id="order-settings-general"
          class="card"
        >
          <div class="card-heading">
            <h4 class="card-title">
              Základné údaje
            </h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="contract-name"
                    class="control-label text-dark text-bold"
                  >
                    Názov zákazky <small>*</small>
                  </label>
                  <div>
                    <input
                      id="contract-name"
                      v-model="order.name"
                      type="text"
                      class="form-control"
                      required="required"
                      disabled="disabled"
                      aria-required="true"
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="estimated-price"
                    class="control-label"
                  >
                    Predpokladaná hodnota v &euro; bez DPH <small v-if="order.type !== 'pz' || !order.private">*</small>
                  </label>
                  <div>
                    <vue-numeric
                      v-model="order.estimatedPrice"
                      class="form-control"
                      currency="€"
                      separator="space"
                      decimal-separator=","
                      :precision="2"
                      :empty-value="0"
                      :disabled="order.divided || !order._canEdit"
                    />
                    <label
                      v-if="form.estimatedPrice.error"
                      class="error"
                    >
                      {{ form.estimatedPrice.errorMessage }}
                    </label>
                    <label v-if="order.divided">
                      Po pridaní častí bude suma automaticky prepočítaná
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie <small>*</small>
                  </label>
                  <div>
                    <div class="radio radio-inline">
                      <input
                        id="funding-by-eu-yes"
                        v-model="order.fundingByEu"
                        type="radio"
                        value="yes"
                        :disabled="!order._canEdit"
                      ><label for="funding-by-eu-yes">
                        Áno
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="funding-by-eu-no"
                        v-model="order.fundingByEu"
                        type="radio"
                        value="no"
                        :disabled="!order._canEdit"
                      ><label for="funding-by-eu-no">
                        Nie
                      </label>
                    </div>
                    <label
                      v-if="form.fundingByEu.error"
                      class="error"
                    >
                      {{ form.fundingByEu.errorMessage }}
                    </label>
                  </div>
                </div>

                <div
                  v-if="order.type !== 'nh'"
                  class="form-group"
                >
                  <label
                    for="announcement-url"
                    class="control-label"
                  >
                    URL v tenderia
                  </label>
                  <div class="input-group">
                    <input
                      id="announcement-url"
                      v-model="order.announcementUrl"
                      type="text"
                      class="form-control"
                      readonly
                    >
                    <button
                      class="input-group-addon copy"
                      @click="copyToClipboard(order.announcementUrl)"
                    >
                      <i class="ei-copy font-size-18" />
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="journal-jnnouncement-date"
                    class="control-label"
                  >
                    Dátum vyhlásenia súťaže
                  </label>
                  <div>
                    <date-input
                      v-model="order.journalAnnouncementDate"
                      name="journal-jnnouncement-date"
                      :value="order.journalAnnouncementDate"
                      :disabled="!order._canEdit"
                    />
                    <label
                      v-if="form.journalAnnouncementDate.error"
                      for="journal-jnnouncement-date"
                      class="error"
                    >
                      {{ form.journalAnnouncementDate.errorMessage }}
                    </label>
                  </div>
                </div>

                <div
                  v-if="order.type !== 'nh'"
                  class="form-group"
                >
                  <label
                    for="journal"
                    class="control-label"
                  >
                    Číslo vestníka
                  </label>
                  <div>
                    <input
                      id="journal"
                      v-model="order.journal"
                      type="text"
                      class="form-control"
                      :disabled="!order._canEdit"
                    >
                    <label
                      v-if="form.journal.error"
                      for="journal"
                      class="error"
                    >
                      {{ form.journal.errorMessage }}
                    </label>
                  </div>
                </div>

                <div
                  v-if="order.type !== 'nh'"
                  class="form-group"
                >
                  <label
                    for="announcement"
                    class="control-label"
                  >
                    Číslo oznámenia/výzvy
                  </label>
                  <div>
                    <input
                      id="announcement"
                      v-model="order.announcement"
                      type="text"
                      class="form-control"
                      :disabled="!order._canEdit"
                    >
                    <label
                      v-if="form.announcement.error"
                      for="announcement"
                      class="error"
                    >
                      {{ form.announcement.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Druh zákazky <small>*</small>
                  </label>
                  <div>
                    <div class="radio radio-inline">
                      <input
                        id="subject-type-goods"
                        v-model="order.subjectType"
                        type="radio"
                        name="order-subject-type"
                        value="goods"
                        :disabled="!order._canEdit"
                      > <label for="subject-type-goods">
                        Tovary
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="subject-type-services"
                        v-model="order.subjectType"
                        type="radio"
                        name="order-subject-type"
                        value="services"
                        :disabled="!order._canEdit"
                      > <label for="subject-type-services">
                        Služby
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="subject-type-work"
                        v-model="order.subjectType"
                        type="radio"
                        name="order-subject-type"
                        value="work"
                        :disabled="!order._canEdit"
                      > <label for="subject-type-work">
                        Práce
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Hlavný CPV kód <small>*</small>
                  </label>
                  <div>
                    <main-cpv-code-select
                      v-if="order.id"
                      v-model="order.cpvCode"
                      :value="order.cpvCode"
                      :disabled="!order._canEdit"
                    />
                    <label
                      v-if="form.cpvCode.error"
                      class="error"
                    >
                      {{ form.cpvCode.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Doplnkové CPV kódy
                  </label>
                  <div>
                    <suppl-cpv-code-select
                      v-if="order.id"
                      v-model="order.cpvCodes"
                      :value="order.cpvCodes"
                      :disabled="!order._canEdit"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="description"
                    class="control-label"
                  >
                    Opis predmetu zákazky <small>*</small>
                  </label>
                  <div>
                    <textarea
                      id="description"
                      v-model="order.description"
                      class="form-control"
                      :disabled="!order._canEdit"
                    />
                    Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br>
                    <label
                      v-if="form.description.error"
                      for="description"
                      class="error"
                    >
                      {{ form.description.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="announcement"
                    class="control-label"
                  >
                    Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác
                  </label>
                  <div>
                    <input
                      id="announcement"
                      v-model="order.mainDeliveryPlace"
                      type="text"
                      class="form-control"
                      :disabled="!order._canEdit"
                    >
                    <label
                      v-if="form.mainDeliveryPlace.error"
                      for="announcement"
                      class="error"
                    >
                      {{ form.mainDeliveryPlace.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="private-note"
                    class="control-label"
                  >
                    Poznámka
                  </label>
                  <div>
                    <textarea
                      id="private-note"
                      v-model="order.privateNote"
                      class="form-control"
                      :disabled="!order._canEdit"
                    />
                    Počet zostávajúcich znakov: {{ privateNoteTextRemaining }}
                  </div>
                </div>

                <div
                  v-if="!order.divided"
                  class="form-group"
                >
                  <label class="control-label">
                    Aukcia <small>*</small>
                  </label>
                  <div>
                    <div class="radio radio-inline">
                      <input
                        id="auction-yes"
                        v-model="order.auction"
                        type="radio"
                        :value="true"
                        :disabled="!order._canEdit"
                      ><label for="auction-yes">
                        Áno
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="auction-no"
                        v-model="order.auction"
                        type="radio"
                        :value="false"
                        :disabled="!order._canEdit"
                      ><label for="auction-no">
                        Nie
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Ponuky a termíny
            </h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="bids-limit-to"
                    class="control-label"
                  >
                    Koniec lehoty na predkladanie ponúk <small>*</small>
                  </label>
                  <div>
                    <date-time-input
                      v-model="order.bidsLimitTo"
                      name="bids-limit-to"
                      :value="order.bidsLimitTo"
                      :disabled="!order._canEdit"
                      :min-date="new Date()"
                    />
                    <label
                      v-if="form.bidsLimitTo.error"
                      for="bids-limit-to"
                      class="error"
                    >
                      {{ form.bidsLimitTo.errorMessage }}
                    </label>
                  </div>
                </div>

                <div
                  v-if="!order.divided"
                  class="form-group"
                >
                  <label
                    for="bids-open-at"
                    class="control-label"
                  >
                    Dátum a čas otvárania ponúk <span v-if="order.procedureType === 'tste'">
                      (ostatné)
                    </span> <small>*</small>
                  </label>
                  <div>
                    <date-time-input
                      v-model="order.bidsOpenAt"
                      name="bids-open-at"
                      :value="order.bidsOpenAt"
                      :disabled="!order._canEdit"
                      :min-date="new Date()"
                    />
                    <label
                      v-if="form.bidsOpenAt.error"
                      for="bids-open-at"
                      class="error"
                    >
                      {{ form.bidsOpenAt.errorMessage }}
                    </label>
                  </div>
                </div>

                <div
                  v-if="canEditBidsOpenAt2 && !order.divided"
                  class="form-group"
                >
                  <label
                    for="bids-open-at2"
                    class="control-label"
                  >
                    Dátum a čas otvárania ponúk (kritériá) <small>*</small>
                  </label>
                  <div>
                    <date-time-input
                      v-model="order.bidsOpenAt2"
                      name="bids-open-at2"
                      :value="order.bidsOpenAt2"
                      :disabled="!order._canEdit"
                      :min-date="new Date()"
                    />
                    <label
                      v-if="form.bidsOpenAt2.error"
                      for="bids-open-at2"
                      class="error"
                    >
                      {{ form.bidsOpenAt2.errorMessage }}
                    </label>
                  </div>
                </div>

                <div
                  v-if="!order.divided"
                  class="form-group"
                >
                  <label class="control-label">
                    Spôsob vyhodnotenia ponúk <small>*</small>
                  </label>
                  <div>
                    <div class="radio">
                      <input
                        id="bidding-method-1"
                        v-model="order.biddingMethod"
                        type="radio"
                        value="lowestPrice"
                        :disabled="!order._canEdit"
                      ><label for="bidding-method-1">
                        Najnižšej ceny
                      </label>
                    </div>
                    <div class="radio">
                      <input
                        id="bidding-method-2"
                        v-model="order.biddingMethod"
                        type="radio"
                        value="priceQualityRatio"
                        :disabled="!order._canEdit"
                      ><label for="bidding-method-2">
                        Najlepšieho pomeru ceny a kvality
                      </label>
                    </div>
                    <div class="radio">
                      <input
                        id="bidding-method-3"
                        v-model="order.biddingMethod"
                        type="radio"
                        value="costEffectiveness"
                        :disabled="!order._canEdit"
                      ><label for="bidding-method-3">
                        Nákladov použitím prístupu nákladovej efektívnosti najmä nákladov počas životného cyklu
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    Minimálne požiadavky na overenie identity uchádzačov pri predkladaní ponuky <small>*</small>
                  </label>
                  <div>
                    <div class="radio">
                      <input
                        id="req-auth-level-eid"
                        v-model="order.requiredAuthLevel"
                        type="radio"
                        :value="30"
                        :disabled="!order._canEdit"
                      ><label for="req-auth-level-eid">
                        eID (ponuku je možné predložiť len registrovanému uchádzačovi prostredníctvom eID)
                      </label>
                    </div>
                    <div class="radio">
                      <input
                        id="req-auth-level-password"
                        v-model="order.requiredAuthLevel"
                        type="radio"
                        :value="20"
                        :disabled="!order._canEdit"
                      ><label for="req-auth-level-password">
                        meno/heslo (ponuku je možné predložiť len po registrácii/prihlásení)
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="description"
                    class="control-label"
                  >
                    Podmienky účasti
                  </label>
                  <div>
                    <textarea
                      id="description"
                      v-model="order.participationConditions"
                      class="form-control"
                      :disabled="!order._canEdit"
                    />
                    Počet zostávajúcich znakov: {{ participationConditionsTextRemaining }}<br>
                    <label
                      v-if="form.participationConditions.error"
                      for="announcement"
                      class="error"
                    >
                      {{ form.participationConditions.errorMessage }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="order.divided"
          class="card"
        >
          <div class="card-heading">
            <h4 class="card-title">
              Nastavenie častí
            </h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    Ponuky možno predložiť na <small>*</small>
                  </label>
                  <div>
                    <div class="radio radio-inline">
                      <input
                        id="parts-bidding-one"
                        v-model="order.partDetails.partsBidding"
                        type="radio"
                        name="parts-bidding"
                        value="one"
                        :disabled="!order._canEdit"
                      > <label for="parts-bidding-one">
                        jednu časť
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="parts-bidding-several"
                        v-model="order.partDetails.partsBidding"
                        type="radio"
                        name="parts-bidding"
                        value="several"
                        :disabled="!order._canEdit"
                      > <label for="parts-bidding-several">
                        niekoľko častí
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="parts-bidding-all"
                        v-model="order.partDetails.partsBidding"
                        type="radio"
                        name="parts-bidding"
                        value="all"
                        :disabled="!order._canEdit"
                      > <label for="parts-bidding-all">
                        všetky časti
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  v-if="order.partDetails.partsBidding === 'several'"
                  class="form-group"
                >
                  <label
                    class="control-label"
                    for="max-parts-bidding"
                  >
                    Maximálny počet častí na ktoré môže uchádzač vložiť ponuku <small>*</small>
                  </label>
                  <div>
                    <input
                      id="max-parts-bidding"
                      v-model.lazy="order.partDetails.maxPartsBidding"
                      :disabled="order.partDetails.partsBidding !== 'several' || !order._canEdit"
                      type="number"
                      min="1"
                      max="100"
                      class="form-control"
                    >
                  </div>
                </div>

                <div
                  v-if="order.partDetails.partsBidding === 'several'"
                  class="form-group"
                >
                  <label
                    class="control-label"
                    for="max-parts-assign"
                  >
                    Maximálny počet častí, ktoré možno zadať jednému uchádzačovi: <small>*</small>
                  </label>
                  <div>
                    <input
                      id="max-parts-assign"
                      v-model.lazy="order.partDetails.maxPartsAssign"
                      :disabled="!order._canEdit"
                      value="1"
                      type="number"
                      min="1"
                      max="100"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  v-if="order.type === 'nz' && order.partDetails.partsBidding !== 'one'"
                  class="form-group"
                >
                  <label class="control-label">
                    Verejný obstarávateľ si vyhradzuje právo prideliť zákazky kombináciou týchto častí alebo skupín častí <small>*</small>
                  </label>
                  <div>
                    <div class="radio radio-inline">
                      <input
                        id="combination-assign-yes"
                        v-model="order.partDetails.combinationAssign"
                        type="radio"
                        name="combination-assign"
                        :value="true"
                        :disabled="!order._canEdit"
                      > <label for="combination-assign-yes">
                        Áno
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="combination-assign-no"
                        v-model="order.partDetails.combinationAssign"
                        type="radio"
                        name="combination-assign"
                        :value="false"
                        :disabled="!order._canEdit"
                      > <label for="combination-assign-no">
                        Nie
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  v-if="order.partDetails.combinationAssign && order.partDetails.partsBidding !== 'one'"
                  class="form-group"
                >
                  <label
                    for="combinationInfo"
                    class="control-label"
                  >
                    Ktoré časti alebo skupiny častí sa dajú kombinovať <small>*</small>
                  </label>
                  <div>
                    <textarea
                      id="description"
                      v-model="order.partDetails.combinationInfo"
                      class="form-control"
                      :disabled="!order._canEdit"
                    />
                    Počet zostávajúcich znakov: {{ combinationInfoTextRemaining }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center no-gutters justify-content-between">
      <div class="row no-gutters">
        <button
          v-if="order.state === 'active' && order._canEdit"
          class="btn btn-tenderia-blue"
          @click="changeStateModal('finished')"
        >
          <i class="icon-check" /> Ukončiť zákazku
        </button>
        <button
          v-if="order.state === 'active' && order._canEdit"
          class="btn btn-danger"
          @click="changeStateModal('cancelled')"
        >
          <i class="icon-close" /> Zrušiť zákazku
        </button>
        <div v-if="order.state === 'planned' && order._canEdit">
          <button
            class="btn btn-danger"
            href=""
            @click="removeOrderModal()"
          >
            <i class="icon-delete" />Zmazať zákazku
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <button
          v-if="order.integration.olink.enabled"
          class="btn btn-success"
          @click="openOlinkModal"
        >
          Zverejniť odkaz na súťažné podklady
        </button>
        <div v-if="order._canEdit">
          <button
            class="btn btn-success"
            href=""
            @click="saveSettings()"
          >
            <i class="icon-save" />Uložiť
          </button>
        </div>
      </div>
    </div>
    <span>Pred zmenou kritérií je potrebné uložiť údaje zákazky</span>
    <admin-criteria />
    <admin-eforms-list v-if="order.integration.eforms.enabled" />
  </div>
</template>

<script>
import AdminOrder from '@/components/order/common/components/AdminOrder'
import AdminCriteria from '@/components/order/common/views/AdminCriteria.vue'
import DateInput from '@/components/forms/DateInput.vue'
import DateTimeInput from '@/components/forms/DateTimeInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import SupplCpvCodeSelect from '@/components/forms/SupplCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'
import OlinkModal from '@/components/order/common/components/OlinkModal.vue'
import AdminEformsList from '@/components/order/common/components/AdminEformsList.vue'

export default {
  name: 'AdminSetting',
  components: { DateInput, DateTimeInput, MainCpvCodeSelect, SupplCpvCodeSelect, VueNumeric, AdminCriteria, AdminEformsList },
  extends: AdminOrder,
  data () {
    return {}
  },
  methods: {
    openOlinkModal () {
      this.$emit('vuedals:new', {
        name: 'olink-modal',
        closeOnBackdrop: false,
        title: 'Zverejnenie odkazu na súťažné podklady',
        component: OlinkModal
      })
    },
    validateBeforePublish () {
      this.validateEstimatedPrice()
      this.validatecpvCode()
      this.validateDescription()
      this.validateBidsOpenAt()
      this.validateBidsOpenAt2()
      this.validateBidsLimitTo()
      this.validateMainDeliveryPlace()
      this.validateFundingByEu()
      this.validateParticipationConditions()
      this.validateJournalAnnouncementDate()
      this.validateJournal()
      this.validateAnnouncement()
      this.validateParts()

      return this.form.estimatedPrice.valid &&
      this.form.cpvCode.valid &&
      this.form.description.valid &&
      this.form.announcement &&
      this.form.journal &&
      this.form.journalAnnouncementDate.valid &&
      this.form.bidsOpenAt.valid &&
      this.form.bidsOpenAt2.valid &&
      this.form.bidsLimitTo &&
      this.form.mainDeliveryPlace.valid &&
      this.form.fundingByEu.valid &&
      this.form.participationConditions.valid &&
      this.form.parts.valid
    },
    async saveSettings (state) {
      let orderIsValid = this.validateBeforePublish()
      if (this.order.state === 'active' && !orderIsValid) {
        return
      }
      this.ShowLoading({ title: 'Ukladám nastavenia zákazky' })
      var data = {
        order: {
          id: this.order.id,
          name: this.order.name,
          estimatedPrice: this.order.estimatedPrice.toString().replaceAll(' ', '').replaceAll(',', '.'),
          description: this.order.description,
          announcementUrl: this.order.announcementUrl,
          journalAnnouncementDate: this.order.journalAnnouncementDate,
          bidsLimitTo: this.order.bidsLimitTo,
          organization: this.order.organization.id,
          privateNote: this.order.privateNote,
          offerOpeningType: this.order.offerOpeningType,
          subjectType: this.order.subjectType,
          cpvCode: this.order.cpvCode,
          cpvCodes: this.order.cpvCodes,
          journal: this.order.journal,
          announcement: this.order.announcement,
          requiredAuthLevel: this.order.requiredAuthLevel,
          mainDeliveryPlace: this.order.mainDeliveryPlace,
          fundingByEu: this.order.fundingByEu,
          participationConditions: this.order.participationConditions,
          private: this.order.private,
        }
      }
      if (!this.order.divided) {
        data.order.bidsOpenAt = this.order.bidsOpenAt
        data.order.biddingMethod = this.order.biddingMethod
        data.order.auction = this.order.auction
      } else {
        data.order.partDetails = this.order.partDetails
      }
      if (!this.order.divided && this.order.procedureType === 'tste') {
        data.order.bidsOpenAt2 = this.order.bidsOpenAt2
      }
      if (this.order.auction) {
        data.order.offerOpeningType = 'nonpublic'
      } else {
        data.order.offerOpeningType = 'distant'
      }
      try {
        await this.$http.post('/order/set', data)
        await this.orderStore.getOrder(this.order.id)
        this.CloseLoading()
        if (state === 'active') {
          this.changeState(state)
        } else {
          setTimeout(() => {
            this.PopupAlert({ title: 'Zmeny boli uložené.' })
          }, 500)
        }
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    }
  }
}
</script>
