<template>
  <div
    id="order-parts"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title ">
        Časti
      </h4>
      <div class="actions">
        <router-link
          v-if="order._canEdit && order.state !== 'active'"
          class="btn btn-tenderia-blue"
          :to="{name: `${orderStore.routePrefix}admin-parts-add`, params: {orderId: order.id}}"
        >
          <i class="icon-add font-size-14" /> Pridať časť
        </router-link>
      </div>
    </div>
    <v-table
      :items="contracts.orders"
      :columns="partsColumns"
      :sort.sync="tableSort"
      item-key="id"
      class="light"
    >
      <template #cell(name)="{ item }">
        <router-link :to="{name: `${orderStore.routePrefix}admin-parts-edit`, params: {orderId: order.id, partId: item.id}}">
          {{ item.name }}
        </router-link>
      </template>
      <template #cell(actions)="{ item }">
        <router-link
          :to="{name: `${orderStore.routePrefix}admin-parts-edit`, params: {orderId: order.id, partId: item.id}}"
          title="Upraviť"
        >
          <i class="icon-pencil" />
        </router-link>
        <a
          v-if="order.state !== 'active'"
          href=""
          title="Zmazať"

          @click.prevent="removePartModal(item)"
        >
          <i class="icon-delete text-danger" />
        </a>
      </template>
    </v-table>
    <div class="card-footer">
      <v-pagination
        v-if="contracts.total"
        :page="page"
        :per-page="perPage"
        :per-page-options="pageSizeOptions"
        :total-elements="contracts.total"
      />
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminParts',
  components: {
    VTable,
    VPagination
  },
  data () {
    return {
      orderStore: useOrderStore(),
      contracts: {},
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    partsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'part',
          label: 'Časť',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    fetchParams () {
      return {
        'parent': this.$router.currentRoute.params.orderId,
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        role: 'buyer'
      }
    }
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    }
  },
  mounted () {
    this.fetchContracts()
  },
  methods: {
    showLoading () {
      $('#order-parts .card-block').LoadingOverlay('show')
    },
    hideLoading () {
      $('#order-parts .card-block').LoadingOverlay('hide')
    },
    removePartModal (part) {
      this.YesNoAlert({
        title: 'Skutočne chcete odstrániť časť?',
        text: 'Časť ' + part.name + ' sa po zmazaní už nebude dať obnoviť.',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removePart(part)
        }
      })
    },
    removePart (part) {
      let data = {
        order: {
          id: part.id,
          state: 'deleted'
        }
      }
      let vm = this
      this.$http.post('/order/state/set', data).then(function () {
        vm.hideLoading()
        vm.OkAlert({ text: `Časť ${part.name} bola zmazaná.` })
        vm.fetchContracts()
      }).catch((error) => {
        vm.hideLoading()
        vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      })
    },
    async fetchContracts () {
      const { data } = await this.$http.post('/order/list', this.fetchParams)
      this.contracts = data
    }
  }
}
</script>
