import EventEmitter from 'eventemitter3'

export default class Uploader
{

  constructor(options)
  {
    this.xhr = null
    this.options = options || {}
    this.files = []
    this.fileIds = []
    this.uploadTokens = []
    this.uploadUrls = []
    this.fileTitles = []
    this.fileMetas = []
    this.target = ''
    this.fileId = ''
    this.fileToken = ''
    this.fileTitle = ''
    this.fileName = ''
    this.fileType = ''
    this.fileMeta = ''
    this.fileIndex = 0
    this.fileCounter = 0
    this.fileLength = 0
    this.chunkIndex = 0
    this.chunkCounter = 0
    this.chunkSize = this.options.chunkSize || 262144
    this.authorization = this.options.authorization || ''
    this.uploadStop = false
    this.dev = (process.env.NODE_ENV === 'development')
    this.confirm = ''
    let EE = this.observer = new EventEmitter()
    this.on = EE.on.bind(EE)
    this.once = EE.once.bind(EE)
    this.off = EE.removeListener.bind(EE)
    this.emit = EE.emit.bind(EE)
    if (this.dev) console.log(':: UPLOADER: Init (v2)')
  }

  destroy()
  {
    this.uploadStop = true
    this.observer && this.observer.removeAllListeners()
    this.observer = null
    this.on = null
    this.once = null
    this.off = null
    this.emit = null
    if (this.dev) console.log(':: UPLOADER: Destroy')
  }

  addFile(file, uploadUrl, token = '', title = '', meta = {})
  {
    if (!file) return
    let fileNr = this.fileIds.length
    this.files.push(file)
    this.fileIds.push(fileNr)
    this.uploadUrls.push(uploadUrl)
    this.uploadTokens.push(token)
    this.fileTitles.push(title)
    this.fileMetas.push(meta)
    this.emit('queue', fileNr, file)
    if (this.dev) console.log(':: UPLOADER: Add File', file, uploadUrl)
  }

  abort()
  {
    this.uploadStop = true
    if (this.dev) console.log(':: UPLOADER: Abort')
  }

  start()
  {
    if (this.dev) console.log(':: UPLOADER: Start', this.files)
    this.uploadStop = false
    if (this.files.length < 1) this.emit('error', new Error('QUEUE_EMPTY'), '')
    if (window.File && window.FileReader && window.FileList && window.Blob)
    {
      for (let i = 0; i < this.files.length-1; i++)
      {
        let f = this.files[i]
        if (f.size === 0) return void this.emit('error', new Error('FILE_INVALID'), this.fileIds[i])
      }
      this.fileIndex = 0
      this.fileCounter = this.files.length
      this.executeNext()
    }
    else
    {
      this.emit('error', new Error('BROWSER_UNSUPPORTED'), '')
    }
  }

  executeNext()
  {
    if (this.dev) console.log(':: UPLOADER: Execute Next:', this.fileIndex)
    if (this.uploadStop) return void this.onAbort()
    let currentFile = this.files[this.fileIndex]
    if (currentFile)
    {
      this.chunkIndex = 0
      this.chunkCounter = Math.floor((currentFile.size - 1) / this.chunkSize) + 1
      this.fileName = currentFile.name
      this.fileType = currentFile.type
      this.fileLength = currentFile.size
      this.fileId = this.fileIds[this.fileIndex]
      this.target = this.uploadUrls[this.fileIndex]
      this.fileToken = this.uploadTokens[this.fileIndex]
      this.fileTitle = this.fileTitles[this.fileIndex]
      this.fileMeta = JSON.stringify(this.fileMetas[this.fileIndex])
      if (!this.target) return void this.emit('error', new Error('URL_INVALID'), this.fileId)
      this.fetchCommand('init')
    }
    else
    {
      this.emit('complete', this.fileId, this.confirm)
    }
  }

  async fetchChunk()
  {
    if (this.uploadStop) return void this.onAbort()
    this.chunkIndex++
    const file = this.files[this.fileIndex]
    const chunkSize = this.chunkSize
    const start = (this.chunkIndex - 1) * chunkSize
    let end = start + chunkSize
    if (start >= this.fileLength)
    {
      if (this.dev) console.log(':: UPLOADER: Fetch Chunk Done')
      this.fileIndex++
      this.executeNext()
    }
    else
    {
      if (this.dev) console.log(':: UPLOADER: Fetch Chunk:', this.fileIndex, this.chunkIndex, start, this.fileLength)
      if (end > this.fileLength) end = this.fileLength
      const chunk = file.slice(start, end)
      const formData = new FormData()
      formData.append('uploadCmd', 'upload')
      formData.append('uploadId', this.fileId)
      formData.append('uploadToken', this.fileToken)
      formData.append('uploadTitle', this.fileTitle)
      formData.append('uploadName', this.fileName)
      formData.append('uploadType', this.fileType)
      formData.append('uploadMeta', this.fileMeta)
      formData.append('uploadIndex', this.chunkIndex)
      formData.append('uploadCount', this.chunkCounter)
      formData.append('uploadBlock', chunk.size)
      formData.append('uploadTotal', this.fileLength)
      formData.append(`uploadPart_${this.chunkIndex}`, chunk)
      try
      {
        const response = await fetch(this.target,
          {
            method: 'POST',
            headers: this.authorization ? { 'Authorization': `Bearer ${this.authorization}` } : {},
            body: formData
          })
        if (response.ok)
        {
          const data = await response.json()
          this.onProgress(data)
        }
        else
        {
          const data = await response.json().catch(_err => {})
          const error = new Error(`HTTP Error: ${response.status} ${response.statusText}`)
          error.statusCode = response.status
          error.type = data?.error?.message
          error.display = data?.error?.display
          throw error
        }
      }
      catch (error)
      {
        if (error?.name === 'AbortError')
        {
          this.onAbort(error)
        }
        else
        {
          this.onError(error)
        }
      }
    }
  }

  async fetchCommand(command)
  {
    if (this.dev) console.log(':: UPLOADER: Fetch Command:', command)
    const formData = new FormData()
    formData.append('uploadCmd', command)
    formData.append('uploadId', this.fileId)
    formData.append('uploadToken', this.fileToken)

    try
    {
      const response = await fetch(this.target,
        {
          method: 'POST',
          headers: this.authorization ? { 'Authorization': `Bearer ${this.authorization}` } : {},
          body: formData
        })
      if (response.ok)
      {
        const data = await response.json()
        this.onProgress(data)
      }
      else
      {
        this.onError()
      }
    }
    catch (error)
    {
      if (error?.name === 'AbortError')
      {
        this.onAbort(error)
      }
      else
      {
        this.onError(error)
      }
    }
  }

  onProgress(response)
  {
    if (this.dev) console.log(':: UPLOADER: On Progress:', response)
    switch (response.status)
    {
    case 'ready':
      this.fetchChunk()
      break
    case 'error':
      this.onError(response.message)
      break
    case 'upload':
      this.emit('progress', this.fileId, response.progress)
      this.fetchChunk()
      break
    case 'ok':
      this.confirm = response.confirm
      this.onComplete()
      break
    default:
      this.onError('UNKNOWN_STATUS')
    }
  }

  onError(err)
  {
    if (this.dev) console.log(':: UPLOADER: On Error:')
    if (!err) err = new Error('UPLOAD_ERROR')
    if (err) console.error(err)
    this.emit('error', err, this.fileId)
  }

  onAbort(err = '')
  {
    if (this.dev) console.log(':: UPLOADER: On Abort')
    if (err) console.error(err)
    this.emit('abort', this.fileId, 'UPLOAD_ABORT'),
    this.fetchCommand('abort')
  }

  onComplete()
  {
    if (this.dev) console.log(':: UPLOADER: On Complete')
    this.emit('complete', this.fileId, 100)
  }

}
