<template>
  <div class="homepage-layout layout free-order eid-login-page dark">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div
        class="main-content"
        id="login"
      >
        <h1>Prihlásenie pomocou eID</h1>
        <p class="mb-4">
          Prosím počkajte, prebieha prihlasovanie pomocou eID.
        </p>
        <router-link
          class="btn btn-success"
          color="green"
          to="/"
        >
          Naspäť na hlavnú stránku
        </router-link>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
import ActiveOrganizationSelect from './ActiveOrganizationSelect.vue'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { useGlobalStore } from '@/storePinia/globalStore'
import { useStatsStore } from '@/storePinia/statsStore'

export default {
  name: 'UserLoginEid',
  components: { AppLoginHeader, AppFooter },
  data: function () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore()
    }
  },
  async mounted () {
    if (this.$route.params.eidToken == null) {
      this.ErrorAlert({ text: 'Chýbajúci eID token' }).then(() => {
        this.$router.push({ path: '/' })
      })
    } else {
      this.ShowLoading()
      try {
        const response = await this.$http.post('/auth/login', { eidToken: this.$route.params.eidToken })
        this.CloseLoading()
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('sessionType', response.data.sessionType)
        this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
        this.globals.sessionType = response.data.sessionType
        this.globals.authToken = response.data.token
        this.globals.user = response.data.user
        this.globals.user.authLevel = response.data.authLevel
        this.globals.user.relations = response.data.relations
        this.globals.user.organizations = response.data.organizations
        this.setUserDefaultOrganization(response.data.organization)
        localStorage.setItem('user', JSON.stringify(this.globals.user))
        this.statsStore.resetPremiumPackageState()
        this.$tenderiaService.syncCountries()
        this.$tenderiaService.syncCpvCodes()
        if (this.globals.user.organizations.length > 1 || !this.globals.userOrganization) {
          this.openOrganizationSelect()
        }
        await this.statsStore.updatePremiumPackageState()
        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        console.log(error)
        this.CloseLoading()
        if (error?.response?.status === 401) {
          this.ErrorAlert({
            title: 'Chyba',
            text: 'Takáto eID identita v systéme tenderia neexistuje. Pokračujte na registráciu alebo prepojte už existujúci účet s vašou eID kartou.',
            showConfirmButton: false,
            footer: `<div class="row">
                      <div class="col-md-6">
                        <a href="/login" class="btn btn-tenderia-blue m-0">Prihlásenie</a>
                      </div>
                      <div class="col-md-6">
                        <a href="/registration/${this.$route.params.eidToken}" class="btn btn-success m-0" title="Registrovaný účet bude prepojený s vašou eID kartou.">Registrácia s eID</a>
                      </div>
                    </div>`
          }, error?.response?.data.error).then(() => {
            this.$router.push({ path: '/' })
          })
        } else {
          this.ErrorAlert({ text: 'Neplatný eID token' }, error).then(() => {
            this.$router.push({ path: '/' })
          })
        }
      }
    }
  },
  methods: {
    setUserDefaultOrganization (organizationId) {
      const selectedOrganization = this.globals.user.organizations.find(org => org.id === organizationId)
      if (selectedOrganization) {
        const selectedRelation = this.globals.user.relations.find(relation => relation.organization === organizationId)
        if (selectedRelation) {
          this.globals.user = {
            ...this.globals.user,
            roles: selectedRelation.roles,
            permissions: selectedRelation.permissions.map(permission => `${permission.group}/${permission.object}/${permission.action}/${permission.level}`)
          }
          this.globals.userOrganization = selectedOrganization
          localStorage.setItem('userOrganization', JSON.stringify(selectedOrganization))
        }
      }
    },
    openOrganizationSelect () {
      this.$emit('vuedals:new', {
        name: 'organization-select-modal',
        escapable: true,
        title: 'Výber aktívnej organizácie',
        props: {
          organizations: this.globals.user.organizations,
          relations: this.globals.user.relations
        },
        component: ActiveOrganizationSelect
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.eid-login-page {
  text-align: center;
  .page-container {
    .main-content {
      align-items: center;
    }
  }
  &.dark {
    background-image: url('../assets/wave-bg.svg');
    background-position: left top -50px;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }
  h1 {
    font-weight: bold;
    color: #fff;
    font-size: 32px;
  }
  p {
    font-size: 20px;
    width: 100%;
    color: #fff;
  }
}
</style>
