import Vue from 'vue'
import Dashboard from '@/components/Dashboard'
import TenderiaApp from '@/components/TenderiaApp'
import TenderiaAppIndex from '@/components/TenderiaAppIndex'
import HomePage from '@/components/HomePage'
import EmailClient from '@/components/emailClient/EmailClient.vue'
import PrintMessage from '@/components/emailClient/PrintMessage.vue'
import UserLoginEid from '@/components/UserLoginEid'
import StatisticsPage from '@/components/StatisticsPage.vue'
import SubscriptionsPage from '@/components/SubscriptionsPage.vue'
import ErrorPage from '@/components/ErrorPage.vue'
// Public market research
import PublicMarketResearch from '@/components/order/MarketResearch/Public'
import PublicMarketReserachDocument from '@/components/order/MarketResearch/Public/Document'
import PublicMarketResearchOffer from '@/components/order/MarketResearch/Public/Offer'
import UserOverview from '@/components/order/MarketResearch/views/UserOverview.vue'
import MarketResearchPublicOfferForm from '@/components/order/MarketResearch/Public/PublicOfferForm'
import MrUserOfferView from '@/components/order/MarketResearch/views/UserOfferView.vue'
// Public pages
import FreeOrder from '@/components/order/FreeOrder'
import FreeOrderHome from '@/components/order/FreeOrder/Home'
import RequestEmailOverview from '@/components/requests/views/RequestEmailOverview.vue'
import EmailRedirectLayout from '@/components/EmailRedirectLayout.vue'
import PublicOrdersOrganizationPage from '@/components/PublicOrdersOrganizationPage.vue'
// Public forms
import UserRegistration from '@/components/UserRegistration'
import LoginPage from '@/components/LoginPage.vue'
import AccountActivation from '@/components/AccountActivation'
import PasswordRecovery from '@/components/PasswordRecovery'
import PasswordReset from '@/components/PasswordReset'
// Public list pages
import PublicOrders from '@/components/PublicOrders.vue'
import PublicOrdersPage from '@/components/PublicOrdersPage.vue'
// List pages
import OrdersPage from '@/components/OrdersPage'
import MyOrdersPage from '@/components/MyOrdersPage'
import RequestsPage from '@/components/RequestsPage.vue'
import InvoicesPage from '@/components/InvoicesPage.vue'
import MySuppliersPage from '@/components/order/MarketResearch/views/MySuppliersPage'
import UserSettingsAccounts from '@/components/UserSettingsAccounts'
import OrganizationDepartments from '@/components/departments/OrganizationDepartments.vue'
import OrganizationBudgets from '@/components/budgets/OrganizationBudgets.vue'
// Forms
import UserOrganizationEdit from '@/components/UserProfile/UserOrganizationEdit.vue'
import OrganizationDepartmentAdd from '@/components/departments/OrganizationDepartmentAdd.vue'
import OrganizationBudgetAdd from '@/components/budgets/OrganizationBudgetAdd.vue'
import UserSettingsAddAccount from '@/components/UserSettingsAddAccount'
import SupplierForm from '@/components/order/common/components/SupplierForm.vue'
// Routers
import Router from 'vue-router'
import TenderiaRouterPlugin from './plugin.js'
import { permissions } from '@/router/permissions.js'
import generateSsseDividedRoutes from './ssseDividedRoutes.js'
import generateDnsRoutes from './dnsRoutes.js'
import generateDnsCallRoutes from './dnsCallRoutes.js'
import PtkRouter from '@/components/order/Ptk/router'
import MrRouter from '@/components/order/MarketResearch/router'
import RequestRouter from '@/components/requests/router'
import InvoiceRouter from '@/components/invoices/router'
import UserProfileRouter from '@/components/UserProfile/router'

// Stores

import { checkIfSomeElementsExistInArray } from '@/utils/helpers.js'
Vue.use(Router)
Vue.use(TenderiaRouterPlugin)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: 'HomePage'
    },
    {
      path: '/print-message/:messageid/:mode',
      name: 'printMessage',
      component: PrintMessage
    },
    {
      path: '/',
      component: TenderiaAppIndex,
      meta: {
        requiresAuth: false
      },
      children: [
        {
          path: '',
          name: 'HomePage',
          component: HomePage,
          meta: {
            list: true
          }
        },
        {
          path: 'login/supplier/:supplierToken',
          component: LoginPage
        },
        {
          path: 'login/invite/:inviteToken',
          component: LoginPage
        },
        {
          path: 'login/eid/:eidToken',
          component: UserLoginEid
        },
        {
          path: 'password-reset',
          component: PasswordReset
        },
        {
          path: 'password-recovery/:token',
          component: PasswordRecovery
        },
        {
          path: 'account-activation/:token',
          component: AccountActivation
        },
        {
          path: 'login',
          name: 'login',
          component: LoginPage
        },
        {
          path: 'registration',
          name: 'registration',
          component: UserRegistration
        },
        {
          path: 'registration/supplier/:supplierToken',
          name: 'registrationSupplier',
          component: UserRegistration
        },
        {
          path: 'registration/invite/:inviteToken',
          name: 'registrationInvite',
          component: UserRegistration
        },
        {
          path: 'registration/:eidToken',
          component: UserRegistration,
          name: 'registration-eid'
        },
        {
          path: '/public/orders',
          component: PublicOrders,
          name: 'public-orders',
          children: [
            {
              path: '',
              component: PublicOrdersPage,
              meta: {
                list: true
              }
            }
          ],
        },
        {
          path: '/public/order/:orderId',
          component: FreeOrder,
          children: [
            {
              path: '',
              name: 'freeOrderHome',
              component: FreeOrderHome
            }
          ]
        },
        {
          path: '/public/organization/:country/:ico',
          component: PublicOrdersOrganizationPage,
          name: 'public-organization',
          meta: {
            list: true
          }
        },
        {
          path: '/public/market-research/:orderId/:token',
          name: 'publicMarketResearch',
          redirect: { name: 'publicMarketResearchHome' },
          component: PublicMarketResearch,
          children: [
            {
              path: 'home',
              name: 'publicMarketResearchHome',
              component: UserOverview
            },
            {
              path: 'offer',
              name: 'publicMarketResearchOffer',
              component: PublicMarketResearchOffer
            },
            {
              path: 'offer/add',
              name: 'publicMarketResearchOfferForm',
              component: MarketResearchPublicOfferForm
            },
            {
              path: 'offer/view',
              name: 'publicMarketResearchOfferView',
              component: MrUserOfferView
            },
            {
              path: 'document/:attachmentindex/:documentid',
              name: 'publicMarketResearchDocument',
              component: PublicMarketReserachDocument
            }
          ]
        },
        {
          path: '/email',
          component: EmailRedirectLayout,
          children: [
            {
              path: 'request/:requestId',
              name: 'requestEmailOverview',
              component: RequestEmailOverview
            }
          ]
        },
        {
          path: '/app',
          name: 'TenderiaApp',
          component: TenderiaApp,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'settings/budgets',
              name: 'settingsBudgets',
              component: OrganizationBudgets,
              meta: {
                viewRights: [permissions.BUDGET_BUDGET_LIST_GET]
              }
            },
            {
              path: 'settings/budgets/add',
              name: 'settingsAddBudget',
              component: OrganizationBudgetAdd,
              meta: {
                viewRights: [permissions.BUDGET_BUDGET_CREATE]
              }
            },
            {
              path: 'settings/budgets/edit/:budgetId',
              name: 'settingsEditBudget',
              component: OrganizationBudgetAdd,
              meta: {
                viewRights: [permissions.BUDGET_BUDGET_SET_1]
              }
            },
            {
              path: 'settings/departments',
              name: 'settingsDepartments',
              component: OrganizationDepartments,
              meta: {
                viewRights: [permissions.ORGANIZATION_UNIT_SET]
              }
            },
            {
              path: 'settings/departments/add',
              name: 'settingsAddDepartment',
              component: OrganizationDepartmentAdd,
              meta: {
                viewRights: [permissions.ORGANIZATION_UNIT_CREATE]
              }
            },
            {
              path: 'settings/departments/edit/:departmentId',
              name: 'settingsEditDepartment',
              component: OrganizationDepartmentAdd,
              meta: {
                viewRights: [permissions.ORGANIZATION_UNIT_SET]
              }
            },
            {
              path: 'settings/suppliers',
              name: 'settingsSuppliers',
              component: MySuppliersPage,
              meta: {
                viewRights: [permissions.ORGANIZATION_SUPPLIER_SET]
              }
            },
            {
              path: 'settings/suppliers/add',
              name: 'settingsAddSupplier',
              component: SupplierForm,
              meta: {
                viewRights: [permissions.ORGANIZATION_SUPPLIER_CREATE]
              }
            },
            {
              path: 'settings/suppliers/edit/:id',
              name: 'settingsEditSupplier',
              component: SupplierForm,
              meta: {
                viewRights: [permissions.ORGANIZATION_SUPPLIER_SET]
              }
            },
            {
              path: 'settings/accounts',
              name: 'settingsAccounts',
              component: UserSettingsAccounts,
              meta: {
                viewRights: [permissions.ORGANIZATION_USER_LIST]
              }
            },
            {
              path: 'settings/accounts/add',
              name: 'settingsAddAccount',
              component: UserSettingsAddAccount,
              meta: {
                viewRights: [permissions.ORGANIZATION_USER_CREATE]
              }
            },
            {
              path: 'settings/accounts/edit/:userid',
              name: 'settingsEditAccount',
              component: UserSettingsAddAccount,
              meta: {
                viewRights: [permissions.ORGANIZATION_USER_SET]
              }
            },
            {
              path: 'settings/organizations',
              name: 'settingsOrganizations',
              component: UserOrganizationEdit
            },
            {
              path: 'messages',
              name: 'messages',
              component: EmailClient,
              meta: {
                viewRights: [permissions.MESSAGE_READ]
              }
            },
            {
              path: 'messages/inbox/:messageid',
              name: 'message',
              component: EmailClient,
              meta: {
                viewRights: [permissions.MESSAGE_READ]
              }
            },
            {
              path: 'dashboard',
              name: 'dashboard',
              component: Dashboard,
              meta: {
                list: true
              }
            },
            {
              path: 'my-orders/:userRole/:state',
              name: 'myOrders',
              component: MyOrdersPage,
              meta: {
                list: true
              }
            },
            {
              path: 'orders/:state',
              name: 'orders',
              component: OrdersPage,
              meta: {
                list: true
              }
            },
            {
              path: 'requests/:state',
              name: 'requests',
              component: RequestsPage,
              meta: {
                viewRights: [permissions.REQUEST_REQUEST_LIST_GET_1],
                list: true
              }
            },
            {
              path: 'myRequests/:state',
              name: 'my-requests',
              component: RequestsPage,
              meta: {
                viewRights: [permissions.REQUEST_REQUEST_LIST_GET_1],
                list: true
              }
            },
            {
              path: 'invoices/:state',
              name: 'invoices',
              component: InvoicesPage,
              meta: {
                list: true
              }
            },
            {
              path: 'statistics',
              name: 'statistics',
              component: StatisticsPage,
            },
            {
              path: 'subscriptions',
              name: 'subscriptions',
              component: SubscriptionsPage,
            },
            ...MrRouter,
            ...PtkRouter,
            ...UserProfileRouter,
            ...RequestRouter,
            ...InvoiceRouter,
            ...generateSsseDividedRoutes(),
            ...generateDnsRoutes(),
            ...generateDnsCallRoutes()
          ]
        },
      ]
    },
    {
      path: '/error/:code',
      name: 'errorPage',
      component: ErrorPage
    },
    {
      path: '/:catchAll(.*)*',
      name: 'allErrorPage',
      component: ErrorPage
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (from.meta.list) {
    localStorage.setItem('backPath', from.fullPath)
  }
  const viewRights = to.meta.viewRights
  const user = JSON.parse(localStorage.getItem('user'))
  const permissions = user && user.permissions
  const userType = user && user.type

  if (userType === 'observer') {
    next()
  } else {
    // check if has permission
    let hasPermission = false
    if (permissions && viewRights) {
      hasPermission = checkIfSomeElementsExistInArray(permissions, viewRights)
    }
    if (viewRights && !hasPermission) {
      next({ name: 'errorPage', params: { code: '403' } })
    } else {
      next()
    }
  }
})
export default router
