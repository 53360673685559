<template>
  <div
    id="add-new-document"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        Pridať verejný dokument k zákazke
      </h4>
    </div>
    <div class="card-block">
      <form>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group">
              <label
                class="control-label"
                for="name"
              >
                Názov <small>*</small>
              </label>
              <input
                id="name"
                v-model.lazy="name"
                type="text"
                class="form-control"
                :class="{ error: v$.name.$error }"
                required="required"
                aria-required="true"
              >
              <label
                v-if="v$.name.$error"
                class="error"
                for="name"
              >
                {{ vuelidateErrors.name }}
              </label>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="document-type"
              >
                Typ dokumentu
              </label>
              <multiselect
                v-model="documentType"
                :options="documentTypeOptions"
                track-by="code"
                label="text"
                open-direction="bottom"
                :clear-on-select="false"
                :close-on-select="true"
                :searchable="true"
                :show-labels="false"
                placeholder="Vyberte typ dokumentu"
              />
              <label
                v-if="v$.documentType.$error"
                class="error"
                for="document-type"
              >
                {{ vuelidateErrors.documentType }}
              </label>
            </div>
            <div class="form-group">
              <label
                class="control-label"
                for="document-type"
              >
                Prístup
              </label>
              <multiselect
                v-model="access"
                :options="accessTypeOptions"
                track-by="code"
                label="text"
                open-direction="bottom"
                :clear-on-select="false"
                :close-on-select="true"
                :searchable="true"
                :show-labels="false"
                placeholder="Vyberte typ prístupu"
              />
              <label
                v-if="v$.access.$error"
                class="error"
                for="document-type"
              >
                {{ vuelidateErrors.access }}
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                class="control-label"
                for="file-upload"
              >
                Súbory
              </label>
              <file-upload-element
                v-model="files"
                :attachments="attachments"
                @remove-saved="removeSavedFile"
              />
              <label
                v-if="v$.files.$error"
                class="error"
                for="file-upload"
              >
                {{ vuelidateErrors.files }}
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="row">
        <button
          class="btn btn-tenderia-grey-blue btn-inverse"
          @click="goBack"
        >
          <i class="icon-arrow-left" /> Späť
        </button>
        <button
          class="btn btn-success"
          @click="uploadDocument"
        >
          <i class="icon-save" /> Uložiť dokument
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadElement from '@/components/forms/FileUploadElement.vue'
//import UploadManager from '@/components/utils/UploadManager.js'
import Multiselect from 'vue-multiselect'
import { useOrderStore } from '@/storePinia/orderStore'
import { useStatsStore } from '@/storePinia/statsStore'
import { useVuelidate } from '@vuelidate/core'
import {
  useVuelidateValidationErrors,
  validateRequired,
  validateRequiredIf,
  validateMaxLength
} from '@/components/validator'

export default {
  name: 'AdminDocumentsAdd',
  components: { FileUploadElement, Multiselect },
  inject: ['UploadProgressModal'],
  data () {
    return {
      orderStore: useOrderStore(),
      statsStore: useStatsStore(),
      name: '',
      files: [],
      attachments: [],
      documentType: '',
      documentTypeOptions: [
        { code: 'sp', text: 'Súťažné podklady' },
        { code: 'vsp', text: 'Vysvetlenie súťažných podkladov' },
        { code: 'zm', text: 'Zmluva' },
        { code: 'izm', text: 'Informácia o zverejnení zmluvy' },
        { code: 'iop', text: 'Informácia o otváraní ponúk' },
        { code: 'ivvp', text: 'Informácia o výsledku vyhodnotenia ponúk' },
        { code: 'onpp', text: 'Odôvodnenie nezrušenia použitého postupu' },
        { code: 'pu', text: 'Ponuky uchádzačov' },
        { code: 'sz', text: 'Správa o zákazke' },
        { code: 'ssup', text: 'Suma skutočne uhradeného plnenia' },
        { code: 'zop', text: 'Zápisnica z otvárania ponúk' },
        { code: 'zvsu', text: 'Zápisnica z vyhodnotenia splnenia podmienok účasti' },
        { code: 'zvp', text: 'Zápisnica z vyhodnotenia ponúk' },
        { code: 'zs', text: 'Zoznam subdodávateľov' },
        { code: 'id', text: 'Iný dokument k zákazke' }
      ],
      access: { code: 'free', text: 'Verejný' },
      accessTypeOptions: [
        { code: 'free', text: 'Verejný' },
        { code: 'order-user', text: 'Neverejný (interný dokument)' }
      ],
    }
  },
  setup () {
    const v$ = useVuelidate({
      $lazy: true,
      $autoDirty: true,
    })
    return { v$ }
  },
  validations () {
    return {
      name: { validateRequired, validateMaxLength: validateMaxLength(250) },
      documentType: { validateRequired },
      files: { validateRequiredIf: validateRequiredIf(Array.isArray(this.attachments) && this.attachments.length === 0) },
      access: { validateRequired }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    vuelidateErrors () {
      return useVuelidateValidationErrors(this.v$.$errors)
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const { name, type, access } = this.order.attachments.find(item => item.id === this.$route.params.id)
      this.name = name
      this.documentType = this.documentTypeOptions.find(docType => docType.code === type)
      this.access = this.accessTypeOptions.find(accessType => accessType.code === access)
      this.fetchFiles()
    }
  },
  methods: {
    async fetchFiles () {
      const fetchParams = {
        parent: this.$route.params.id,
        limit: 1000
      }
      const { data } = await this.$http.post('/file/list', fetchParams)
      this.attachments = data.files
    },
    async removeSavedFile (value) {
      try {
        await this.$http.post('/file/set', { file: { id: value.fileList[value.index].id, parent: null } })
        this.attachments.splice(value.index, 1)
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error)
      }
    },
    goBack () {
      window.history.go(-1)
    },
    uploadFinish () {
      this.CloseLoading()
      setTimeout(async () => {
        await this.orderStore.getOrder(this.order.id)
        this.OkAlert({ text: 'Dokument bol úspešne uložený' }).then(() => {
          window.history.go(-1)
        })
      }, 500)
    },
    uploadError (error) {
      this.CloseLoading()
      setTimeout(() => {
        this.ErrorAlert({ text: 'Pri ukladaní dokumentu nastala chyba.' }, error)
      }, 500)
    },
    async uploadDocument () {
      const isValid = await this.v$.$validate()
      if (!isValid) return
      var data = {
        file: {
          id: this.$route.params.id,
          name: this.name,
          description: this.description,
          type: this.documentType.code,
          access: this.access.code,
          kind: 'document',
          order: this.order.id
        }
      }

      try
      {
        const response = await this.$http.post('/file/set', data)
        if (this.files.length === 0)
        {
          this.uploadFinish()
          return
        }
        const titles = ['Ukladám prílohy dokumentu', 'Prosím čakajte', 'Zvážte aktiváciu Prémiových služieb', 'Získate zobrazenie priebehu nahrávania', 'Získate plnú rýchlosť nahrávania súborov', 'Získate mnoho ďalších funkcií']
        if (this.$route.params.id)
        {
          await this.UploadProgressModal({ files: this.files, title: 'Ukladám prílohy dokumentu', titles, onDone: this.uploadFinish, onError: this.uploadError, fields: { parent: this.$route.params.id, access: 'free' } })
          //this.UploadProgressAlert({ title: 'Ukladám prílohy dokumentu' }, this.statsStore)
          //const uploader = new UploadManager(this.uploadFinish, this.uploadError, { parent: this.$route.params.id, access: 'free' }, this.$http)
          //uploader.startUpload(this.files, null)
        }
        else
        {
          this.parent = response.data.id
          await this.UploadProgressModal({ files: this.files, title: 'Ukladám prílohy dokumentu', titles, onDone: this.attachFile, onError: this.uploadError, fields: { parent: response.data.id, access: 'free' } })
          //this.UploadProgressAlert({ title: 'Ukladám prílohy dokumentu' }, this.statsStore)
          //const uploader = new UploadManager(this.attachFile, this.uploadError, { parent: response.data.id, access: 'free' }, this.$http)
          //uploader.startUpload(this.files, null)
        }

        /* OLD
        this.UploadProgressAlert({ title: 'Ukladám prílohy dokumentu' }, this.statsStore)
        if (this.$route.params.id)
        {
          if (this.files.length === 0) {
            this.uploadFinish()
            return
          }
          const uploader = new UploadManager(this.uploadFinish, this.uploadError, { parent: this.$route.params.id, access: 'free' }, this.$http)
          uploader.startUpload(this.files, null)
        }
        else
        {
          this.parent = response.data.id
          const uploader = new UploadManager(this.attachFile, this.uploadError, { parent: response.data.id, access: 'free' }, this.$http)
          uploader.startUpload(this.files, null)
        }
        */
      } catch (error) {
        console.log(error)
        this.uploadError()
      }
    },
    attachFile () {
      var data = {
        order: {
          id: this.order.id
        },
        attachment: {
          id: this.parent
        }
      }
      var vm = this
      this.$http.post('/order/attachment/add', data).then(function () {
        vm.uploadFinish()
      }).catch((error) => {
        vm.uploadError(error)
      })
    }
  }
}
</script>
