<template>
  <div class="row">
    <div class="col-md-12">
      <offer-widget :order="order" />
    </div>
  </div>
</template>

<script>
import OfferWidget from '@/components/order/common/components/OfferWidget.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'MrUserOffers',
  components: { OfferWidget },
  data() {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order() {
      return this.orderStore.order
    },
  }
}
</script>
