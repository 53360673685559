<template>
  <multiselect
    v-model="selectedType"
    :options="options"
    track-by="code"
    label="text"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="true"
    :show-labels="false"
    :class="{ error: error }"
    placeholder="Vyberte si typ organizačnej jednotky"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'DepartmentTypeSelect',
  components: { Multiselect },
  props: {
    value: {
      type: String,
      default: null
    },
    error: Boolean
  },
  data () {
    return {
      options: [
        { code: 'department', text: 'Oddelenie' },
        { code: 'division', text: 'Odbor' },
        { code: 'unit', text: 'Úsek' },
        { code: 'section', text: 'Referát' },
        { code: 'headquarters', text: 'Ústredie' }
      ]
    }
  },
  computed: {
    selectedType: {
      get() {
        return this.options.find(option => option.code === this.value) || null
      },
      set(val) {
        this.$emit('input', val ? val.code : '')
      }
    }
  }
}
</script>
