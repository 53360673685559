<template>
  <multiselect
    v-model="contractor"
    :options="contractors"
    track-by="name"
    label="name"
    openDirection="bottom"
    placeholder="Vyberte si obstarávateľa"
    :show-labels="false"
    :disabled="disabled"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'ContractorSelect',
  components: { Multiselect },
  props: {
    value: String,
    disabled: Boolean
  },
  data () {
    return {
      globals: useGlobalStore(),
      contractors: []
    }
  },
  computed: {
    contractor: {
      get() {
        return this.contractors.find(contractor => contractor.id === this.value)
      },
      set(val) {
        this.$emit('input', val ? val.id : null)
      }
    }
  },
  mounted () {
    this.contractors = this.globals.user.organizations
  },
}
</script>
