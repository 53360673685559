<template>
  <div class="row">
    <div class="col-md-12">
      <div
        :id="`order-settings-items-${order.id}`"
        class="card"
      >
        <div class="card-heading">
          <h4 class="card-title">
            Položky - {{ order.name }}
          </h4>
          <div class="actions">
            <button
              v-if="canEdit"
              class="btn btn-tenderia-blue"
              @click.prevent="addItem()"
            >
              <i class="icon-add font-size-14" /> Pridať novú položku
            </button>
          </div>
        </div>
        <v-table
          :items="order.items"
          :columns="itemsColumns"
          item-key="id"
          class="light"
        >
          <template #cell(actions)="{ _item, row }">
            <a
              v-if="canEdit"
              href=""
              title="Upraviť"
              @click.prevent="updateItem(row)"
            >
              <i class="icon-pencil" />
            </a>
            <a
              v-if="canEdit"
              href=""
              title="Zmazať"
              @click.prevent="removeItemModal(row)"
            >
              <i class="icon-delete text-danger" />
            </a>
          </template>
        </v-table>
      </div>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'
import VTable from '@/components/ui/Table.vue'
export default {
  name: 'ItemsListWidget',
  components: { VTable },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    parent: {
      type: Object,
      default: () => null
    }
  },
  data: function () {
    return {
      orderStore: useOrderStore(),
      canEdit: this.parent ? this.parent._canEdit : this.order._canEdit
    }
  },
  computed: {
    itemsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'amount',
          label: 'Množstvo'
        },
        {
          key: 'unit',
          label: 'Jednotka'
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  methods: {
    showLoading () {
      $(`#order-settings-items-${this.order.id} .card-block`).LoadingOverlay('show')
    },
    hideLoading () {
      $(`#order-settings-items-${this.order.id} .card-block`).LoadingOverlay('hide')
    },
    addItem () {
      if (this.parent) {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-items-add-part`, params: { orderId: this.parent.id, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-items-add`, params: { orderId: this.order.id } })
      }
    },
    updateItem (index) {
      if (this.parent) {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-items-update-part`, params: { orderId: this.parent.id, partId: this.order.id, index: index } })
      } else {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-items-update`, params: { orderId: this.order.id, index: index } })
      }
    },
    removeItemModal (index) {
      this.YesNoAlert({
        title: 'Skutočne chcete odstrániť položku?',
        text: 'Položka ' + this.order.items[index].name + ' sa po zmazaní už nebude dať obnoviť.',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeItem(index)
        }
      })
    },
    removeItem (index) {
      this.showLoading()
      var data = {
        order: {
          id: this.order.id
        },
        item: {
          id: this.order.items[index]._id
        }
      }
      var vm = this
      this.$http.post('/order/item/remove', data).then(function () {
        vm.order.items.splice(index, 1)
        vm.hideLoading()
        setTimeout(() => {
          vm.OkAlert({ text: 'Položka bola zmazaná' })
        }, 500)
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(() => {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }, 500)
      })
    }
  }
}
</script>
