<script setup>
import { inject } from 'vue'
const props = defineProps({ href: String, label: String, file: Object })
const handleDownloadFile = inject('handleDownloadFile')
</script>

<template>
  <a :href="props.href" target="_blank" @click.prevent="() => handleDownloadFile(props.href, props.file)">
    {{ props.label }}
  </a>
</template>
