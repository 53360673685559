<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title ">
        Výzvy
      </h4>
      <div class="actions">
        <button
          :disabled="!canCreateTender"
          class="btn btn-tenderia-blue"
          @click.prevent="newTenderModal()"
        >
          <i class="icon-add font-size-14" /> Pridať výzvu
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row align-items-baseline tabel-filters">
        <div class="col-md-4 form-group">
          <label class="text-bold text-dark">
            Hľadaj v názve alebo popise:
          </label>
          <input
            v-model="fulltext"
            class="form-control"
          >
        </div>
        <div class="col-md-3 form-group">
          <label class="text-bold text-dark">
            Dátum vyhlásenia od:
          </label>
          <date-input
            v-model="journalAnnouncementDateFrom"
            name="journalAnnouncementDateFrom"
            :min-date="new Date()"
          />
        </div>
        <div class="col-md-3 form-group">
          <label class="text-bold text-dark">
            Dátum vyhlásenia do:
          </label>
          <date-input
            v-model="journalAnnouncementDateTo"
            name="journalAnnouncementDateTo"
            :min-date="new Date()"
          />
        </div>
      </div>
    </div>
    <v-table
      :items="contracts.orders"
      :columns="contractsColumns"
      :sort.sync="tableSort"
      item-key="id"
      class="light"
    >
      <template #cell(name)="{ item }">
        <order-link
          :user-type="'admin'"
          :order="item"
        />
      </template>
      <template #cell(journalAnnouncementDate)="{ item }">
        {{ $format.formatDateTime(item.journalAnnouncementDate) }}
      </template>
      <template #cell(procedureType)="{ item }">
        {{ $format.formatProcedureType(item) }}
      </template>
      <template #cell(bidsOpenAt)="{ item }">
        {{ $format.formatDateTime(item.bidsOpenAt) }}
      </template>
      <template #cell(state)="{ item }">
        <order-state
          :state="item.state"
          display="text"
        />
      </template>
    </v-table>
    <div class="card-footer">
      <v-pagination
        v-if="contracts.total"
        :page="page"
        :per-page="perPage"
        :per-page-options="pageSizeOptions"
        :total-elements="contracts.total"
      />
    </div>
  </div>
</template>

<script>
import OrderLink from '@/components/order/OrderLink.vue'
import DnsNewTenderModal from '../components/NewTenderModal'
import OrderState from '@/components/OrderState.vue'
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import DateInput from '@/components/forms/DateInput.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminTenders',
  components: {
    OrderState,
    OrderLink,
    VTable,
    VPagination,
    DateInput
  },
  data () {
    return {
      orderStore: useOrderStore(),
      contracts: {},
      fulltext: '',
      journalAnnouncementDateFrom: '',
      journalAnnouncementDateTo: '',
      tableSort: {
        sortBy: 'journalAnnouncementDate',
        sort: 'desc'
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    canCreateTender () {
      return this.order._canEdit && this.order.state === 'active'
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    contractsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov zákazky',
          sortable: true
        },
        {
          key: 'organization.name',
          label: 'Názov obstarávateľa',
          sortable: true
        },
        {
          key: 'journalAnnouncementDate',
          label: 'Dátum vyhlásenia súťaže',
          sortable: true
        },
        {
          key: 'bidsOpenAt',
          label: 'Dátum otvárania ponúk',
          sortable: true
        },
        {
          key: 'procedureType',
          label: 'Druh postupu',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav',
          sortable: true
        }
      ]
    },
    fetchParams () {
      return {
        limit: this.perPage,
        page: this.page,
        offset: this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        journalAnnouncementFrom: this.journalAnnouncementDateFrom,
        journalAnnouncementTo: this.journalAnnouncementDateTo,
        filter: this.fulltext,
        parent: this.$router.currentRoute.params.orderId,
        role: 'buyer'
      }
    }
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    }
  },
  mounted () {
    this.fetchContracts()
  },
  methods: {
    newTenderModal () {
      if (this.canCreateTender) {
        this.$emit('vuedals:new', {
          name: 'analytics-settings-modal',
          escapable: true,
          title: 'Nová výzva',
          props: {
            parent: this.order
          },
          component: DnsNewTenderModal
        })
      }
    },
    async fetchContracts () {
      const { data } = await this.$http.post('/order/list', this.fetchParams)
      this.contracts = data
    }
  }
}
</script>
