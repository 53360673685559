<template>
  <div class="input-group date">
    <input
      type="text"
      :id="name"
      :name="name"
      :disabled="disabled"
      v-model="formatedDate"
      autocomplete="off"
      class="form-control"
      :class="{ error: error }"
      :placeholder="placeholder"
    />
    <button
      v-if="formatedDate && !disabled"
      class="input-group-addon clear"
      @click="clearDate"
    >
      <i class="icon-close font-size-18"></i>
    </button>
  </div>
</template>

<script>
import moment from 'moment'
require('bootstrap-datetime-picker')
export default {
  name: 'DateInput',
  props: ['name', 'value', 'disabled', 'minDate', 'maxDate', 'error', 'placeholder'],
  data () {
    return {
      date: new Date()
    }
  },
  computed: {
    // Computed property for formatedDate
    formatedDate: {
      get() {
        return this.value ? moment(this.value).format('DD. MM. YYYY') : ''
      },
      set(newVal) {
        const parsedDate = moment(newVal, 'DD. MM. YYYY', true)
        if (parsedDate.isValid()) {
          this.$emit('input', parsedDate.toISOString())
        } /* else {
          this.$emit('input', null) // Emit null if the date is invalid
        } */
      }
    }
  },
  methods: {
    clearDate () {
      this.formatedDate = ''
      this.$emit('input', null)
    }
  },
  mounted () {
    if (this.value) {
      this.date = new Date(this.value)
    }
    var vm = this
    var dateTimePicker = $(`#${vm.name}`).datetimepicker({
      format: 'dd. mm. yyyy',
      fontAwesome: true,
      language: 'sk',
      autoclose: true,
      todayHighlight: true,
      weekStart: 1,
      initialDate: this.date,
      timezone: 2,
      minView: 2,
      startDate: this.minDate || false,
      endDate: this.maxDate || false
    })
    dateTimePicker.on('change', function () {
      let date = $(`#${this.name}`).datetimepicker('getDate')
      vm.$emit('input', date ? date.toISOString() : '')
    })
  }
}
</script>
