<script setup>

import { ref, onMounted, onUnmounted } from 'vue'
import { formatNumber } from '@/utils/helpers.js'

const props = defineProps({ row: Object })
const _isError = ref(false)
const _isLoading = ref(false)
const rowClass  = 'row'
const labelClass = 'col col-md-4 control-label'
const valueClass = 'col'

const category = props.row.category || '-'
const action = props.row.action || '-'
const user = props.row.user || {}
const data = props.row.data || {}
const order = data.order || data.previousOrder || {}
const offer = data.offer || data.request?.offer || {}
const item = data.item || {}
const request = data.request || {}
const supplier = action === 'remove' ? data.removed[0] || {} : data.request?.supplier || {}
const member = action === 'create' ? data.member : data.order?.commission ? data.order.commission.find(member => member._id === request.memberId) || {} : {}
const criterion = data.criterion || {}

let to = ''
if (category === 'order:communication')
{
  if (request.to.length !== 0)
  {
    to = `${request.to[0].firstname} ${request.to[0].firstname} <${request.to[0].email}>`
  }
  else
  {
    data.request.bcc.forEach(recipient => {to += `${recipient.firstname} ${recipient.lastname} <${recipient.email}>, `})
    to = to.substring(0, to.length - 2)
  }
}

const formatIco = function(value)
{
  if (value.length === 1) return value[0]
  else                    return value.join(', ')
}

const formatNum = function (value, decimalCount)
{
  return formatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } })
}

onMounted(async () =>
{
})

onUnmounted(async () =>
{
})

</script>

<template>
  <div class="logs-detail-list">
    <div class="containter mx-3 mb-5">
      <!-- generic data -->
      <template>
        <div :class="rowClass">
          <div :class="labelClass">Kategória:</div>
          <div :class="valueClass">{{ $format.formatLogsCategory(category) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Názov:</div>
          <div :class="valueClass">{{ $format.formatLogsActions(action, row) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Dátum:</div>
          <div :class="valueClass">{{ $format.formatDateTime(row.createdAt) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Používateľ:</div>
          <div :class="valueClass">{{ `${user.title1} ${user.firstname} ${user.lastname} ${user.title2}` }}</div>
        </div>
      </template>

      <!-- order details -->
      <template v-if="category === 'order'">
        <div :class="rowClass">
          <div :class="labelClass">Názov:</div>
          <div :class="valueClass">{{ order.name }}</div>
        </div>
      </template>

      <!-- order parts -->
      <template v-if="category === 'order:parts'">
        <div :class="rowClass">
          <div :class="labelClass">Názov časti:</div>
          <div :class="valueClass">{{ order.name }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Poznámka:</div>
          <div :class="valueClass">{{ order.description }}</div>
        </div>
      </template>

      <!-- order documents -->
      <template v-if="category === 'order:documents'">
        <div :class="rowClass">
          <div :class="labelClass">Názov dokumentu:</div>
          <div :class="valueClass">{{ action === 'remove' ? data.removed[0].name : data.attachments[0].name }}</div>
        </div>
      </template>

      <!-- order communication -->
      <template v-if="category === 'order:communication'">
        <div :class="rowClass">
          <div :class="labelClass">Predmet:</div>
          <div :class="valueClass">{{ request.subject }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Odosielateľ:</div>
          <div :class="valueClass">{{ `${request.from.firstname} ${data.request.from.lastname} <${data.request.from.email}>` }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Adresát:</div>
          <div :class="valueClass">{{ to }}</div>
        </div>
      </template>

      <!-- order:criteria -->
      <template v-if="category === 'order:criteria'">
        <div :class="rowClass">
          <div :class="labelClass">Názov kritéria:</div>
          <div :class="valueClass">{{ criterion.name }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Typ:</div>
          <div :class="valueClass">{{ $format.formatCriterionType(criterion.type) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Povaha:</div>
          <div :class="valueClass">{{ $format.formatNature(criterion.nature) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Minimálna cena:</div>
          <div :class="valueClass">{{ criterion.minimalValue === Number.MIN_SAFE_INTEGER ? "-&infin;" : formatNum(criterion.minimalValue, criterion.decimalCount) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Maximálna cena:</div>
          <div :class="valueClass">{{ criterion.maximalValue === Number.MAX_SAFE_INTEGER ? "&infin;" : formatNum(criterion.maximalValue, criterion.decimalCount) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Maximálny počet bodov:</div>
          <div :class="valueClass">{{ criterion.maximumPoints }}</div>
        </div>
      </template>

      <!-- order commision -->
      <template v-if="category === 'order:commision'">
        <div :class="rowClass">
          <div :class="labelClass">Člen komisie:</div>
          <div :class="valueClass">{{ `${member.title1} ${member.firstname} ${member.lastname} ${member.title2}` }}</div>
        </div>
      </template>

      <!-- offer -->
      <template v-if="category === 'offer'">
        <div :class="rowClass">
          <div :class="labelClass">Meno:</div>
          <div :class="valueClass">{{ offer.applicantDetails.businessName || offer.applicantDetails.groupName }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Adresa:</div>
          <div :class="valueClass">{{ offer.applicantDetails.address }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Ičo:</div>
          <div :class="valueClass">{{ formatIco(offer.applicantDetails.ico) }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Kontaktná osoba:</div>
          <div :class="valueClass">{{ offer.applicantDetails.contactPerson }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Telefónne číslo:</div>
          <div :class="valueClass">{{ offer.applicantDetails.phone }}</div>
        </div>
      </template>

      <!-- order items -->
      <template v-if="category === 'order:items'">
        <div :class="rowClass">
          <div :class="labelClass">Názov položky:</div>
          <div :class="valueClass">{{ item.name }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Počet:</div>
          <div :class="valueClass">{{ item.amount }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Jednotka:</div>
          <div :class="valueClass">{{ item.unit }}</div>
        </div>
      </template>

      <!-- order suppliers -->
      <template v-if="category === 'order:suppliers'">
        <div :class="rowClass">
          <div :class="labelClass">Meno:</div>
          <div :class="valueClass">{{ supplier.name }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Adresa:</div>
          <div :class="valueClass">{{ `${supplier.address} ${supplier.address2}, ${supplier.city}, ${supplier.zip}` }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Ičo:</div>
          <div :class="valueClass"><span> {{ supplier.ico }} </span></div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Kontaktná osoba:</div>
          <div :class="valueClass">{{ supplier.contactPerson }}</div>
        </div>
        <div :class="rowClass">
          <div :class="labelClass">Telefónne číslo:</div>
          <div :class="valueClass">{{ supplier.phone }}</div>
        </div>
      </template>

      <!-- iszu details -->
      <template v-if="category === 'order:iszu'">
        <div :class="rowClass" v-if="this.row?.data?.order">
          <div :class="labelClass">Číslo zákazky v ISZU:</div>
          <div :class="valueClass">{{ data.order }}</div>
        </div>
      </template>

      <!-- epvo details -->
      <template v-if="category === 'order:epvo'">
        <div :class="rowClass" v-if="this.row?.data?.order">
          <div :class="labelClass">Číslo zákazky v platforme:</div>
          <div :class="valueClass">{{ data.order }}</div>
        </div>
        <div :class="rowClass" v-if="this.row?.data?.email">
          <div :class="labelClass">Email:</div>
          <div :class="valueClass">Výzva odoslaná na adresu: {{ data.email }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
  .logs-detail-list .row
  {
    border-bottom: 1px solid #ddd;
    padding: 2px 0;
  }
  .logs-detail-list .col:first-child
  {
    color: rgb(72, 68, 136);
  }
</style>
