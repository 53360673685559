<template>
  <div class="layout free-order">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <div class="public-form-container p-3">
          <div class="registration-header">
            <span class="registration-title">
              <span class="text-primary">
                Aktivácia
              </span>
              účtu<span class="text-primary">
                .
              </span>
            </span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">
                        Heslo <small>*</small>
                      </label>
                      <password
                        type="password"
                        placeholder="Heslo"
                        defaultClass="form-control"
                        v-model="password1"
                        :toggle="true"
                        :badge="false"
                        required="required"
                        aria-required="true"
                      />
                      <label
                        v-if="form.password1.error"
                        id="password1-error"
                        class="error"
                        for="password1"
                      >
                        Zvolené heslo je príliš jednoduché!
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label">
                        Zopakujte heslo <small>*</small>
                      </label>
                      <password
                        type="password"
                        placeholder="Zopakujte heslo"
                        defaultClass="form-control"
                        v-model="password2"
                        :toggle="true"
                        :badge="false"
                        :showStrengthMeter="false"
                        required="required"
                        aria-required="true"
                      />
                      <label
                        v-if="form.password2.error"
                        id="password2-error"
                        class="error"
                        for="password2"
                      >
                        Heslá sa musia zhodovať.
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="row no-gutters justify-content-center w-100">
              <router-link
                to="/"
                class="btn btn-tenderia-grey-blue btn-inverse"
              >
                <i class="icon-arrow-left"></i> Späť
              </router-link>
              <button
                type="submit"
                @click.prevent="activate"
                class="btn btn-tenderia-blue"
                :disabled="sendDisabled"
              >
                Odoslať
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
var zxcvbn = require('zxcvbn')

export default {
  name: 'AccountActivation',
  components: {
    Password,
    AppLoginHeader,
    AppFooter
  },
  data () {
    return {
      password1: null,
      password2: null,
      form: {
        password1: {
          error: false,
          valid: false
        },
        password2: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    sendDisabled: function () {
      return !(this.form.password1.valid && this.form.password2.valid)
    }
  },
  watch: {
    password1 () {
      this.checkPasswordStrength()
      this.checkPasswordSameness()
    },
    password2 () {
      this.checkPasswordSameness()
    }
  },
  methods: {
    checkPasswordStrength () {
      let score = zxcvbn(this.password1).score
      let field = this.form.password1
      field.valid = field.error = false

      if (score > 1) {
        field.valid = true
      } else {
        field.error = true
      }
      return field.valid
    },
    checkPasswordSameness () {
      let field = this.form.password2
      field.valid = field.error = false

      if (this.password1 === this.password2) {
        field.valid = true
      } else {
        field.error = true
      }
      return field.valid
    },
    async activate () {
      if (!(this.checkPasswordStrength() && this.checkPasswordSameness())) {
        return
      }
      this.ShowLoading()
      var data = {
        user: {
          token: this.$route.params.token,
          password: this.password1,
          passcheck: this.password2
        }
      }
      try {
        await this.$http.post('/user/account/activate', data)
        this.CloseLoading()
        this.OkAlert({ title: 'Aktivácia účtu bola úspešná.' })
        this.$router.push('/')
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    }
  },
  mounted () {
    if (this.$route.params.token == null) {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
<style scoped lang="scss">
.Password {
  max-width: unset;
}
</style>
