<template>
  <div class="input-group date">
    <input
      type="text"
      :id="name"
      :name="name"
      :disabled="disabled"
      v-model="formatedDateTime"
      autocomplete="off"
      class="form-control"
    />
    <button
      v-if="formatedDateTime && !disabled"
      class="input-group-addon clear"
      @click="clearDate"
    >
      <i class="icon-close font-size-18"></i>
    </button>
  </div>
</template>

<script>
import moment from 'moment'
require('bootstrap-datetime-picker')

export default {
  name: 'DateTimeInput',
  props: ['name', 'value', 'disabled', 'minDate', 'maxDate'],
  data() {
    return {
      date: new Date() // Internal date representation
    }
  },
  computed: {
    // Computed property for formatedDateTime
    formatedDateTime: {
      get() {
        return this.value ? moment(this.value).format('DD. MM. YYYY HH:mm') : ''
      },
      set(newVal) {
        const parsedDate = moment(newVal, 'DD. MM. YYYY HH:mm', true)
        if (parsedDate.isValid()) {
          this.$emit('input', parsedDate.toISOString())
        } /* else {
          this.$emit('input', null) // Emit null if the date is invalid
        } */
      }
    }
  },
  methods: {
    clearDate() {
      this.$emit('input', null) // Emit null to clear the date-time value
    }
  },
  mounted() {
    if (this.value) {
      this.date = new Date(this.value)
    }

    const vm = this
    const dateTimePicker = $(`#${vm.name}`).datetimepicker({
      format: 'dd. mm. yyyy hh:ii',
      fontAwesome: true,
      language: 'sk',
      autoclose: true,
      todayHighlight: true,
      weekStart: 1,
      initialDate: this.date,
      timezone: 2,
      startDate: this.minDate || false,
      endDate: this.maxDate || false
    })

    // Listen for changes on the date picker
    dateTimePicker.on('change', function () {
      const date = $(`#${vm.name}`).datetimepicker('getDate')
      vm.$emit('input', date ? date.toISOString() : '')
    })
  }
}
</script>
