<template>
  <div class="card chart-card">
    <div class="card-heading">
      <h4 class="card-title">
        Zákazky podľa stavu
      </h4>
    </div>
    <div
      v-if="statesChartData"
      class="p-2"
    >
      <pie-chart-echart
        :data="statesChartData"
        :innerText="'Celkom'"
        :innerSubtext="orderStateTotal"
        :tooltipFormat="tooltipFormat"
        :color="statesChartColors"
      />
    </div>

    <div
      v-else
      class="card-body"
    >
      <span>Momentálne neobsahuje žiadne záznamy</span>
    </div>
  </div>
</template>

<script>
import PieChartEchart from '@/components/ui/PieChartEchart.vue'

export default
{
  name: 'OrderStatesCharts',
  components: { PieChartEchart },
  data () {
    return {
      orderStateStats: null,
      orderStateTotal: 0
    }
  },
  computed: {
    tooltipFormat () {
      return (params) => {
        return `<span class="text-bold">${params.name}:</span> ${params.value} (${params.percent}%)`
      }
    },
    statesChartColors () {
      const colors = ['#FF9D3B', '#1FCB7C', '#3E97EF', '#EB5757'] // Corresponding to desiredOrder
      const desiredOrder = ['planned', 'active', 'finished', 'cancelled']
      const stats = this.orderStateStats

      if (!stats || stats.length === 0) {
        return null
      }

      // Filter desiredOrder based on states with a count > 0
      const availableStates = desiredOrder.filter(state => {
        const stat = stats.find(item => item.state === state)
        return stat && stat.count > 0
      })

      // Return the colors corresponding to the available states
      return availableStates.map(state => {
        const stateIndex = desiredOrder.indexOf(state)
        return colors[stateIndex]
      })
    },
    statesChartData () {
      const desiredOrder = ['planned', 'active', 'finished', 'cancelled']
      const stats = this.orderStateStats
      if (!stats || stats?.length === 0) {
        return null
      }

      // Prepare the data structure based on the fetched object
      const data = desiredOrder.map((state) => {
        const stat = stats.find((item) => item.state === state)

        return {
          state,
          name: stat?.label || this.$format.orderState(state),
          value: stat?.count || 0
        }
      })

      return data
    }
  },
  methods: {
    async fetchStatistics () {
      const { data: { stats } } = await this.$http.post('/stats/generate', {
        object: 'orders',
        type: 'order-state-stats'
      })
      this.orderStateTotal = stats?.total || 0
      this.orderStateStats = stats?.stats
    },
  },
  mounted () {
    this.fetchStatistics()
  }
}
</script>
