<template>
  <multiselect
    v-model="selectedCpvCode"
    :disabled="disabled"
    :options="cpvCodes"
    track-by="code"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="true"
    :custom-label="customLabel"
    :placeholder="placeholder"
    :show-labels="false"
    :loading="loading"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'MainCpvCodeSelect',
  components: { Multiselect },
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      required: false,
      default: 'Vyberte CPV kód'
    }
  },
  data() {
    return {
      loading: false,
      cpvCodes: []
    }
  },
  computed: {
    selectedCpvCode: {
      get() {
        return this.cpvCodes.find(cpvCode => cpvCode.code === this.value) || null
      },
      set(value) {
        this.$emit('input', value ? value.code : '')
      }
    }
  },
  methods: {
    customLabel({ text, code }) {
      return `${text} - (${code})`
    }
  },
  async mounted() {
    this.loading = true
    var codes = JSON.parse(localStorage.getItem('CPV_CODES_MAIN'))
    if (codes != null) {
      this.cpvCodes = codes
      this.loading = false
    } else {
      const response = await this.$http.post('/enum/cpvmain' + '/list', { limit: 10000 })
      localStorage.setItem('CPV_CODES_MAIN', JSON.stringify(response.data.enum))
      this.cpvCodes = response.data.enum
      this.loading = false
    }
  }
}
</script>
