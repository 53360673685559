<template>
  <multiselect
    v-model="selectedUsers"
    :options="users"
    track-by="id"
    label="email"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    placeholder="Vyberte si používateľov"
    :loading="loading"
  >
    <template #option="{option}">
      {{ option.firstname }} {{ option.lastname }} ({{ option.email }})
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'RequestApproverSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      globals: useGlobalStore(),
      users: [],
      loading: false
    }
  },
  computed: {
    fetchParams () {
      return {
        organization: {
          id: this.globals.userOrganization.id
        }
      }
    },
    selectedUsers: {
      get() {
        return this.users.filter(user => this.value.find(val => val.id === user.id))
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  async mounted () {
    this.loading = true
    let { data } = await this.$http.post('/organization/approver/list', this.fetchParams)
    this.loading = false
    this.users = data.approvers
  },
}
</script>
