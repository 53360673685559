<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title">
        <span v-if="part.parent && part.part">
          Časť {{ part.part }} -
        </span>Vyhodnotenie ponúk
      </h4>
    </div>
    <template v-if="openingEnabledBySettings">
      <v-table
        :items="offers"
        :columns="tableColumns"
        item-key="id"
        class="light"
      >
        <template #header="{headersStyles}">
          <thead>
            <col>
            <col>
            <colgroup :span="part ? part.criteria.length * 2 : order.criteria.length * 2" />
            <col>
            <col>
            <tr>
              <th
                :style="headersStyles[4]"
                rowspan="2"
                class="valign-middle text-center"
              >
                Por. č.
              </th>
              <th
                :style="headersStyles[1]"
                rowspan="2"
                class="valign-middle text-center"
              >
                Uchádzač
              </th>
              <th
                :colspan="part ? part.criteria.length * 2 : order.criteria.length * 2"
                scope="colgroup"
                class="text-center border right"
              >
                Návrhy na plnenie kritérií na vyhodnotenie ponúk
              </th>
              <th
                :style="headersStyles[3]"
                rowspan="2"
                class="valign-middle text-center"
              >
                Celkový počet bodov
              </th>
              <th
                :style="headersStyles[4]"
                rowspan="2"
                class="valign-middle text-center"
              >
                Poradie
              </th>
            </tr>
            <tr>
              <template v-if="part">
                <th
                  v-for="criterion in part.criteria"
                  :key="criterion.id"
                  :style="headersStyles[2]"
                  scope="col"
                  colspan="2"
                  class="text-center second-row"
                >
                  <div class="two-row-header">
                    <div>
                      {{ criterion.name }} <span v-if="criterion.type === 'price'">
                        (EUR)
                      </span><span v-else>
                        ({{ criterion.unit }})
                      </span>
                    </div>
                    <span class="text-secondary">
                      Max. počet bodov: {{ criterion.maximumPoints }}
                    </span>
                  </div>
                </th>
              </template>
              <template v-else>
                <th
                  v-for="criterion in order.criteria"
                  :key="criterion.id"
                  :style="headersStyles[2]"
                  scope="col"
                  colspan="2"
                  class="text-center second-row"
                >
                  <div class="two-row-header">
                    <div>
                      {{ criterion.name }} <span v-if="criterion.type === 'price'">
                        (EUR)
                      </span><span v-else>
                        ({{ criterion.unit }})
                      </span>
                    </div>
                    <span class="text-secondary">
                      Max. počet bodov: {{ criterion.maximumPoints }}
                    </span>
                  </div>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template #body>
          <tbody>
            <tr
              v-for="offer in offers"
              :key="offer.id"
            >
              <td>{{ offer.rankByDate }}.</td>
              <td>
                <span>{{ offer.applicantDetails.businessName }}</span>
              </td>
              <template v-if="part">
                <template v-for="criterion in part.criteria">
                  <td
                    :key="`${offer.id}-${criterion._id}-value`"
                    class="text-right"
                  >
                    <span v-if="proposals[offer.id][criterion._id]">
                      {{ formatProposalValue(proposals[offer.id][criterion._id]) }}
                    </span>
                  </td>
                  <td
                    :key="`${offer.id}-${criterion._id}-points`"
                    class="text-right"
                  >
                    <span v-if="proposals[offer.id][criterion._id]">
                      {{ $format.numFixed2(proposals[offer.id][criterion._id].points) }}
                    </span>
                  </td>
                </template>
              </template>
              <template v-else>
                <template v-for="criterion in order.criteria">
                  <td
                    :key="`${offer.id}-${criterion._id}-value`"
                    class="text-right"
                  >
                    <span v-if="proposals[offer.id][criterion._id]">
                      {{ formatProposalValue(proposals[offer.id][criterion._id]) }}
                    </span>
                  </td>
                  <td
                    :key="`${offer.id}-${criterion._id}-points`"
                    class="text-right"
                  >
                    <span v-if="proposals[offer.id][criterion._id]">
                      {{ $format.numFixed2(proposals[offer.id][criterion._id].points) }}
                    </span>
                  </td>
                </template>
              </template>

              <td class="text-center">
                {{ $format.numFixed2(offer.totalPoints) }}
              </td>
              <td class="text-center">
                {{ offer.rankByPoints }}.
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </template>
    <div
      v-else
      class="card-block"
    >
      <p class="text-bold text-center pdd-vertical-25 font-size-20">
        Otváranie ponúk je neverejné.
      </p>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import { useStatsStore } from '@/storePinia/statsStore'
let sFormatNumber = require('simple-format-number')

export default {
  name: 'UserOffersEvaluationWidget',
  components: { VTable },
  props: {
    order: {
      type: Object,
      required: true
    },
    part: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      statsStore: useStatsStore(),
      offers: [],
      openingDate: '',
      proposals: {},
      criteria: {},
      loading: false,
      openingEnabledBySettings: false
    }
  },
  computed: {
    serverTime () {
      return new Date(this.statsStore.currentTime)
    },
    openingEnabled () {
      if (this.part) {
        return this.openingEnabledBySettings && this.part.isApplicant && this.openingDate && this.serverTime > this.openingDate
      } else {
        return this.openingEnabledBySettings && this.order.isApplicant && this.openingDate && this.serverTime > this.openingDate
      }
    },
    tableColumns () {
      return [
        {
          key: 'rankByDate'
        },
        {
          key: 'applicant'
        },
        {
          key: 'criteria'
        },
        {
          key: 'totalPoints'
        },
        {
          key: 'rankByPoints'
        }
      ]
    }
  },
  watch: {
    openingEnabled (value) {
      if (value) {
        this.loadOffers()
      }
    }
  },
  created () {
    if (this.part) {
      if (this.part.procedureType === 'tste' && this.part.bidsOpenAt2) {
        this.openingDate = new Date(this.part.bidsOpenAt2)
      }
      if (this.part.procedureType === 'ssse' && this.part.bidsOpenAt) {
        this.openingDate = new Date(this.part.bidsOpenAt)
      }
      if (!this.part.auction && this.part.offerOpeningType === 'distant') {
        this.openingEnabledBySettings = true
      }
    } else {
      if (this.order.procedureType === 'tste' && this.order.bidsOpenAt2) {
        this.openingDate = new Date(this.order.bidsOpenAt2)
      }
      if (this.order.procedureType === 'ssse' && this.order.bidsOpenAt) {
        this.openingDate = new Date(this.order.bidsOpenAt)
      }
      if (!this.order.auction && this.order.offerOpeningType === 'distant') {
        this.openingEnabledBySettings = true
      }
    }
  },
  methods: {
    formatProposalValue (proposal) {
      return proposal
        ? sFormatNumber(proposal.value, { fractionDigits: this.criteria[proposal.criterion].decimalCount, symbols: { decimal: ',', grouping: ' ' } })
        : ''
    },
    prepareOfferProposals (offer) {
      let proposals = {}
      offer.proposals.forEach(proposal => {
        proposals[proposal.criterion] = proposal
      })
      this.proposals[offer.id] = proposals
    },
    prepareCriteria () {
      if (this.part) {
        this.part.criteria.forEach(criterion => {
          this.criteria[criterion._id] = criterion
        })
      } else {
        this.order.criteria.forEach(criterion => {
          this.criteria[criterion._id] = criterion
        })
      }
    },
    async loadOffers () {
      this.prepareCriteria()
      this.offers = []
      let offersArray = []
      if (this.part) {
        let { data: { offers } } = await this.$http.post('/offer/catalog', { order: this.part.id, limit: 100, sort: 'sentAt', stage: '!excluded' })
        offersArray = offers
        offersArray.forEach(offer => {
          if (this.part.procedureType === 'ssse' && this.part.bidsOpenAt || (this.part.procedureType === 'tste' && this.part.bidsOpenAt2 && offer.stage !== 'excluded')) {
            this.offers.push(offer)
            this.prepareOfferProposals(offer)
          }
        })
      } else {
        let { data: { offers } } = await this.$http.post('/offer/catalog', { order: this.order.id, limit: 100, sort: 'sentAt', stage: '!excluded' })
        offersArray = offers
        offersArray.forEach(offer => {
          if (this.order.procedureType === 'ssse' && this.order.bidsOpenAt || (this.order.procedureType === 'tste' && this.order.bidsOpenAt2 && offer.stage !== 'excluded')) {
            this.offers.push(offer)
            this.prepareOfferProposals(offer)
          }
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.second-row {
  border-right: 1px solid #E0E0E0;
  .two-row-header {
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;
  }
}
</style>
