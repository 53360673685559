<template>
  <div>
    <component :is="overviewComponent" />
  </div>
</template>

<script>
import UserOverviewSsseDivided from '../OrderTypeSsseDivided/views/UserOverview.vue'
import UserOverviewDns from '../OrderTypeDns/views/UserOverview.vue'
import UserOverviewMarket from '../MarketResearch/views/UserOverview.vue'
import UserOverviewPtk from '../Ptk/views/UserOverview.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'FreeOrderHome',
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    overviewComponent () {
      if (this.order.type === 'nh' && this.order.subtype === 'nh') {
        return UserOverviewMarket
      } else if (this.order.type === 'ptk' && this.order.subtype === 'ptk') {
        return UserOverviewPtk
      } else if (this.order.procedureType === 'dns') {
        return UserOverviewDns
      } else {
        return UserOverviewSsseDivided
      }
    }
  },
}
</script>
