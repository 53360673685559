<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Údaje organizácie
        </h4>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group">
              <label class="text-dark text-bold">
                Názov spoločnosti <small>*</small>
              </label>
              <input
                id="org-name"
                type="text"
                v-model="organization.name"
                class="form-control"
                :class="{ error: v$.organization.name.$error }"
              />
              <label
                v-if="v$.organization.name.$error"
                class="error"
              >
                {{ vuelidateErrors.name }}
              </label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="text-dark text-bold">
                IČO <small>*</small>
              </label>
              <input
                id="ic"
                type="text"
                v-model="organization.ico"
                class="form-control"
                disabled
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label class="text-dark text-bold">
                IČ DPH
              </label>
              <input
                id="icdph"
                type="text"
                v-model="organization.icdph"
                class="form-control"
                :class="{ error: v$.organization.icdph.$error }"
              />
              <label
                v-if="v$.organization.icdph.$error"
                class="error"
              >
                {{ vuelidateErrors.icdph }}
              </label>
            </div>
          </div>
          <div class="col-md-9">
            <div class="form-group">
              <label class="text-dark text-bold">
                Ulica <small>*</small>
              </label>
              <input
                id="address"
                type="text"
                v-model="organization.address"
                class="form-control"
                :class="{ error: v$.organization.address.$error }"
              />
              <label
                v-if="v$.organization.address.$error"
                class="error"
              >
                {{ vuelidateErrors.address }}
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="text-dark text-bold">
                Číslo domu <small>*</small>
              </label>
              <input
                id="address2"
                type="text"
                v-model="organization.address2"
                class="form-control"
                :class="{ error: v$.organization.address2.$error }"
              />
              <label
                v-if="v$.organization.address2.$error"
                class="error"
              >
                {{ vuelidateErrors.address2 }}
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="text-dark text-bold">
                Mesto <small>*</small>
              </label>
              <input
                id="city"
                type="text"
                v-model="organization.city"
                class="form-control"
                :class="{ error: v$.organization.city.$error }"
              />
              <label
                v-if="v$.organization.city.$error"
                class="error"
              >
                {{ vuelidateErrors.city }}
              </label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="text-dark text-bold">
                PSČ <small>*</small>
              </label>
              <input
                id="zip"
                type="text"
                v-model="organization.zip"
                class="form-control"
                :class="{ error: v$.organization.zip.$error }"
              />
              <label
                v-if="v$.organization.zip.$error"
                class="error"
              >
                {{ vuelidateErrors.zip }}
              </label>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label class="text-dark text-bold">
                Krajina
              </label>
              <country-select
                v-model="organization.country"
                disabled
              ></country-select>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <button
              id="save-org"
              @click="updateOrganization"
              :disabled="v$.organization.$errors.length > 0"
              class="btn btn-success"
            >
              <i class="icon-save"></i> Aktualizovať organizáciu
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from '@/components/forms/CountrySelect.vue'
import { useVuelidate } from '@vuelidate/core'
import {
  useVuelidateValidationErrors,
  validateRequired,
  validateIcdph,
  validateZip
} from '@/components/validator'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserOrganizationEdit',
  components: { CountrySelect },
  setup () {
    const v$ = useVuelidate({
      $lazy: true,
      $autoDirty: true,
    })
    return { v$ }
  },
  validations () {
    return {
      organization: {
        name: { validateRequired },
        ico: { validateRequired },
        icdph: { validateIcdph },
        address: { validateRequired },
        address2: { validateRequired },
        city: { validateRequired },
        zip: { validateRequired, validateZip },
      },
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      organization: {
        id: useGlobalStore().userOrganization.id,
        name: useGlobalStore().userOrganization.name,
        ico: useGlobalStore().userOrganization.ico,
        icdph: useGlobalStore().userOrganization.icdph,
        address: useGlobalStore().userOrganization.address,
        address2: useGlobalStore().userOrganization.address2,
        city: useGlobalStore().userOrganization.city,
        country: useGlobalStore().userOrganization.country,
        zip: useGlobalStore().userOrganization.zip,
        mode: 'edit'
      }
    }
  },
  computed: {
    vuelidateErrors () {
      return useVuelidateValidationErrors(this.v$.$errors)
    }
  },
  methods: {
    async updateOrganization () {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      let data = {
        organization: this.organization
      }
      try {
        await this.$http.post('/organization/set', data)
        this.globals.userOrganization = { ...this.globals.userOrganization, ...this.organization }
        localStorage.setItem('userOrganization', JSON.stringify(this.globals.userOrganization))
        this.OkAlert({ title: 'Hotovo', text: 'Údaje o vašej organizácii boli zmenené.' })
      } catch (error) {
        this.ErrorAlert({ title: 'Chyba', text: 'Nepodarilo sa aktualizovať údaje' }, error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
i {
  font-size: 18px;
  line-height: 14px;
}
</style>
