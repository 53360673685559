import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminCriteria from '@/components/order/common/views/AdminCriteria'
import AdminCriteriaAdd from '@/components/order/common/views/AdminCriteriaAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import AdminItems from '@/components/order/common/views/AdminItems'
import AdminItemsAdd from '@/components/order/common/views/AdminItemsAdd'
import AdminParts from '@/components/order/common/views/AdminParts'
import AdminPartsAdd from '@/components/order/common/views/AdminPartsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import Admin from '@/components/order/OrderTypeSsseDivided/views/Admin'
import AdminApplicants from '@/components/order/OrderTypeSsseDivided/views/AdminApplicants'
import AdminHome from '@/components/order/OrderTypeSsseDivided/views/AdminHome'
import AdminInterested from '@/components/order/OrderTypeSsseDivided/views/AdminInterested'
import AdminOfferView from '@/components/order/common/views/AdminOfferView.vue'
import AdminOffers from '@/components/order/common/views/AdminOffers'
import AdminOverview from '@/components/order/OrderTypeSsseDivided/views/AdminOverview'
import AdminSettings from '@/components/order/OrderTypeSsseDivided/views/AdminSettings.vue'
import User from '@/components/order/OrderTypeSsseDivided/views/User'
import UserOverview from '@/components/order/OrderTypeSsseDivided/views/UserOverview'
import UserOffersOpening from '@/components/order/common/views/UserOffersOpening'
import UserOffers from '@/components/order/OrderTypeSsseDivided/views/UserOffers'
import UserOfferView from '@/components/order/common/views/UserOfferView.vue'
import UserOffersAdd from '@/components/order/OrderTypeSsseDivided/views/UserOffersAdd'
import AdminAuctions from '@/components/order/common/views/AdminAuctions'
import AdminAuctionsAdd from '@/components/order/common/views/AdminAuctionsAdd'
import UserAuctions from '@/components/order/common/views/UserAuctions'
import AdminSuppliers from '@/components/order/common/views/AdminSuppliers'
import AdminInviteSupplier from '@/components/order/common/views/AdminInviteSupplier'
import SupplierForm from '@/components/order/common/components/SupplierForm'
import SupplierSearch from '@/components/order/common/components/SupplierSearch'
import { permissions } from '@/router/permissions.js'

/*
  'pz-vs': 'pt-', // 'Podlimitná zákazka'
  'nz-vs': 'pt-', // 'Nadlimitná zákazka - verejná súťaž'
  'nz-prk': 'prk-', // 'Priame rokovacie konanie'
  'ob-prk': 'prk-', // 'Zákazka v oblasti obrany a bezpečnosti - priame rokovacie konanie'
  'nz-ip': 'ip-', // 'Nadlimitná zákazka - inovatívne partnerstvo'
  'nk-ovs': 'ovs-', // 'Nadlimitná koncesia - obchodná verejná súťaž'
  'sn-sn': 'sn-', // 'Súťaž návrhov'
  'pk-pk': 'pk-' // 'Podlimitná koncesia'
*/

const prefixes = [
  'pt',
  'prk',
  'ip',
  'ovs',
  'sn',
  'pk'
]

const ssseDividedRouter = (prefix) => {
  const routes = [
    //Admin routes
    {
      path: `${prefix}/:orderId`,
      name: `${prefix}-admin`,
      redirect: { name: `${prefix}-admin-overview` },
      component: Admin,
      children: [
        {
          path: 'home',
          name: `${prefix}-admin-home`,
          redirect: { name: `${prefix}-admin-overview` },
          component: AdminHome,
          children: [
            {
              path: 'overview',
              name: `${prefix}-admin-overview`,
              component: AdminOverview,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'settings',
              name: `${prefix}-admin-settings`,
              component: AdminSettings,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents',
              name: `${prefix}-admin-documents`,
              component: AdminDocuments,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents/add',
              name: `${prefix}-admin-documents-add`,
              component: AdminDocumentsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents/edit/:id',
              name: `${prefix}-admin-documents-edit`,
              component: AdminDocumentsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'parts',
              name: `${prefix}-admin-parts`,
              component: AdminParts,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'parts/add',
              name: `${prefix}-admin-parts-add`,
              component: AdminPartsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'parts/edit/:partId',
              name: `${prefix}-admin-parts-edit`,
              component: AdminPartsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items',
              name: `${prefix}-admin-items`,
              component: AdminItems,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items/add',
              name: `${prefix}-admin-items-add`,
              component: AdminItemsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items/add/part/:partId',
              name: `${prefix}-admin-items-add-part`,
              component: AdminItemsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items/update/:index',
              name: `${prefix}-admin-items-update`,
              component: AdminItemsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items/add/part/:partId/:index',
              name: `${prefix}-admin-items-update-part`,
              component: AdminItemsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria',
              name: `${prefix}-admin-criteria`,
              component: AdminCriteria,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria/add',
              name: `${prefix}-admin-criteria-add`,
              component: AdminCriteriaAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria/add/part/:partId',
              name: `${prefix}-admin-criteria-add-part`,
              component: AdminCriteriaAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria/update/:index',
              name: `${prefix}-admin-criteria-update`,
              component: AdminCriteriaAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria/add/part/:partId/:index',
              name: `${prefix}-admin-criteria-update-part`,
              component: AdminCriteriaAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            }
          ]
        },
        {
          path: 'communication',
          name: `${prefix}-admin-communication`,
          component: AdminCommunication,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'communication/add',
          name: `${prefix}-admin-communication-add`,
          component: AdminCommunicationAdd,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'offers',
          name: `${prefix}-admin-offers`,
          props: {
            round: 1
          },
          component: AdminOffers,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'offers2',
          name: `${prefix}-admin-offers-round2`,
          props: {
            round: 2
          },
          component: AdminOffers,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'offers/:offerId',
          name: `${prefix}-admin-offers-view`,
          component: AdminOfferView,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'offers/part/:partId/:offerId',
          name: `${prefix}-admin-offers-view-parts`,
          component: AdminOfferView,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'interested',
          name: `${prefix}-admin-interested`,
          component: AdminInterested,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'applicants',
          name: `${prefix}-admin-applicants`,
          component: AdminApplicants,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'auctions',
          name: `${prefix}-admin-auctions`,
          component: AdminAuctions,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'auctions/add',
          name: `${prefix}-admin-auctions-add`,
          component: AdminAuctionsAdd,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        }
      ]
    },
    //User routes
    {
      path: `${prefix}/user/:orderId`,
      name: `${prefix}-user`,
      redirect: { name: `${prefix}-user-overview` },
      component: User,
      children: [
        {
          path: 'overview',
          name: `${prefix}-user-overview`,
          component: UserOverview,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'communication',
          name: `${prefix}-user-communication`,
          component: UserCommunication,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'communication/add',
          name: `${prefix}-user-communication-add`,
          component: UserCommunicationAdd,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers',
          name: `${prefix}-user-offers`,
          component: UserOffers,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers-opening',
          name: `${prefix}-user-offers-opening`,
          component: UserOffersOpening,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers/view',
          name: `${prefix}-user-offers-view`,
          component: UserOfferView,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers/part/:partId',
          name: `${prefix}-user-offers-view-parts`,
          component: UserOfferView,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers/add',
          name: `${prefix}-user-offers-add`,
          component: UserOffersAdd,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers/add/part/:partId',
          name: `${prefix}-user-offers-add-parts`,
          component: UserOffersAdd,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'auctions',
          name: `${prefix}-user-auctions`,
          component: UserAuctions,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        }
      ]
    }
  ]

  if (prefix === 'pt') {
    routes[0].children.push(
      {
        path: 'suppliers',
        name: `${prefix}-admin-suppliers`,
        component: AdminSuppliers,
        meta: {
          viewRights: [permissions.ORDER_ORDER_CREATE_0]
        },
      },
      {
        path: 'suppliers/invite',
        name: `${prefix}-admin-suppliers-invite`,
        component: AdminInviteSupplier,
        redirect: {
          name: `${prefix}-admin-suppliers-search`
        },
        children: [
          {
            path: 'add',
            name: `${prefix}-admin-suppliers-add`,
            component: SupplierForm,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          },
          {
            path: 'edit/:id',
            name: `${prefix}-admin-suppliers-edit`,
            component: SupplierForm,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          },
          {
            path: 'search',
            name: `${prefix}-admin-suppliers-search`,
            component: SupplierSearch,
            meta: {
              viewRights: [permissions.ORDER_ORDER_CREATE_0]
            },
          }
        ]
      })
  }

  return routes
}

const generateSsseDividedRoutes = () => {
  const routers = prefixes.flatMap(prefix => ssseDividedRouter(prefix))
  return routers
}

export default generateSsseDividedRoutes
