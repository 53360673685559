<template>
  <div class="homepage-layout light">
    <div class="header navbar">
      <div class="header-container">
        <div class="header-container-inner text-right align-bottom">
          <div class="header-logo">
            <a
              @click.prevent
              href=""
              title="tenderia"
              rel="home"
              class="site-logo text-white"
            >
              <img
                src="/tenderia-white.png"
                alt=""
                typeof="foaf:Image"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
    <app-sub-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <router-view />
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
  <!-- Page Container END -->
</template>

<script>
import AppFooter from '@/components/AppFooter.vue'
import AppSubHeader from '@/components/order/AppSubHeader.vue'
export default {
  name: 'EmailRedirectLayout',
  components: { AppFooter, AppSubHeader }
}
</script>
