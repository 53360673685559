<template>
  <div>
    <admin-offers-submenu  v-if="order.procedureType === 'tste'" />
    <div class="row mt-3">
      <div class="col-md-12" v-if="order.divided">
        <admin-offers-parts v-for="part in order._parts" :order="order" :part="part" :round="round" :key="part.id" />
      </div>
      <div class="col-md-12" v-else>
        <admin-offers-parts :order="order" :round="round" />
      </div>
    </div>
  </div>
</template>


<script>
import AdminOffersParts from '@/components/order/common/components/AdminOffersParts.vue'
import AdminOffersSubmenu from '@/components/order/common/components/AdminOffersSubmenu.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminOffers',
  components: { AdminOffersParts, AdminOffersSubmenu },
  props: {
    round: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  },
}
</script>
