<template>
  <div>
    <ul
      class="nav nav-tabs"
      id="order-tabs"
      role="tablist"
    >
      <li class="nav-item">
        <button
          class="active"
          id="new-tab"
          data-toggle="tab"
          data-target="#new"
          role="tab"
          @click="activateTab('#new')"
        >
          Založiť novú zákazku
        </button>
      </li>
      <li class="nav-item">
        <button
          id="clone-tab"
          data-toggle="tab"
          data-target="#clone"
          role="tab"
          @click="activateTab('#clone')"
        >
          Klonovať zákazku
        </button>
      </li>
    </ul>
    <div
      class="tab-content"
      id="myTabContent"
    >
      <div
        class="tab-pane fade show active p-2"
        id="new"
        role="tabpanel"
      >
        <new-order-form :config="config" />
      </div>
      <div
        class="tab-pane fade p-2"
        id="clone"
        role="tabpanel"
      >
        <clone-order-form :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
import NewOrderForm from './NewOrderForm.vue'
import CloneOrderForm from './CloneOrderForm.vue'

export default {
  name: 'OrderModalTabs',
  components: { NewOrderForm, CloneOrderForm },
  props: {
    config: Object
  },
  data () {
    return {
      activeTab: '',
      actions: {
        new: '',
        clone: 'Klonovať zákazku'
      }
    }
  },
  methods: {
    activateTab (tab) {
      const targetTab = this.$el.querySelector(`button[data-target="${tab}"]`)
      if (targetTab) {
        const allTabs = this.$el.querySelectorAll('#order-tabs button')
        allTabs.forEach(button => button.classList.remove('active'))
        targetTab.classList.add('active')

        const allTabPanes = this.$el.querySelectorAll('.tab-pane')
        allTabPanes.forEach(pane => pane.classList.remove('show', 'active'))
        const targetPane = this.$el.querySelector(tab)
        if (targetPane) {
          targetPane.classList.add('show', 'active')
        }
      }
    }
  },
  created () {
    if (this.config.action) {
      this.activeTab = this.actions[this.config.action]
    }
  }
}
</script>
