<template>
  <div>
    <div class="row">
      <template v-if="order.divided">
        <user-offers-parts
          v-for="part in order._parts"
          :key="part.id"
          :order="order"
          :part="part"
        />
      </template>
      <div
        v-else
        class="col-md-12"
      >
        <user-offers-parts :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import UserOffersParts from '../components/UserOffersParts.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'UserOffersOpening',
  components: { UserOffersParts },
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  }
}
</script>
