<template>
  <div class="layout">
    <app-login-header :order="order" />
    <app-sub-header :back-link="backLink" />
    <!-- Page Container START -->
    <div class="page-container">
      <registration-banner class="pdd-top-15" />
      <!-- Content Wrapper START -->
      <div class="main-content">
        <div class="container-fluid">
          <ul class="order-main-menu mb-3">
            <li>
              <router-link :to="{ name: 'publicMarketResearchHome', params: $route.params}">
                Prehľad
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'publicMarketResearchOffer', params: $route.params}">
                Moja ponuka
              </router-link>
            </li>
          </ul>
          <router-view ref="child"></router-view>
        </div>
      </div>
      <!-- Content Wrapper END -->

      <!-- Footer START -->

      <!-- Footer END -->
    </div>
    <!-- Page Container END -->
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
import RegistrationBanner from '@/components/ui/RegistrationBanner.vue'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppSubHeader from '@/components/order/AppSubHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import { useGlobalStore } from '@/storePinia/globalStore'
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'PublicMarketResearch',
  components: { AppFooter, AppLoginHeader, RegistrationBanner, AppSubHeader },
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      orderStore: useOrderStore(),
      backLink: this.$route.fullPath,
    }
  },
  beforeRouteEnter (to, from, next) {
    // Access the previous route from the 'from' argument
    next(vm => {
      vm.backLink = from.fullPath
    })
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  },
  methods: {
    setUserDefaultOrganization (organizationId) {
      const selectedOrganization = this.globals.user.organizations.find(org => org.id === organizationId)
      if (selectedOrganization) {
        const selectedRelation = this.globals.user.relations.find(relation => relation.organization === organizationId)
        if (selectedRelation) {
          this.globals.user = {
            ...this.globals.user,
            roles: selectedRelation.roles,
            permissions: selectedRelation.permissions.map(permission => `${permission.group}/${permission.object}/${permission.action}/${permission.level}`)
          }
          this.globals.userOrganization = selectedOrganization
          localStorage.setItem('userOrganization', JSON.stringify(selectedOrganization))
        }
      }
    },
    logout () {
      this.$http.post('/auth/logout')
      localStorage.clear()
      this.globals.sessionType = null
      this.globals.authToken = null
      this.globals.user = null
      this.statsStore.premiumPackage = null
    }
  },
  async mounted () {
    window.addEventListener('beforeunload', this.logout)
    try {
      this.ShowLoading()
      this.loading = true
      const response = await this.$http.post('/auth/login', { supplierToken: this.$route.params.token })
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('sessionType', response.data.sessionType)
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
      this.globals.sessionType = response.data.sessionType
      this.globals.authToken = response.data.token
      this.globals.user = response.data.user
      this.globals.user.authLevel = response.data.authLevel
      this.globals.user.relations = response.data.relations
      this.$tenderiaService.syncCountries()
      this.$tenderiaService.syncCpvCodes()
      this.globals.user.organizations = response.data.organizations
      this.setUserDefaultOrganization(response.data.organization)
      localStorage.setItem('user', JSON.stringify(this.globals.user))
      await this.orderStore.readOrder(this.$route.params.orderId)
      await this.statsStore.updatePremiumPackageState()
      this.loading = false
      this.CloseLoading()
    } catch (error) {
      this.CloseLoading()
      this.ErrorAlert({ text: 'Neplatná pozvánka' }, error).then(() => { this.$router.push({ path: '/' }) })
    }
  },
  destroyed () {
    if (this.globals.user) {
      this.logout()
    }
  }
}
</script>
<style type="text/css">
  .free-order .page-container {
    padding-left: 16px;
  }
  .user-info {
    min-width: 150px;
  }
</style>
