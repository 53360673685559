<template>
  <div class="layout free-order">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <div class="public-form-container p-3">
          <div class="registration-header">
            <span class="registration-title">
              <span class="text-primary">
                Vytvorte si účet
              </span>
              tenderia<span class="text-primary">
                .
              </span>
            </span>
            <span class="registration-subtitle">
              Registrácia používateľa
            </span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <template>
                <template v-if="showOrganizationFields">
                  <div class="row">
                    <div class="col-md-12 row no-gutters align-items-baseline">
                      <h4>Firma / organizácia</h4>
                      <v-badge
                        v-if="form.manualMode"
                        color="primary"
                        glow
                      >
                        <small>Manuálne zadané údaje</small>
                      </v-badge>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="name"
                        >
                          Názov <small>*</small>
                        </label>
                        <rpo-search
                          placeholder="Názov"
                          :value="form.manualMode ? profile.organization.name : form.organization"
                          :manualMode="form.manualMode"
                          :error="v$.profile.organization.name.$error"
                          @manualModeChange="onManualModeChange"
                          @input="updateOrganizationName"
                        >
                          <template #prepend>
                            <span />
                          </template>
                        </rpo-search>
                        <label
                          v-if="v$.profile.organization.name.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.name }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="ic"
                        >
                          IČO <small>*</small>
                        </label>
                        <rpo-search
                          placeholder="IČO"
                          :value="form.manualMode ? profile.organization.ico : form.organization"
                          :error="v$.profile.organization.ico.$error"
                          itemText="cin"
                          :manualMode="form.manualMode"
                          @manualModeChange="onManualModeChange"
                          @input="updateOrganizationCin"
                        >
                          <template #prepend>
                            <span />
                          </template>
                        </rpo-search>
                        <label
                          v-if="v$.profile.organization.ico.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.ico }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="icdph"
                        >
                          IČ DPH
                        </label>
                        <input
                          id="icdph"
                          type="text"
                          v-model="profile.organization.icdph"
                          class="form-control"
                          placeholder="IČ DPH"
                          :class="{ error: v$.profile.organization.icdph.$error }"
                        />
                        <label
                          v-if="v$.profile.organization.icdph.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.icdph }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-9">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="address"
                        >
                          Ulica <small>*</small>
                        </label>
                        <input
                          id="address"
                          type="text"
                          v-model="profile.organization.address"
                          class="form-control"
                          placeholder="Ulica"
                          :class="{ error: v$.profile.organization.address.$error }"
                        />
                        <label
                          v-if="v$.profile.organization.address.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.address }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="address2"
                        >
                          Číslo domu <small>*</small>
                        </label>
                        <input
                          id="address2"
                          type="text"
                          v-model="profile.organization.address2"
                          class="form-control"
                          placeholder="Číslo domu"
                          :class="{ error: v$.profile.organization.address2.$error }"
                        />
                        <label
                          v-if="v$.profile.organization.address2.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.address2 }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="city"
                        >
                          Mesto <small>*</small>
                        </label>
                        <input
                          id="city"
                          type="text"
                          v-model="profile.organization.city"
                          class="form-control"
                          placeholder="Mesto"
                          :class="{ error: v$.profile.organization.city.$error }"
                        />
                        <label
                          v-if="v$.profile.organization.city.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.city }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="zip"
                        >
                          PSČ <small>*</small>
                        </label>
                        <input
                          id="zip"
                          type="text"
                          v-model="profile.organization.zip"
                          class="form-control"
                          placeholder="PSČ"
                          :class="{ error: v$.profile.organization.zip.$error }"
                        />
                        <label
                          v-if="v$.profile.organization.zip.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.zip }}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label
                          class="control-label"
                          for="country"
                        >
                          Krajina <small>*</small>
                        </label>
                        <country-select
                          v-model="profile.organization.country"
                          :value="profile.organization.country"
                        ></country-select>
                        <label
                          v-if="v$.profile.organization.country.$error"
                          class="error"
                        >
                          {{ vuelidateErrors.country }}
                        </label>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="row">
                  <div class="col-md-12">
                    <h4>Používateľ</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        class="control-label"
                        for="firstname"
                      >
                        Meno <small>*</small>
                      </label>
                      <input
                        id="firstname"
                        type="text"
                        v-model="profile.user.firstname"
                        class="form-control"
                        placeholder="Meno"
                        :class="{ error: v$.profile.user.firstname.$error }"
                      />
                      <label
                        v-if="v$.profile.user.firstname.$error"
                        class="error"
                      >
                        {{ vuelidateErrors.firstname }}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        class="control-label"
                        for="lastname"
                      >
                        Priezvisko <small>*</small>
                      </label>
                      <input
                        id="lastname"
                        type="text"
                        v-model="profile.user.lastname"
                        class="form-control"
                        placeholder="Priezvisko"
                        :class="{ error: v$.profile.user.lastname.$error }"
                      />
                      <label
                        v-if="v$.profile.user.lastname.$error"
                        class="error"
                      >
                        {{ vuelidateErrors.lastname }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        class="control-label"
                        for="title"
                      >
                        Titul pred menom
                      </label>
                      <input
                        id="title1"
                        type="text"
                        v-model="profile.user.title1"
                        class="form-control"
                        placeholder="Titul"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        class="control-label"
                        for="title2"
                      >
                        Titul za menom
                      </label>
                      <input
                        id="title2"
                        type="text"
                        v-model="profile.user.title2"
                        class="form-control"
                        placeholder="Titul"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        class="control-label"
                        for="email"
                      >
                        Email <small>*</small>
                      </label>
                      <input
                        id="email"
                        type="text"
                        v-model.lazy="profile.user.email"
                        class="form-control"
                        placeholder="Email"
                        :class="{ error: v$.profile.user.email.$error }"
                      />
                      <label
                        v-if="v$.profile.user.email.$error"
                        class="error"
                      >
                        {{ vuelidateErrors.email }}
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label
                        class="control-label"
                        for="phone"
                      >
                        Telefónne čislo <small>*</small>
                      </label>
                      <input
                        id="phone"
                        type="text"
                        v-model="profile.user.phone"
                        class="form-control"
                        placeholder="Zadávajte v tvare +421 123 456 789"
                        :class="{ error: v$.profile.user.phone.$error }"
                      />
                      <label
                        v-if="v$.profile.user.phone.$error"
                        class="error"
                      >
                        {{ vuelidateErrors.phone }}
                      </label>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="row no-gutters justify-content-center w-100">
              <router-link
                to="/"
                class="btn btn-tenderia-grey-blue btn-inverse"
              >
                <i class="icon-arrow-left"></i> Späť
              </router-link>
              <button
                type="submit"
                @click.prevent="registerUser"
                class="btn btn-tenderia-blue"
                :disabled="showOrganizationFields ? v$.profile.$errors.length > 0 : v$.profile.user.$errors.length > 0"
              >
                Zaregistrovať sa
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
</template>

<script>
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import CountrySelect from './forms/CountrySelect.vue'
import RpoSearch from '@/components/ui/RpoSearch.vue'
import VBadge from '@/components/ui/Badge.vue'
import { useVuelidate } from '@vuelidate/core'
import {
  useVuelidateValidationErrors,
  validateRequired,
  validatePhone,
  validateIcdph,
  validateZip,
  validateEmail,
  validateUniqueEmail
} from '@/components/validator'
export default {
  name: 'UserRegistration',
  components: { CountrySelect, AppLoginHeader, AppFooter, RpoSearch, VBadge },
  setup () {
    const v$ = useVuelidate({
      $lazy: true,
      $autoDirty: true,
    })
    return { v$ }
  },
  validations () {
    return {
      profile: {
        organization: {
          name: { validateRequired },
          ico: { validateRequired },
          icdph: { validateIcdph },
          address: { validateRequired },
          address2: { validateRequired },
          city: { validateRequired },
          zip: { validateRequired, validateZip },
          country: { validateRequired }
        },
        user: {
          firstname: { validateRequired },
          lastname: { validateRequired },
          email: { validateRequired, validateEmail, validateUniqueEmail: validateUniqueEmail(this.profile.user) },
          phone: { validateRequired, validatePhone }
        }
      }
    }
  },
  data () {
    return {
      eidToken: '',
      supplierToken: '',
      inviteToken: '',
      form: {
        organization: {},
        manualMode: false
      },
      profile: {
        organization: {
          id: '',
          name: '',
          ico: '',
          icdph: '',
          address: '',
          address2: '',
          city: '',
          country: '',
          zip: '',
          mode: 'registration'
        },
        user: {
          firstname: '',
          lastname: '',
          title1: '',
          title2: '',
          email: '',
          phone: '',
          mode: 'registration',
        }
      }
    }
  },
  computed: {
    showOrganizationFields () {
      return !this.supplierToken
    },
    vuelidateErrors () {
      return useVuelidateValidationErrors(this.v$.$errors)
    }
  },
  watch: {
    'form.organization' (value) {
      if (value && value.cin && !this.form.manualMode) {
        this.profile.organization.address = value.address.street
        this.profile.organization.address2 = value.address.building_number
        this.profile.organization.city = value.address.municipality
        this.profile.organization.zip = value.address.postal_code?.replace(/\s+/g, '')
        this.profile.organization.country = value.address.country.toUpperCase()
        this.profile.organization.name = value.name
        this.profile.organization.ico = value.cin
      }
      if (!value) {
        this.profile.organization.address = ''
        this.profile.organization.address2 = ''
        this.profile.organization.city = ''
        this.profile.organization.zip = ''
        this.profile.organization.country = ''
        this.profile.organization.name = ''
        this.profile.organization.ico = ''
      }
    }
  },
  created () {
    if (this.$route.params.eidToken) {
      this.eidToken = this.$route.params.eidToken
    }
    if (this.$route.params.supplierToken) {
      this.supplierToken = this.$route.params.supplierToken
    } else if (this.$route.params.inviteToken) {
      this.inviteToken = this.$route.params.inviteToken
    }
  },
  methods: {
    updateOrganizationName (val) {
      if (this.form.manualMode) {
        this.profile.organization.name = val
      } else {
        this.form.organization = val
      }
    },
    updateOrganizationCin (val) {
      if (this.form.manualMode) {
        this.profile.organization.ico = val
      } else {
        this.form.organization = val
      }
    },
    onManualModeChange (val) {
      this.form.manualMode = val
      this.form.organization = null
      this.profile.organization.name = ''
      this.profile.organization.ico = ''
    },
    async registerUser () {
      const isFormCorrect = await this.v$.profile.$validate()
      if (!isFormCorrect) return
      this.ShowLoading()
      let profile = Object.assign({}, this.profile)
      if (this.eidToken) {
        profile.eidToken = this.eidToken
      }
      if (this.supplierToken) {
        profile.supplierToken = this.supplierToken
        profile.organization = null
        profile.representative = null
      }
      if (this.inviteToken) {
        profile.inviteToken = this.inviteToken
      }

      try {
        let { data: { notify } } = await this.$http.post('/user/register', profile)
        this.CloseLoading()
        $('#registration-modal').modal('toggle')
        if (notify) {
          this.OkAlert({
            title: 'Registrácia čaká na schválenie',
            text: 'Vaša registrácia bude overená administrátorom do 24 hodín. V prípade, že sa tak nestane kontaktujte technickú podporu.'
          })
        } else {
          this.OkAlert({
            title: 'Potvrďte svoju registráciu',
            text: 'Proces registrácie dokončíte kliknutím na odkaz, ktorý sme Vám odoslali na email ' + this.profile.user.email
          })
        }
        this.$router.push('/')
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ title: 'Pri registrácii nastala chyba', text: 'V prípade opakovania problému kontaktujte technickú podporu' }, error)
      }
    }
  }
}
</script>
