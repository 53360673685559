<template>
  <div class="p-2">
    <template>
      <div class="row">
        <div
          v-if="order.divided"
          class="col-md-12 text-bold"
        >
          Vyberte si časti, do ktorých sa chcete zaradiť ako záujemca:
        </div>
        <div
          v-else
          class="col-md-12 text-bold"
        >
          Chcete odoslať žiadosť o zaradenie do zoznamu záujemcov v zákazke {{ order.name }}?
        </div>
      </div>
      <div
        v-for="part in parts"
        :key="part.id"
        class="row"
      >
        <div class="col-md-12">
          <div class="checkbox">
            <input
              v-if="!part.isInterested"
              :id="`part-${part.id}`"
              v-model="selectedParts"
              name="selected-parts"
              :value="part.id"
              type="checkbox"
            >
            <input
              v-else
              :id="`part-${part.id}`"
              name="selected-parts"
              :value="part.id"
              type="checkbox"
              disabled
              checked
            >
            <label
              :for="`part-${part.id}`"
              :class="{'text-dark': !part.isInterested}"
            >
              Časť {{ part.part }} - {{ part.name }} <i
                v-if="part.isInterested"
                class="icon-info font-size-11"
                title="Vaša žiadosť o zaradenie medzi známych záujemcov už bola zaregistrovaná."
              />
            </label>
          </div>
        </div>
      </div>
    </template>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-success"
        :disabled="saveDisabled"
        @click.prevent="save()"
      >
        Odoslať
      </button>
    </div>
  </div>
</template>

<script>
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'InterestedModal',
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      parts: [],
      selectedParts: [],
      current: 0,
      saveDisabled: false
    }
  },
  created () {
    this.saveDisabled = false
    if (this.order.divided) {
      this.parts = this.order._parts
    } else {
      this.selectedParts.push(this.order.id)
    }
  },
  methods: {
    save () {
      if (this.selectedParts.length > 0) {
        this.saveDisabled = true
        this.current = 0
        this.sendRequest()
      } else {
        this.InfoAlert({ title: 'Musíte zvoliť aspoň jednu časť zákazky.' })
      }
    },
    async sendRequest () {
      if (this.current < this.selectedParts.length) {
        let messageBody = `Týmto žiadame o zaradenie medzi známych záujemcov. Identifikácia záujemcu:<br />
                           ${this.globals.userOrganization.name}<br />
                           ${this.globals.userOrganization.address} ${this.globals.userOrganization.address2}<br />
                           ${this.globals.userOrganization.zip} ${this.globals.userOrganization.city}<br />
                           IČO: ${this.globals.userOrganization.ico}<br />
                           <br />
                           Kontaktná osoba: ${this.globals.user.title1}${this.globals.user.firstname} ${this.globals.user.lastname}${this.globals.user.title2}<br />
                           Mail: ${this.globals.user.email}<br />
                           Telefónne číslo: ${this.globals.user.phone}`
        let data = {
          message: {
            type: 'zu',
            subject: 'Žiadosť o zaradenie medzi záujemcov',
            body: messageBody,
            order: this.selectedParts[this.current],
            organization: this.globals.userOrganization.id,
            access: 'user',
            to: this.order.user.id,
            toOrgs: [this.order.organization.id],
            attachments: []
          }
        }

        try {
          const response = await this.$http.post('/message/set', data)
          await this.$http.post('/message/send', { message: { id: response.data.id } })
          this.current++
          this.sendRequest()
        } catch (error) {
          this.saveDisabled = false
          this.$emit('vuedals:close')
          this.ErrorAlert({ text: 'Pri spracovaní vašej žiadosti nastala chyba.' }, error)
        }
      } else {
        this.saveDisabled = false
        this.$emit('vuedals:close', 'update')
        this.OkAlert({ text: 'Vaša žiadosť o zaradenie medzi záujemcov bola odoslaná.' })
      }
    }
  }
}
</script>
