<template>
  <div class="card chart-card">
    <div class="card-heading">
      <h4 class="card-title">
        Zákazky podľa hodnoty
      </h4>
    </div>
    <div
      v-if="valuesChartData"
      class="p-2"
    >
      <pie-chart-echart
        :data="valuesChartData"
        :innerText="'Celkom'"
        :innerSubtext="orderValueTotal"
        :tooltipFormat="tooltipFormat"
        :labelFormat="labelFormat"
      />
    </div>

    <div
      v-else
      class="card-body"
    >
      <span>Momentálne neobsahuje žiadne záznamy</span>
    </div>
  </div>
</template>

<script>
import PieChartEchart from '@/components/ui/PieChartEchart.vue'

export default
{
  name: 'OrderValuesChart',
  components: { PieChartEchart },
  data () {
    return {
      orderValueStats: null,
      orderValueTotal: 0
    }
  },
  computed: {
    tooltipFormat () {
      return (params) => {
        return `<span class="text-bold">${params.name}:</span> ${this.$format.shortCurrencyFixed2(params.value)} (${params.data.count})`
      }
    },
    labelFormat () {
      return (params) => {
        return `${this.$format.shortCurrency(params.value)}`
      }
    },
    valuesChartData () {
      const stats = this.orderValueStats
      if (!stats || stats?.length === 0) {
        return null
      }

      // Prepare the data structure based on the fetched object
      const data = stats
        .map((stat) => {
          return {
            name: stat.label,
            value: stat.value,
            count: stat.count
          }
        })

      return data
    }
  },
  methods: {
    async fetchStatistics () {
      const { data: { stats } } = await this.$http.post('/stats/generate', {
        object: 'orders',
        type: 'order-type-stats'
      })
      this.orderValueTotal = (stats?.total && this.$format.shortCurrency(stats.total)) || 0
      this.orderValueStats = stats?.stats
    },
  },
  mounted () {
    this.fetchStatistics()
  }
}
</script>
