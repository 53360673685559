<script setup>

import { ref, computed, onMounted } from 'vue'
import { TenderiaHttp as $http } from '@/services/TenderiaHttp'
import { useOrderStore } from '@/storePinia/orderStore'
import VTable from '@/components/ui/Table.vue'
import EformsModal from '@/components/order/common/components/AdminEformsModal.vue'

const $emit = defineEmits(['vuedals:new'])
const orderStore = useOrderStore()
//const isLoading = ref(false)
const isError = ref(false)
const isProgress = ref(false)
const progressText = ref('Prosím čakajte...')
const progressValue = ref(0)
const progressJob = ref(null)
const eformsData = ref({})
const eformsColumns = [
  { key: 'name', label: 'Názov oznámenia' },
  { key: 'statusText', label: 'Stav' },
  { key: 'actions', label: 'Akcie' }
]

const eformsNotices = computed(() =>
{
  const arr = []
  const notices = eformsData.value.eformsData?.notices || []
  for (const n in notices) arr.push(notices[n])
  const existingNotices = Array.from(arr)

  const newNotices = eformsData.value?.eformsNotices || []
  for (const n of newNotices)
  {
    const exists = existingNotices.find(item => String(item.type) === String(n.type))
    if (!exists) arr.push({ type: n.type, name: n.name, status: '', statusText: 'Nevytvorené' })
  }
  return arr
})

const order = computed(() => { return orderStore.order })

const openOrderPairModal = async function()
{
  $emit('vuedals:new',
    {
      name: 'eforms-order-modal',
      closeOnBackdrop: false,
      dismissable: true,
      title: '',
      component: EformsModal,
      props: { mode: 'message', text: 'Halo', orderId: String(order.value.id) },
      onDismiss: async () => { await loadEformsDbData(true) }
    })
}

const openNoticeModal = async function(noticeId)
{
  const errs = eformsData.value.eformsData?.noticeErrors
  if (errs) errs[[noticeId]] = []
  $emit('vuedals:new',
    {
      name: 'eforms-notice-modal',
      closeOnBackdrop: false,
      dismissable: true,
      title: '',
      component: EformsModal,
      props: { mode: 'notice', orderId: String(order.value.id), noticeId: String(noticeId) },
      onDismiss: async () => { await loadEformsDbData(true) }
    })
}

const loadEformsDbData = async function(silent = false)
{
  const params = { order: order.value.id, service: 'eforms', action: 'eforms-load-db' }
  isError.value = false
  if (!silent)
  {
    isProgress.value = true
    progressValue.value = 0
  }
  const { data } = await $http.post('/order/service/execute', params)
  isProgress.value = false
  eformsData.value = data || {}
}

const executeAction = async function (act, param)
{
  isError.value = false
  try
  {
    progressJob.value = null
    const params = { order: order.value.id, action: act, parameter: param }
    const { data } = await $http.post('/order/service/execute', params)
    progressJob.value = data.jobId
    if (progressJob.value)
    { // background job
      startCheckingProgress()
    }
  }
  catch (error)
  {
    showError('Nepodarilo sa načítať dáta zo systému eForms...')
  }
}

const startCheckingProgress = function ()
{
  // if (isProgress.value) return
  isProgress.value = true
  checkJobProgress()
}

const checkJobProgress = async function ()
{
  try
  {
    const params = { order: order.value.id, job: progressJob.value }
    const { data } = await $http.post('/order/service/status', params)
    const isRunning = data.running
    const progress = data.progress
    const details = data.details
    progressValue.value = progress
    progressText.value = details
    if (isRunning) setTimeout(() => { checkJobProgress() }, 2500)
    else stopCheckingProgress()
    if (progress === -1) showError(details)
  }
  catch (error)
  {
    showError('Nepodarilo sa načítať dáta zo systému eForms')
  }
}

const stopCheckingProgress = function ()
{
  isProgress.value = false
  progressValue.value = 0
  progressJob.value = null
  loadEformsDbData(true)
}

const showError = function(err)
{
  isError.value = true
  progressText.value = err
  progressValue.value = -1
}

onMounted(async () =>
{
  await loadEformsDbData()
})

</script>

<template>
  <div class="row" v-if="order.integration.eforms.enabled">
    <div class="col-md-12">
      <div class="card">
        <div class="card-heading">
          <h4 class="card-title ">Podpora pre systém eForms</h4>
          <div class="actions">
            <button v-if="eformsData.eformsOrderId && !isProgress" class="btn btn-secondary" @click="executeAction('eforms-sync')"><i class="icon-synch"></i> Aktualizovať z eForms</button>
          </div>
        </div>
        <div>
          <template v-if="isError">
            <!-- ERROR --------------------------------------->
            <div class="px-3 py-5">
              <div class="text-left">
                <div class="text-danger text-semibold"><i class="icon-warning font-size-24" style="vertical-align: bottom;margin-right: 8px;"></i>Nie je možné dokončiť operáciu</div>
                <div>{{ progressText }}</div>
              </div>
              <button class="btn btn-secondary text-left mt-4" @click="loadEformsDbData"><i class="icon-synch" /> Načítať znova</button>
            </div>
          </template>

          <template v-else-if="isProgress">
            <!-- PROGRESS ------------------------------------>
            <div class="column px-2 py-5">
              <div class="col-md-12 text-center">
                <div>{{ progressText }}</div>
                <div v-if="isProgress">
                  <div v-if="progressValue < 0"></div>
                  <div v-if="progressValue === 0" class="text-center"><div class="spinner-border text-info" role="status"><span class="sr-only">Prosím čakajte...</span></div></div>
                  <div v-else class="progress" style="height: 8px;">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" :style="{ width: progressValue + '%' }" :aria-valuenow="progressValue" aria-valuemin="0" aria-valuemax="100" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <!-- ORDER DETAILS ------------------------------->
            <ul class="list-group">
              <li v-if="eformsData.eformsOrderId" class="list-group-item d-flex justify-content-between align-items-center" style="border-radius: 0; border: none; background: #eee; padding: 1rem;">
                <div>
                  <div class="text-success text-semibold m-0"><i class="icon-check font-size-20" style="vertical-align: bottom;margin-right: 4px;"></i>eForms zákazka vytvorená</div>
                  <div>Číslo zákazky: <a :href="eformsData.eformsOrderLink" target="_blank">{{ eformsData.eformsOrderId }}</a> <span v-if="eformsData?.eformsData?.order?.state">({{ eformsData?.eformsData?.order?.state }})</span></div>
                </div>
                <!--<a :href="eformsData.eformsOrderLink" class="btn btn-link btn-sm" role="button" target="_blank">Otvoriť v eForms</a>-->
              </li>

              <li v-else class="list-group-item d-flex justify-content-between align-items-center">
                <div v-if="order.type === 'pz' && order.private" class="text-danger text-semibold"><i class="icon-warning font-size-24" style="vertical-align: bottom;margin-right: 8px;"></i>Zákazka nebola nájdená v systéme eForms. Ak vyhlasujete zákazku bez zverejnenia, nie je potrebné vypĺňať zákazku v eForms. Pozvánky pre vybraných dodávateľov budú odoslané prostredníctvom elektornickej platformy (EVO).</div>
                <div v-else class="text-danger text-semibold"><i class="icon-warning font-size-24" style="vertical-align: bottom;margin-right: 8px;"></i>Zákazka nebola nájdená v systéme eForms</div>
                <div class="d-flex">
                  <button class="btn btn-secondary btn-sm" role="button" @click="openOrderPairModal">Pripojiť existujúcu</button>
                  <button class="btn btn-info btn-sm" role="button" @click="executeAction('eforms-new-order')">Vytvoriť</button>
                </div>
              </li>
            </ul>

            <!-- NOTICE DETAILS  ----------------------------->
            <v-table v-if="eformsData.eformsOrderId && eformsNotices.length" :items="eformsNotices" :columns="eformsColumns" item-key="id" class="light">
              <template #cell(name)="{ item }">
                <a v-if="item.link" :href="item.link" target="_blank" title="Otvoriť oznámenie v eForms">{{ item.name }}</a>
                <span v-else class="text-semibold">{{ item.name }}</span>
                <br>
                <a @click.stop="openNoticeModal(item.id)" v-if="eformsData?.eformsData?.noticeErrors?.[item.id]?.length" class="text-danger text-semibold"><i class="icon-warning font-size-20" style="vertical-align: bottom;margin-right: 4px;"></i>{{ `Formulár obsahuje ${eformsData?.eformsData?.noticeErrors?.[item.id]?.length} nesprávne vyplnených polí`}}</a>
              </template>
              <template #cell(statusText)="{ item }">{{ item.statusText }}</template>
              <template #cell(actions)="{ item }">
                <button v-if="item.status == ''" @click.stop="executeAction('eforms-add-notice', item.type)" class="btn btn-sm btn-success" title="Nové oznámenie"  style="vertical-align: bottom; min-width: auto; min-height: auto; margin: 0;">Vytvoriť</button>
                <a v-if="item.id" @click.stop="openNoticeModal(item.id)" class="text-primary" title="Otvoriť oznámenie"><i class="icon-doc-1" /></a>
                <a v-if="item.preview" :href="item.preview" target="_blank" class="text-primary" title="Náhľad v PDF"><i class="icon-pdf" /></a>
              </template>
              <!-- NO NOTICES => CREATE NEW -->
              <template #noData>
                <tbody>
                  <tr>
                    <td class="p-4" :colspan="eformsColumns.length">
                      <div>Žiadne oznámenia</div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
            <!------------------------------------------------>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
