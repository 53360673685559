<template>
  <form class="p-2">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label
            :for="`nazov-zakazky`"
            class="text-dark text-bold"
          >
            Názov novej zákazky <small>*</small>
          </label>
          <input
            :id="`nazov-zakazky`"
            type="text"
            v-model="orderTitle"
            :class="{ error: form.orderTitle.error, valid: form.orderTitle.valid }"
            class="form-control"
            required="required"
            aria-required="true"
          >
          <label
            v-if="form.orderTitle.error"
            class="error"
            :for="`nazov-zakazky`"
          >
            Toto pole je povinné. Môžete zadať max. 200 znakov.
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="text-dark text-bold">
            Obstarávateľ
          </label>
          <contractor-select
            disabled
            :value="contractor"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-group">
        <label
          class="text-dark text-bold"
          for="select-type"
        >
          Postup <small>*</small>
        </label>
        <procedure-select v-model="procedure" />
        <label
          v-if="form.procedure.error"
          class="error"
          for="select-type"
        >
          Pole je povinné.
        </label>
      </div>
    </div>
    <div
      v-if="procedure.type === 'pz'"
      class="row"
    >
      <div class="col-md-12 form-group">
        <label class="control-label">
          Viditeľnosť <small>*</small>
        </label>
        <div>
          <div class="radio">
            <input
              id="private-yes"
              type="radio"
              v-model="orderPrivate"
              :value="true"
            /><label for="private-yes">
              <strong>Neverejná</strong> (zákazka podľa §108 ZVO s PHZ do 800 000 tis pre stavebné práce a podlimitná na tovary a služby s oslovením minimálne troch vybraných dodávateľov bez zverejnenia vo vestníku verejného obstarávania)
            </label>
          </div>
          <div class="radio">
            <input
              id="private-no"
              type="radio"
              v-model="orderPrivate"
              :value="false"
            /><label for="private-no">
              <strong>Verejná</strong> (zákazka podľa §110, s PHZ nad 800 000 tis pre stavebné práce a dobrovoľne pre tovary a služby, s vyhlásením vo vestníku verejného obstarávania, v tenderia bude zobrazená medzi verejnými zákazkami)
            </label>
          </div>
        </div>
        <label
          v-if="form.orderPrivate.error"
          class="error"
          :for="`viditelnost`"
        >
          Pole je povinné.
        </label>
      </div>
    </div>
    <div
      class="row"
      v-if="isDividalbe(procedure.subtype)"
    >
      <div class="col-md-12 form-group">
        <label class="control-label">
          Delí sa zákazka na časti?
        </label><br>
        <div class="radio radio-inline">
          <input
            type="radio"
            :value="true"
            v-model="divided"
            id="divided-yes"
          /> <label for="divided-yes">
            Áno
          </label>
        </div>
        <div class="radio radio-inline">
          <input
            type="radio"
            :value="false"
            v-model="divided"
            id="divided-no"
          /> <label for="divided-no">
            Nie
          </label>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close"
      >
        Zrušiť
      </button>
      <button
        class="btn btn-success"
        @click.prevent="createOrder"
      >
        Vytvoriť zákazku
      </button>
    </div>
  </form>
</template>

<script>
import ProcedureSelect from '@/components/NewOrderModal/ProcedureSelect.vue'
import ContractorSelect from '@/components/forms/ContractorSelect.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'TransformToOrderModal',
  components: { ContractorSelect, ProcedureSelect },
  data () {
    return {
      globals: useGlobalStore(),
      orderTitle: '',
      contractor: '',
      orderPrivate: null,
      divided: false,
      procedure: {
        type: '',
        subtype: ''
      },
      form: {
        orderTitle: {
          error: false,
          valid: false
        },
        orderPrivate: {
          error: false,
          valid: false
        },
        procedure: {
          error: false,
          valid: false
        }
      },
      validators: [
        this.validateOrderTitle,
        this.validateOrderPrivate,
        this.validateProcedure
      ]
    }
  },
  created () {
    this.contractor = this.globals.userOrganization.id
  },
  methods: {
    close () {
      this.$emit('vuedals:close', null)
    },
    async createOrder () {
      if (!this.validate()) {
        return
      }
      try {
        this.ShowLoading({ title: 'Vytváram zákazku zo žiadanky' })
        const orderData = {
          request: {
            id: this.$route.params.requestId
          },
          order: {
            name: this.orderTitle,
            organization: this.contractor,
            type: this.procedure.type,
            subtype: this.procedure.subtype,
            divided: this.divided,
            private: this.orderPrivate
          }
        }
        const { data } = await this.$http.post('/request/convert', orderData)
        this.CloseLoading()
        this.PopupAlert({ title: 'Zákazka bola vytvorená.' })
        this.close()
        this.$openOrder(data.id, this.procedure.type, this.procedure.subtype)
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ title: 'Chyba', text: 'Zákazku sa nepodarilo vytvoriť' }, error)
      }
    },
    isDividalbe (subtype) {
      const dividable = ['vs', 'ip', 'prk', 'sn', 'pk']
      if (dividable.includes(subtype)) {
        return true
      } else {
        return false
      }
    },
    validate () {
      return this.validators.reduce((accumulator, validator) => validator() && accumulator, true)
    },
    validateOrderTitle () {
      let titleLength = this.orderTitle.replace(/\s/g, '').length
      let state = this.form.orderTitle
      state.valid = state.error = false
      if (titleLength > 0 && titleLength <= 200) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    },
    validateOrderPrivate () {
      let state = this.form.orderPrivate
      if (this.procedure.type === 'pz') {
        state.error = state.valid = false
        if (this.orderPrivate !== null) {
          state.valid = true
        } else {
          state.error = true
        }
      } else {
        state.valid = true
      }
      return state.valid
    },
    validateProcedure () {
      let state = this.form.procedure
      state.error = state.valid = false
      if (this.procedure && this.procedure.type && this.procedure.subtype) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    }
  },
  watch: {
    orderTitle () {
      this.validateOrderTitle()
    },
    orderPrivate () {
      this.validateOrderPrivate()
    },
    procedure () {
      this.validateProcedure()
      this.divided = false
    }
  }
}
</script>
