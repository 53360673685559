//import { Uploader } from '@/components/tenderia-uploader.js'
import Uploader from '@/components/utils/UploadLibrary'

import { mimeType } from 'mime-type/with-db'
import { useStatsStore } from '@/storePinia/statsStore'

export default class UploadManager
{
  constructor (onDone, onError, fields, transport, authorization, onProgress)
  {
    this.files = []
    this.uploadedFiles = []
    this.current = 0
    this.onDone = onDone
    this.onProgress = onProgress
    this.uploadStatus = {}
    this.$http = transport
    this.fields = fields
    this.onError = onError
    this.uploadToken = ''
    this.authorization = authorization || localStorage.getItem('token')
    this.offset = 1
  }

  getFiles()
  {
    return this.files
  }

  getFileType(index)
  {
    return this.files[index].type
  }

  startUpload(files, options)
  {
    this.files = files
    this.uploadedFiles = []
    this.next(0)
    if (options && options.totalCount)
    {
      this.uploadStatus.total = options.totalCount
    }
    else
    {
      this.uploadStatus.total = files.length
    }
    if (options && options.offset) {
      this.offset = options.offset + 1
    }
  }

  next(nr)
  {
    this.current = nr
    if (nr < this.files.length)
    {
      this.setFile(nr)
      return false
    }
    else
    {
      this.onDone(this.uploadedFiles.map(item => item.id))
      return true
    }
  }

  updateProgress(nr, percent)
  {
    try
    {
      this.uploadStatus.currentIndex = nr + this.offset
      this.uploadStatus.currentName = this.files[nr].name
      this.uploadStatus.currentPercent = percent
      if (this.onProgress)  this.onProgress(this.uploadStatus)
      else                  useStatsStore().setUploadStatus(this.uploadStatus)
    }
    catch (e)
    {
      console.log(e)
    }
  }

  async setFile(nr)
  {
    this.updateProgress(nr, 0)
    var data = { file: Object.assign({}, this.fields) }
    if (!data.file.name) data.file.name = this.files[nr].name
    data.file.mime = mimeType.lookup(this.files[nr].name)
    try {
      const response = await this.$http.post('/file/set', data)
      this.uploadedFiles[nr] = { index: nr, id: response.data.id, mime: data.file.mime, token: response.data.uploadToken }
      this.uploadFile(nr)
    }
    catch (error)
    {
      console.log(error)
      this.uploadedFiles[nr] = { index: nr, error: error }
      this.onError(error)
    }
  }

  uploadFile(nr)
  {
    const scope = this
    let uploader = new Uploader({ authorization: this.authorization, chunkSize: 524288 })
    uploader.on('progress', function onProgress (id, progress)
    {
      scope.updateProgress(nr, progress)
    }.bind(this))
    uploader.on('complete', function onComplete ()
    {
      scope.updateProgress(nr, 100)
      let done = this.next(nr+1)
      if (done) uploader.destroy()
    }.bind(this))
    uploader.on('error', this.onError)
    uploader.on('abort', this.onError)

    const uploadToken = this.uploadedFiles[nr]?.token
    uploader.addFile(this.files[nr], process.env.VUE_APP_BACKEND_URL + '/file/upload', uploadToken)
    uploader.start()
  }

}
