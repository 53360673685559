<template>
  <div class="header navbar">
    <div class="header-container">
      <div class="header-container-inner text-right align-bottom">
        <div class="header-logo">
          <router-link
            :to="!globals.user || isUserType ? '/' : ''"
            title="tenderia"
            rel="home"
            class="site-logo text-white"
          >
            <img
              src="/tenderia-white.png"
              alt=""
              typeof="foaf:Image"
            >
          </router-link>
        </div>
        <template v-if="this.globals.user && this.globals.sessionType === 'supplier'">
          <button
            @click="logout"
            class="btn btn-danger"
          >
            <i class="icon-turn-off mr-1" />Odhlásiť sa
          </button>
        </template>
        <template v-else>
          <div
            v-if="globals.user"
            class="header-user-navigation"
          >
            <ul class="nav-right">
              <li>
                <div v-if="globals.user && statsStore.premiumPackage">
                  <router-link to="/app/subscriptions" :title="statsStore.premiumPackage?.state?.valid ? 'Prémiové služby aktívne' : 'Bez prémiových služieb'">
                    <template v-if="statsStore.premiumPackage?.state?.valid">
                      <i class="icon-win text-white"></i>
                      <span class="badge badge-pill badge-success" style="font-size: 5px; position: absolute; margin-top: -4px;">&nbsp;</span>
                    </template>
                    <template v-else>
                      <i class="icon-win text-white"></i>
                      <span class="badge badge-pill badge-danger" style="font-size: 5px; position: absolute; margin-top: -4px;">&nbsp;</span>
                    </template>
                  </router-link>
                </div>
              </li>
              <li>
                <router-link
                  v-if="isUserType"
                  title="Domovská stránka"
                  to="/app/dashboard"
                >
                  <i class="icon-home"></i>
                </router-link>
              </li>
              <li>
                <div
                  v-if="canManageMessages"
                  class="header-messages"
                >
                  <router-link
                    to="/app/messages"
                    title="Správy"
                  >
                    <i class="icon-messages"></i>
                    <span v-if="newMessages > 0">
                      {{ newMessages }}
                    </span>
                  </router-link>
                </div>
              </li>
              <li class="user-profile dropdown">
                <a
                  href=""
                  class="user-info btn btn-tenderia-blue dropdown-toggle d-sm-flex d-none"
                  data-toggle="dropdown"
                >
                  <i class="icon-lock"></i>
                  <span class="name mr-2">
                    {{ globals.user.title1 }} {{ globals.user.firstname }} {{ globals.user.lastname }} {{ globals.user.title2 }}
                  </span>
                  <i class="icon-dropdown font-size-10"></i>
                </a>
                <a
                  href=""
                  class="dropdown-toggle d-sm-none btn btn-tenderia-blue header-btn"
                  data-toggle="dropdown"
                >
                  <span>{{ globals.user.firstname[0] }}{{ globals.user.lastname[0] }}</span>
                </a>
                <ul class="dropdown-menu">
                  <li v-if="isUserType && this.globals.user.organizations.length > 1">
                    <a
                      href=""
                      @click.prevent="openOrganizationSelect"
                    >
                      <i class="icon-dupllicate-1"></i>
                      <span>Prepnúť organizáciu</span>
                    </a>
                  </li>
                  <li v-if="isUserType">
                    <router-link to="/app/profile">
                      <i class="icon-settings"></i>
                      <span>Nastavenia</span>
                    </router-link>
                  </li>
                  <li class="logout">
                    <a
                      href=""
                      @click.prevent="logout"
                    >
                      <i class="icon-logout"></i>
                      <span>Odhlásiť sa</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div
            v-else
            class="header-user-navigation"
          >
            <ul class="nav-right">
              <li class="user-profile dropdown" v-if="!$route.fullPath.includes('login')">
                <button
                  class="btn btn-tenderia-blue dropdown-toggle d-none d-sm-flex"
                  data-toggle="dropdown"
                  id="dropdown-toggle"
                >
                  <i class="icon-lock"></i>
                  <span>{{ TEXT('STR_LOGIN_BUTTON') }}</span>
                </button>
                <a
                  href=""
                  class="dropdown-toggle d-sm-none btn btn-tenderia-blue header-btn"
                  data-toggle="dropdown"
                >
                  <i class="icon-lock"></i>
                </a>
                <div class="dropdown-menu login-header-dropdown-menu">
                  <form @submit.prevent="login">
                    <div class="login-form">
                      <div class="form-group">
                        <label
                          for="email"
                          class="control-label text-secondary"
                        >
                          E-mail
                        </label>
                        <input
                          id="email"
                          type="text"
                          v-model="user"
                          class="form-control"
                          placeholder="Email"
                          :class="{ error: error }"
                        >
                      </div>
                      <div class="form-group">
                        <label
                          for="password"
                          class="control-label text-secondary"
                        >
                          Heslo
                        </label>
                        <input
                          id="password"
                          type="password"
                          v-model="password"
                          class="form-control"
                          placeholder="Heslo"
                          :class="{ error: error }"
                        >
                        <label
                          v-if="error"
                          id="password-error"
                          class="error"
                          for="password"
                        >
                          Email alebo heslo je nesprávne.
                        </label>
                      </div>
                      <button
                        class="btn btn-tenderia-blue m-0"
                        type="submit"
                      >
                        <span v-if="!loading">
                          {{ TEXT('STR_LOGIN_BUTTON') }}
                        </span>
                        <div
                          v-if="loading"
                          class="spinner-border spinner-border-sm text-light"
                          role="status"
                        >
                          <span class="sr-only">
                            Prihlasujem...
                          </span>
                        </div>
                      </button>
                      <a
                        :href="eIdLoginUrl"
                        class="btn btn-tenderia-blue btn-inverse m-0 mt-2 d-none d-sm-flex"
                        title="Prihlásenie pomocou občianskeho preukazu s čipom"
                      >
                        {{ TEXT('STR_LOGIN_BUTTON_EID') }}
                      </a>
                      <div class="mt-sm-2 mb-sm-0 mt-3 mb-2 text-center">
                        <span class="text-secondary">
                          Zabudli ste heslo?
                          <router-link to="/password-reset" class="">Obnova hesla</router-link>
                        </span>
                      </div>
                    </div>
                  </form>
                  <div class="registration">
                    <span>
                      Chcete sa <router-link
                        to="/registration"
                        class="text-bold text-primary"
                      >
                        zaregistrovať?
                      </router-link>
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <router-link
                  class="btn btn-success d-none d-sm-flex"
                  to="/registration"
                >
                  <i class="icon-lock"></i>
                  <span>Registrácia</span>
                </router-link>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import ActiveOrganizationSelect from './ActiveOrganizationSelect.vue'
import { permissions } from '@/router/permissions.js'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'AppLoginHeader',
  inject: ['TEXT'],
  props: ['order'],
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      message: 'Zadajte Vaše meno a heslo pre prihlásenie',
      user: null,
      password: null,
      error: false,
      loading: false,
      eIdLoginUrl: process.env.VUE_APP_BACKEND_URL + '/auth/eid/login/start?a=login'
    }
  },
  computed: {
    isUserType () { return this.globals.user && this.globals.user.type !== 'observer' && this.globals.sessionType !== 'supplier' },
    canManageMessages () { return this.globals.user.permissions && this.globals.user.permissions.includes(permissions.MESSAGE_READ) },
    newMessages () { return this.statsStore.newMessages },
    routeId () {
      const key = Object.keys(this.$route.params).find(item => item.includes('Id') || item.includes('id'))
      return this.$route.params[key]
    }
  },
  methods: {
    async login () {
      if (this.user === null || this.password === null) {
        this.error = true
        return
      }
      this.error = false
      this.loading = true
      var data = {
        login: this.user.trim(),
        password: this.password
      }

      if (this.$route.params.supplierToken) {
        data.supplierToken = this.$route.params.supplierToken
      }

      if (this.$route.params.inviteToken) {
        data.inviteToken = this.$route.params.inviteToken
      }

      try {
        const response = await this.$http.post('/auth/login', data)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('sessionType', response.data.sessionType)
        this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
        this.globals.sessionType = response.data.sessionType
        this.globals.authToken = response.data.token
        this.globals.user = response.data.user
        this.globals.user.authLevel = response.data.authLevel
        this.globals.user.relations = response.data.relations
        this.statsStore.resetPremiumPackageState()
        this.$tenderiaService.syncCountries()
        this.$tenderiaService.syncCpvCodes()
        localStorage.setItem('user', JSON.stringify(this.globals.user))
        if (response.data.user.type === 'observer') {
          this.$router.push({ name: `${this.$orderRoutePrefix(response.data.order.type, response.data.order.subtype)}admin-overview`, params: { orderId: response.data.order.id } })
        } else {
          this.globals.user.organizations = response.data.organizations
          this.setUserDefaultOrganization(response.data.organization)
          if (this.globals.user.organizations.length > 1 || !this.globals.userOrganization) {
            this.openOrganizationSelect()
          }
          if (!this.order) {
            this.$router.push({ name: 'dashboard' })
          }
        }
        localStorage.setItem('user', JSON.stringify(this.globals.user))
        this.statsStore.setNewMessages()
        this.statsStore.setMessagesInterval()
        await this.statsStore.updatePremiumPackageState()
      } catch (error) {
        console.log(error)
        this.error = true
        this.loading = false
      }
    },
    setUserDefaultOrganization (organizationId) {
      const selectedOrganization = this.globals.user.organizations.find(org => org.id === organizationId)
      if (selectedOrganization) {
        const selectedRelation = this.globals.user.relations.find(relation => relation.organization === organizationId)
        if (selectedRelation) {
          this.globals.user = {
            ...this.globals.user,
            roles: selectedRelation.roles,
            permissions: selectedRelation.permissions.map(permission => `${permission.group}/${permission.object}/${permission.action}/${permission.level}`)
          }
          this.globals.userOrganization = selectedOrganization
          localStorage.setItem('userOrganization', JSON.stringify(selectedOrganization))
        }
      }
    },
    openOrganizationSelect () {
      this.$emit('vuedals:new', {
        name: 'organization-select-modal',
        escapable: true,
        title: 'Výber aktívnej organizácie',
        props: {
          organizations: this.globals.user.organizations,
          relations: this.globals.user.relations
        },
        component: ActiveOrganizationSelect
      })
    },
    async logout () {
      try {
        let sessionType = localStorage.getItem('sessionType')
        let token = localStorage.getItem('token')
        if (sessionType && sessionType.toLowerCase() === 'eid') {
          localStorage.clear()
          window.location = process.env.VUE_APP_BACKEND_URL + '/auth/eid/logout/start?token=' + token
        } else {
          await this.$http.post('/auth/logout')
          localStorage.clear()
          this.$router.push({ path: '/' })
        }
      } catch (error) {
        if (this.$router.path !== '/') {
          this.$router.push({ path: '/' })
        }
      } finally {
        this.statsStore.clearMessagesInterval()
        localStorage.clear()
        this.globals.authToken = null
        this.globals.user = null
        this.globals.userOrganization = null
      }
    }
  },
  mounted () {
    $('#dropdown-toggle').dropdown()
    if (this.globals.user && this.globals.authToken && !this.statsStore.messagesInterval && this.isUserType) {
      this.statsStore.setNewMessages()
      this.statsStore.setMessagesInterval()
    }
  },
}

</script>
<style lang="scss" scoped>
.registration {
  padding: 20px;
  text-align: center;
  background-color: #F1F3F5;
}
.dropdown-toggle {
  &::after {
    display: none;
  }
}
</style>
