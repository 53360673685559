<template>
  <router-link
    v-if="$route.path.includes('public')"
    :to="`/public/order/${order.id}`"
  >
    {{ order.name }}
  </router-link>
  <router-link
    v-else
    :to="{ name: linkName, params: {orderId: order.id} }"
  >
    {{ order.name }}
  </router-link>
</template>

<script>
export default {
  name: 'OrderLink',
  props: {
    order: Object,
    userType: String
  },
  data () {
    return {
      routePrefixes: {
        'nh-nh': 'mr',
        'pz-vs': 'pt',
        'nz-vs': 'pt',
        'nz-us': 'us',
        'nk-us': 'us',
        'ob-us': 'us',
        'us-vyz': 'usv',
        'ob-kd': 'kd',
        'nk-kd': 'kd',
        'kd-vyz': 'kdv',
        'nz-rkz': 'rkz',
        'nk-rkz': 'rkz',
        'ob-rkz': 'rkz',
        'rkz-vyz': 'rkzv',
        'nz-prk': 'prk',
        'ob-prk': 'prk',
        'nz-ip': 'ip',
        'nk-vs': 'pt',
        'dns-dns': 'dns',
        'dns-vyz': 'dnsv',
        'ptk-ptk': 'ptk',
        'nz-sd': 'sd',
        'nk-sd': 'sd',
        'sd-vyz': 'sdv',
        'nk-ovs': 'ovs',
        'sn-sn': 'sn',
        'pk-pk': 'pk'
      }
    }
  },
  computed: {
    prefix () {
      return this.routePrefixes[`${this.order.type}-${this.order.subtype}`]
    },
    linkName () {
      return `${this.prefix}-${this.userType}-overview`
    }
  }
}
</script>
