<template>
  <div class="container-fluid">
    <router-view ref="child" />
  </div>
</template>

<script>
import { useRequestStore } from '@/storePinia/requestStore'

export default {
  name: 'RequestHome',
  data () {
    return {
      requestStore: useRequestStore()
    }
  },
  methods: {
    async loadRequest () {
      this.ShowLoading()
      try {
        this.requestStore.getRequest(this.$route.params.requestId)
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať informácie o žiadanke.' }, error)
      }
    },
  },
  mounted () {
    if (this.$route.params.requestId) {
      this.loadRequest()
    }
  },
  beforeRouteLeave (to, from, next) {
    this.requestStore.resetRequest()
    next()
  },
}
</script>
