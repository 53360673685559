<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h1
          v-if="$route.params.state === 'all'"
          class="card-title"
        >
          Všetky moje zákazky
        </h1>
        <h1
          v-else
          class="card-title"
        >
          {{orderState($route.params.state)}}
        </h1>
        <div class="actions">
          <button
            @click="showFilter = !showFilter"
            class="btn btn-success filter-btn"
          >
            <i class="icon-filter"></i><span>{{showFilter ? 'Skryť' : 'Zobraziť'}} filter</span>
          </button>
        </div>
      </div>
      <v-collapsible>
        <div
          v-if="showFilter"
          class="card-block"
        >
          <div class="row gutters-sm align-items-baseline tabel-filters">
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Hľadaj v názve alebo popise:
              </label>
              <input
                v-model="fulltext"
                class="form-control"
              >
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Dátum vyhlásenia od:
              </label>
              <date-input
                name="journal-jnnouncement-date"
                v-model="journalAnnouncementDateFrom"
                :max-date="new Date()"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Dátum vyhlásenia do:
              </label>
              <date-input
                name="journal-jnnouncement-date-to"
                v-model="journalAnnouncementDateTo"
                :max-date="new Date()"
              />
            </div>
          </div>
        </div>
      </v-collapsible>
      <v-table
        :items="contracts.orders"
        :columns="contractsColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template #cell(name)="{ item }">
          <order-link
            :user-type="$route.params.userRole === 'buyer' ? 'admin' : 'user'"
            :order="item"
          />
        </template>
        <template #cell(journalAnnouncementDate)="{ item }">
          {{ $format.formatDateTime(item.journalAnnouncementDate) }}
        </template>
        <template #cell(procedureType)="{ item }">
          {{ $format.formatProcedureType(item) }}
        </template>
        <template #cell(bidsOpenAt)="{ item }">
          {{ $format.formatDateTime(item.bidsOpenAt) }}
        </template>
        <template #cell(state)="{ item }">
          <div class="row no-gutters align-items-baseline">
            <order-state :state="item.state"></order-state>
            <order-actions-menu
              v-if="userRole === 'buyer'"
              :order="item"
            />
          </div>
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="contracts.total"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="contracts.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OrderLink from './order/OrderLink.vue'
import OrderState from './OrderState.vue'
import VTable from './ui/Table.vue'
import VCollapsible from './ui/Collapsible.vue'
import VPagination from './ui/Pagination.vue'
import DateInput from '@/components/forms/DateInput.vue'
import OrderActionsMenu from './OrderActionsMenu.vue'

export default {
  name: 'MyOrdersPage',
  components: {
    OrderState,
    OrderLink,
    VTable,
    VCollapsible,
    VPagination,
    DateInput,
    OrderActionsMenu
  },
  data () {
    return {
      contracts: {},
      showFilter: true,
      fulltext: '',
      journalAnnouncementDateFrom: '',
      journalAnnouncementDateTo: '',
      tableSort: {
        sortBy: 'journalAnnouncementDate',
        sort: 'desc'
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    userRole () {
      return this.$route.params.userRole
    },
    contractsColumns () {
      const items = [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'journalAnnouncementDate',
          label: 'Dátum vyhlásenia súťaže',
          sortable: true
        },
        {
          key: 'bidsOpenAt',
          label: 'Dátum otvárania ponúk',
          sortable: true
        },
        {
          key: 'procedureType',
          label: 'Druh postupu',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav',
          sortable: true,
          header: {
            width: 80
          }
        }
      ]

      if (this.userRole === 'contractor') {
        items.splice(1, 0, {
          key: 'organization.name',
          label: 'Obstarávateľ',
          sortable: true
        })
      }

      return items
    },
    fetchParams () {
      const paramsMap = {
        all: '',
        active: 'active',
        planned: 'planned',
        finished: ['cancelled', 'finished']
      }

      return {
        'state': paramsMap[this.$route.params.state],
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        'sort': this.$format.sortString(this.tableSort),
        'journalAnnouncementFrom': this.journalAnnouncementDateFrom,
        'journalAnnouncementTo': this.journalAnnouncementDateTo,
        'filter': this.fulltext,
        'role': this.$route.params.userRole
      }
    }
  },
  methods: {
    orderState (value) {
      var orderStates = {
        planned: 'V príprave',
        active: 'Prebiehajúce',
        finished: 'Ukončené',
        cancelled: 'Zrušené'
      }
      return orderStates[value]
    },
    async fetchContracts () {
      const { data } = await this.$http.post('/order/list', this.fetchParams)
      this.contracts = data
    }
  },
  mounted () {
    this.fetchContracts()
  },
  watch: {
    fetchParams () {
      this.fetchContracts()
    }
  }
}
</script>
