<template>
  <div
    v-if="!loading || order.id"
    class="container-fluid"
  >
    <ul class="order-main-menu mb-3">
      <li>
        <router-link :to="{ name: 'ptk-user-overview', params: {orderId: $route.params.orderId}}">Prehľad</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'ptk-user-communication', params: {orderId: $route.params.orderId}}">Komunikácia</router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'PtkUser',
  data () {
    return {
      orderStore: useOrderStore(),
      loading: false,
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    canInterest () {
      return this.order.state === 'active' && !this.order.isInterested
    }
  },
  provide () {
    return {
      order: this.order,
    }
  },
  methods: {
    async loadOrder () {
      try {
        this.ShowLoading()
        this.loading = true
        await this.orderStore.readOrder(this.$route.params.orderId)
        this.loading = false
        this.CloseLoading()
      } catch (error) {
        this.loading = false
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať zákazku' }, error)
      }
    }
  },
  mounted () {
    this.loadOrder()
  },
}
</script>
