<template>
  <multiselect
    v-model="selectedUsers"
    :options="users"
    track-by="id"
    label="email"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    :placeholder="placeholder"
  >
    <template #option="{option}">
      {{ option.firstname }} {{ option.lastname }} ({{ option.email }})
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserSelect',
  components: { Multiselect },
  props: {
    value: Array,
    placeholder: {
      type: String,
      default: 'Vyberte si používateľov'
    },
    includeAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      users: []
    }
  },
  computed: {
    fetchParams () {
      return {
        organization: {
          id: this.globals.userOrganization.id
        }
      }
    },
    selectedUsers: {
      get() {
        return this.users.filter(user => this.value.includes(user.id))
      },
      set(val) {
        this.$emit('input', val.map(user => user.id))
      }
    }
  },
  async mounted () {
    let { data } = await this.$http.post('/organization/user/list', this.fetchParams)
    if (this.includeAll) {
      this.users = data.users
    } else {
      this.users = data.users.filter(user => user.id !== this.globals.user.id)
    }
  }
}
</script>
