<template>
  <div>
    <div class="layout">
      <!-- Header START -->
      <app-login-header />
      <app-sub-header />
      <!-- Header END -->
      <!-- Page Container START -->
      <div class="page-container">
        <div class="column">
          <side-navigation v-if="globals.user?.type !== 'observer'" />
          <registration-banner />
        </div>
        <!-- Content Wrapper START -->
        <div class="main-content">
          <router-view :key="`${$route.params.orderId}-${$route.params.state}-${$route.params.operationStatus}-${$route.params.messagesd}-${$route.params.mode}`" />
        </div>
        <!-- Content Wrapper END -->
      </div>
      <!-- Footer START -->
      <footer class="content-footer">
        <app-footer />
      </footer>
      <!-- Footer END -->
      <div class="side-panel">
        <div class="side-panel-wrapper">
          <div class="mobile-panel-opener side-panel-toggle">
            <i class="icon-calendar"></i>
          </div>
          <div class="side-panel-opener side-panel-toggle">
            <i class="icon-hide"></i>
            <span class="toggl-text">
              Skryť
            </span>
          </div>
          <div class="scrollable">
            <div class="open-panel">
              <calendar-widget></calendar-widget>
            </div>
            <div class="closed-panel">
              <span class="text-black mx-1 mt-3">
                Serverový čas:
              </span>
              <span class="text-bold text-black mb-3">
                {{ serverTime }}
              </span>
              <button
                @click="calendarToggle"
                class="btn side-item"
                title="Kalendár"
              >
                <i class="icon-calendar"></i>
              </button>
              <button
                @click="printPage"
                class="btn side-item"
                title="Vytlačiť"
              >
                <i class="icon-print"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Page Container END -->
    </div>
  </div>
</template>

<script>

import SideNavigation from './SideNavigation.vue'
import NewOrderModal from '@/components/NewOrderModal'
import CalendarWidget from './CalendarWidget.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppSubHeader from '@/components/order/AppSubHeader.vue'
import moment from 'moment'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import RegistrationBanner from '@/components/ui/RegistrationBanner.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'TenderiaApp',
  components: { SideNavigation, CalendarWidget, AppFooter, AppSubHeader, AppLoginHeader, RegistrationBanner },
  inject: ['eventBus'],
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      order: {}
    }
  },
  computed: {
    canEditProfile () { return this.globals.user?.type !== 'observer' },
    serverTime () {
      return moment(this.statsStore.currentTime).format('HH:mm')
    },
    routePath () {
      return this.$route.fullPath
    }
  },
  mounted () {
    this.eventBus.$on('open-order-modal', (config) => {
      this.openNewOrderWindow(config)
    })
    // Side Panel Toogle
    function sidePanelToggle () {
      $('.side-panel-toggle').on('click', function (e) {
        $('.side-panel').toggleClass('side-panel-open')
        e.preventDefault()
      })
    }

    function init () {
      sidePanelToggle()
    }

    init()
  },
  destroyed () {
    this.eventBus.$off('open-order-modal')
  },
  methods: {
    calendarToggle () {
      $('.side-panel').toggleClass('side-panel-open')
    },
    printPage () {
      window.print()
    },
    openNewOrderWindow (config) {
      this.$emit('vuedals:new', {
        name: 'new-order-modal',
        escapable: true,
        title: 'Zadanie zákazky',
        props: { config: config },
        component: NewOrderModal
      })
    }
  }
}
</script>
