<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h1
          v-if="$route.params.state === 'all'"
          class="card-title"
        >
          Všetky zmluvy
        </h1>
        <h1
          v-else
          class="card-title"
        >
          {{invoiceState($route.params.state)}}
        </h1>
        <div class="actions">
          <button
            @click="showFilter = !showFilter"
            class="btn btn-success"
          >
            <i class="icon-filter"></i><span>{{showFilter ? 'Skryť' : 'Zobraziť'}} filter</span>
          </button>
        </div>
      </div>
      <v-collapsible>
        <div
          v-if="showFilter"
          class="card-block"
        >
          <div class="row gutters-sm align-items-baseline tabel-filters">
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Hľadaj v názve alebo popise:
              </label>
              <input
                v-model="fulltext"
                class="form-control"
              >
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Dátum vytvorenia od:
              </label>
              <date-input
                name="date"
                v-model="dateFrom"
                :max-date="new Date()"
              />
            </div>
            <div class="col-md-4 form-group">
              <label class="text-bold text-dark">
                Dátum vytvorenia do:
              </label>
              <date-input
                name="date-to"
                v-model="dateTo"
                :max-date="new Date()"
              />
            </div>
          </div>
        </div>
      </v-collapsible>
      <v-table
        :items="items"
        :columns="invoicesColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template #cell(name)="{ item }">
          <a
            href=""
            @click.prevent=""
          >
            {{ item.name  }}
          </a>
        </template>
        <template #cell(date)="{ item }">
          <div class="column">
            <span>{{ item.dateFrom  }}</span>
            <span>{{ item.dateTo  }}</span>
          </div>
        </template>
        <template #cell(state)="{ item }">
          <invoice-state :state="item.state" />
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="invoices.total"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="invoices.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VTable from './ui/Table.vue'
import VCollapsible from './ui/Collapsible.vue'
import VPagination from './ui/Pagination.vue'
import DateInput from '@/components/forms/DateInput.vue'
import InvoiceState from './invoices/components/InvoiceState.vue'

export default {
  name: 'InvoicesPage',
  components: {
    VTable,
    VCollapsible,
    VPagination,
    DateInput,
    InvoiceState
  },
  data () {
    return {
      invoices: {},
      showFilter: true,
      fulltext: '',
      dateFrom: '',
      dateTo: '',
      tableSort: {
        sortBy: 'date',
        sort: 'desc'
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    items () {
      return [
        {
          number: '248/2024',
          name: 'Zmluva o poskytnutí dotácie z rozpočtovej kapitoly Ministerstva cestovného ruchu a športu Slovenskej republiky',
          partner: 'Malé Karpaty',
          price: '94 960,00 €',
          dateFrom: '26.06.2024',
          dateTo: 'Neuvedené',
          state: 'active'
        },
        {
          number: '2024002019',
          name: 'Zmluva o poskytovaní finančného príspevku na prevádzku poskytovanej sociálnej služby',
          partner: 'Sociálny dom ANTIC, n.o.',
          price: '4 560,00 €',
          dateFrom: '26.06.2024',
          dateTo: '31.03.2025',
          state: 'active'
        },
        {
          number: '24-441-00548',
          name: 'Zmluva o poskytnutí finančných prostriedkov č. 24-441-00548',
          partner: 'Obec Raslavice',
          price: '12 000,00 €',
          dateFrom: '21.03.2024',
          dateTo: 'Neuvedené',
          state: 'active'
        },
        {
          number: '582/2023',
          name: 'sanácia obvodového muriva voči vzlínaniu vlhkosti - Kaplnka sv.Alžbety',
          partner: 'Sanex, s.r.o.',
          price: '60 347,42 €',
          dateFrom: '21.10.2023',
          dateTo: '31.12.2023',
          state: 'ended'
        },
        {
          number: '21/2023',
          name: 'Zmluva o dielo č. 3/2023',
          partner: 'Keraming a.s.',
          price: '10 435,98 €',
          dateFrom: '13.09.2023',
          dateTo: '30.09.2023',
          state: 'ended'
        }
      ]
    },
    invoicesColumns () {
      return [
        {
          key: 'number',
          label: 'PČ',
          sortable: true
        },
        {
          key: 'name',
          label: 'Názov',
          header: {
            width: 300
          },
          sortable: true
        },
        {
          key: 'partner',
          label: 'Partner',

          sortable: true
        },
        {
          key: 'price',
          label: 'Cena',
          sortable: true
        },
        {
          key: 'date',
          label: 'Od - Do',
          sortable: true
        },
        {
          key: 'state',
          label: 'Stav',
          sortable: true
        }
      ]
    },
    fetchParams () {
      const paramsMap = {
        all: '',
        planned: 'planned',
        pending: 'pending',
        approved: 'approved',
        rejected: 'rejected',
        archived: 'archived,'
      }

      return {
        'state': paramsMap[this.$router.currentRoute.params.state],
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        'sort': this.$format.sortString(this.tableSort),
        'dateFrom': this.dateFrom,
        'dateTo': this.dateTo,
        'filter': this.fulltext

      }
    }
  },
  methods: {
    invoiceState (value) {
      var invoiceStates = {
        active: 'Aktívne',
        ended: 'Ukončené'
      }
      return invoiceStates[value]
    },
    async fetchInvoices () {
      const { data } = await this.$http.post('/invoice/list', this.fetchParams)
      this.invoices = data
    }
  }/* ,
  mounted () {
    this.fetchInvoices()
  },
  watch: {
    fetchParams (val, oldVal) {
      this.fetchInvoices()
    }
  } */
}
</script>
