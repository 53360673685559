<template>
  <div class="containter">
    <div class="row">
      <div class="col-md-4 control-label">
        Kategória:
      </div>
      <div class="col">
        {{ $format.formatLogsCategory(row.category) }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Názov:
      </div>
      <div class="col">
        {{ $format.formatLogsActions(row.action, row) }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Dátum:
      </div>
      <div class="col">
        {{ $format.formatDateTime(row.createdAt) }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Používateľ:
      </div>
      <div class="col">
        {{ `${row.user.title1} ${row.user.firstname} ${row.user.lastname} ${row.user.title2}` }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Výsledok:
      </div>
      <div class="col">
        {{ result }}
      </div>
    </div>
    <!-- epvo related details -->
    <template v-if="row?.category === 'order:epvo'">
      <div class="row" v-if="this.row?.data?.order">
        <div class="col-md-4 control-label">Číslo zákazky v platforme:</div>
        <div class="col">{{ this.row.data.order }}</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OrderSettingsLogDetail',
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      result: ''
    }
  },
  created () {
    this.result = parseInt(this.row.status) < 400 ? 'Úspech' : this.formatLogErrorMessage(this.row.category, this.row.data.permission)
  },
  methods: {
    formatLogErrorMessage (category, message) {
      let errors = {
        'order:general': {
          '': ''
        },
        'order:parts': {
          '': ''
        },
        'order:documents': {
          '': ''
        },
        'order:communication': {
          '': ''
        },
        'order:criteria': {
          '': ''
        },
        'order:commision': {
          '': ''
        },
        'order:offers': {
          'REQUEST_ERROR_TIMELIMIT_CONSTRAINT': 'Vypršanie času',
          'REQUEST_ERROR_ACCESS_DENIED': 'Prístup zamietnutý'
        },
        'order:items': {
          '': ''
        },
        'order:persons': {
          '': ''
        },
        'order:suppliers': {
          '': ''
        }
      }
      if (!errors[category])  return '-'
      return errors[category][message] || 'Chyba'
    }
  }
}
</script>
