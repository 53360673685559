<template>
  <form id="exclude-offer-form p-2">
    <div class="row form-group">
      <label class="col-md-3 control-label text-dark text-bold">
        Dôvod vylúčenia ponuky: <small>*</small>
      </label>
      <div class="col-md-9 checkbox">
        <div class="radio">
          <input
            id="legal-basis-1"
            v-model="legalBasis"
            type="radio"
            value="vlo"
          ><label for="legal-basis-1">
            Mimoriadne nízka ponuka
          </label>
        </div>
        <div class="radio">
          <input
            id="legal-basis-2"
            v-model="legalBasis"
            type="radio"
            value="p40zvo"
          ><label for="legal-basis-2">
            &#167; 40 ods. 6 ZVO
          </label>
        </div>
        <div class="radio">
          <input
            id="legal-basis-3"
            v-model="legalBasis"
            type="radio"
            value="p53zvo"
          ><label for="legal-basis-3">
            &#167; 53 ZVO
          </label>
        </div>
        <label
          v-if="form.legalBasis.error"
          class="error"
          for="reason-of-appeal"
        >
          Musíte vybrať jednu z možností.
        </label>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-3" />
      <div class="col-md-9">
        <textarea
          id="reason-of-appeal"
          v-model="decision"
          class="form-control"
        />
        Počet zostávajúcich znakov: {{ decisionTextRemaining }}
        <label
          v-if="form.decision.error"
          class="error"
          for="reason-of-appeal"
        >
          Dôvod musí byť vyplnený.
        </label>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close(null)"
      >
        <i class="icon-close" /> Zavrieť
      </button>
      <button
        class="btn btn-tenderia-success"
        @click.prevent="save()"
      >
        <i class="icon-save" /> Uložiť
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ExcludeOfferModal',
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      decision: '',
      legalBasis: '',
      form: {
        decision: {
          error: false,
          valid: false
        },
        legalBasis: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    decisionTextRemaining: function () {
      return 4000 - this.decision.length
    }
  },
  methods: {
    validateDecision: function () {
      var state = this.form.decision
      state.error = state.valid = false
      if (this.decision.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    validateLegalBasis: function () {
      var state = this.form.legalBasis
      state.error = state.valid = false
      if (this.legalBasis) {
        state.valid = true
      } else {
        state.error = true
      }
    },
    validate: function () {
      this.validateDecision()
      this.validateLegalBasis()

      return this.form.decision.valid && this.form.legalBasis.valid
    },
    showLoading: function () {
      $('#exclude-offer-form').LoadingOverlay('show')
    },
    hideLoading: function () {
      $('#exclude-offer-form').LoadingOverlay('hide')
    },
    close: function (data) {
      this.$emit('vuedals:close', data)
    },
    save: function () {
      if (!this.validate()) {
        return
      }
      this.showLoading()
      var data = {
        offer: {
          id: this.offer.id,
          stage: 'excluded',
          legalBasis: this.legalBasis,
          decision: this.decision
        }
      }
      var vm = this
      this.$http.post('/offer/stage/set', data).then(function () {
        vm.hideLoading()
        vm.OkAlert({ text: 'Ponuka bola vylúčená.' })
        vm.close(data.offer)
      }).catch((error) => {
        vm.hideLoading()
        vm.close()
        vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      })
    }
  }
}
</script>
