<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div
          id="order-settings-general"
          class="card"
        >
          <div class="card-heading">
            <h4 class="card-title">
              Základné údaje
            </h4>
          </div>
          <div class="card-block">
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="contract-name"
                    class="control-label text-dark text-bold"
                  >
                    Názov zákazky <small>*</small>
                  </label>
                  <div>
                    <input
                      id="contract-name"
                      v-model="order.name"
                      type="text"
                      class="form-control"
                      required="required"
                      disabled="disabled"
                      aria-required="true"
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="estimated-price"
                    class="control-label"
                  >
                    Predpokladaná hodnota v &euro; bez DPH <small>*</small>
                  </label>
                  <div>
                    <vue-numeric
                      v-model="order.estimatedPrice"
                      class="form-control"
                      currency="€"
                      separator="space"
                      decimal-separator=","
                      :precision="2"
                      :empty-value="0"
                      :disabled="!order._canEdit"
                    />
                    <label
                      v-if="form.estimatedPrice.error"
                      class="error"
                    >
                      {{ form.estimatedPrice.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Obstarávanie sa týka projektu a/alebo programu financovaného z fondov Európskej únie <small>*</small>
                  </label>
                  <div>
                    <div class="radio radio-inline">
                      <input
                        id="funding-by-eu-yes"
                        v-model="order.fundingByEu"
                        type="radio"
                        value="yes"
                        :disabled="!order._canEdit"
                      ><label for="funding-by-eu-yes">
                        Áno
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="funding-by-eu-no"
                        v-model="order.fundingByEu"
                        type="radio"
                        value="no"
                        :disabled="!order._canEdit"
                      ><label for="funding-by-eu-no">
                        Nie
                      </label>
                    </div>
                    <label
                      v-if="form.fundingByEu.error"
                      class="error"
                    >
                      {{ form.fundingByEu.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="announcement-url"
                    class="control-label"
                  >
                    URL v tenderia
                  </label>
                  <div class="input-group">
                    <input
                      id="announcement-url"
                      v-model="order.announcementUrl"
                      type="text"
                      class="form-control"
                      readonly
                    >
                    <button
                      class="input-group-addon copy"
                      @click="copyToClipboard(order.announcementUrl)"
                    >
                      <i class="ei-copy font-size-18" />
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="journal-jnnouncement-date"
                    class="control-label"
                  >
                    Dátum vyhlásenia súťaže
                  </label>
                  <div>
                    <date-input
                      v-model="order.journalAnnouncementDate"
                      name="journal-jnnouncement-date"
                      :value="order.journalAnnouncementDate"
                      :disabled="!order._canEdit"
                    />
                    <label
                      v-if="form.journalAnnouncementDate.error"
                      for="journal-jnnouncement-date"
                      class="error"
                    >
                      {{ form.journalAnnouncementDate.errorMessage }}
                    </label>
                  </div>
                </div>

                <div
                  v-if="order.type === 'dns'"
                  class="form-group"
                >
                  <label
                    for="duration"
                    class="control-label"
                  >
                    Trvanie DNS (v mesiacoch) <small>*</small>
                  </label>
                  <div>
                    <input
                      id="duration"
                      v-model.lazy="order.duration"
                      :disabled="!order._canEdit"
                      type="number"
                      min="1"
                      max="48"
                      class="form-control"
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="journal"
                    class="control-label"
                  >
                    Číslo vestníka
                  </label>
                  <div>
                    <input
                      id="journal"
                      v-model="order.journal"
                      type="text"
                      class="form-control"
                      :disabled="!order._canEdit"
                    >
                    <label
                      v-if="form.journal.error"
                      for="journal"
                      class="error"
                    >
                      {{ form.journal.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="announcement"
                    class="control-label"
                  >
                    Číslo oznámenia/výzvy
                  </label>
                  <div>
                    <input
                      id="announcement"
                      v-model="order.announcement"
                      type="text"
                      class="form-control"
                      :disabled="!order._canEdit"
                    >
                    <label
                      v-if="form.announcement.error"
                      for="announcement"
                      class="error"
                    >
                      {{ form.announcement.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Druh zákazky <small>*</small>
                  </label>
                  <div>
                    <div class="radio radio-inline">
                      <input
                        id="subject-type-goods"
                        v-model="order.subjectType"
                        type="radio"
                        name="order-subject-type"
                        value="goods"
                        :disabled="!order._canEdit"
                      > <label for="subject-type-goods">
                        Tovary
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="subject-type-services"
                        v-model="order.subjectType"
                        type="radio"
                        name="order-subject-type"
                        value="services"
                        :disabled="!order._canEdit"
                      > <label for="subject-type-services">
                        Služby
                      </label>
                    </div>
                    <div class="radio radio-inline">
                      <input
                        id="subject-type-work"
                        v-model="order.subjectType"
                        type="radio"
                        name="order-subject-type"
                        value="work"
                        :disabled="!order._canEdit"
                      > <label for="subject-type-work">
                        Práce
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Hlavný CPV kód <small>*</small>
                  </label>
                  <div>
                    <main-cpv-code-select
                      v-if="order.id"
                      v-model="order.cpvCode"
                      :value="order.cpvCode"
                      :disabled="!order._canEdit"
                    />
                    <label
                      v-if="form.cpvCode.error"
                      class="error"
                    >
                      {{ form.cpvCode.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">
                    Doplnkové CPV kódy
                  </label>
                  <div>
                    <suppl-cpv-code-select
                      v-if="order.id"
                      v-model="order.cpvCodes"
                      :value="order.cpvCodes"
                      :disabled="!order._canEdit"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="description"
                    class="control-label"
                  >
                    Opis predmetu zákazky <small>*</small>
                  </label>
                  <div>
                    <textarea
                      id="description"
                      v-model="order.description"
                      class="form-control"
                      :disabled="!order._canEdit"
                    />
                    Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br>
                    <label
                      v-if="form.description.error"
                      for="description"
                      class="error"
                    >
                      {{ form.description.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="announcement"
                    class="control-label"
                  >
                    Hlavné miesto dodania tovaru/poskytnutia služby/vykonania stavebných prác
                  </label>
                  <div>
                    <input
                      id="announcement"
                      v-model="order.mainDeliveryPlace"
                      type="text"
                      class="form-control"
                      :disabled="!order._canEdit"
                    >
                    <label
                      v-if="form.mainDeliveryPlace.error"
                      for="announcement"
                      class="error"
                    >
                      {{ form.mainDeliveryPlace.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    for="private-note"
                    class="control-label"
                  >
                    Poznámka
                  </label>
                  <div>
                    <textarea
                      id="private-note"
                      v-model="order.privateNote"
                      class="form-control"
                      :disabled="!order._canEdit"
                    />
                    Počet zostávajúcich znakov: {{ privateNoteTextRemaining }}
                  </div>
                </div>

                <div class="form-group">
                  <label
                    v-if="order.type === 'dns'"
                    for="bids-limit-to"
                    class="control-label"
                  >
                    Koniec lehoty na predkladanie žiadostí o zaradenie do DNS <small>*</small>
                  </label>
                  <label
                    v-else
                    for="bids-limit-to"
                    class="control-label"
                  >
                    Koniec lehoty na predkladanie žiadostí o účasť <small>*</small>
                  </label>
                  <div>
                    <date-time-input
                      v-model="order.bidsLimitTo"
                      name="bids-limit-to"
                      :value="order.bidsLimitTo"
                      :disabled="!order._canEdit"
                      :min-date="new Date()"
                    />
                    <label
                      v-if="form.bidsLimitTo.error"
                      for="bids-limit-to"
                      class="error"
                    >
                      {{ form.bidsLimitTo.errorMessage }}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label
                    v-if="order.type === 'dns'"
                    for="bids-limit-to"
                    class="control-label"
                  >
                    Koniec lehoty na predkladanie žiadostí o zaradenie do DNS <small>*</small>
                  </label>
                  <label
                    v-else
                    for="bids-open-at"
                    class="control-label"
                  >
                    Dátum a čas otvárania  žiadostí o účasť
                  </label>
                  <div>
                    <date-time-input
                      v-model="order.bidsOpenAt"
                      name="bids-open-at"
                      :value="order.bidsOpenAt"
                      :disabled="!order._canEdit"
                      :min-date="new Date()"
                    />
                    <label
                      v-if="form.bidsOpenAt.error"
                      for="bids-open-at"
                      class="error"
                    >
                      {{ form.bidsOpenAt.errorMessage }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center no-gutters justify-content-between">
      <div class="row no-gutters">
        <button
          v-if="order.state === 'active' && order._canEdit"
          class="btn btn-tenderia-blue"
          @click="changeStateModal('finished')"
        >
          <i class="icon-check" /> Ukončiť zákazku
        </button>
        <button
          v-if="order.state === 'active' && order._canEdit"
          class="btn btn-danger"
          @click="changeStateModal('cancelled')"
        >
          <i class="icon-close" /> Zrušiť zákazku
        </button>
        <div v-if="order.state === 'planned' && order._canEdit">
          <button
            class="btn btn-danger"
            href=""
            @click="removeOrderModal()"
          >
            <i class="icon-delete" />Zmazať zákazku
          </button>
        </div>
      </div>
      <div class="row no-gutters">
        <button
          v-if="order.integration.eforms.enabled"
          class="btn btn-success"
          @click="openEformsModal"
        >
          Vyplniť v eForms
        </button>
        <div v-if="order._canEdit">
          <button
            class="btn btn-success"
            href=""
            @click="saveSettings()"
          >
            <i class="icon-save" />Uložiť
          </button>
        </div>
      </div>
    </div>
    <admin-eforms-list v-if="order.integration.eforms.enabled" />
  </div>
</template>

<script>
import AdminOrder from '@/components/order/common/components/AdminOrder'
import DateInput from '@/components/forms/DateInput.vue'
import DateTimeInput from '@/components/forms/DateTimeInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import SupplCpvCodeSelect from '@/components/forms/SupplCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'
import AdminEformsList from '@/components/order/common/components/AdminEformsList.vue'

export default {
  name: 'AdminSettings',
  components: { DateInput, DateTimeInput, MainCpvCodeSelect, SupplCpvCodeSelect, VueNumeric, AdminEformsList },
  extends: AdminOrder,
  methods: {
    validateBeforePublish () {
      this.validateEstimatedPrice()
      this.validatecpvCode()
      this.validateDescription()
      this.validateBidsOpenAt()
      this.validateBidsLimitTo()
      this.validateMainDeliveryPlace()
      this.validateFundingByEu()
      this.validateJournalAnnouncementDate()
      this.validateJournal()
      this.validateAnnouncement()

      return this.form.estimatedPrice.valid && this.form.cpvCode.valid && this.form.description.valid && this.form.journalAnnouncementDate.valid && this.form.bidsOpenAt.valid && this.form.bidsLimitTo && this.form.journal.valid && this.form.announcement.valid && this.form.mainDeliveryPlace.valid && this.form.fundingByEu.valid
    },
    async saveSettings (state) {
      let orderIsValid = this.validateBeforePublish()
      if (this.order.state === 'active' && !orderIsValid) {
        return
      }
      this.ShowLoading({ title: 'Ukladám nastavenia zákazky' })
      let data = {
        order: {
          id: this.order.id,
          name: this.order.name,
          estimatedPrice: this.order.estimatedPrice.toString().replaceAll(' ', '').replaceAll(',', '.'),
          description: this.order.description,
          announcementUrl: this.order.announcementUrl,
          journalAnnouncementDate: this.order.journalAnnouncementDate,
          bidsLimitTo: this.order.bidsLimitTo,
          bidsOpenAt: this.order.bidsOpenAt,
          organization: this.order.organization.id,
          privateNote: this.order.privateNote,
          subjectType: this.order.subjectType,
          cpvCode: this.order.cpvCode,
          cpvCodes: this.order.cpvCodes,
          journal: this.order.journal,
          announcement: this.order.announcement,
          mainDeliveryPlace: this.order.mainDeliveryPlace,
          fundingByEu: this.order.fundingByEu,
          duration: this.order.duration || null
        }
      }
      try {
        await this.$http.post('/order/set', data)
        await this.orderStore.getOrder(this.order.id)
        this.CloseLoading()
        if (state === 'active') {
          this.changeState(state)
        } else {
          setTimeout(() => {
            this.PopupAlert({ title: 'Zmeny boli uložené.' })
          }, 500)
        }
      } catch (error) {
        this.Closeloading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    }
  }
}
</script>
