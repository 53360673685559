<template>
  <div class="container-fluid">
    <router-view ref="child" />
  </div>
</template>

<script>

function initialState () {
  return {
    approvalRound: 0,
    name: '',
    description: '',
    value: 0,
    valueWithVat: 0,
    approvers: null,
    budgets: null,
    subjectType: '',
    cpvCode: '',
    justification: '',
    deliveryDate: null,
    note: '',
    applicantInfo: '',
    state: '',
    items: null,
    suppliers: null,
    approvalStances: null,
    id: ''
  }
}

export default {
  name: 'InvoiceHome',
  data () {
    return {
      invoice: initialState()
    }
  },
  provide () {
    return {
      invoice: this.invoice
    }
  },
  methods: {

  },

  created () {

  }
}
</script>
