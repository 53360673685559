<template>
  <div class="card">
    <div class="card-heading">
      <h4 class="card-title ">
        Aukcie
      </h4>
      <div class="actions">
        <button
          :disabled="!canCreateAuction"
          class="btn btn-tenderia-blue"
          @click.prevent="newAuctionModal()"
        >
          <i class="icon-add font-size-14" /> Vytvoriť aukciu
        </button>
      </div>
    </div>
    <v-table
      :items="auctionItems"
      :columns="auctionsColumns"
      :sort.sync="tableSort"
      item-key="id"
      class="light"
    >
      <template #cell(name)="{ item }">
        <a
          :href="`https://aukcie.tenderia.sk/#/${item.auctionId}/auction_setting/`"
          target="_blank"
        >
          {{ item.auction.name }}
        </a>
      </template>
    </v-table>
    <div class="card-footer">
      <v-pagination
        v-if="auctions.auctions"
        :page="page"
        :per-page="perPage"
        :per-page-options="pageSizeOptions"
        :total-elements="auctions.total"
      />
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminAuctions',
  components: {
    VTable,
    VPagination
  },
  data () {
    return {
      orderStore: useOrderStore(),
      auctions: {},
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    auctionItems () {
      return (this.auctions && this.auctions.auctions) || []
    },
    canCreateAuction () {
      return this.order._canEdit
    },
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    auctionsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        }
      ]
    },
    fetchParams () {
      return {
        limit: this.perPage,
        page: this.page,
        offset: this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort),
        order: this.$route.params.orderId
      }
    }
  },
  watch: {
    fetchParams () {
      this.fetchAuctions()
    }
  },
  mounted () {
    this.fetchAuctions()
  },
  methods: {
    newAuctionModal () {
      if (this.canCreateAuction) {
        this.$router.push({ name: `${this.orderStore.routePrefix}admin-auctions-add` })
      }
    },
    async fetchAuctions () {
      const { data } = await this.$http.post('/auction/list', this.fetchParams)
      this.auctions = data
    }
  }
}
</script>
