export default {
  STR_LOGIN_BUTTON:         'Prihlásiť sa',
  STR_LOGIN_BUTTON_EID:     'Prihlásiť sa s eID',
  STR_WARNING:              'UPOZORNENIE',
  STR_PREMIUM_TITLE:        'Prémiové služby',
  STR_PREMIUM_PROMO:        'Využívajte systém tenderia naplno aktivovaním <strong>prémiových služieb</strong>, alebo pokračujte ďalej bezplatne.',
  STR_PREMIUM_WARN_FILES:   'Bez prémiových služieb môžete nahrávať súbory len jednotlivo. Celkový počet súborov však nie je obmedzený. Pre možnosť hromadného výberu súborov pre nahrávanie si aktivujte',
  STR_PREMIUM_WARN_SIZE:    'Pre uloženie súboru (prílohy) s veľkosťou {size} sa už vyžaduje balíček prémiových služieb',
  STR_PREMIUM_WARN_FORMAT:  'Pre uloženie súboru (prílohy) vo formáte "{format}" sa už vyžaduje balíček prémiových služieb',
  STR_PREMIUM_WARN_DANGER:  'Súbor vo formáte "{format}" nie je možné uložiť',
  STR_UPLOAD_FILE_LIMIT:    '(Maximálna veľkosť jedného súboru je 2GB.)',
  STR_UPLOAD_DRAG_INFO:     'Pretiahnite sem dokumenty z plochy alebo priečinkov, ktoré chcete nahrať alebo <label for="{id}">kliknite sem a vyberte súbory</label> na nahratie.'
}
