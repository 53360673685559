<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Identifikácia dodávateľa
            </h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div
                class="row"
                v-if="offer.applicantDetails.groupName"
              >
                <div class="col-md-5">
                  <b>Názov skupiny dodávateľov</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName }}
                </div>
              </div>
              <div
                class="row"
                v-else
              >
                <div class="col-md-5">
                  <b>Obchodné meno alebo názov uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.businessName }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Sídlo alebo miesto podnikania uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.address }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IČO</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li
                      v-for="(ico, index) in offer.applicantDetails.ico"
                      :key="`ico-${index}`"
                    >
                      {{ ico }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Platca DPH</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatYesNo(offer.applicantDetails.vatPayer) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Štát</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.country }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Meno a priezvisko kontaktnej osoby</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.contactPerson }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefón</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.phone }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Fax</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.fax }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Email</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Systémové informácie
            </h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div
                class="row"
                v-if="offer.state !== 'new'"
              >
                <div class="col-md-5">
                  <b>Dátum odoslania uchádzačom</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDateTime(offer.sentAt) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IP adresa uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.userIP }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav ponuky</b>
                </div>
                <div class="col-md-7">
                  {{$format.formatOfferStage(offer.stage)}}
                </div>
              </div>
              <div
                class="row"
                v-if="offer.stage === 'excluded'"
              >
                <div class="col-md-5">
                  <b>Dôvod vylúčenia ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ offer.decision }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-if="order.items.length > 0"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Položky
            </h4>
          </div>
          <items-table
            v-if="ready"
            :order="order"
            :offer="offer"
            :decimal-count="decimalCount"
            :editable="false"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Návrh na plnenie kritérií
            </h4>
          </div>
          <div class="card-block">
            <div
              class="row form-group"
              v-for="criterion in criteria"
              :key="criterion._id"
            >
              <div class="col-md-9">
                <label
                  :for="criterion._id"
                  class="text-bold text-dark"
                >
                  {{ criterion.name}}
                </label>
              </div>
              <div class="col-md-2 text-dark">
                {{ formatNumber(criterion.value, criterion.decimalCount) }}
              </div>
              <div class="col-md-1 text-dark">
                {{ criterion.unit }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Prílohy kritérií
            </h4>
          </div>
          <v-table
            :items="offer.attachments2"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template #cell(name)="{ item }">
              <a
                :href="globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template #cell(type)="{ item }">
              {{ $format.formatFileType(item.mime) }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Doklady vyžadované výzvou
            </h4>
          </div>
          <v-table
            :items="offer.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template #cell(name)="{ item }">
              <a
                :href="globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>
            </template>
            <template #cell(type)="{ item }">
              {{ $format.formatFileType(item.mime) }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click="goBack"
      >
        <i class="icon-arrow-left"></i> Späť
      </button>
      <button
        v-if="canExclude"
        class="btn btn-danger"
        @click="excludeModal()"
      >
        Vylúčiť ponuku
      </button>
    </div>
  </div>
</template>

<script>
import ItemsTable from '@/components/order/common/components/ItemsTable.vue'
import BaseOfferView from '@/components/order/common/components/BaseOfferView.vue'
import VTable from '@/components/ui/Table.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'MrAdminOfferView',
  extends: BaseOfferView,
  components: { ItemsTable, VTable },
  data () {
    return {
      globals: useGlobalStore()
    }
  },
  async created () {
    try {
      if (this.globals.user.type === 'observer') {
        let { data: { offer } } = await this.$http.post('/offer/observe', { offer: { id: this.$route.params.offerId } })
        this.offer = offer
      } else {
        let { data: { offer } } = await this.$http.post('/offer/open', { offer: { id: this.$route.params.offerId } })
        this.offer = offer
      }
      this.prepareCriteria()
      if (this.order.biddingMethod === 'lowestPrice') {
        let criterion = this.findPriceCriterion()
        this.decimalCount = criterion.decimalCount
      }
      this.ready = true
    } catch (error) {
      this.ErrorAlert({ text: 'Pri načítavaní ponuky nastala chyba.' }, error)
    }
  },
  computed: {
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  }
}
</script>
