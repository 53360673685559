import { defineStore } from 'pinia'
import { TenderiaHttp as http } from '@/services/TenderiaHttp.js'

const initialRequest = () => ({
  approvalRound: 0,
  name: '',
  description: '',
  value: 0,
  valueWithVat: 0,
  approvers: [],
  budgets: [],
  subjectType: '',
  cpvCode: '',
  justification: '',
  deliveryDate: '',
  note: '',
  applicantInfo: '',
  state: '',
  items: [],
  suppliers: [],
  approvalStances: null,
  id: ''
})

const initialRequestItem = () => ({
  name: '',
  amount: 0,
  unit: '',
  unitPrice: 0,
  estimatedPrice: 0,
  estimatedPriceWithVat: 0,
  vat: 0,
  description: '',
  budget: ''
})

export const useRequestStore = defineStore('request', {
  state: () => ({
    request: initialRequest(),
    requestItem: initialRequestItem()
  }),
  actions: {
    async getRequest(requestId) {
      const { data: { request } } = await http.post('/request/get', { request: { id: requestId } })
      this.request = request
    },
    resetRequest() {
      this.request = initialRequest()
    },
    resetRequestItem() {
      this.requestItem = initialRequestItem()
    }
  }
})
