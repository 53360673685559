<template>
  <v-table
    :items="items"
    :columns="itemsColumns"
    item-key="id"
    class="light"
  >
    <template #body>
      <tbody>
        <tr
          v-for="item in items"
          :key="item._id"
        >
          <td>
            <span
              v-if="item.description"
              :title="item.description"
            >
              {{ item.name }}
            </span>
            <span v-else>
              {{ item.name }}
            </span>
          </td>
          <td>{{ item.amount }}</td>
          <td>{{ item.unit }}</td>
          <td>
            <input
              :id="item._id"
              v-model="item.userInput"
              size="5"
              type="text"
              :disabled="!editable"
              class="form-control"
              @change="validateItem(item)"
            >
          </td>
          <td class="text-right">
            {{ formatDecimal(item.sum) }}
          </td>
        </tr>
        <tr class="text-bold border top">
          <td
            :colspan="4"
            align="right"
          >
            Cena v EUR spolu
          </td>
          <td align="right">
            {{ formatDecimal(total) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-table>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
var sFormatNumber = require('simple-format-number')

export default {
  name: 'ItemsTable',
  components: { VTable },
  props: {
    order: {
      type: Object,
      default: () => ({ items: [] })
    },
    offer: {
      type: Object,
      default: () => ({ items: [] })
    },
    decimalCount: {
      type: Number,
      default: 2
    },
    editable: Boolean
  },
  data () {
    return {
      items: []
    }
  },
  computed: {
    total () {
      return this.items.reduce((accumulator, currentValue) => currentValue.sum + accumulator, 0)
    },
    itemsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov položky'
        },
        {
          key: 'amount',
          label: 'Počet'
        },
        {
          key: 'unit',
          label: 'MJ'
        },
        {
          key: 'userInput',
          label: 'Cena v EUR za MJ'
        },
        {
          key: 'sum',
          label: 'Cena v EUR spolu'
        }
      ]
    }
  },
  created () {
    for (let orderItem of this.order.items) {
      let item = Object.assign({}, orderItem, { value: 0, userInput: '', vat: 0, sum: 0 })
      if (this.offer.items) {
        for (let offerItem of this.offer.items) {
          if (orderItem._id === offerItem.item) {
            item.userInput = this.formatNumber(offerItem.value, this.decimalCount)
            break
          }
        }
      }
      this.items.push(item)
      this.validateItem(item)

    }
  },
  methods: {
    formatDecimal (value) {
      return sFormatNumber(value, { fractionDigits: this.decimalCount, symbols: { decimal: ',', grouping: ' ' } })
    },
    formatNumber (value, decimalCount) {
      return (value ? sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } }) : '')
    },
    validateItem (item) {
      try {
        item.value = item.userInput.replace(/\s+/g, '').replace(',', '.')
      } catch (error) {
        item.value = 0
      }

      item.sum = item.value * item.amount

      this.$emit('offer-items', {
        items: this.items.map(item => {
          return { item: item._id, value: Number(item.value).toFixed(this.decimalCount) } }),
        total: this.total.toFixed(this.decimalCount)
      })
    }
  }
}
</script>
