<template>
  <div
    class="state-badge invoice-state"
    :class="[stateClass, display !== 'text' ? 'icon-only' : '']"
  >
    <i
      :class="stateIconClass"
      class="font-size-18"
    ></i><span v-if="display === 'text'">
      {{ invoiceState(state) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'InvoiceState',
  props: ['state', 'display'],
  computed: {
    stateClass: function () {
      var classes = {
        active: {
          'active': true
        },
        ended: {
          'ended': true
        }
      }
      return classes[this.state]
    },
    stateIconClass: function () {
      var classes = {
        active: {
          'icon-active': true
        },
        ended: {
          'icon-ended': true
        }
      }
      return classes[this.state]
    },
    buttonClass: function () {
      var classes = {
        active: 'btn-success',
        ended: 'btn-danger'
      }
      return classes[this.state]
    }
  },
  methods: {
    invoiceState (value) {
      var invoiceStates = {
        active: 'Aktívna',
        ended: 'Ukončená'
      }
      return invoiceStates[value]
    }
  }
}
</script>
