<template>
  <form>
    <div class="row p-2">
      <div class="col-md-12">
        <div class="form-group">
          <label
            for="nazov-zakazky"
            class="text-dark text-bold"
          >
            Používatelia <small>*</small>
          </label>
          <user-select
            v-model="users"
            :class="{ error: form.users.error, valid: form.users.valid }"
            aria-required="true"
          />
          <label
            v-if="form.users.error"
            class="error"
            for="nazov-zakazky"
          >
            Toto pole je povinné.
          </label>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close"
      >
        Zrušiť
      </button>
      <button
        class="btn btn-success"
        @click.prevent="addUsers"
      >
        Pridať
      </button>
    </div>
  </form>
</template>

<script>
import UserSelect from '@/components/forms/UserSelect.vue'
export default {
  name: 'AddDepartmentUserModal',
  components: { UserSelect },
  data () {
    return {
      users: [],
      form: {
        users: {
          error: false,
          valid: false
        }
      }
    }
  },
  methods: {
    showLoading () {
      $('.vuedal').LoadingOverlay('show')
    },
    hideLoading () {
      $('.vuedal').LoadingOverlay('hide')
    },
    close () {
      this.$emit('vuedals:close', null)
    },
    async addUsers () {
      if (!this.validateUser()) {
        return
      }
      this.showLoading()
      let usersData = {
        unit: {
          id: this.$route.params.departmentId
        },
        user: this.users
      }
      try {
        await this.$http.post('/orgunit/user/set', usersData)
        this.hideLoading()
        this.close()
      } catch (error) {
        this.hideLoading()
        this.ErrorAlert({ title: 'Chyba', text: 'Užívateľov sa nepodarilo pridať' }, error)
      }
    },
    validateUser () {
      let state = this.form.users
      state.valid = state.error = false
      if (this.users.length > 0) {
        state.valid = true
      } else {
        state.error = true
      }
      return state.valid
    }
  },
  watch: {
    users () {
      this.validateUser()
    }
  }
}
</script>
