<template>
  <div class="banner">
    <a
      class="ad i1"
      href="http://www.skolaobstaravania.sk/"
      target="_blank"
    >
      Škola obstarávania
    </a>
    <a
      class="ad i2"
      href="https://eforms.uvo.gov.sk/"
      target="_blank"
    >
      eForms
    </a>
    <a
      class="ad i3"
      href="http://www.transparex.sk/conflicts"
      target="_blank"
    >
      Konflikt záujmov
    </a>
    <v-carousel :items="slides">
      <template v-slot="{ item }">
        <img
          class="tx-logo"
          src="@/assets/images/logo-tx-white.svg"
          height="20"
          alt=""
          typeof="foaf:Image"
        >
        <div class="slide dark">
          <strong
            class="my-2"
            v-text="item.text1"
          />
          <span
            class="xl"
            v-text="item.text2"
          />
          <strong v-text="item.text3" />
          <a
            class="btn btn-tenderia-blue m-4"
            href="http://www.transparex.sk/registration"
            target="_blank"
          >
            Registrácia
          </a>
        </div>
      </template>
    </v-carousel>
  </div>
</template>

<script>
import VCarousel from '@/components/ui/Carousel.vue'
var sFormatNumber = require('simple-format-number')

export default {
  components: { VCarousel },
  methods: {
    formatNumber (value, decimalCount) {
      return (value ? sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } }) : '')
    }
  },
  computed: {
    slides () {
      return [
        {
          text1: 'Viac ako',
          text2: this.$format.num(217824),
          text3: 'referencií dodávateľov',
          image: require('../../assets/images/registration/bg1.jpg')
        }, {
          text1: 'Viac ako',
          text2: this.$format.num(71397),
          text3: 'dodávateľov',
          image: require('../../assets/images/registration/bg2.jpg')
        }, {
          text1: 'Viac ako',
          text2: this.$format.num(11162),
          text3: 'obstarávateľov',
          image: require('../../assets/images/registration/bg3.jpg')
        }, {
          text1: 'Viac ako',
          text2: this.$format.num(999859),
          text3: 'ponúk',
          image: require('../../assets/images/registration/bg4.jpg')
        }, {
          text1: 'Najväčšia databáza zákaziek verejného obstarávania',
          image: require('../../assets/images/registration/bg5.jpg')
        }, {
          text1: 'Viac ako',
          text2: this.$format.num(1858472),
          text3: 'organizácií',
          image: require('../../assets/images/registration/bg6.jpg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;

  @media (max-width: 1200px) {
    display: none;
  }
}
.carousel {
  height: 480px;
  border-radius: 3px;
  .slide {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px 20px 20px;
    text-align: center;
    font-size: 20px;
    .xl {
      font-size: 28px;
    }
  }

  .tx-logo {
    width: 100%;
    margin-top: 20px;
  }
}

.ad {
    border-radius: 3px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 12px;
    text-decoration: none;
    color: #fff;
    outline: none;
    font-weight: 700;
    &.i1 {
      background-image: url('../../assets/images/bg3.jpg');
    }
    &.i2 {
      background-image: url('../../assets/images/bg4.jpg');
    }
    &.i3 {
      background-image: url('../../assets/images/bg5.jpg');
    }
    &.btn {
      justify-content: flex-start;
      white-space: initial;
    }
  }
</style>
