<template>
  <div
    v-if="!loading || order.id"
    class="container-fluid"
  >
    <ul
      class="order-main-menu"
      :class="{'mb-3': !$route.fullPath.includes('/home/') && !$route.fullPath.includes('/offers')}"
    >
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}admin-home`, params: {orderId: $route.params.orderId}}">
          Prehľad
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}admin-interested`, params: {orderId: $route.params.orderId}}">
          Záujemcovia
        </router-link>
      </li>
      <li v-if="order.type === 'pz' && order.private">
        <router-link :to="{name: `${orderStore.routePrefix}admin-suppliers`, params: {orderId: $route.params.orderId}}">
          Pozvánky
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}admin-communication`, params: {orderId: $route.params.orderId}}">
          Komunikácia
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}admin-applicants`, params: {orderId: $route.params.orderId}}">
          Uchádzači
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}admin-offers`, params: {orderId: $route.params.orderId}}">
          Ponuky
        </router-link>
      </li>
      <!-- <li>
        <router-link :to="{ name: `${orderStore.routePrefix}admin-auctions`, params: {orderId: $route.params.orderId}}">
          Aukcie
        </router-link>
      </li> -->
    </ul>
    <router-view ref="child" />
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'Admin',
  data () {
    return {
      orderStore: useOrderStore(),
      loading: false,
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    oldOrder: {
      get () {
        return this.orderStore.oldOrder
      },
      set (value) {
        this.orderStore.oldOrder = value
      }
    },
    orderChanged: {
      get () {
        return this.orderStore.orderChanged
      },
      set (value) {
        this.orderStore.orderChanged = value
      }
    }
  },
  watch: {
    order: {
      handler (order) {
        if (!this.loading && !isEqual({ ...order }, this.oldOrder)) {
          this.orderChanged = true
          this.oldOrder = { ...order }
        }
      },
      deep: true,
      immediate: false
    }
  },
  mounted () {
    this.loadOrder()
  },
  methods: {
    async loadOrder () {
      try {
        this.ShowLoading()
        this.loading = true
        await this.orderStore.getOrder(this.$route.params.orderId)
        this.loading = false
        this.CloseLoading()
      } catch (error) {
        console.error(error)
        this.loading = false
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať zákazku' }, error)
      }
    }
  }
}
</script>
