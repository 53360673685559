import { TenderiaHttp } from '@/services/TenderiaHttp'
var zxcvbn = require('zxcvbn')
import { helpers, required, email, sameAs, minValue, requiredIf, maxLength, numeric } from '@vuelidate/validators'

export const useVuelidateValidationErrors = (errors) => {
  return errors.reduce((acc, value) => {
    return { ...acc, [value.$property]: value.$message }
  }, {})
}

export const validateRequired = helpers.withMessage(() => 'Toto pole je povinné', required)
export const validateRequiredIf = (data) => {
  return helpers.withMessage(() => 'Toto pole je povinné', requiredIf(data))
}
export const validateEmail = helpers.withMessage(() => 'Email musí byť uvedený v tvare napr. meno@domena.sk', (value) => !helpers.req(value) || email)
export const validateUniqueEmail = (data) => {
  return helpers.withMessage(() => 'Používateľ s týmto emailom už v systéme existuje.', helpers.withAsync(async (value) => {
    if (!value) return true

    if (!!value && data.mode !== 'edit' && data.mode !== 'profile') {
      let unique = true
      try {
        await TenderiaHttp.post('/user/exists', { user: { email: value } })

        if (data.mode === 'invite') { //if in invite mode and email is unique change back to create mode
          data.mode = 'create'
        }
        unique = true
      } catch {
        if (data.mode === 'create' || data.mode !== 'registration') { //if in create mode (adding user to organization) and email is not unique change to invite mode
          data.mode = 'invite'
        } else {
          unique = false
        }
      }
      return unique
    } else {
      return true
    }
  }))
}
export const validatePhone = helpers.withMessage(
  () => 'Telefónne čislo musí byť uvedené v tvare napr. +421123456789',
  (value) => !helpers.req(value) || helpers.regex(/^\+([0-9] ?){6,15}[0-9]$/)(value)
)
export const validatePasswordStrength = helpers.withMessage(
  () => 'Zvolené heslo je príliš jednoduché!',
  (value) => !helpers.req(value) || zxcvbn(value).score > 1
)
export const validateSameAsPassword = (param) => {
  return helpers.withMessage(
    () => 'Heslá sa musia zhodovať.',
    (value) => !helpers.req(value) || sameAs(param)(value)
  )
}
export const validateIcdph = helpers.withMessage(
  () => 'IČ DPH musí byť zadané v tvare SK0123456789',
  (value) => !helpers.req(value) || helpers.regex(/^[a-zA-Z]{2,2}[0-9]{10,10}$/)(value)
)
export const validateZip = helpers.withMessage(
  () => 'PSČ musí byť zadané v tvare 12345',
  (value) => !helpers.req(value) || helpers.regex(/^[0-9]{4,7}$/)(value)
)
export const validateMinValue = (min) => {
  return helpers.withMessage(
    () => `Hodnota musí byť väčšia alebo rovná ${min}`,
    minValue(min)
  )
}
export const validateMaxLength = (max) => {
  return helpers.withMessage(
    () => `Dĺžka musí byť menšia alebo rovná ${max}`,
    maxLength(max)
  )
}
export const validateNumeric = helpers.withMessage(
  () => 'Hodnota musí byť číslo',
  numeric
)

const validator = {

  firstnameMinLength: 1,
  firstnameMaxLength: 255,
  lastnameMinLength: 1,
  lastnameMaxLength: 255,
  cityMinLength: 1,
  cityMaxLenght: 255,

  validateFirstname: function (value) {
    return this.validateString(value, this.firstnameMaxLength, this.firstnameMinLength)
  },
  validateLastname: function (value) {
    return this.validateString(value, this.lastnameMaxLength, this.lastnameMinLength)
  },
  validateCity: function (value) {
    return this.validateString(value, this.cityMaxLenght, this.cityMinLength)
  },
  validateNumeric: function (value) {
    return !/^\s*$/.test(value) && !isNaN(value)
  },
  validateString: function (value, maxLength, minLength) {
    return (value && value.length > minLength && value.length < maxLength)
  },
  validateEmail: function (value) {
    var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(String(value).toLowerCase())
  },
  validateUrl: function (value) {
    var regex = /^(((http|https|ftp|ftps|sftp):\/\/)|((w|W){3}(\d)?\.))[\w?!./:;,\-_=#+*%@"()&]+\S$/
    return regex.test(String(value).toLowerCase())
  },
  validateIco: function (value) {
    var regex = /^(HRB\s?\d{1,6}|DE\d{9}|[A-Z]\d{7}[A-Z0-9]|\d{8}[A-Z]|\d{14}|\d{9}|\d{8}|[A-Z0-9]{16}|[A-Z]{2}\d{6}|\d{10}|FN\s?\d{6}[a-z]?|\d{11}-\d{1}-\d{2}|\d{6,14}-?\d{0,4})$/
    return regex.test(value)
  },
  validatePhone: function (value) {
    var regex = /^\+([0-9] ?){6,15}[0-9]$/
    return regex.test(value)
  }
}

export default validator
