<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Organizačné jednotky
        </h4>
        <div class="actions">
          <router-link
            class="btn btn-tenderia-blue"
            to="/app/settings/departments/add"
          >
            <i class="icon-add font-size-14"></i> Pridať organizačnú jednotku
          </router-link>
        </div>
      </div>
      <v-table
        :items="departments.data"
        :columns="departmentsColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template #cell(type)="{ item }">
          {{ $format.formatDepartmentType(item.type) }}
        </template>
        <template #cell(actions)="{ item }">
          <router-link
            :to="'/app/settings/departments/edit/' + item.id"
            title="Upraviť organizačnú jednotku"
          >
            <i class="icon-pencil"></i>
          </router-link>
          <a
            @click.stop="removeDepartmentModal(item)"
            class="text-danger"
            title="Zmazať"
          >
            <i class="icon-delete"></i>
          </a>
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="departments.count"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="departments.count"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'

export default {
  name: 'DepartmentSettingsAccounts',
  components: { VTable, VPagination },
  data: function () {
    return {
      departments: {},
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    departmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov',
          sortable: true
        },
        {
          key: 'type',
          label: 'Typ',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    },
    fetchParams () {
      return {
        'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort)

      }
    }
  },
  methods: {
    async fetchDepartments () {
      try {
        const { data } = await this.$http.post('/orgunit/list', this.fetchParams)
        this.departments = { data: data.units, count: data.total }
      } catch (error) {
        this.ErrorAlert({ text: 'Nastala chyba.' }, error)
      }
    },
    removeDepartmentModal (department) {
      this.YesNoAlert({
        title: 'Zmazať organizačnú jednotku',
        text: `Skutočne chcete zmazať organizačnú jednotku ${department.name}?`,
        type: 'warning',
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.removeDepartment(department)
        }
      })
    },
    async removeDepartment (department) {
      try {
        let data = {
          unit: {
            id: department.id
          }
        }
        await this.$http.post('/orgunit/delete', data)
        this.OkAlert({ text: `Organizačná jednotka ${department.name} bola zmazaná.` })
        this.fetchDepartments()
      } catch (error) {
        this.ErrorAlert({ text: 'Organizačnú jedmotku sa nepodarilo zmazať.' }, error)
      }
    }
  },
  mounted () {
    this.fetchDepartments()
  },
  watch: {
    fetchParams () {
      this.fetchDepartments()
    }
  }
}
</script>
