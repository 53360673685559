import { defineStore } from 'pinia'
import { orderRoutePrefix } from '@/router/plugin'
import { TenderiaHttp as http } from '@/services/TenderiaHttp.js'
import { useGlobalStore } from './globalStore'

const initialOrder = () => ({
  type: '',
  subtype: '',
  name: '',
  description: '',
  privateNote: '',
  identifier: '',
  divided: false,
  childs: [],
  parts: 0,
  part: 0,
  duration: 0,
  partDetails: {
    partsBidding: '',
    maxPartsBidding: 0,
    maxPartsAssign: 0,
    combinationAssign: false,
    combinationInfo: '',
    _id: ''
  },
  journal: '',
  announcement: '',
  subjectType: '',
  biddingMethod: '',
  procedureType: '',
  procedureReverse: false,
  cpvCode: '',
  cpvCodes: [],
  cpvSuppl: [],
  uvoOrderId: '',
  userHome: '',
  request: null,
  attachments: [],
  estimatedPrice: 0,
  announcementUrl: '',
  mainDeliveryPlace: '',
  participationConditions: '',
  fundingByEu: '',
  auction: false,
  offerOpeningType: '',
  bidsStartAt: null,
  bidsLimitTo: null,
  bidsOpenAt: null,
  bidsOpenAt2: null,
  bidsEvaluateAt: null,
  endAt: null,
  requiredAuthLevel: 0,
  journalAnnouncementDate: null,
  private: false,
  archive: false,
  state: '',
  suppliers: [],
  interested: [],
  applicants: [],
  participants: [],
  qualifiedApplicants: [],
  attendants: [],
  items: [],
  criteria: [],
  commission: [],
  relatedPersons: [],
  relatedEntities: [],
  createdAt: null,
  updatedAt: null,
  organization: {
    name: '',
    type: '',
    ico: '',
    address: '',
    address2: '',
    city: '',
    zip: '',
    country: '',
    id: ''
  },
  user: {
    phone: '',
    city: '',
    title1: '',
    title2: '',
    relations: [],
    email: '',
    firstname: '',
    lastname: '',
    id: ''
  },
  parent: null,
  cpvCodeText: {
    code: '',
    texts: {
      SK: ''
    }
  },
  cpvCodesText: [],
  cpvSupplText: [],
  id: '',
  integration: {
    olink: {
      enabled: false,
      needAuth: ''
    },
    epvo: {
      enabled: false,
      needAuth: ''
    },
    eforms: {
      enabled: false,
      needAuth: ''
    }
  },
  _canEdit: false,
})

export const useOrderStore = defineStore('order', {
  state: () => ({
    order: initialOrder(),
    oldOrder: {},
    orderChanged: false,
    parent: null,
    routePrefix: '',
  }),
  actions: {
    setRoutePrefix(order) {
      this.routePrefix = orderRoutePrefix(order.type, order.subtype)
    },
    resetOrder() {
      this.order = initialOrder()
      this.oldOrder = {}
      this.orderChanged = false
      this.parent = null
      this.routePrefix = ''
    },
    setOrder(order) {
      this.order = order
      this.oldOrder = { ...order }
      this.routePrefix = orderRoutePrefix(order.type, order.subtype)
    },
    async getOrder(orderId) {
      const globalStore = useGlobalStore()
      const user = globalStore.user
      this.resetOrder()
      let { data: { order, integration, parent } } = await http.post('/order/get', { order: { id: orderId } })
      order.attachments = order.attachments.reverse()
      order._canEdit = user.type !== 'observer'
      order.announcementUrl = this.order.announcementUrl || `${process.env.VUE_APP_BACKEND_HOST}/public/order/${order.id}`
      if (order.divided) {
        let { data: { orders: parts } } = await http.post('/order/list', { parent: orderId, limit: 100, role: 'buyer' })
        order._parts = parts.map(part => Object.assign({}, { _canEdit: user.type !== 'observer' }, part))
      }
      if (parent) {
        this.parent = parent
        this.setOrder({ ...order, integration })
      } else {
        this.setOrder({ ...order, integration })
      }
    },
    async readOrder(orderId) {
      this.resetOrder()
      let { data: { order, parent, user } } = await http.post('/order/read', { order: { id: orderId } })
      order.attachments = order.attachments.reverse()
      if (user) {
        order.isApplicant = user.isApplicant,
        order.isInterested = user.isInterested
      }
      if (order.divided) {
        let { data: { orders: parts } } = await http.post('/order/catalog', { parent: orderId, limit: 100 })
        order._parts = parts
      }
      if (parent) {
        this.parent = parent
        this.setOrder({ ...order })
      } else {
        this.setOrder({ ...order })
      }
    }
  }
})
