<template>
  <multiselect
    v-model="selectedSupplier"
    :options="suppliers"
    track-by="_id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    placeholder="Vyberte si používateľov"
    :loading="loading"
  >
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'RequestSuppliersSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      globals: useGlobalStore(),
      suppliers: [],
      loading: false
    }
  },
  computed: {
    fetchParams () {
      return {
        organization: {
          id: this.globals.userOrganization.id
        }
      }
    },
    selectedSupplier: {
      get() {
        return this.suppliers.filter(supplier => this.value.find(val => val._id === supplier._id))
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  async mounted () {
    this.loading = true
    let { data } = await this.$http.post('/organization/supplier/list', this.fetchParams)
    this.loading = false
    this.suppliers = data.suppliers
  }
}
</script>
