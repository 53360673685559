<template>
  <div
    v-if="!loading || order.id"
    class="container-fluid"
  >
    <ul class="order-main-menu mb-3">
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-overview`, params: {orderId: $route.params.orderId}}">
          Prehľad
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-communication`, params: {orderId: $route.params.orderId}}">
          Komunikácia
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-applications`, params: {orderId: $route.params.orderId}}">
          Žiadosť o účasť
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: `${orderStore.routePrefix}user-tenders`, params: {orderId: $route.params.orderId}}">
          Výzvy na PP
        </router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'User',
  provide () {
    return {
      order: this.order
    }
  },
  data () {
    return {
      orderStore: useOrderStore(),
      loading: false,
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  },
  mounted () {
    this.loadOrder()
  },
  methods: {
    async loadOrder () {
      try {
        this.ShowLoading()
        this.loading = true
        await this.orderStore.readOrder(this.$route.params.orderId)
        this.loading = false
        this.CloseLoading()
      } catch (error) {
        this.loading = false
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať zákazku' }, error)
      }
    }
  }
}
</script>
