import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminCriteria from '@/components/order/common/views/AdminCriteria'
import AdminCriteriaAdd from '@/components/order/common/views/AdminCriteriaAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import AdminItems from '@/components/order/common/views/AdminItems'
import AdminItemsAdd from '@/components/order/common/views/AdminItemsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import Admin from '@/components/order/OrderTypeDnsCall/views/Admin'
import AdminApplicants from '@/components/order/OrderTypeDnsCall/views/AdminApplicants'
import AdminHome from '@/components/order/OrderTypeDnsCall/views/AdminHome'
import AdminInterested from '@/components/order/OrderTypeDnsCall/views/AdminInterested'
import AdminOffers from '@/components/order/common/views/AdminOffers'
import AdminOfferView from '@/components/order/common/views/AdminOfferView.vue'
import AdminOverview from '@/components/order/OrderTypeDnsCall/views/AdminOverview'
import AdminSettings from '@/components/order/OrderTypeDnsCall/views/AdminSettings.vue'
import User from '@/components/order/OrderTypeDnsCall/views/User'
import UserOffers from '@/components/order/OrderTypeDnsCall/views/UserOffers'
import UserOffersAdd from '@/components/order/OrderTypeDnsCall/views/UserOffersAdd'
import UserOffersOpening from '@/components/order/common/views/UserOffersOpening'
import UserOfferView from '@/components/order/common/views/UserOfferView.vue'
import UserOverview from '@/components/order/OrderTypeDnsCall/views/UserOverview'
import { permissions } from '@/router/permissions.js'

/*
  'us-vyz': 'usv-', // 'Užšia súťaž - výzva'
  'kd-vyz': 'kdv-', // 'Koncesný dialóg - výzva'
  'rkz-vyz': 'rkzv-', // 'Rokovacie konanie so zverejnením - výzva'
  'dns-vyz': 'dnsv-', // 'Dynamický nákupný systém - výzva'
  'sd-vyz': 'sdv-' // 'Súťažný dialóg - výzva'
*/

const prefixes = ['usv', 'kdv', 'rkzv', 'dnsv', 'sdv']

const dnsCallRouter = (prefix) => {
  const routes = [
    // Admin routes
    {
      path: `${prefix}/admin/:orderId`,
      name: `${prefix}-admin`,
      redirect: { name: `${prefix}-admin-overview` },
      component: Admin,
      children: [
        {
          path: 'home',
          name: `${prefix}-admin-home`,
          redirect: { name: `${prefix}-admin-overview` },
          component: AdminHome,
          children: [
            {
              path: 'overview',
              name: `${prefix}-admin-overview`,
              component: AdminOverview,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'settings',
              name: `${prefix}-admin-settings`,
              component: AdminSettings,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents',
              name: `${prefix}-admin-documents`,
              component: AdminDocuments,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents/add',
              name: `${prefix}-admin-documents-add`,
              component: AdminDocumentsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents/edit/:id',
              name: `${prefix}-admin-documents-edit`,
              component: AdminDocumentsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items',
              name: `${prefix}-admin-items`,
              component: AdminItems,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items/add',
              name: `${prefix}-admin-items-add`,
              component: AdminItemsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'items/update/:index',
              name: `${prefix}-admin-items-update`,
              component: AdminItemsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria',
              name: `${prefix}-admin-criteria`,
              component: AdminCriteria,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria/add',
              name: `${prefix}-admin-criteria-add`,
              component: AdminCriteriaAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'criteria/update/:index',
              name: `${prefix}-admin-criteria-update`,
              component: AdminCriteriaAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            }
          ]
        },
        {
          path: 'communication',
          name: `${prefix}-admin-communication`,
          component: AdminCommunication,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'communication/add',
          name: `${prefix}-admin-communication-add`,
          component: AdminCommunicationAdd,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'offers',
          name: `${prefix}-admin-offers`,
          props: {
            round: 1
          },
          component: AdminOffers,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'offers/:offerId',
          name: `${prefix}-admin-offers-view`,
          component: AdminOfferView,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'interested',
          name: `${prefix}-admin-interested`,
          component: AdminInterested,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'applicants',
          name: `${prefix}-admin-applicants`,
          component: AdminApplicants,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        }
      ]
    },
    // User routes
    {
      path: `${prefix}/user/:orderId`,
      name: `${prefix}-user`,
      redirect: { name: `${prefix}-user-overview` },
      component: User,
      children: [
        {
          path: 'overview',
          name: `${prefix}-user-overview`,
          component: UserOverview,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'communication',
          name: `${prefix}-user-communication`,
          component: UserCommunication,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'communication/add',
          name: `${prefix}-user-communication-add`,
          component: UserCommunicationAdd,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers',
          name: `${prefix}-user-offers`,
          component: UserOffers,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers/add',
          name: `${prefix}-user-offers-add`,
          component: UserOffersAdd,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers/view',
          name: `${prefix}-user-offers-view`,
          component: UserOfferView,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'offers-opening',
          name: `${prefix}-user-offers-opening`,
          component: UserOffersOpening,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        }
      ]
    }
  ]

  return routes
}

const generateDnsCallRoutes = () => {
  const routers = prefixes.flatMap(prefix => dnsCallRouter(prefix))
  return routers
}

export default generateDnsCallRoutes
