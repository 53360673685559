import moment from 'moment'
import numberFormat from './number-format'
import mapValues from 'lodash/mapValues'
import { filesize } from 'filesize'
import { mimeType } from 'mime-type/with-db'
const mime = mimeType

export function sortString(params) {
  if (params && params.sortBy && params.sort) {
    return `${params.sort === 'desc' ? '-' : ''}${params.sortBy}`
  }
}

export function country(code) {
  return JSON.parse(localStorage.getItem('COUNTRIES')).find(country => country.code === code).text
}

export const orderState = (value) => {
  const orderStates = {
    planned: 'V príprave',
    active: 'Prebiehajúce',
    finished: 'Ukončené',
    cancelled: 'Zrušené'
  }
  return orderStates[value]
}

const numeric = mapValues({
  perc: numberFormat('percent'),
  perc2: numberFormat('percent2'),
  num: numberFormat('num'),
  numFixed1: numberFormat('numFixed1'),
  numFixed2: numberFormat('numFixed2'),
  shortNum: numberFormat('shortNum'),
  currency: numberFormat('currency', ['currency']).currency('EUR').pre(Math.trunc),
  shortCurrency: numberFormat('shortCurrency', ['currency']).currency('EUR'),
  shortCurrencyFixed2: numberFormat('shortCurrencyFixed2', ['currency']).currency('EUR'),
  price: numberFormat('price', ['currency']).currency('EUR')
}, fn => fn.locale('sk-SK'))

const vatCurrency = numeric.currency
  .empty('n/a')
  .post((res, args) => `${res} (${args[2] ? 's DPH' : 'bez DPH'})`)

const vatPrice = numeric.price
  .empty('n/a')
  .post((res, args) => `${res} (${args[2] ? 's DPH' : 'bez DPH'})`)

export function formatLogStatus (status) {
  return parseInt(status) < 400 ? 'Úspech' : 'Chyba'
}

export function formatLogsCategory(value) {
  const logsCategory = {
    'auth': 'Autentifikácia',
    'order': 'Zákazka - všeobecné',
    'order:parts': 'Zákazka - časti',
    'order:documents': 'Zákazka - dokumenty',
    'order:communication': 'Zákazka - komunikácia',
    'order:criteria': 'Zákazka - kritériá',
    'offer': 'Zákazka - ponuky',
    'order:items': 'Zákazka - položky',
    'order:persons': 'Zákazka - ľudia',
    'order:suppliers': 'Zákazka - dodávatelia',
    'order:epvo': 'Zákazka - elektronická platforma',
    'order:iszu': 'Zákazka - ISZU',
    'order:eforms': 'Zákazka - EFORMS'
  }
  return logsCategory[value] || ''
}

export function formatLogsActions(value, row) {
  const logsActions = {
    'order': {
      'create': 'Založenie zákazky',
      'set': 'Zmena nastavení',
      'state': 'Zmena stavu zákazky',
    },
    'order:parts': {
      'create': 'Pridanie časti č.',
      'start': 'Spustenie výzvy',
      'set': 'Zmena nastavení časti',
      'remove': 'Zmazanie časti',
      'state': 'Zmena stavu časti',
    },
    'order:documents': {
      'create': 'Pridanie dokumentu',
      'remove': 'Zmazanie dokumentu',
    },
    'order:communication': {
      'send': 'Správa ',
    },
    'order:criteria': {
      'create': 'Pridanie kritéria',
      'set': 'Zmena nastavení kritéria',
      'remove': 'Zmazanie kritéria',
    },
    'order:commision': {
      'create': 'Pridanie člena komisie',
      'recall': 'Odvolanie člena komisie',
      'edit': 'Zmena člena komisie',
    },
    'offer': {
      'submit': 'Predloženie ponuky',
      'revoke': 'Odvolanie ponuky',
      'open': 'Otvorenie ponuky',
      'exclude': 'Vylúčenie ponuky',
      'remove': 'Odstránenie ponuky',
    },
    'order:items': {
      'create': 'Pridanie položky',
      'set': 'Zmena položky',
      'remove': 'Zmazanie položky',
    },
    'order:persons': {
      'create': 'Pridanie osoby',
      'set': 'Zmena údajov osoby',
      'remove': 'Zmazanie osoby',
    },
    'order:suppliers': {
      'set': 'Pridanie dodávateľa',
      'remove': 'Zmazanie dodávateľa',
      'invitation': 'Odoslanie pozvánky pre dodávateľa',
    },
    'order:epvo': {
      'create': 'Založenie zákazky',
      'invitation': 'Odoslanie výzvy na predkladanie ponúk pre dodávateľa'
    },
    'order:iszu': {
      'create': 'Založenie zákazky',
    },
    'order:eforms': {
      'create': 'Založenie zákazky',
      'pair': 'Spárovanie zákazky',
    },
    'order:entities': {
      'set': 'Zmena',
      'remove': 'Zmazanie',
      'create': 'Pridanie',
    },
  }

  let string = ''
  try {
    switch (row.category) {
    case 'order':
      string = ''
      break
    case 'order:parts':
      string = `${row.data.order.part} - ${row.data.order.name}`
      break
    case 'order:documents':
      string = row.action === 'remove' ? row.data.removed[0].name : row.data.attachments[0].name
      break
    case 'order:communication':
    {
      let to = ''
      if (row.data.request.to.length !== 0) {
        to = `${row.data.request.to[0].firstname} ${row.data.request.to[0].lastname} <${row.data.request.to[0].email}>`
      } else {
        row.data.request.bcc.forEach(recipient => {
          to += `${recipient.firstname} ${recipient.lastname} <${recipient.email}>, `
        })
        to = to.substring(0, to.length - 2)
      }
      string = `${row.data.subject} od ${row.data.request.from.firstname} ${row.data.request.from.lastname} <${row.data.request.from.email}> pre ${to}`
      break
    }
    case 'order:criteria':
      string = row.data.criterion.name
      break
    case 'order:commision':
    {
      let member = row.action === 'create' ? row.data.member : row.data.order.commission.find(member => member._id === row.data.request.memberId)
      string = `${member.title1} ${member.firstname} ${member.lastname} ${member.title2}`.trim()
      break
    }
    case 'offer':
      string = row.status === '200' ? (row.data.offer.applicantDetails.groupName || row.data.offer.applicantDetails.businessName) : (row.data.request.offer.applicantDetails.groupName || row.data.request.offer.applicantDetails.businessName)
      break
    case 'order:items':
      string = row.data.item.name
      break
    case 'order:persons':
      string = ''
      break
    case 'order:suppliers':
      string = row.action === 'remove' ? row.data.removed[0].name : row.data.request.supplier.name
      break
    case 'order:epvo':
    {
      if (row.action === 'create') {
        string = row.data.order.name
      }
      if (row.action === 'invitation') {
        string = row.data.request.offer.applicantDetails.groupName || row.data.request.offer.applicantDetails.businessName
      }
      break
    }
    case 'order:iszu':
    {
      if (row.action === 'create')  string = row.data.order.name
      break
    }
    case 'order:entities':
    {
      let entity = row.action === 'remove' ? row.data.entity : row.data.relatedEntity
      if (entity.type === 'person') {
        string = 'záujmovej osoby - '
        string += `${entity.title1} ${entity.firstname} ${entity.lastname} ${entity.title2}`.trim()
      } else {
        string = `záujmovej organizácie - ${entity.businessName} IČO: ${entity.cin}`
      }
      break
    }
    default:
      string = ''
    }
  } catch (error) {
    string = ''
  }
  if (!logsActions[row.category]?.[value]) return ''
  return string ? `${logsActions[row.category][value]} ${string}` : logsActions[row.category][value]
}

export function formatFileSize(value) {
  return filesize(value)
}

export function formatFileType(value) {
  return mime.extension(value)
}

export function formatDate(value) {
  return value ? moment(value).format('DD. MM. YYYY') : ''
}

export function formatDateTime(value) {
  return value ? moment(value).format('DD. MM. YYYY HH:mm') : ''
}

export function formatTime(value) {
  return value ? moment(value).format('HH:mm:ss') : ''
}

export function formatYesNo(value) {
  return value === 'yes' ? 'Áno' : 'Nie'
}

export function formatOfferState(value) {
  const stages = {
    new: 'Neodoslaná',
    sent: 'Odoslaná',
    seen: 'Otvorená',
  }
  return stages[value] || ''
}

export function formatOfferStage(value) {
  const stages = {
    unopened: 'Neotvorená',
    opened: 'Otvorená',
    opened2: 'Otvorená',
    accepted: 'Prijatá',
    excluded: 'Vylúčená',
    victory: 'Víťazná'
  }
  return stages[value] || ''
}

export function formatOrderSubject(value) {
  const subjects = {
    goods: 'Tovary',
    services: 'Služby',
    work: 'Práce'
  }
  return subjects[value] || ''
}

export function formatOfferOpeningType(value) {
  const types = {
    nonpublic: 'Neverejné',
    personal: 'Fyzické',
    distant: 'Dištančné'
  }
  return types[value] || ''
}

export function formatLegalBasis(value) {
  const types = {
    'vlo': 'Mimoriadne nízka ponuka',
    'p40zvo': '&#167; 40 ods. 6 ZVO',
    'p53zvo': '&#167; 53 ZVO'
  }
  return types[value] || ''
}

export function formatPartsBidding(value) {
  const types = {
    one: 'jednu časť',
    several: 'niekoľko častí',
    all: 'všetky časti'
  }
  return types[value] || ''
}

export function formatProcedureType(order) {
  const procedureType = {
    'nh-nh': 'Prieskum trhu',
    'pz-vs': 'Podlimitná zákazka',
    'nz-vs': 'Nadlimitná zákazka - verejná súťaž',
    'dns-dns': 'Dynamický nákupný systém',
    'ptk-ptk': 'Predbežné trhové konzultácie',
    'nz-us': 'Nadlimitná zákazka - užšia súťaž',
    'nk-us': 'Nadlimitná koncesia - užšia súťaž',
    'ob-us': 'Zákazka v oblasti obrany a bezpečnosti - užšia súťaž',
    'nz-sd': 'Nadlimitná zákazka - súťažný dialóg',
    'nk-sd': 'Nadlimitná koncesia - súťažný dialóg',
    'nk-kd': 'Nadlimitná koncesia - koncesný dialóg',
    'ob-kd': 'Zákazka v oblasti obrany a bezpečnosti - koncesný dialóg',
    'nz-rkz': 'Nadlimitná zákazka - rokovacie konanie so zverejnením',
    'nk-rkz': 'Nadlimitná koncesia - rokovacie konanie so zverejnením',
    'ob-rkz': 'Zákazka v oblasti obrany a bezpečnosti - rokovacie konanie so zverejnením',
    'nz-prk': 'Priame rokovacie konanie',
    'ob-prk': 'Zákazka v oblasti obrany a bezpečnosti - priame rokovacie konanie',
    'nz-ip': 'Nadlimitná zákazka - inovatívne partnerstvo',
    'nk-ovs': 'Nadlimitná koncesia - obchodná verejná súťaž',
    'sn-sn': 'Súťaž návrhov',
    'pk-pk': 'Podlimitná koncesia',
    'nk-vs': 'Nadlimitná koncesia - verejná súťaž'
  }
  return procedureType[`${order.type}-${order.subtype}`]
}

export function formatMessageType(value) {
  const tenderiaMessageTypes = {
    'nam': 'Námietka',
    'zvpd': 'Žiadosť o vysvetlenie alebo doplnenie predložených dokladov',
    'zvp': 'Žiadosť o vysvetlenie ponuky',
    'zvnp': 'Žiadosť o vysvetlenie mimoriadne nízkej ponuky',
    'zpde': 'Žiadosť o predloženie dokladov nahradených Jednotným európskym dokumentom',
    'vsp': 'Vysvetlenie súťažných podkladov',
    'znio': 'Žiadosť o nahradenie inej osoby',
    'zns': 'Žiadosť o nahradenie subdodávateľa',
    'iop': 'Informácia o otváraní ponúk',
    'ovu': 'Oznámenie o vylúčení uchádzača',
    'ovp': 'Oznámenie o vylúčení ponuky',
    'zop': 'Zápisnica z otvárania ponúk',
    'ovhp': 'Oznámenie o vyhodnotení ponúk',
    'id': 'Iný dokument',
    'zvsp': 'Žiadosť o vysvetlenie súťažných podkladov',
    'ozvp': 'Odpoveď na žiadosť o vysvetlenie ponuky',
    'ozvpd': 'Odpoveď na žiadosť o vysvetlenie alebo doplnenie predložených dokladov',
    'ozvnp': 'Odpoveď na  žiadosť o vysvetlenie mimoriadne nízkej ponuky',
    'ozpde': 'Odpoveď na žiadosť o predloženie dokladov nahradených Jednotným európskym dokumentom',
    'oznio': 'Odpoveď na žiadosť o nahradenie inej osoby',
    'ozns': 'Odpoveď na žiadosť o nahradenie subdodávateľa',
    'zon': 'Žiadosť o nápravu',
    'pzzz': 'Oznámenie o zaradení medzi záujemcov',
    'zu': 'Identifikácia známeho záujemcu',
    'np': 'Nová ponuka',
    'op': 'Odvolanie ponuky',
    'pnp': 'Potvrdenie o odoslaní ponuky',
    'sys': 'Systémová správa',
    'sys-alt': 'Systémová správa',
    'dns-vyz': 'Výzva na predkladanie ponúk',
    'wbr': 'Školenia',
  }
  return tenderiaMessageTypes[value] || value // Return the value if not found
}

export function formatDocumentType(value) {
  const tenderiaDocumentTypes = {
    sp: 'Súťažné podklady',
    vsp: 'Vysvetlenie súťažných podkladov',
    zm: 'Zmluva',
    izm: 'Informácia o zverejnení zmluvy',
    iop: 'Informácia o otváraní ponúk',
    ivvp: 'Informácia o výsledku vyhodnotenia ponúk',
    onpp: 'Odôvodnenie nezrušenia použitého postupu',
    pu: 'Ponuky uchádzačov',
    sz: 'Správa o zákazke',
    ssup: 'Suma skutočne uhradeného plnenia',
    zop: 'Zápisnica z otvárania ponúk',
    zvsu: 'Zápisnica z vyhodnotenia splnenia podmienok účasti',
    zvp: 'Zápisnica z vyhodnotenia ponúk',
    zs: 'Zoznam subdodávateľov',
    id: 'Iný dokument k zákazke',
  }

  return tenderiaDocumentTypes[value] || value // Return the original value if not found
}

export function formatLegalForm(value) {
  const legalForms = {
    sp: 'štátny podnik',
    as: 'akciová spoločnosť',
    es: 'európska spoločnosť',
    sro: 'spoločnosť s ručením obmedzeným',
    vos: 'verejná obchodná spoločnosť',
    ks: 'komanditná spoločnosť',
    spfo: 'samostatne podnikajúca fyz. osoba',
    d: 'družstvo',
    jsna: 'jednoduchá spoločnosť na akcie',
    ed: 'európske družstvo',
    ezhz: 'európske zoskupenie hosp. záujmov',
    ozp: 'organizačná zložka podniku',
    ozzo: 'organizačná zložka zahran. osoby',
    op: 'obecný podnik',
    ou: 'obecný úrad',
    eiamo: 'európska inštitúcia/agentúra alebo medzinárodná organizácia',
    mso: 'ministerstvo alebo iný štátny orgán vrátane jeho regionálnych alebo miestnych útvarov',
    orvp: 'organizácia riadená verejným právom',
    rmau: 'regionálna alebo miestna agentúra/úrad',
    rmo: 'regionálny alebo miestny orgán',
    sau: 'štátna agentúra/úrad',
    in: 'iná',
  }

  return legalForms[value] || value // Return the original value if not found
}

export function formatDepartmentType(value) {
  const departmentTypes = {
    'department': 'Oddelenie',
    'division': 'Odbor',
    'unit': 'Úsek',
    'section': 'Referát',
  }

  return departmentTypes[value] || value // Return the original value if not found
}

export function formatNature(value) {
  var nature = {
    'ascending': 'Stúpajúce',
    'descending': 'Klesajúce'
  }
  return nature[value]
}

export function formatCriterionType(value) {
  var types = {
    'price': 'Cenové',
    'noprice': 'Necenové'
  }
  return types[value]
}

export function formatApplicantName(offer) {
  let name = offer.applicantDetails.businessName || offer.organization.name
  return offer.applicantDetails.groupName || name
}

export default {
  sortString,
  country,
  orderState,
  formatLogStatus,
  formatLogsCategory,
  formatLogsActions,
  formatFileSize,
  formatFileType,
  formatDate,
  formatDateTime,
  formatTime,
  formatYesNo,
  formatOfferState,
  formatOfferStage,
  formatOrderSubject,
  formatOfferOpeningType,
  formatLegalBasis,
  formatPartsBidding,
  formatProcedureType,
  formatMessageType,
  formatDocumentType,
  formatLegalForm,
  formatDepartmentType,
  formatNature,
  formatCriterionType,
  formatApplicantName,
  ...numeric,
  vatCurrency, // (val, currency, vat) => ...
  vatPrice, // (val, currency, vat) => ...
}
