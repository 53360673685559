<template>
  <div id="order-items">
    <template v-if="!order.divided">
      <items-list-widget
        :order="order"
        :parent="null"
      />
    </template>
    <template v-else>
      <items-list-widget
        v-for="part in order._parts"
        :key="part.id"
        :order="part"
        :parent="order"
      />
    </template>
  </div>
</template>

<script>
import ItemsListWidget from '../components/ItemsListWidget.vue'
import { useOrderStore } from '@/storePinia/orderStore'
export default {
  name: 'AdminItems',
  components: { ItemsListWidget },
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  }
}
</script>
