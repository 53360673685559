<template>
  <div
    id="order-settings-add-item"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        Pridať novú položku
      </h4>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label
              class="control-label"
              for="item-name"
            >
              Názov položky <small>*</small>
            </label>
            <input
              id="subject"
              v-model.lazy="item.name"
              type="text"
              class="form-control"
              :class="{ error: form.name.error, valid: form.name.valid }"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.name.error"
              class="error"
              for="item-name"
            >
              Názov položky musí byť vyplnený a môže obsahovať max. 250 znakov.
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label
              class="control-label"
              for="amount"
            >
              Množstvo <small>*</small>
            </label>
            <input
              id="amount"
              v-model.lazy="item.amount"
              type="number"
              class="form-control"
              :class="{ error: form.amount.error, valid: form.amount.valid }"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.amount.error"
              class="error"
              for="amount"
            >
              Množstvo musí byť vyplnené.
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label
              class="control-label"
              for="unit"
            >
              Jednotka <small>*</small>
            </label>
            <input
              id="unit"
              v-model.lazy="item.unit"
              type="text"
              class="form-control"
              :class="{ error: form.unit.error, valid: form.unit.valid }"
              required="required"
              aria-required="true"
            >
            <label
              v-if="form.unit.error"
              class="error"
              for="unit"
            >
              Jednotka musí byť vyplnená.
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="control-label">
              Podrobný popis
            </label>
            <textarea
              id="description"
              v-model="item.description"
              class="form-control"
            />
            Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br>
            <label
              v-if="form.description.error"
              class="error"
              for="description"
            >
              Popis môže obsahovať max. 4000 znakov.
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <button
          class="btn btn-tenderia-grey-blue btn-inverse"
          @click="goBack"
        >
          <i class="icon-arrow-left" /> Späť
        </button>
        <button
          class="btn btn-success"
          @click="addItem"
        >
          <i class="icon-save" /> Uložiť
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminItemsAdd',
  data () {
    return {
      orderStore: useOrderStore(),
      item: {
        type: 'common',
        priceAffect: true,
        name: '',
        amount: '',
        unit: '',
        description: ''
      },
      form: {
        name: {
          error: false,
          valid: false
        },
        amount: {
          error: false,
          valid: false
        },
        unit: {
          error: false,
          valid: false
        },
        description: {
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    descriptionTextRemaining () {
      return 4000 - this.item.description.length
    }
  },
  created () {
    if (!isNaN(this.$route.params.index)) {
      if (this.$route.params.partId) {
        const part = this.order._parts.filter(part => part.id === this.$route.params.partId)
        this.item = part[0].items[this.$route.params.index]
      } else {
        this.item = this.order.items[this.$route.params.index]
      }
      this.item.id = this.item._id
    }
  },
  methods: {
    goBack () {
      window.history.go(-1)
    },
    async addItem () {
      this.ShowLoading()
      var data = {
        order: {
          id: this.$route.params.partId ? this.$route.params.partId : this.$route.params.orderId
        },
        item: this.item
      }
      try {
        await this.$http.post('/order/item/set', data)
        await this.orderStore.getOrder(this.$route.params.orderId)
        this.CloseLoading()
        this.OkAlert({ text: 'Položka bola uložená' })
        this.goBack()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba' }, error)
      }
    }
  }
}
</script>
