<template>
  <div class="layout">
    <app-login-header :order="order" />
    <app-sub-header :back-link="backLink" />
    <!-- Page Container START -->
    <div class="page-container">
      <registration-banner class="pdd-top-15" />
      <!-- Content Wrapper START -->
      <div class="main-content">
        <div
          v-if="!loading || order.id"
          class="container-fluid"
        >
          <ul class="order-main-menu mb-3">
            <li>
              <router-link :to="{ name: 'freeOrderHome', params: {orderId: this.$route.params.orderId}}">
                Prehľad
              </router-link>
            </li>
          </ul>
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!-- Content Wrapper END -->

    <!-- Footer START -->
    <footer class="content-footer">
      <app-footer />
    </footer>
    <!-- Footer END -->
  </div>
  <!-- Page Container END -->
</template>

<script>

import RegistrationBanner from '@/components/ui/RegistrationBanner.vue'
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppSubHeader from '@/components/order/AppSubHeader.vue'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'FreeOrder',
  components: { AppLoginHeader, AppFooter, RegistrationBanner, AppSubHeader },
  data () {
    return {
      loading: false,
      orderStore: useOrderStore(),
      globalStore: useGlobalStore(),
      backLink: this.$route.fullPath,
    }
  },
  beforeRouteEnter (to, from, next) {
    // Access the previous route from the 'from' argument
    next(vm => {
      vm.backLink = from.fullPath
    })
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    user () {
      return this.globalStore.user
    }
  },
  methods: {
    async loadOrder () {
      try {
        this.ShowLoading()
        this.loading = true
        await this.orderStore.readOrder(this.$route.params.orderId)
        this.loading = false
        this.CloseLoading()
      } catch (error) {
        this.loading = false
        this.CloseLoading()
        this.ErrorAlert({ text: 'Nepodarilo sa načítať zákazku' }, error)
      }
    }
  },
  watch: {
    user () {
      console.log('user changed')
      this.loadOrder()
    }
  },
  mounted () {
    this.loadOrder()
  }
}
</script>
