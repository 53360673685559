<template>
  <multiselect
    v-model="category"
    :options="options"
    track-by="code"
    label="text"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="true"
    :searchable="true"
    :show-labels="false"
    placeholder="Vyberte si kategóriu"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: 'CategorySelect',
  components: { Multiselect },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      options: [
        { code: 'order', text: 'Zákazka - všeobecné' },
        { code: 'order:parts', text: 'Zákazka - časti' },
        { code: 'order:documents', text: 'Zákazka - dokumenty' },
        { code: 'order:communication', text: 'Zákazka - komunikácia' },
        { code: 'order:criteria', text: 'Zákazka - kritériá' },
        /* { code: 'order:commision', text: 'Zákazka - komisia' }, */
        { code: 'offer', text: 'Zákazka - ponuky' },
        { code: 'order:items', text: 'Zákazka - položky' },
        { code: 'order:persons', text: 'Zákazka - ľudia' },
        { code: 'order:suppliers', text: 'Zákazka - dodávatelia' }
      ]
    }
  },
  computed: {
    category: {
      get() {
        return this.options.find(option => option.code === this.value)
      },
      set(val) {
        this.$emit('input', val ? val.code : null)
      }
    }
  },
}
</script>
