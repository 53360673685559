<template>
  <div
    id="my-offer"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        <span v-if="order.parent && order.part">
          Časť {{ order.part }} -
        </span>{{ orderStore.order.name }}
      </h4>
      <div class="actions">
        <button
          v-if="canCreateOffer && !canRevokeOffer"
          :disabled="!canCreateOffer"
          class="btn btn-tenderia-blue"
          @click="addOffer()"
        >
          <template v-if="offer.id">
            <i class="icon-pencil" /> Upraviť ponuku
          </template><template v-else>
            <i class="icon-add font-size-14" /> Vytvoriť ponuku
          </template>
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <p class="py-3">
            Koniec lehoty na predkladanie ponúk: <span
              v-if="order.bidsLimitTo"
              class="text-bold"
            >
              {{ $format.formatDateTime(order.bidsLimitTo) }}
            </span><span
              v-else
              class="text-bold"
            >
              Termín nebol stanovený
            </span>
          </p>
          <p
            v-if="canCreateOffer"
            class="text-dark text-center pdd-vertical-25"
          >
            <countdown
              :deadline="bidsLimitTo"
              :server-time="serverTime"
            />
          </p>
          <p
            v-if="!authLevelComplied"
            class="text-dark text-center text-danger lead"
          >
            Pre predloženie alebo odvolanie ponuky je vyžadované prihlásenie pomocou eID!
          </p>
        </div>
      </div>
    </div>
    <v-table
      v-if="offer.id"
      :items="[offer]"
      :columns="offerColumns"
      item-key="id"
      class="light"
    >
      <template #cell(createdAt)="{ item }">
        {{ $format.formatDateTime(item.createdAt) }}
      </template>
      <template #cell(updatedAt)="{ item }">
        {{ $format.formatDateTime(item.updatedAt) }}
      </template>
      <template #cell(sentAt)="{ item }">
        <span v-if="item.state !== 'new'">
          {{ $format.formatDateTime(item.sentAt) }}
        </span>
      </template>
      <template #cell(state)="{ item }">
        <v-badge
          class="m-0"
          :color="item.state !== 'new' ? 'green' : 'red'"
          glow
        >
          {{ $format.formatOfferState(item.state) }}
        </v-badge>
      </template>
    </v-table>
    <p
      v-else
      class="text-bold text-dark text-center pdd-vertical-50"
    >
      Nemáte vytvorenú žiadnu ponuku
    </p>
    <div class="card-footer">
      <div class="row">
        <button
          v-if="offer.id"
          class="btn btn-tenderia-blue"
          @click="openOffer()"
        >
          <span>Zobraziť ponuku</span>
        </button>
        <button
          v-if="canSendOffer"
          class="btn btn-success"
          @click="sendOffer()"
        >
          <i class="icon-send" /> <span>Odoslať ponuku</span>
        </button>
        <button
          v-if="offer.id && offer.state === 'new'"
          class="btn btn-danger"
          @click="removeOfferModal()"
        >
          <i class="icon-delete" /> <span>Zmazať ponuku</span>
        </button>
        <button
          v-if="canRevokeOffer"
          class="btn btn-warning"
          @click="revokeOfferModal()"
        >
          <i class="icon-close" /> Odvolať ponuku
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import VTable from '@/components/ui/Table.vue'
import VBadge from '@/components/ui/Badge.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'OfferWidget',
  components: { Countdown, VTable, VBadge },
  inject: ['blockNonPremiumUser', 'slowNonPremiumUser'],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      globals: useGlobalStore(),
      orderStore: useOrderStore(),
      statsStore: useStatsStore(),
      offer: {
        id: null
      }
    }
  },
  computed: {
    offerColumns () {
      return [
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia'
        },
        {
          key: 'updatedAt',
          label: 'Dátum poslednej aktualizácie'
        },
        {
          key: 'sentAt',
          label: 'Dátum odoslania ponuky'
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    bidsLimitTo () {
      return this.order.bidsLimitTo ? new Date(this.order.bidsLimitTo) : false
    },
    serverTime () {
      return new Date(this.statsStore.currentTime)
    },
    canSendOffer () {
      return this.offer.id && this.offer.state === 'new' && this.canCreateOffer && this.authLevelComplied
    },
    canCreateOffer () {
      return this.bidsLimitTo && this.serverTime < this.bidsLimitTo && this.authLevelComplied
    },
    canRevokeOffer () {
      return this.offer.id && this.offer.state === 'sent' && this.canCreateOffer && this.authLevelComplied
    },
    authLevelComplied () {
      return this.globals.user.authLevel >= this.order.requiredAuthLevel
    }
  },
  async created () {
    let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.order.id } })
    if (offer.id) {
      this.offer = offer
    }
  },
  methods: {
    async addOffer () {
      await this.slowNonPremiumUser()
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.orderStore.routePrefix}user-offers-add-parts`, params: { orderId: this.order.parent, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.orderStore.routePrefix}user-offers-add`, params: { orderId: this.order.id } })
      }
    },
    async sendOffer () {
      await this.slowNonPremiumUser()
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.orderStore.routePrefix}user-offers-add-parts`, params: { orderId: this.order.parent, partId: this.order.id, action: 'send' } })
      } else {
        this.$router.push({ name: `${this.orderStore.routePrefix}user-offers-add`, params: { orderId: this.order.id, action: 'send' } })
      }
    },
    async openOffer () {
      await this.slowNonPremiumUser()
      if (this.order.parent && this.order.part) {
        this.$router.push({ name: `${this.orderStore.routePrefix}user-offers-view-parts`, params: { orderId: this.order.parent, partId: this.order.id } })
      } else {
        this.$router.push({ name: `${this.orderStore.routePrefix}user-offers-view`, params: { orderId: this.order.id } })
      }
    },
    async revokeOfferModal () {
      await this.slowNonPremiumUser()
      this.YesNoAlert({
        text: 'Skutočne chcete odvolať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odvolať'
      }).then((result) => {
        if (result.value) {
          this.revokeOffer()
        }
      })
    },
    async revokeOffer () {
      this.ShowLoading({ title: 'Odvolávam ponuku' })
      try {
        await this.$http.post('/offer/set', { offer: { organization: this.globals.userOrganization.id, order: this.order.id, id: this.offer.id, state: 'new' } })
        this.offer.state = 'new'
        await this.orderStore.readOrder(this.orderStore.order.id)
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    },
    async removeOfferModal () {
      await this.slowNonPremiumUser()
      this.YesNoAlert({
        text: 'Skutočne chcete zmazať vašu ponuku?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeOffer()
        }
      })
    },
    async removeOffer () {
      this.ShowLoading({ title: 'Ponuka sa odstraňuje' })
      try {
        await this.$http.post('/offer/remove', { offer: { order: this.order.id } })
        this.offer = {
          id: null
        }
        this.CloseLoading()
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    }
  }
}
</script>
