import AdminCommunication from '@/components/order/common/views/AdminCommunication'
import AdminCommunicationAdd from '@/components/order/common/views/AdminCommunicationAdd'
import AdminDocuments from '@/components/order/common/views/AdminDocuments'
import AdminDocumentsAdd from '@/components/order/common/views/AdminDocumentsAdd'
import UserCommunication from '@/components/order/common/views/UserCommunication'
import UserCommunicationAdd from '@/components/order/common/views/UserCommunicationAdd'
import Admin from '@/components/order/OrderTypeDns/views/Admin'
import AdminApplications from '@/components/order/OrderTypeDns/views/AdminApplications'
import AdminApplicationsView from '@/components/order/OrderTypeDns/views/AdminApplicationsView'
import AdminHome from '@/components/order/OrderTypeDns/views/AdminHome'
import AdminOverview from '@/components/order/OrderTypeDns/views/AdminOverview'
import AdminSettings from '@/components/order/OrderTypeDns/views/AdminSettings.vue'
import AdminApplicants from '@/components/order/OrderTypeDns/views/AdminApplicants'
import AdminTenders from '@/components/order/OrderTypeDns/views/AdminTenders'
import AdminInterested from '@/components/order/OrderTypeDns/views/AdminInterested'
import User from '@/components/order/OrderTypeDns/views/User'
import UserApplications from '@/components/order/OrderTypeDns/views/UserApplications'
import UserApplicationsAdd from '@/components/order/OrderTypeDns/views/UserApplicationsAdd'
import UserApplicationsView from '@/components/order/OrderTypeDns/views/UserApplicationsView'
import UserOverview from '@/components/order/OrderTypeDns/views/UserOverview'
import UserTenders from '@/components/order/OrderTypeDns/views/UserTenders'
import { permissions } from '@/router/permissions.js'

/*
  'nz-us': 'us-', // 'Nadlimitná zákazka - užšia súťaž'
  'nk-us': 'us-', // 'Nadlimitná koncesia - užšia súťaž'
  'ob-us': 'us-', // 'Zákazka v oblasti obrany a bezpečnosti - užšia súťaž'
  'ob-kd': 'kd-', // 'Zákazka v oblasti obrany a bezpečnosti - koncesný dialóg'
  'nk-kd': 'kd-', // 'Nadlimitná koncesia - koncesný dialóg'
  'nz-rkz': 'rkz-', // 'Nadlimitná zákazka - rokovacie konanie so zverejnením'
  'nk-rkz': 'rkz-', // 'Nadlimitná koncesia - rokovacie konanie so zverejnením'
  'ob-rkz': 'rkz-', // 'Zákazka v oblasti obrany a bezpečnosti - rokovacie konanie so zverejnením'
  'dns-dns': 'dns-', // 'Dynamický nákupný systém'
  'nz-sd': 'sd-', // 'Nadlimitná zákazka - súťažný dialóg'
  'nk-sd': 'sd-', // 'Nadlimitná koncesia - súťažný dialóg'
*/

const prefixes = [
  'us',
  'kd',
  'rkz',
  'dns',
  'sd'
]

const dnsRouter = (prefix) => {
  const routes = [
    // Admin routes
    {
      path: `${prefix}/admin/:orderId`,
      name: `${prefix}-admin`,
      redirect: { name: `${prefix}-admin-overview` },
      component: Admin,
      children: [
        {
          path: 'home',
          name: `${prefix}-admin-home`,
          redirect: { name: `${prefix}-admin-overview` },
          component: AdminHome,
          children: [
            {
              path: 'overview',
              name: `${prefix}-admin-overview`,
              component: AdminOverview,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'settings',
              name: `${prefix}-admin-settings`,
              component: AdminSettings,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents',
              name: `${prefix}-admin-documents`,
              component: AdminDocuments,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents/add',
              name: `${prefix}-admin-documents-add`,
              component: AdminDocumentsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            },
            {
              path: 'documents/edit/:id',
              name: `${prefix}-admin-documents-edit`,
              component: AdminDocumentsAdd,
              meta: {
                viewRights: [permissions.ORDER_ORDER_CREATE_0]
              },
            }
          ]
        },
        {
          path: 'communication',
          name: `${prefix}-admin-communication`,
          component: AdminCommunication,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'communication/add',
          name: `${prefix}-admin-communication-add`,
          component: AdminCommunicationAdd,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'interested',
          name: `${prefix}-admin-interested`,
          component: AdminInterested,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'tenders',
          name: `${prefix}-admin-tenders`,
          component: AdminTenders,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'applications',
          name: `${prefix}-admin-applications`,
          component: AdminApplications,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'applicants',
          name: `${prefix}-admin-applicants`,
          component: AdminApplicants,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        },
        {
          path: 'applications/view/:applicationId',
          name: `${prefix}-admin-applications-view`,
          component: AdminApplicationsView,
          meta: {
            viewRights: [permissions.ORDER_ORDER_CREATE_0]
          },
        }
      ]
    },
    // User routes
    {
      path: `${prefix}/user/:orderId`,
      name: `${prefix}-user`,
      redirect: { name: `${prefix}-user-overview` },
      component: User,
      children: [
        {
          path: 'overview',
          name: `${prefix}-user-overview`,
          component: UserOverview,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'tenders',
          name: `${prefix}-user-tenders`,
          component: UserTenders,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'communication',
          name: `${prefix}-user-communication`,
          component: UserCommunication,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'communication/add',
          name: `${prefix}-user-communication-add`,
          component: UserCommunicationAdd,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'applications',
          name: `${prefix}-user-applications`,
          component: UserApplications,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'applications/add',
          name: `${prefix}-user-applications-add`,
          component: UserApplicationsAdd,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        },
        {
          path: 'applications/view',
          name: `${prefix}-user-applications-view`,
          component: UserApplicationsView,
          meta: {
            viewRights: [permissions.ORDER_OFFER_SET]
          },
        }
      ]
    }
  ]

  return routes
}

const generateDnsRoutes = () => {
  const routers = prefixes.flatMap(prefix => dnsRouter(prefix))
  return routers
}

export default generateDnsRoutes
