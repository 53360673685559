<template>
  <div
    id="my-offer"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        Žiadosť o účasť
      </h4>
      <div class="actions">
        <button
          v-if="offer.id"
          class="btn btn-tenderia-blue"
          @click="openOffer()"
        >
          <span>Zobraziť žiadosť</span>
        </button>
        <button
          v-if="canSendApplication"
          class="btn btn-success"
          @click="sendOffer()"
        >
          <i class="icon-send" /> <span>Odoslať žiadosť</span>
        </button>
        <button
          v-if="canEditApplication"
          class="btn btn-danger"
          @click="removeOfferModal()"
        >
          <i class="icon-delete" /> <span>Zmazať žiadosť</span>
        </button>
        <button
          v-if="canRevokeApplication"
          class="btn btn-warning"
          @click="revokeOfferModal()"
        >
          <i class="icon-close" /> Odvolať žiadosť
        </button>
        <button
          v-if="canCreateApplication"
          class="btn btn-tenderia-blue"
          @click="addOffer()"
        >
          <i class="icon-add font-size-14" /> Vytvoriť žiadosť
        </button>
        <button
          v-if="canEditApplication"
          class="btn btn-tenderia-blue"
          @click="addOffer()"
        >
          <i class="icon-pencil" /> Upraviť žiadosť
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="row">
        <div class="col-md-12">
          <p class="text-dark text-center pdd-vertical-25">
            Koniec lehoty na predkladanie žiadostí o účasť: <span
              v-if="order.bidsLimitTo"
              class="text-bold"
            >
              {{ $format.formatDateTime(order.bidsLimitTo) }}
            </span><span
              v-else
              class="text-bold"
            >
              Termín nebol stanovený
            </span>
          </p>
          <p
            v-if="displayTimer"
            class="text-dark text-center pdd-vertical-25"
          >
            <countdown
              :deadline="bidsLimitTo"
              :server-time="serverTime"
            />
          </p>
          <p
            v-if="!authLevelComplied"
            class="text-dark text-center text-danger lead"
          >
            Pre predloženie alebo odvolanie žiadosti je vyžadované prihlásenie pomocou eID!
          </p>
        </div>
      </div>
    </div>
    <v-table
      v-if="offer.id"
      :items="[offer]"
      :columns="offerColumns"
      item-key="id"
      class="light"
    >
      <template #cell(createdAt)="{ item }">
        {{ $format.formatDateTime(item.createdAt) }}
      </template>
      <template #cell(updatedAt)="{ item }">
        {{ $format.formatDateTime(item.updatedAt) }}
      </template>
      <template #cell(sentAt)="{ item }">
        <span v-if="item.state !== 'new'">
          {{ $format.formatDateTime(item.sentAt) }}
        </span>
      </template>
      <template #cell(state)="{ item }">
        <state :application="item" />
      </template>
    </v-table>
    <p
      v-else
      class="text-bold text-dark text-center pdd-vertical-50"
    >
      Nemáte vytvorenú žiadnu ponuku
    </p>
  </div>
</template>

<script>
import Countdown from '@/components/Countdown.vue'
import State from '../components/UserApplicationState'
import VTable from '@/components/ui/Table.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserApplications',
  components: { Countdown, State, VTable },
  inject: ['slowNonPremiumUser'],
  data () {
    return {
      globals: useGlobalStore(),
      statsStore: useStatsStore(),
      orderStore: useOrderStore(),
      offer: {
        id: null,
        state: null
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    offerColumns () {
      return [
        {
          key: 'createdAt',
          label: 'Dátum vytvorenia'
        },
        {
          key: 'updatedAt',
          label: 'Dátum poslednej aktualizácie'
        },
        {
          key: 'sentAt',
          label: 'Dátum odoslania ponuky'
        },
        {
          key: 'state',
          label: 'Stav'
        }
      ]
    },
    displayTimer () {
      return this.serverTime < this.bidsLimitTo
    },
    bidsLimitTo () {
      return new Date(this.order.bidsLimitTo)
    },
    serverTime () {
      return new Date(this.statsStore.currentTime)
    },
    canCreateApplication () {
      return this.basicRequirements && !this.offer.id
    },
    canEditApplication () {
      return this.basicRequirements && this.offer.id && this.offer.state === 'new'
    },
    canSendApplication () {
      return this.basicRequirements && this.offer.id && this.offer.state === 'new'
    },
    canRevokeApplication () {
      return this.basicRequirements && this.offer.id && this.offer.state === 'sent' && this.serverTime <= this.bidsLimitTo
    },
    basicRequirements () {
      return this.order.state === 'active' && this.authLevelComplied
    },
    authLevelComplied () {
      return this.globals.user.authLevel >= this.order.requiredAuthLevel
    }
  },
  async created () {
    let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.order.id } })
    if (offer.id) {
      this.offer = offer
    }
  },
  methods: {
    async addOffer () {
      await this.slowNonPremiumUser()
      this.$router.push({ name: `${this.orderStore.routePrefix}user-applications-add`, params: { orderId: this.order.id } })
    },
    async sendOffer () {
      await this.slowNonPremiumUser()
      this.$router.push({ name: `${this.orderStore.routePrefix}user-applications-add`, params: { orderId: this.order.id, action: 'send' } })
    },
    async openOffer () {
      await this.slowNonPremiumUser()
      this.$router.push({ name: `${this.orderStore.routePrefix}user-applications-view`, params: { orderId: this.order.id } })
    },
    async revokeOfferModal () {
      await this.slowNonPremiumUser()
      this.YesNoAlert({
        text: 'Skutočne chcete odvolať vašu žiadosť?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Odvolať'
      }).then((result) => {
        if (result.value) {
          this.revokeOffer()
        }
      })
    },
    async revokeOffer () {
      this.ShowLoading({ title: 'Odvolávam žiadosť' })
      try {
        await this.$http.post('/offer/set', { offer: { organization: this.globals.userOrganization.id, order: this.order.id, id: this.offer.id, state: 'new' } })
        this.offer.state = 'new'
        await this.orderStore.readOrder(this.order.id)
        this.CloseLoading()
        this.PopupAlert({ title: 'Žiadosť bola odvolaná' })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    },
    async removeOfferModal () {
      await this.slowNonPremiumUser()
      this.YesNoAlert({
        text: 'Skutočne chcete zmazať vašu žiadosť?',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeOffer()
        }
      })
    },
    async removeOffer () {
      this.ShowLoading({ title: 'Odstraňujem žiadosť' })
      try {
        await this.$http.post('/offer/remove', { offer: { order: this.order.id } })
        this.offer = { id: null }
        this.CloseLoading()
        this.PopupAlert({ title: 'Žiadosť bola zmazaná' })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    }
  }
}
</script>
