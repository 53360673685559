<template>
  <multiselect
    v-model="selectedRoles"
    :options="roles"
    track-by="id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    placeholder="Vyberte si role"
    :loading="loading"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'RolesSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      globals: useGlobalStore(),
      roles: [],
      loading: false
    }
  },
  computed: {
    selectedRoles: {
      get() {
        return this.roles.filter(role => this.value.includes(role.id))
      },
      set(val) {
        this.$emit('input', val.map(role => role.id))
      }
    }
  },
  async mounted () {
    this.loading = true
    const response = await this.$http.post('/organization/role/list', {
      organization: {
        id: this.globals.userOrganization.id
      }
    })
    this.loading = false
    this.roles = response.data.roles
  }
}
</script>
