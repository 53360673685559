<template>
  <div>
    <div
      id="order-documents"
      class="card"
    >
      <div class="card-heading">
        <h4 class="card-title ">
          Dokumenty
        </h4>
        <div class="actions">
          <router-link
            v-if="order._canEdit"
            class="btn btn-tenderia-blue"
            :to="{name: `${orderStore.routePrefix}admin-documents-add`, params: {orderId: order.id}}"
          >
            <i class="icon-add font-size-14" /> Pridať dokument
          </router-link>
          <button
            v-if="order.attachments && order.attachments.length > 0"
            class="btn btn-tenderia-grey-blue"
            @click.prevent="downloadDocuments()"
          >
            <i class="icon-download" /> Stiahnuť všetky dokumenty
          </button>
        </div>
      </div>
      <v-table
        :columns="documentsColumns"
        :items="orderPublicDocuments"
        :selected="selectedRows"
        item-key="id"
        class="light"
        @row-click="toggleRowDetail"
      >
        <template #cell(name)="{ _item, value }">
          <div class="row no-gutters align-items-center attachment-name">
            <i class="icon-doc-1 mr-2" />
            <span v-text="value" />
          </div>
        </template>
        <template #cell(type)="{ item }">
          {{ $format.formatDocumentType(item.type) }}
        </template>
        <template #cell(date)="{ item }">
          {{ $format.formatDate(item.createdAt) }}
        </template>
        <template #cell(updatedDate)="{ item }">
          {{ $format.formatDateTime(item.updatedAt) }}
        </template>
        <template #cell(actions)="{ item, row }">
          <a
            v-if="order._canEdit"
            class="text-danger"
            title="Zmazať"
            @click.stop="removeDocumentModal(item.id, item.name, row)"
          >
            <i class="icon-delete" />
          </a>
          <a
            v-if="order._canEdit"
            class="text-primary"
            title="Upraviť"
            @click.stop="editDocument(item)"
          >
            <i class="icon-pencil" />
          </a>
          <a
            title="Stiahnuť všetky súbory"

            @click.stop="downloadDocument(item.id)"
          >
            <i class="icon-download" />
          </a>
        </template>
        <template #item-detail="{ item }">
          <div class="attachments-list row no-gutters">
            <!-- nested padding container for better resize animation -->
            <div>
              <div
                v-for="attachment in attachments[item.id]"
                :key="attachment.id"
                class="attachment row no-gutters align-items-center"
              >
                <div class="row no-gutters align-items-center">
                  <div class="file-icon">
                    <i
                      :class="fileClass(attachment)"
                      class="fa"
                    />
                  </div>
                  <a
                    class="icon row align-items-center no-gutters"
                    :href="globals.backendHost + attachment.link + '?token=' + globals.authToken"
                    target="_blank"
                  >
                    {{ attachment.name }}
                    <i class="icon-download ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-table>
    </div>
    <div
      v-if="Array.isArray(orderPrivateDocuments) && orderPrivateDocuments.length > 0"
      id="order-documents-private"
      class="card"
    >
      <div class="card-heading">
        <h4 class="card-title ">
          Privátne dokumenty
        </h4>
      </div>
      <v-table
        :columns="documentsColumns"
        :items="orderPrivateDocuments"
        :selected="selectedRows"
        item-key="id"
        class="light"
        @row-click="toggleRowDetail"
      >
        <template #cell(name)="{ _item, value }">
          <div class="row no-gutters align-items-center attachment-name">
            <i class="icon-doc-1 mr-2" />
            <span v-text="value" />
          </div>
        </template>
        <template #cell(type)="{ item }">
          {{ $format.formatDocumentType(item.type) }}
        </template>
        <template #cell(date)="{ item }">
          {{ $format.formatDate(item.createdAt) }}
        </template>
        <template #cell(updatedDate)="{ item }">
          {{ $format.formatDateTime(item.updatedAt) }}
        </template>
        <template #cell(actions)="{ item, row }">
          <a
            v-if="order._canEdit"
            class="text-danger"
            title="Zmazať"
            @click.stop="removeDocumentModal(item.id, item.name, row)"
          >
            <i class="icon-delete" />
          </a>
          <a
            v-if="order._canEdit"
            class="text-primary"
            title="Upraviť"
            @click.stop="editDocument(item)"
          >
            <i class="icon-pencil" />
          </a>
          <a
            title="Stiahnuť všetky súbory"

            @click.stop="downloadDocument(item.id)"
          >
            <i class="icon-download" />
          </a>
        </template>
        <template #item-detail="{ item }">
          <div class="attachments-list row no-gutters">
            <!-- nested padding container for better resize animation -->
            <div>
              <div
                v-for="attachment in attachments[item.id]"
                :key="attachment.id"
                class="attachment row no-gutters align-items-center"
              >
                <div class="row no-gutters align-items-center">
                  <div class="file-icon">
                    <i
                      :class="fileClass(attachment)"
                      class="fa"
                    />
                  </div>
                  <a
                    class="icon row align-items-center no-gutters"
                    :href="globals.backendHost + attachment.link + '?token=' + globals.authToken"
                    target="_blank"
                  >
                    {{ attachment.name }}
                    <i class="icon-download ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-table>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/ui/Table.vue'
import { useOrderStore } from '@/storePinia/orderStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'AdminDocuments',
  components: { VTable },
  data () {
    return {
      globals: useGlobalStore(),
      orderStore: useOrderStore(),
      authToken: localStorage.getItem('token'),
      selectedRows: [],
      attachments: {}
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    orderPublicDocuments () {
      return this.order.attachments.filter(a => a.access === 'free')
    },
    orderPrivateDocuments () {
      return this.order.attachments.filter(a => a.access === 'order-user')
    },
    documentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'date',
          label: 'Dátum'
        },
        {
          key: 'updatedDate',
          label: 'Dátum aktualizácie'
        },
        {
          key: 'actions',
          label: 'Akcie'
        }
      ]
    }
  },
  methods: {
    async toggleRowDetail (item) {
      if (!this.attachments[item.id]) {
        await this.fetchFiles(item.id)
      }
      this.selectedRows = this.selectedRows.includes(item.id)
        ? this.selectedRows.filter(i => i !== item.id)
        : [...this.selectedRows, item.id]
    },
    fileExt (file) {
      return this.$format.formatFileType(file.mime)
    },
    fileClass (file) {
      const fileExt = this.fileExt(file)
      return {
        'icon-pdf text-danger': fileExt === 'pdf',
        'icon-doc text-primary': fileExt === 'doc' || fileExt === 'docx' || fileExt === 'odt' || fileExt === 'rtf',
        'icon-excel text-success': fileExt === 'xls' || fileExt === 'xlsx' || fileExt === 'ods',
        'icon-archive': fileExt === 'zip' || fileExt === 'rar',
        'icon-doc-1': fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif'
      }
    },
    async fetchFiles (id) {
      const fetchParams = {
        parent: id,
        limit: 1000
      }
      const { data } = await this.$http.post('/file/list', fetchParams)
      this.attachments[id] = data.files
    },
    showLoading () {
      $('#order-documents .card-block').LoadingOverlay('show')
    },
    hideLoading () {
      $('#order-documents .card-block').LoadingOverlay('hide')
    },
    editDocument (item) {
      this.$router.push({ name: `${this.orderStore.routePrefix}admin-documents-edit`, params: { id: item.id } })
    },
    removeDocumentModal (id, name, index) {
      this.YesNoAlert({
        title: 'Skutočne chcete zmazať dokument?',
        text: 'Dokument ' + name + ' sa po zmazaní už nebude dať obnoviť.',
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Zmazať'
      }).then((result) => {
        if (result.value) {
          this.removeDocument(id, index)
        }
      })
    },
    removeDocument (id, index) {
      this.showLoading()
      var data = {
        order: {
          id: this.$route.params.orderId
        },
        attachment: {
          id: id
        }
      }
      var vm = this
      this.$http.post('/order/attachment/remove', data).then(function () {
        vm.order.attachments.splice(index, 1)
        vm.hideLoading()
        setTimeout(() => {
          vm.OkAlert({ text: '' })
        }, 500)
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(() => {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }, 500)
      })
    },
    async downloadDocument (id) {
      this.ShowLoading({ title: 'Pripravujem súbory na stiahnutie' })
      var data = {
        file: {
          id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    },
    async downloadDocuments () {
      this.ShowLoading({ title: 'Pripravujem dokumenty na stiahnutie' })
      var data = {
        order: {
          id: this.order.id
        }
      }
      try {
        let { data: { downloadToken } } = await this.$http.post('/file/generatezip', data)
        this.CloseLoading()
        this.YesNoAlert({
          title: 'Prílohy sú pripravené na stiahnutie',
          type: 'info',
          cancelButtonText: 'Zrušiť',
          confirmButtonText: 'Stiahnuť'
        }).then((result) => {
          if (result.value) {
            window.open(`${this.globals.backendUrl}/file/zip?download=${downloadToken}&token=${this.globals.authToken}`)
          }
        })
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri sťahovaní nastala chyba' }, error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.attachments-list {
  background-color: rgba(62, 151, 239, 0.05); // transparent bg to keep shadow under it visible
  border-top: 1px solid #E0E0E0;
}
.attachment {
  margin-left: 36px;
}
.attachment-name {
  cursor: pointer;
  color: #3e97ef;
  font-weight: bold;
}
</style>
