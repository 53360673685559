import { defineStore } from 'pinia'
import { TenderiaHttp as http } from '@/services/TenderiaHttp.js'
import { useGlobalStore } from '@/storePinia/globalStore'

const initialUploadStatus = () => ({
  currentIndex: '',
  currentName: '',
  currentPercent: '',
})

export const useStatsStore = defineStore('stats', {
  state: () => ({
    currentTime: 0,
    newMessages: 0,
    messagesInterval: null,
    uploadStatus: initialUploadStatus(),
    premiumPackage: null
  }),
  getters: {
    premiumActive: (state) => { return state.premiumPackage?.state?.valid === true },
    premiumUserEmail: (state) => { return state.premiumPackage?.state?.email || '' }
  },
  actions: {
    setMessagesInterval() {
      this.messagesInterval = window.setInterval(() => {
        this.setNewMessages()
      }, 60000)
    },
    clearMessagesInterval() {
      window.clearInterval(this.messagesInterval)
      this.messagesInterval = null
    },
    setCurrentTime(time) {
      this.currentTime = time
    },
    async setNewMessages() {
      console.log('setNewMessages')
      const response = await http.post('/message/count', { mode: 'inbox-order' })
      this.newMessages = response.data.unreadMessages
    },
    setUploadStatus (uploadStatus) {
      this.uploadStatus = uploadStatus
    },
    resetPremiumPackageState()
    {
      this.premiumPackage = null
    },
    async updatePremiumPackageState()
    {
      const globals = useGlobalStore()
      if (globals?.user?.id)
      {
        this.premiumPackage = await http.post('/organization/package/state', {}).then(result => result?.data || {}).catch(console.error)
      }
      else
      {
        this.premiumPackage = null
      }
    }
  }
})
