<template>
  <div class="homepage-layout layout free-order error-page dark">
    <app-login-header />
    <!-- Page Container START -->
    <div class="page-container">
      <div class="main-content">
        <template v-if="error.statusCode == '403'">
          <span class="error code-status">
            403
          </span>
          <h1>Prístup zamietnutý</h1>
          <p class="mb-4">
            Ospravedlňujeme sa, ale do tejto časti nemáte prístup.
          </p>
          <router-link
            class="btn btn-success"
            color="green"
            to="/"
          >
            Naspäť na hlavnú stránku
          </router-link>
        </template>
        <template v-else-if="error.statusCode < '403' || error.statusCode >= '404' && error.statusCode < '500'">
          <span class="code-status">
            404
          </span>
          <h1>Stránka nenájdená</h1>
          <p class="mb-4">
            Ospravedlňujeme sa, ale stránka, ktorú hľadáte, neexistuje alebo bola zmazaná.
          </p>
          <router-link
            class="btn btn-success"
            color="green"
            to="/"
          >
            Naspäť na hlavnú stránku
          </router-link>
        </template>
        <template v-else-if="error.statusCode === '500'">
          <span class="error my-4">
            500
          </span>
          <h1>Server error</h1>
          <p class="mb-4">
            Ospravedlňujeme sa, ale došlo k neočakávanej chybe.
          </p>
          <router-link
            class="btn btn-success"
            color="green"
            to="/"
          >
            Naspäť na hlavnú stránku
          </router-link>
        </template>
      </div>
    </div>
    <footer class="content-footer">
      <app-footer />
    </footer>
  </div>
  <!-- Page Container END -->
</template>

<script>
import AppLoginHeader from '@/components/AppLoginHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
export default {
  name: 'ErrorPage',
  components: { AppLoginHeader, AppFooter },
  data () {
    return {
      error: {
        statusCode: this.$route.params.code || 404
      }
    }
  },
  methods: {
    refresh () {
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.error-page {
  text-align: center;
  .page-container {
    .main-content {
      align-items: center;
    }
  }
  &.dark {
    background-image: url('../assets/wave-bg.svg');
    background-position: left top -50px;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .code-status {
    font-size: 180px;
    line-height: 210px;
    font-weight: bold;
    color: var(--color-primary);
    text-shadow: 0px 24px 54px rgba(var(--color-primary-rgb), 0.3);
  }
  .error {
    font-size: 180px;
    line-height: 210px;
    font-weight: bold;
    color: var(--color-red);
    text-shadow: 0px 24px 54px rgba(var(--color-red-rgb), 0.3);
  }
  h1 {
    font-weight: bold;
    color: #fff;
    font-size: 32px;
  }
  p {
    font-size: 20px;
    width: 100%;
    color: #fff;
  }
}
</style>
