<template>
  <div>
    <order-settings-log-detail :row="row" />
    <div class="row">
      <div class="col-md-4 control-label">
        Názov kritéria:
      </div>
      <div class="col">
        {{ row.data.criterion.name }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Typ:
      </div>
      <div class="col">
        {{ $format.formatCriterionType(row.data.criterion.type) }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Povaha:
      </div>
      <div class="col">
        {{ $format.formatNature(row.data.criterion.nature) }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Minimálna cena:
      </div>
      <div class="col">
        {{ row.data.criterion.minimalValue === Number.MIN_SAFE_INTEGER ? "-&infin;" : formatNumber(row.data.criterion.minimalValue, row.data.criterion.decimalCount) }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Maximálna cena:
      </div>
      <div class="col">
        {{ row.data.criterion.maximalValue === Number.MAX_SAFE_INTEGER ? "&infin;" : formatNumber(row.data.criterion.maximalValue, row.data.criterion.decimalCount) }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Maximálny počet bodov:
      </div>
      <div class="col">
        {{ row.data.criterion.maximumPoints }}
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close()"
      >
        <i class="icon-close" /> Zavrieť
      </button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
var sFormatNumber = require('simple-format-number')
export default {
  name: 'OrderSettingsLogOrderCriteria',
  components: { OrderSettingsLogDetail },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    },
    formatNumber: function (value, decimalCount) {
      return sFormatNumber(parseFloat(value), { fractionDigits: decimalCount, symbols: { decimal: ',', grouping: ' ' } })
    }
  }
}
</script>

<style>

</style>
