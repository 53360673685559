<script setup>

import { ref, computed, onMounted, onUnmounted } from 'vue'
import Multiselect from 'vue-multiselect'
import { TenderiaHttp as $http } from '@/services/TenderiaHttp'
import Alerts from '@/components/mixins/Alerts'
import { useOrderStore } from '@/storePinia/orderStore'

const props = defineProps({ mode: String, text: String, orderId: String, noticeId: String })
const $emit = defineEmits(['vuedals:close'])
const orderStore = useOrderStore()
const isClosed = ref(false)
const isError = ref(false)
const showWarnings = ref(false)
const isProgress = ref(false)
const progressJob = ref(null)
const messageText = ref('Prosím čakajte...')
const progressValue = ref(0)
const progressTimeout = ref(null)
const eformsData = ref(null)
const eformsResult = ref(null)
const selectedEformsOrder = ref(null)

const order = computed(() => { return orderStore.order })
const isEditable = computed(() => (['RP', ''].includes(eformsNotice.value?.[props.noticeId]?.status || '')))
const warningList = computed(() => {
  const list = []
  for (const w of eformsNoticeWarnings.value?.[props.noticeId] || [])
  {
    const short = w.message.replace(/"/g, '').replace('is an invalid value', 'má nesprávnu hodnotu')
    list.push({ text: short, code: w.field })
  }
  return list
})

const eformsNotice = computed(() =>
{
  return eformsData.value?.eformsData?.notices || {}
})

const eformsNoticeWarnings = computed(() =>
{
  return eformsData.value?.eformsData?.noticeErrors || {}
})

const suitableOrders = computed(() =>
{
  return eformsResult.value?.orders || []
})

const loadEformsDbData = async function(silent = false)
{
  const params = { order: props.orderId, service: 'eforms', action: 'eforms-load-db' }
  isError.value = false
  if (!silent)
  {
    isProgress.value = true
    progressValue.value = 0
  }
  const { data } = await $http.post('/order/service/execute', params)
  isProgress.value = false
  eformsData.value = data || {}
}

const executeAction = async function (act, param)
{
  isError.value = false
  try
  {
    const params = { order: props.orderId, action: act, parameter: param }
    const { data } = await $http.post('/order/service/execute', params)
    progressJob.value = data.jobId
    if (progressJob.value && !isClosed.value)
    { // background job
      startCheckingProgress()
    }
  }
  catch (error)
  {
    showError('Nepodarilo sa načítať dáta zo systému eForms...')
  }
}

const startCheckingProgress = function ()
{
  isProgress.value = true
  checkJobProgress()
}

const checkJobProgress = async function ()
{
  try
  {
    const params = { order: props.orderId, job: progressJob.value }
    const { data } = await $http.post('/order/service/status', params)
    const isRunning = data.running
    const progress = data.progress
    const details = data.details
    eformsResult.value = data.result || null
    progressValue.value = progress
    messageText.value = details
    if (progress === -1) showError(details)
    if (isRunning) progressTimeout.value = setTimeout(() => { checkJobProgress() }, 2500)
    else await stopCheckingProgress()
  }
  catch (error)
  {
    showError('Nepodarilo sa načítať dáta zo systému eForms')
  }
}

const stopCheckingProgress = async function ()
{
  isProgress.value = false
  progressValue.value = 0
  await loadEformsDbData(true)
}

const showError = function(err)
{
  isError.value = true
  messageText.value = err
  progressValue.value = -1
}

const closeModal = function()
{
  $emit('vuedals:close', null)
}

const pairOrders = function()
{
  Alerts.methods.YesNoAlert({ title: '', text: 'Ste si istý že prepájate identickú zákazku v oboch systémoch?', type: 'warning', cancelButtonText: 'Nie', confirmButtonText: 'Áno', focusCancel: true })
    .then(async (result) =>
    {
      if (result.value && selectedEformsOrder.value?.id)
      {
        await executeAction('eforms-pair-order', selectedEformsOrder.value?.id)
      }
      else closeModal()
    })

}

onMounted(async () =>
{
  showWarnings.value = false
  isClosed.value = false
  if (props.mode === 'notice')        await executeAction('eforms-check-notice', props.noticeId)
  else if (props.mode === 'order')    await executeAction('eforms-pair-order')
  else if (props.mode === 'message')  messageText.value = props.text
})

onUnmounted(async () =>
{
  isClosed.value = true
  if (progressTimeout.value)
  {
    clearTimeout(progressTimeout.value)
    isProgress.value = false
    progressValue.value = 0
  }
})

</script>

<template>
  <div>
    <!-- loading -->
    <div v-if="isProgress">
      <div class="card-block my-5">
        <div class="column">
          <div class="col-md-12 text-center">
            <div>{{ messageText }}</div>
            <div class="progress" style="height: 8px;">
              <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" :style="{ width: progressValue + '%' }" :aria-valuenow="progressValue" aria-valuemin="0" aria-valuemax="100" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- error -->
    <div v-else-if="isError">
      <div class="card-block my-5">
        <div class="column">
          <div class="col-md-12 text-center">
            <!--<div><i class="icon-warning" style="font-size: 4rem; color: #500"></i></div>-->
            <h4 style="color: #300;">
              Chyba pri spracovaní požiadavky
            </h4>
            <div style="color: #500">
              {{ messageText }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- eforms notice data from db -->
    <div v-else-if="props.mode === 'notice' && eformsNotice?.[props.noticeId]?.link">
      <div class="card-body">
        <div class="text-semibold font-size-16 text-center">{{ eformsNotice?.[props.noticeId]?.name }}</div>
        <p class="text-center">{{ eformsNotice?.[props.noticeId]?.statusText || '' }}</p>
        <div class="d-flex justify-content-center">
          <div class="btn-group" role="group" aria-label="Button group">
            <button v-if="isEditable" type="button" class="btn btn-primary mx-1" @click="executeAction('eforms-fill-notice', props.noticeId)">
              <i class="icon-doc" />Vyplniť automaticky v eForms
            </button>
            <a :href="eformsNotice?.[props.noticeId]?.link" target="_blank" class="btn btn-secondary mx-1">
              <i class="icon-pencil" /> Otvoriť formulár v eForms
            </a>
          </div>
        </div>
        <div>
          <button v-if="isEditable && warningList.length" type="button" class="btn btn-link btn-block mx-1 text-danger" style="font-weight: normal;" @click="showWarnings = !showWarnings">
            {{ showWarnings ? 'Skryť nesprávne vyplnené polia v eForms' : 'Zobraziť nesprávne vyplnené polia vo formulári' }} <span v-if="warningList.length" class="mx-2 badge badge-danger">{{ warningList.length }}</span>
          </button>
        </div>

        <div v-show="isEditable && showWarnings">
          <ol class="text-left" style="list-style-type: decimal; font-size: small; color: #500; transition: height 0.5s ease-out;">
            <li v-for="(warning, index) in warningList" :key="`err-${index}`" :title="warning.code">
              {{ warning.text }}
            </li>
          </ol>
        </div>
      </div>
    </div>

    <!-- eforms order pairing -->
    <div v-else-if="props.mode === 'order'">
      <div class="card-body">
        <label class="control-label">Výber eForms zákazky na prepojenie s tenderia zákazkou </label>
        <multiselect
          v-model="selectedEformsOrder"
          :options="suitableOrders"
          track-by="name"
          label="name"
          openDirection="bottom"
          :clear-on-select="false"
          :close-on-select="true"
          :searchable="true"
          placeholder=""
          :show-labels="false"
          :allow-empty="false"
          :preselect-first="true"
          :show-no-results="false"
          noResult="Žiadna zhoda"
          noOptions="Žiadne zákazky na výber"
        >
          <template slot="option" slot-scope="{ option }">
            <div class="text-semibold">{{ option.name }}</div>
            <small>{{ new Date(option.stamp).toLocaleDateString() }} <span>({{ option.stateText }})</span> </small>
          </template>
          <template slot="singleLabel" slot-scope="{ option }">
            <div class="text-semibold">{{ option.name }}</div>
            <small>{{ new Date(option.stamp).toLocaleDateString() }} <span>({{ option.stateText }})</span> </small>
          </template>
        </multiselect>

        <p class="text-center mt-4 mb-0">Zákazka v systéme eForms:</p>
        <p class="text-center m-0 text-semibold"><a :href="selectedEformsOrder?.link || '#'" target="_blank">{{ selectedEformsOrder?.name || '' }}</a></p>
        <p class="text-center m-0">bude prepojená na tenderia zákazku:</p>
        <p class="text-center m-0 text-semibold"><a :href="'/public/order/'+order?.id" target="_blank">{{ order?.name || '' }}</a></p>

        <div class="d-flex justify-content-center mt-4">
          <div class="btn-group" role="group" aria-label="Button group">
            <button type="button" class="btn btn-primary mx-1" @click="pairOrders">
              <i class="icon-check" />Prepojiť
            </button>
            <button type="button" class="btn btn-light mx-1" @click="closeModal">
              <i class="icon-close" />Zrušiť
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- message -->
    <div v-else-if="props.mode === 'message'">
      <div class="card-block my-5">
        <div class="column">
          <div class="col-md-12 text-center">
            <div>
              {{ messageText }}
            </div>
            <div class="d-flex justify-content-center mt-5">
              <button type="button" class="btn btn-primary mx-1" @click="closeModal">Zavrieť</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- fallback -->
    <div v-else>
      <div class="card-block my-5">
        <div class="column">
          <div class="col-md-12 text-center">
            <div class="spinner-border text-info" role="status"><span class="sr-only">Prosím čakajte...</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
