<script setup>

// premium services

import { ref, inject, computed, watch, onMounted, onUnmounted } from 'vue'
//import { TenderiaHttp as $http } from '@/services/TenderiaHttp'
import { useStatsStore } from '@/storePinia/statsStore'

const showPurchaseModal = inject('showPurchaseModal')
const statsStore = useStatsStore()

const waitTimer = ref(null)
const currentTime = ref(statsStore.currentTime)
const diffTime = ref(0)
const isAvailable = computed(() => statsStore.premiumPackage?.state)
const paidLabel = computed(() => statsStore.premiumPackage?.state?.title || 'Prémiové služby')
const paidLimit = computed(() =>
{
  const subscription = statsStore.premiumPackage?.state?.subscription || false
  if (statsStore.premiumPackage?.state?.validTo)
  {
    const diff = diffTime.value
    const date = new Date(statsStore.premiumPackage?.state?.validTo)
    if (diff > 0)
    {
      const renewText = subscription ? '(automatické predĺženie)' : ''
      const oneDay = 86400000
      const twoHour = 7200000
      if (diff < twoHour)
      {
        const minutes = Math.ceil(diff / (60 * 1000))
        return `Platnosť končí o ${minutes} min. ${renewText}`
      }
      else if (diff < oneDay)
      {
        const hours = Math.ceil(diff / (60 * 60 * 1000))
        return `Platnosť končí o ${hours} hod. ${renewText}`
      }
      else
      {
        return `Platnosť do: ${date.toLocaleDateString()} ${renewText}`
      }
    }
    else
    {
      return 'Platnosť skončila'
    }
  }
  else
  {
    return 'Aktívne'
  }
})

watch(paidLabel, (_value, _old) =>
{
  timeUpdate()
})

const timeUpdate = async function()
{
  if (statsStore.premiumPackage?.state?.validTo)
  {
    if (waitTimer.value)  clearTimeout(waitTimer.value)
    waitTimer.value = setTimeout(timeUpdate, 60000)
    currentTime.value = statsStore.currentTime
    const now = new Date(currentTime.value)
    const date = new Date(statsStore.premiumPackage?.state?.validTo)
    const diff = date - now
    diffTime.value = diff
    if (diff < 0) await statsStore.updatePremiumPackageState()
  }
  else
  {
    diffTime.value = 0
  }
}

const openPurchaseModal = async function()
{
  const isActive = statsStore.premiumPackage?.state?.valid
  const text = isActive ? 'Predĺženie platnosti na ďalšie obdobie' : ''
  showPurchaseModal({ title: 'Prémiové služby', text: text, onDismiss: async () => { await statsStore.updatePremiumPackageState() } })
}

const openCancelModal = async function()
{
  showPurchaseModal({ title: 'Zrušiť prémiové služby', mode: 'cancel', onDismiss: async () => { await statsStore.updatePremiumPackageState() } })
}

onMounted(async () =>
{
  await statsStore.updatePremiumPackageState()
  timeUpdate()
})

onUnmounted(async () =>
{
  if (waitTimer.value)  clearTimeout(waitTimer.value)
})

</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-heading">
          <h4 class="card-title ">Stav prémiových služieb</h4>
          <div v-if="isAvailable" class="actions">
            <div v-if="!statsStore.premiumPackage?.state?.valid" class="text-right">
              <span class="text-bold text-warning">BEZ PRÉMIOVÝCH SLUŽIEB</span>
              <br>
              <span class="text-white">(Len základné funkcie)</span>
            </div>
            <div v-else>
              <div class="text-white text-bold text-right">{{ paidLabel }}<br><span class="text-semibold text-success">{{ paidLimit }}</span></div>
            </div>
          </div>
        </div>

        <div v-if="isAvailable">
          <div class="pricing_block bg-light">
            <div class="container pb-4">
              <div class="row justify-content-center">
                <div class="col-md-8 text-center">
                  <h6 class="subtitle font-weight-normal"></h6>
                </div>
              </div>
              <!-- row  -->
              <div class="row mt-2">
                <!-- column  -->
                <div class="col-lg-6 d-flex align-items-stretch">
                  <div class="flex-fill card card-shadow mb-4" :style="{border: !statsStore.premiumActive ? '2px solid #d1ceac' : 'none'}">
                    <div class="card-body p-0">
                      <div class="d-flex align-items-center">
                        <h5 class="w-100 m-0 font-weight-medium p-3 d-flex justify-content-between align-items-center" style="background: #f9f6d2; padding: 6px 16px; color: #a89400;">
                          ZÁKLADNÉ FUNKCIE
                          <span v-if="!statsStore.premiumPackage?.state?.valid" class="badge badge-warning mx-2">Zdarma</span>
                        </h5>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 align-self-center">
                          <div class="list-inline font-14 font-weight-medium text-dark">
                            <button class="py-2 d-flex justify-content-between align-items-center list-group-item list-group-item-action no-border" v-for="(item,index) in statsStore.premiumPackage?.state?.free || []" :key="index">
                              <div class="align-self-start pt-1">
                                <i class="icon-close text-secondary m-2" style="opacity: 0.5"></i>
                              </div>
                              <div class="flex-grow-1" :style="{opacity: item.active ? 1 : 0.9}">
                                <span>{{ item.title }}</span>
                                <br>
                                <span class="font-weight-light">{{ item.value }}</span>
                              </div>
                              <!--<div>
                                <span v-if="item.active" class="badge badge-secondary badge-pill">Aktívne</span>
                              </div>-->
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- column  -->
                <!-- column  -->
                <div class="col-lg-6 d-flex align-items-stretch">
                  <div class="flex-fill card card-shadow mb-4" :style="{border: statsStore.premiumActive ? '2px solid #64cbc1' : 'none'}">
                    <div class="card-body p-0">
                      <div class="d-flex align-items-center">
                        <h5 class="w-100 font-medium p-3 d-flex justify-content-between align-items-center" style="background: #e8fce8; padding: 6px 16px; color: #64bd64;">
                          PRÉMIOVÉ FUNKCIE
                          <span v-if="statsStore.premiumPackage?.state?.valid" class="badge badge-success mx-2">Aktívne</span>
                        </h5>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 align-self-center">
                          <div class="list-inline font-14 font-weight-medium text-dark">
                            <button class="py-2 d-flex justify-content-between align-items-center list-group-item list-group-item-action no-border" v-for="(item,index) in statsStore.premiumPackage?.state?.paid || []" :key="index">
                              <div class="align-self-start pt-1">
                                <i v-if="item.active" class="icon-check text-success m-2"></i>
                                <i v-else class="icon-check text-secondary m-2" style="opacity: 0.3"></i>
                              </div>
                              <div class="flex-grow-1" :style="{opacity: item.active ? 1 : 0.5}">
                                <span>{{ item.title }}</span>
                                <br>
                                <span class="font-weight-light">{{ item.value }}</span>
                              </div>
                              <!--<div>
                                <span v-if="item.active" class="badge badge-success badge-pill">Aktívne</span>
                              </div>-->
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- column  -->
              </div>
              <div class="row" v-if="statsStore.premiumPackage?.state?.valid && statsStore.premiumPackage?.state?.subscription === true">
                <div class="col-md-12 d-flex justify-content-center">
                  <button type="button" class="btn btn-lg btn-danger" style="min-width: 0" @click="openCancelModal">ZRUŠIŤ PRÉMIOVÉ SLUŽBY</button>
                </div>
              </div>
              <div class="row" v-else-if="statsStore.premiumPackage?.state?.validTo">
                <div class="col-md-12 d-flex justify-content-center">
                  <button type="button" class="btn btn-lg btn-success" style="min-width: 0" @click="openPurchaseModal">OBNOVIŤ PRÉMIOVÉ SLUŽBY</button>
                </div>
              </div>
              <div class="row" v-else-if="statsStore.premiumPackage?.state?.valid === false">
                <div class="col-md-12 d-flex justify-content-center">
                  <button type="button" class="btn btn-lg btn-success" style="min-width: 0" @click="openPurchaseModal">OBJEDNAŤ PRÉMIOVÉ SLUŽBY</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="card-block my-5">
            <div class="column">
              <div v-if="isAvailable" class="col-md-12 text-center">
                <div class="spinner-border text-info" role="status">
                  <span class="sr-only">Prosím čakajte...</span>
                </div>
              </div>
              <div v-else class="col-md-12 text-center">
                <h5 style="color: #300;">
                  Stav prémiových služieb nie je aktuálne dostupný
                </h5>
                <div style="color: #500">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pricing_block {
  color: #8d97ad;
  font-weight: 500;
}

.pricing_block h1,
.pricing_block h2,
.pricing_block h3,
.pricing_block h4,
.pricing_block h5,
.pricing_block h6 {
  color: #3e4555;
}

.pricing_block .font-weight-medium {
  font-weight: 500;
}

.pricing_block .bg-light {
  background-color: #f4f8fa !important;
}

.pricing_block h5 {
    line-height: 22px;
    font-size: 18px;
}

.pricing_block .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.pricing_block .card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.pricing_block .price-box sup {
  top: -20px;
  font-size: 16px;
}

.pricing_block .price-box .display-5 {
  line-height: 58px;
  font-size: 3rem;
}

.pricing_block .btn-info-gradiant {
		background: #188ef4;
    background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.pricing_block .btn-info-gradiant:hover {
		background: #316ce8;
    background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.pricing_block .btn-md {
    padding: 15px 45px;
    font-size: 16px;
}

.pricing_block .text-info {
    color: #188ef4 !important;
}

.pricing_block .badge-danger {
    background-color: #ff4d7e;
}

.pricing_block .font-14 {
	font-size: 14px;
}

</style>
