<template>
  <multiselect
    v-model="selectedDepartments"
    :options="departments"
    track-by="id"
    label="name"
    openDirection="bottom"
    :clear-on-select="false"
    :close-on-select="false"
    :searchable="true"
    :show-labels="false"
    :multiple="true"
    :loading="loading"
    placeholder="Vyberte si organizačné jednotky"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'DepartmentSelect',
  components: { Multiselect },
  props: {
    value: Array
  },
  data () {
    return {
      globals: useGlobalStore(),
      departments: [],
      loading: false
    }
  },
  computed: {
    fetchParams () {
      return {
        id: this.globals.userOrganization.id
      }
    },
    selectedDepartments: {
      get() {
        return this.departments.filter(department => this.value.includes(department.id))
      },
      set(val) {
        this.$emit('input', val.map(department => department.id))
      }
    }
  },
  async mounted () {
    this.loading = true
    let { data } = await this.$http.post('/orgunit/list', this.fetchParams)
    this.departments = data.units
    this.loading = false
  }
}
</script>
