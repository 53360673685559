<template>
  <div>
    <h1>
      <span v-if="offerOrder.parent && offerOrder.part && offerOrder.subtype !== 'vyz'">
        Ponuka pre časť č. {{ offerOrder.part }}
      </span>
    </h1>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Identifikácia dodávateľa
            </h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Uchádzač je členom skupiny dodávateľov, ktorá predkladá ponuku</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName ? 'Áno' : 'Nie' }}
                </div>
              </div>
              <div
                v-if="offer.applicantDetails.groupName"
                class="row"
              >
                <div class="col-md-5">
                  <b>Názov skupiny dodávateľov</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.groupName }}
                </div>
              </div>
              <div
                v-else
                class="row"
              >
                <div class="col-md-5">
                  <b>Obchodné meno alebo názov uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.businessName }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Sídlo alebo miesto podnikania uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.address }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IČO</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li
                      v-for="(ico, index) in offer.applicantDetails.ico"
                      :key="`ico-${index}`"
                    >
                      {{ ico }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Platca DPH</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatYesNo(offer.applicantDetails.vatPayer) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Štát</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.country }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Zoznam osôb oprávnených konať v mene uchádzača</b>
                </div>
                <div class="col-md-7">
                  <ul class="list-unstyled">
                    <li
                      v-for="(authorizedPerson, index) in offer.applicantDetails.authorizedPersons"
                      :key="`authorized-person-${index}`"
                    >
                      {{ authorizedPerson }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Meno a priezvisko kontaktnej osoby</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.contactPerson }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Telefón</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.phone }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Fax</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.fax }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Email</b>
                </div>
                <div class="col-md-7">
                  {{ offer.applicantDetails.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Systémové informácie
            </h4>
          </div>
          <div class="card-block">
            <div class="list-table">
              <div class="row">
                <div class="col-md-5">
                  <b>Dátum odoslania uchádzačom</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatDateTime(offer.sentAt) }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>IP adresa uchádzača</b>
                </div>
                <div class="col-md-7">
                  {{ offer.userIP }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <b>Stav ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ $format.formatOfferState(offer.state) }}
                </div>
              </div>
              <div
                v-if="offer.stage === 'excluded'"
                class="row"
              >
                <div class="col-md-5">
                  <b>Dôvod vylúčenia ponuky</b>
                </div>
                <div class="col-md-7">
                  {{ offer.decision }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(offerOrder.items) && offerOrder.items.length > 0"
      class="row"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Položky
            </h4>
          </div>
          <items-table
            v-if="ready"
            :order="offerOrder"
            :offer="offer"
            :decimal-count="decimalCount"
            :editable="false"
          />
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(criteria) && criteria.length > 0"
      class="row"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Návrh na plnenie kritérií
            </h4>
          </div>
          <div class="card-block">
            <div
              v-for="criterion in criteria"
              :key="criterion._id"
              class="row form-group"
            >
              <div class="col-md-9">
                <label
                  :for="criterion._id"
                  class="text-bold text-dark"
                >
                  {{ criterion.name }}
                </label>
              </div>
              <div class="col-md-2 text-dark">
                {{ formatNumber(criterion.value, criterion.decimalCount) }}
              </div>
              <div class="col-md-1 text-dark">
                {{ criterion.unit }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(criteria) && criteria.length > 0"
      class="row"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Prílohy kritérií
            </h4>
          </div>
          <v-table
            :items="offer.attachments2"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template #cell(name)="{ item }">
              <file-download-link :href="globals.backendHost + item.link + '?token=' + globals.authToken" :label="item.name" :file="item" />
              <!--<a
                :href="globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
                @click="(event) => handleDownload(event, item)"
              >
                {{ item.name }}
              </a>-->
            </template>
            <template #cell(type)="{ item }">
              {{ $format.formatFileType(item.mime) }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div
      v-if="offerOrder.procedureType === 'tste'"
      class="row"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Sprievodný text ponuky
            </h4>
          </div>
          <div class="card-block">
            <div class="row">
              <div
                v-if="offer.body"
                class="col-md-12"
              >
                {{ offer.body }}
              </div>
              <p
                v-else
                class="text-dark text-center pdd-vertical-25"
              >
                Sprievodný text ponuky uchádzač nevyplnil.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="offerOrder.procedureType === 'tste'"
      class="row"
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-heading">
            <h4 class="card-title">
              Prílohy ponuky
            </h4>
          </div>
          <v-table
            :items="offer.attachments"
            :columns="attachmentsColumns"
            item-key="id"
            class="light"
          >
            <template #cell(name)="{ item }">
              <file-download-link :href="globals.backendHost + item.link + '?token=' + globals.authToken" :label="item.name" :file="item" />
              <!--<a
                :href="globals.backendHost + item.link + '?token=' + authToken"
                target="_blank"
              >
                {{ item.name }}
              </a>-->
            </template>
            <template #cell(type)="{ item }">
              {{ $format.formatFileType(item.mime) }}
            </template>
          </v-table>
        </div>
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-blue"
        @click="goBack"
      >
        <i class="icon-arrow-left" /> Späť
      </button>
      <button
        v-if="canSendOffer"
        class="btn btn-success"
        @click="sendOffer()"
      >
        <i class="icon-send" /> <span>Odoslať ponuku</span>
      </button>
      <button
        v-if="canCreateOffer && !canRevokeOffer"
        :disabled="!canCreateOffer"
        class="btn btn-tenderia-grey-blue"
        @click="addOffer()"
      >
        <i class="icon-pencil" /> Upraviť ponuku
      </button>
    </div>
  </div>
</template>

<script>
import ItemsTable from '@/components/order/common/components/ItemsTable.vue'
import BaseUserOfferView from '@/components/order/common/components/BaseUserOfferView.vue'
import VTable from '@/components/ui/Table.vue'
import FileDownloadLink from '@/components/utils/FileDownloadLink.vue'
import { useStatsStore } from '@/storePinia/statsStore'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserOfferView',
  components: { ItemsTable, VTable, FileDownloadLink },
  inject: ['showPurchaseModal'],
  extends: BaseUserOfferView,
  data () {
    return {
      offerOrder: {},
      globals: useGlobalStore(),
      statsStore: useStatsStore()
    }
  },
  computed: {
    attachmentsColumns () {
      return [
        {
          key: 'name',
          label: 'Názov'
        },
        {
          key: 'mime',
          label: 'Typ'
        }
      ]
    }
  },
  async created ()
  {
    if (!this.order.id) {
      return
    }
    if (this.$route.params.partId) {
      for (let i = 0; i < this.order._parts.length; i++) {
        if (this.order._parts[i].id === this.$route.params.partId) {
          Object.assign(this.offerOrder, this.order._parts[i])
          break
        }
      }
      console.log(JSON.stringify(this.offerOrder))
    } else {
      Object.assign(this.offerOrder, this.order)
    }
    try {
      let { data: { offer } } = await this.$http.post('/offer/get', { offer: { order: this.offerOrder.id } })
      this.offer = offer
      this.bidsLimitTo = this.offerOrder.bidsLimitTo ? new Date(this.offerOrder.bidsLimitTo) : false
      this.prepareCriteria()
      this.ready = true
    } catch (error) {
      this.ErrorAlert({ text: 'Pri načítavaní ponuky nastala chyba.' }, error)
    }
  },
  methods:
  {
    /*handleDownload (event, file)
    {
      const premium = this.statsStore.premiumActive
      const size = file?.size || 0
      const limit = 2*1024*1024
      if (size >= limit && premium === false)
      { // show modal with premium services options
        this.showPurchaseModal({ text: 'Pre sťahovanie väčších súborov zvážte zakúpenie prémiových služieb s výrazne vyššou rýchlosťou sťahovania súborov' })
      }
    }*/
  }
}
</script>
