<template>
  <div
    class="state-badge order-state"
    :class="[stateClass, display !== 'text' ? 'icon-only' : '']"
  >
    <i
      :class="stateIconClass"
      class="font-size-18"
    ></i><span v-if="display === 'text'">
      {{ $format.orderState(state) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'OrderState',
  props: ['state', 'display'],
  computed: {
    stateClass: function () {
      var classes = {
        planned: {
          'planned': true
        },
        active: {
          'active': true
        },
        finished: {
          'finished': true
        },
        cancelled: {
          'canceled': true
        }
      }
      return classes[this.state]
    },
    stateIconClass: function () {
      var classes = {
        planned: {
          'icon-pending': true
        },
        active: {
          'icon-active': true
        },
        finished: {
          'icon-check': true
        },
        cancelled: {
          'icon-ended': true
        }
      }
      return classes[this.state]
    }
  },
}
</script>
