<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-heading">
        <h4 class="card-title">
          Používatelia
        </h4>
        <div class="actions">
          <router-link
            class="btn btn-tenderia-blue"
            to="/app/settings/accounts/add"
          >
            <i class="icon-add font-size-14"></i> Pridať používateľa
          </router-link>
        </div>
      </div>
      <v-table
        :items="users.data"
        :columns="usersColumns"
        :sort.sync="tableSort"
        item-key="id"
        class="light"
      >
        <template #cell(state)="{ item }">
          <v-badge
            class="m-0"
            :color="formatStates(item.state).color"
            glow
          >
            {{ formatStates(item.state).text }}
          </v-badge>
        </template>
        <template #cell(roles)="{ item }">
          {{ item.roles.join(', ') }}
        </template>
        <template #cell(actions)="{ item }">
          <template v-if="item.id !== globals.user.id">
            <router-link
              v-if="item.state !== 'registered' && item.state !== 'deleted'"
              :to="'/app/settings/accounts/edit/' + item.id"

              title="Upraviť používateľa"
            >
              <i class="icon-pencil"></i>
            </router-link>
            <a
              v-if="item.homeOrg === globals.userOrganization.id && item.state !== 'banned' && item.state !== 'registered' && item.state !== 'deleted'"
              @click.stop="removeUserModal(item, 'ban')"
              class="text-danger"
              title="Obmedziť prístup"
            >
              <i class="icon-ban-user"></i>
            </a>
            <a
              v-if="item.homeOrg === globals.userOrganization.id && item.state === 'banned' && item.state !== 'registered' && item.state !== 'deleted'"
              @click.stop="removeUserModal(item, 'unban')"
              class="text-success"
              title="Obnoviť prístup"
            >
              <i class="icon-unban-user"></i>
            </a>
            <a
              v-if="item.homeOrg === globals.userOrganization.id && item.state !== 'deleted'"
              @click.stop="removeUserModal(item, 'delete')"
              class="text-danger"
              title="Zmazať"
            >
              <i class="icon-delete"></i>
            </a>
            <a
              v-if="item.homeOrg !== globals.userOrganization.id"
              @click.stop="removeUserModal(item, 'organizaion')"
              class="text-danger"
              title="Odstrániť z organizácie"
            >
              <i class="icon-close"></i>
            </a>
          </template>
        </template>
      </v-table>
      <div class="card-footer">
        <v-pagination
          v-if="users.count"
          :page="page"
          :per-page="perPage"
          :per-page-options="pageSizeOptions"
          :total-elements="users.count"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VTable from '@/components/ui/Table.vue'
import VPagination from '@/components/ui/Pagination.vue'
import VBadge from '@/components/ui/Badge.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'UserSettingsAccounts',
  components: { VTable, VPagination, VBadge },
  inject: ['blockNonPremiumUser'],
  data: function () {
    return {
      globals: useGlobalStore(),
      users: {},
      tableSort: {
        sortBy: 'createdAt',
        sort: 'desc'
      }
    }
  },
  computed: {
    pageSizeOptions () {
      return [5, 10, 25, 50]
    },
    page () {
      return parseInt(this.$route.query.page) || 1
    },
    perPage () {
      const perPage = parseInt(this.$route.query.size)
      const valid = perPage && this.pageSizeOptions.includes(perPage)
      return valid ? perPage : 10
    },
    usersColumns () {
      return [
        {
          key: 'firstname',
          label: 'Meno'/* ,
          sortable: true */
        },
        {
          key: 'lastname',
          label: 'Priezvisko'/* ,
          sortable: true */
        },
        {
          key: 'email',
          label: 'E-mail'/* ,
          sortable: true */
        },
        {
          key: 'state',
          label: 'Stav'/* ,
          sortable: true */
        },
        {
          key: 'roles',
          label: 'Role'
        },
        {
          key: 'actions',
          label: 'Akcie',
          header: {
            width: 130
          }
        }
      ]
    },
    fetchParams () {
      return {
        /* 'limit': this.perPage,
        'page': this.page,
        'offset': this.perPage * this.page - this.perPage,
        sort: this.$format.sortString(this.tableSort), */
        organization: {
          id: this.globals.userOrganization.id
        }
      }
    }
  },
  methods: {
    formatStates (state) {
      switch (state) {
      case 'active':
        return {
          text: 'Aktívny',
          color: 'green'
        }
      case 'banned':
        return {
          text: 'Obmedzený',
          color: 'red'
        }
      case 'registered':
        return {
          text: 'Registrovaný',
          color: 'orange'
        }
      case 'deleted':
        return {
          text: 'Vymazaný',
          color: 'grey'
        }
      default:
        return {
          text: 'n/a',
          color: 'grey'
        }
      }
    },
    async removeUserModal (user, action) {
      if (await this.blockNonPremiumUser() === true) return
      let title = ''
      let text = ''
      let callBack = null
      if (action === 'delete') {
        title = `Skutočne chcete zmazať používateľa ${user.firstname} ${user.lastname}`
        text = 'Používateľ bol zmazaný.'
        callBack = () => this.$http.post('/organization/user/remove', { user: user.id, action })
      } else if (action === 'ban') {
        title = `Skutočne chcete obmedziť prístup používateľa ${user.firstname} ${user.lastname}`
        text = 'Používateľovi bol obmedzený prístup.'
        callBack = () => this.$http.post('/organization/user/remove', { user: user.id, action })
      } else if (action === 'unban') {
        title = `Skutočne chcete obnoviť prístup používateľa ${user.firstname} ${user.lastname}`
        text = 'Používateľovi bol obnovený prístup.'
        callBack = () => this.$http.post('/organization/user/remove', { user: user.id, action })
      } else if (action === 'organization') {
        title = `Skutočne chcete odstrániť z organizácie používateľa ${user.firstname} ${user.lastname}`
        text = 'Používateľ bol odstránený z organizácie.'
        callBack = () => this.$http.post('/organization/user/remove', { user: user.id })
      } else {
        return
      }
      this.YesNoAlert({
        title,
        focusCancel: true,
        cancelButtonText: 'Zrušiť',
        confirmButtonText: 'Potvrdiť'
      }).then(async (result) => {
        if (result.value) {
          this.ShowLoading()
          try {
            await callBack()
            this.CloseLoading()
            this.OkAlert({ text })
            this.fetchUsers()
          } catch (error) {
            this.CloseLoading()
            this.ErrorAlert({ text: 'Pri spracovaní nastala chyba.' }, error)
          }
        }
      })
    },
    async fetchUsers () {
      const { data } = await this.$http.post('/organization/user/list', this.fetchParams)
      /* let users = []
      let currentUserId = this.globals.user.id
      data.users.forEach(function (user) {
        if (user.id !== currentUserId) {
          users.push(user)
        }
      }) */
      this.users = { data: data.users, count: data.total }
    }
  },
  mounted () {
    this.fetchUsers()
  },
  watch: {
    fetchParams () {
      this.fetchUsers()
    }
  }
}
</script>
