<template>
  <div>
    <order-settings-log-detail :row="row" />
    <div class="row">
      <div class="col-md-4 control-label">
        Meno:
      </div>
      <div class="col">
        {{ `${member.title1} ${member.firstname} ${member.lastname} ${member.title2}` }}
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close()"
      >
        <i class="icon-close" /> Zavrieť
      </button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOrderCommission',
  components: { OrderSettingsLogDetail },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      member: null
    }
  },
  created () {
    if (this.row.action === 'create') {
      this.member = this.row.data.member
    } else {
      let id = this.row.data.request.memberId
      this.member = this.row.data.order.commission.find(member => member._id === id)
    }
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    }
  }
}
</script>

<style>

</style>
