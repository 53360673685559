<template>
  <div>
    <div class="tenderia-submenu">
      <router-link :to="{ name: `${orderStore.routePrefix}admin-settings`, params: {orderId: $route.params.orderId}}">
        Nastavenia
      </router-link>
      <router-link
        v-if="order.divided"
        :to="{ name: `${orderStore.routePrefix}admin-parts`, params: $route.params}"
      >
        Časti
      </router-link>
      <router-link :to="{ name: `${orderStore.routePrefix}admin-documents`, params: $route.params}">
        Dokumenty
      </router-link>
      <router-link :to="{ name: `${orderStore.routePrefix}admin-items`, params: $route.params}">
        Položky
      </router-link>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from '@/storePinia/orderStore'
export default {
  name: 'AdminHome',
  data () {
    return {
      orderStore: useOrderStore(),
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
  },
}
</script>
