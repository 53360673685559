<template>
  <div class="container-fluid">
    <div class="email-app">
      <div class="sec-side-nav">
        <div class="side-nav-inner">
          <ul>
            <li>
              <a
                href=""
                :class="{'active': mode === 'inbox-order' }"
                @click.prevent="loadMessages('inbox-order')"
              >
                <i class="icon-delivered"></i><span>Prijatá</span>
              </a>
            </li>
            <li>
              <a
                href=""
                :class="{'active': mode === 'sent-order' }"
                @click.prevent="loadMessages('sent-order')"
              >
                <i class="icon-send"></i><span>Odoslaná</span>
              </a>
            </li>
            <li>
              <a
                href=""
                :class="{'active': mode === 'draft-order' }"
                @click.prevent="loadMessages('draft-order')"
              >
                <i class="icon-pencil"></i><span>Koncepty</span>
              </a>
            </li>
            <li>
              <a
                href=""
                :class="{'active': mode === 'junk-order' }"
                @click.prevent="loadMessages('junk-order')"
              >
                <i class="icon-delete"></i><span>Zmazaná</span>
              </a>
            </li>
            <div class="ml-auto">
              <slot name="append">
              </slot>
            </div>
          </ul>
        </div>
      </div>
      <div class="email-wrapper">
        <div class="email-list">
          <div
            class="email-list-tools"
            :class="mode"
          >
            <div>
              <div class="active-folder-name">
                {{ listHeader }}
              </div>
              <div class="email-list-search">
                <input
                  class="form-control"
                  type="text"
                  v-model="filter"
                  @input="onChangeEmailSearch"
                  placeholder="Hľadať správy"
                />
              </div>
            </div>
            <ul class="tools">
              <li class="mr-4 mr-md-2">
                <a
                  v-if="this.canEdit"
                  href=""
                  @click.prevent="setFlags(1)"
                  title="Označiť ako prečítané"
                >
                  <i class="icon-check"></i>
                </a>
              </li>
              <li>
                <a
                  v-if="this.canEdit && mode !== 'junk-order'"
                  href=""
                  @click.prevent="setFlags(8)"
                  title="Presunúť do koša"
                >
                  <i class="icon-delete text-danger"></i>
                </a>
              </li>
              <li>
                <a
                  v-if="this.canEdit && mode === 'junk-order'"
                  href=""
                  @click.prevent="clearFlags(8)"
                  title="Obnoviť"
                >
                  <i class="icon-synch text-success"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="email-list-wrapper scrollable">
            <div class="list-view-group-container">
              <ul class="email-list-item">
                <component
                  :is="itemComponent"
                  @select-message="selectedMessages.push(index)"
                  @unselect-message="selectedMessages.splice(selectedMessages.indexOf(index), 1)"
                  @item-clicked="setCurrentMessageIndex(index)"
                  v-for="(message, index) in messages"
                  :key="message.id"
                  :message="message"
                  :active="currentMessageId === message.id"
                >
                </component>
              </ul>
              <infinite-loading
                :identifier="infiniteId"
                @infinite="infiniteHandler"
                direction="bottom"
                spinner="waveDots"
              >
                <div
                  slot="no-more"
                  class="mt-3"
                >
                  Žiadne ďaľšie správy
                </div>
                <div
                  slot="no-results"
                  class="mt-3"
                >
                  Žiadne správy
                </div>
              </infinite-loading>
            </div>
          </div>
        </div>
        <component
          v-if="currentMessageId"
          :is="emailContentComponent"
          :currentMessage="currentMessage"
          :mode="mode"
          :can-edit="this.canEdit"
          @back-to-list="backToList()"
          @remove-message="removeMessage()"
          @undelete-message="undeleteMessage()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import EmailHelper from '../utils/EmailHelper.js'
import EmailContentOrder from './EmailContentOrder.vue'
import EmailContentDefault from './EmailContentDefault.vue'
import EmailContentLoader from './EmailContentLoader.vue'
import ItemReceived from './ItemReceived.vue'
import ItemSent from './ItemSent.vue'
import ItemJunk from './ItemJunk.vue'
import ItemDraft from './ItemDraft.vue'
import InfiniteLoading from 'vue-infinite-loading'
import debounce from 'lodash/debounce'
import { useGlobalStore } from '@/storePinia/globalStore'

export default {
  name: 'EmailClient',
  components: { EmailContentOrder, EmailContentDefault, EmailContentLoader, ItemReceived, ItemSent, ItemJunk, ItemDraft, InfiniteLoading },
  props: {
    order: Object
  },
  data () {
    return {
      globals: useGlobalStore(),
      emailContentComponent: null,
      messages: [],
      mode: 'inbox-order',
      page: 0,
      limit: 15,
      filter: '',
      infiniteId: +new Date(),
      itemComponent: 'ItemReceived',
      listHeader: 'Prijatá pošta',
      currentMessageId: '',
      currentMessageIndex: null,
      currentMessage: {},
      selectedMessages: [],
      itemComponents: {
        'inbox-order': 'ItemReceived',
        'sent-order': 'ItemSent',
        'junk-order': 'ItemJunk',
        'draft-order': 'ItemDraft'
      },
      listHeaders: {
        'inbox-order': 'Prijatá pošta',
        'sent-order': 'Odoslaná pošta',
        'draft-order': 'Koncepty',
        'junk-order': 'Zmazaná pošta'
      }
    }
  },
  computed: {
    canEdit () {
      return this.globals.user.type !== 'observer'
    }
  },
  created () {
    this.loadMessages(this.mode)
  },
  methods: {
    showLoading () {
      $('.email-list').LoadingOverlay('show')
    },
    hideLoading () {
      $('.email-list').LoadingOverlay('hide')
    },
    infiniteHandler ($state) {
      var vm = this
      if (this.$route.params.messageid) {
        this.limit = 1000
      }
      this.itemComponent = this.itemComponents[this.mode]
      this.listHeader = this.listHeaders[this.mode]
      var data = {
        limit: this.limit,
        offset: this.page * this.limit,
        mode: this.mode,
        filter: this.filter,
        sort: '-createdAt'
      }

      if (this.order && this.order.id) {
        data.order = this.order.id
      }

      this.$http.post('/message/list', data).then(function (response) {
        if (response.data.messages.length) {
          response.data.messages.forEach(function (message) {
            let emailHelper = new EmailHelper(message, vm.mode)
            let messageBuild = emailHelper.buildMetadata()

            message.from = messageBuild.from
            message.to = messageBuild.to.slice(0, -2)

            vm.messages.push(message)
          })

          $state.loaded()

          if (response.data.messages.length < vm.limit) {
            $state.complete()
          }
          if (window.innerWidth > 768) {
            if (vm.currentMessageIndex !== null && vm.messages.length > vm.currentMessageIndex) {
              vm.setCurrentMessageIndex(vm.currentMessageIndex)
            } else {
              vm.setCurrentMessageIndex(0)
            }
          }
        } else {
          $state.complete()
        }

        if (vm.$route.params.messageid) {
          let currentMessageIndex = vm.getMessageIndexById(vm.$route.params.messageid)
          if (currentMessageIndex) {
            vm.setCurrentMessageIndex(currentMessageIndex)
          }
        }
        vm.page += 1
      }).catch((error) => {
        if (error.response && error?.response?.status === 403) {
          $state.complete()
        } else {
          this.ErrorAlert({ text: 'Nepodarilo sa načítať ďalšie správy.' }, error)
        }
      })
    },
    onChangeEmailSearch: debounce(function (e) {
      this.filter = e.target.value
      this.loadMessages(this.mode)
    }, 800),
    loadMessages (mode) {
      this.page = 0
      this.mode = mode
      this.messages = []
      this.currentMessageIndex = null
      this.currentMessageId = ''
      this.infiniteId += 1
    },
    backToList () {
      this.currentMessageId = ''
    },
    setFlags (flag) {
      if (this.selectedMessages.length < 1) {
        return
      }
      var vm = this
      this.showLoading()
      var messages = []
      this.selectedMessages.forEach(function (index) {
        messages.push(vm.messages[index].id)
      })
      this.$http.post('/message/flags/set', { message: { id: messages }, flags: { set: flag } }).then(function () {
        for (let i = 0; i < vm.selectedMessages.length; i++) {
          if (flag === 8) {
            vm.messages.splice(vm.selectedMessages[i], 1)
          } else {
            vm.messages[vm.selectedMessages[i]].userData.flags = vm.messages[vm.selectedMessages[i]].userFlags | flag
          }
        }
        if (flag === 8) {
          vm.selectedMessages = []
          vm.loadMessages(vm.mode)
        }
        vm.hideLoading()
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(() => {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }, 500)
      })
    },
    clearFlags (flag) {
      if (this.selectedMessages.length < 1) {
        return
      }
      var vm = this
      this.showLoading()
      var messages = []
      this.selectedMessages.forEach(function (index) {
        messages.push(vm.messages[index].id)
      })
      this.$http.post('/message/flags/set', { message: { id: messages }, flags: { clear: flag } }).then(function () {
        for (let i = 0; i < vm.selectedMessages.length; i++) {
          if (flag === 8) {
            vm.messages.splice(vm.selectedMessages[i], 1)
          } else {
            vm.messages[vm.selectedMessages[i]].userData.flags &= ~flag
          }
        }
        if (flag === 8) {
          vm.selectedMessages = []
          vm.loadMessages(vm.mode)
        }
        vm.hideLoading()
      }).catch((error) => {
        vm.hideLoading()
        setTimeout(() => {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }, 500)
      })
    },
    setCurrentMessageIndex (index) {
      this.loadMessageByIndex(index)
    },
    setCurrentMessageId (messageId) {
      this.loadMessageByIndex(this.getMessageIndexById(messageId))
    },
    async loadMessageByIndex (messageIndex) {
      this.emailContentComponent = EmailContentLoader
      try {
        let messageId = this.messages[messageIndex].id
        let { data: { message, userStats, order } } = await this.$http.post('/message/get', { message: { id: messageId } })
        this.currentMessage = message
        this.currentMessage.userStats = userStats
        this.currentMessage.parentOrder = order
        this.currentMessageId = messageId
        this.currentMessageIndex = messageIndex
        this.emailContentComponent = message.order && message.order.id ? EmailContentOrder : EmailContentDefault
      } catch (error) {
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    },
    getMessageIndexById (messageId) {
      return this.messages.findIndex(message => message.id === messageId)
    },
    removeMessage () {
      var vm = this
      this.$http.post('/message/flags/set', { message: { id: this.currentMessageId }, flags: { set: 8 } }).then(function () {
        if (vm.messages.length > 1) {
          vm.messages.splice(vm.currentMessageIndex, 1)
          if (vm.messages.length <= vm.currentMessageIndex) {
            vm.currentMessageIndex -= 1
          }
          vm.currentMessageId = vm.messages[vm.currentMessageIndex].id
        } else {
          vm.currentMessageId = null
          vm.currentMessageIndex = null
          vm.currentMessage = null
          vm.messages = []
        }
      }).catch((error) => {
        setTimeout(() => {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }, 500)
      })
    },
    undeleteMessage () {
      var vm = this
      this.$http.post('/message/flags/set', { message: { id: this.currentMessageId }, flags: { clear: 8 } }).then(function () {
        if (vm.messages.length > 1) {
          vm.messages.splice(vm.currentMessageIndex, 1)
          if (vm.messages.length <= vm.currentMessageIndex) {
            vm.currentMessageIndex -= 1
          }
          vm.currentMessageId = vm.messages[vm.currentMessageIndex].id
        } else {
          vm.currentMessageId = null
          vm.currentMessageIndex = null
          vm.currentMessage = null
          vm.messages = []
        }
      }).catch((error) => {
        setTimeout(() => {
          vm.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
        }, 500)
      })
    }
  }
}
</script>
