<template>
  <div>
    <order-settings-log-detail :row="row" />
    <div class="row">
      <div class="col-md-4 control-label">
        Meno:
      </div>
      <div class="col">
        {{ offerData.applicantDetails.businessName || offerData.applicantDetails.groupName }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Adresa:
      </div>
      <div class="col">
        {{ offerData.applicantDetails.address }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Ičo:
      </div>
      <div class="col">
        <span> {{ formatIco(offerData.applicantDetails.ico) }} </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Kontaktná osoba:
      </div>
      <div class="col">
        {{ offerData.applicantDetails.contactPerson }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 control-label">
        Telefónne číslo:
      </div>
      <div class="col">
        {{ offerData.applicantDetails.phone }}
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close()"
      >
        <i class="icon-close" /> Zavrieť
      </button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOffers',
  components: { OrderSettingsLogDetail },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      offerData: null
    }
  },
  created () {
    this.offerData = this.row.status === '200' ? this.row.data.offer : this.row.data.request.offer
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    },
    formatIco (value) {
      if (value.length === 1) {
        return value[0]
      } else {
        return value.join(', ')
      }
    }
  }
}
</script>

<style>

</style>
