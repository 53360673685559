<template>
  <div
    id="order-parts-add"
    class="card"
  >
    <div class="card-heading">
      <h4 class="card-title">
        Základné údaje
      </h4>
    </div>
    <div class="card-block">
      <div class="row mrg-top-15">
        <div class="col-md-7 form-group">
          <label
            for="contract-name"
            class="text-dark text-bold"
          >
            Názov časti <small>*</small>
          </label>
          <input
            id="contract-name"
            v-model="part.name"
            type="text"
            class="form-control"
            required="required"
            aria-required="true"
            :disabled="!order._canEdit"
          >
        </div>
        <div class="col-md-2 form-group">
          <label
            for="part-number"
            class="text-dark text-bold"
          >
            Por. číslo <small>*</small>
          </label>
          <input
            id="part-number"
            v-model="part.part"
            type="number"
            min="1"
            max="100"
            class="form-control"
            :disabled="!order._canEdit"
          >
        </div>
        <div class="col-md-3 form-group">
          <label
            for="estimated-price"
            class="text-dark text-bold"
          >
            Predpokladaná hodnota v &euro; bez DPH <small>*</small>
          </label>
          <div>
            <p>
              <vue-numeric
                v-model="part.estimatedPrice"
                class="form-control"
                currency="€"
                separator="space"
                decimal-separator=","
                :precision="2"
                :empty-value="0"
                :disabled="!order._canEdit"
              />
            </p>
            <label
              v-if="form.estimatedPrice.error"
              class="error"
            >
              {{ form.estimatedPrice.errorMessage }}
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 form-group">
          <label class="text-dark text-bold">
            Druh zákazky <small>*</small>
          </label><br>
          <div class="radio radio-inline">
            <input
              id="subject-type-goods"
              v-model="part.subjectType"
              type="radio"
              name="order-subject-type"
              value="goods"
              :disabled="!order._canEdit"
            > <label for="subject-type-goods">
              Tovary
            </label>
          </div>
          <div class="radio radio-inline">
            <input
              id="subject-type-services"
              v-model="part.subjectType"
              type="radio"
              name="order-subject-type"
              value="services"
              :disabled="!order._canEdit"
            > <label for="subject-type-services">
              Služby
            </label>
          </div>
          <div class="radio radio-inline">
            <input
              id="subject-type-work"
              v-model="part.subjectType"
              type="radio"
              name="order-subject-type"
              value="work"
              :disabled="!order._canEdit"
            > <label for="subject-type-work">
              Práce
            </label>
          </div>
        </div>
        <div class="col-md-4 form-group">
          <label class="text-dark text-bold">
            Hlavný CPV kód <small>*</small>
          </label><br>
          <main-cpv-code-select
            v-if="$route.params.partid && part.id || !$route.params.partid"
            v-model="part.cpvCode"
            :value="part.cpvCode"
            :disabled="!order._canEdit"
          />
          <label
            v-if="form.cpvCode.error"
            class="error"
          >
            {{ form.cpvCode.errorMessage }}
          </label>
        </div>
        <div class="col-md-5 form-group">
          <label class="text-dark text-bold">
            Doplnkové CPV kódy
          </label><br>
          <suppl-cpv-code-select
            v-if="$route.params.partid && part.id || !$route.params.partid"
            v-model="part.cpvCodes"
            :value="part.cpvCodes"
            :disabled="!order._canEdit"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 form-group">
          <label
            for="description"
            class="text-dark text-bold"
          >
            Opis predmetu zákazky <small>*</small>
          </label>
          <textarea
            id="description"
            v-model="part.description"
            class="form-control"
            :disabled="!order._canEdit"
          />
          Počet zostávajúcich znakov: {{ descriptionTextRemaining }}<br>
          <label
            v-if="form.description.error"
            for="description"
            class="error"
          >
            {{ form.description.errorMessage }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label
              for="private-note"
              class="text-dark text-bold"
            >
              Poznámka
            </label>
            <textarea
              id="private-note"
              v-model="part.privateNote"
              class="form-control"
              :disabled="!order._canEdit"
            />
            Počet zostávajúcich znakov: {{ privateNoteTextRemaining }}
          </div>
          <div class="checkbox">
            <label class="text-dark text-bold">
              Spôsob vyhodnotenia ponúk: <small>*</small>
            </label><br>
            <div class="radio">
              <input
                id="bidding-method-1"
                v-model="part.biddingMethod"
                type="radio"
                value="lowestPrice"
                :disabled="!order._canEdit"
              ><label for="bidding-method-1">
                Najnižšej ceny
              </label>
            </div>
            <div class="radio">
              <input
                id="bidding-method-2"
                v-model="part.biddingMethod"
                type="radio"
                value="priceQualityRatio"
                :disabled="!order._canEdit"
              ><label for="bidding-method-2">
                Najlepšieho pomeru ceny  a kvality
              </label>
            </div>
            <div class="radio">
              <input
                id="bidding-method-3"
                v-model="part.biddingMethod"
                type="radio"
                value="costEffectiveness"
                :disabled="!order._canEdit"
              ><label for="bidding-method-3">
                Nákladov použitím prístupu nákladovej efektívnosti najmä nákladov počas životného cyklu
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label
              for="bids-open-at"
              class="text-dark text-bold"
            >
              Dátum a čas otvárania ponúk <span v-if="order.procedureType === 'tste'">
                (ostatné)
              </span> <small>*</small>
            </label>
            <date-time-input
              v-model="part.bidsOpenAt"
              name="bids-open-at"
              :value="part.bidsOpenAt"
              :disabled="!order._canEdit"
            />
            <label
              v-if="form.bidsOpenAt.error"
              for="bids-open-at"
              class="error"
            >
              {{ form.bidsOpenAt.errorMessage }}
            </label>
          </div>
          <div
            v-if="canEditBidsOpenAt2"
            class="form-group"
          >
            <label
              for="bids-open-at2"
              class="text-dark text-bold"
            >
              Dátum a čas otvárania ponúk (kritériá) <small>*</small>
            </label>
            <date-time-input
              v-model="part.bidsOpenAt2"
              name="bids-open-at2"
              :value="part.bidsOpenAt2"
              :disabled="!order._canEdit"
            />
            <label
              v-if="form.bidsOpenAt2.error"
              for="bids-open-at2"
              class="error"
            >
              {{ form.bidsOpenAt2.errorMessage }}
            </label>
          </div>
          <div class="checkbox">
            <label class="text-dark text-bold">
              Aukcia: <small>*</small>
            </label>
            <div class="radio radio-inline">
              <input
                id="auction-yes"
                v-model="part.auction"
                type="radio"
                :value="true"
                :disabled="!order._canEdit"
              ><label for="auction-yes">
                Áno
              </label>
            </div>
            <div class="radio radio-inline">
              <input
                id="auction-no"
                v-model="part.auction"
                type="radio"
                :value="false"
                :disabled="!order._canEdit"
              ><label for="auction-no">
                Nie
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="order._canEdit"
      class="card-footer"
    >
      <div class="row">
        <button
          class="btn btn-tenderia-grey-blue btn-inverse"
          @click="goBack"
        >
          <i class="icon-arrow-left" /> Späť
        </button>
        <button
          class="btn btn-success"
          @click.prevent="savePart()"
        >
          <i class="icon-save" />Uložiť
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimeInput from '@/components/forms/DateTimeInput.vue'
import MainCpvCodeSelect from '@/components/forms/MainCpvCodeSelect.vue'
import SupplCpvCodeSelect from '@/components/forms/SupplCpvCodeSelect.vue'
import VueNumeric from 'vue-numeric'
import { useStatsStore } from '@/storePinia/statsStore'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminPartsAdd',
  components: { DateTimeInput, MainCpvCodeSelect, SupplCpvCodeSelect, VueNumeric },
  data () {
    return {
      orderStore: useOrderStore(),
      statsStore: useStatsStore(),
      part: {
        user: {},
        description: '',
        privateNote: '',
        auction: false,
        biddingMethod: 'lowestPrice',
        cpvCode: null,
        cpvCodes: []
      },
      form: {
        cpvCode: {
          errorMessage: '',
          error: false,
          valid: false
        },
        description: {
          errorMessage: '',
          error: false,
          valid: false
        },
        bidsOpenAt: {
          errorMessage: '',
          error: false,
          valid: false
        },
        bidsOpenAt2: {
          errorMessage: '',
          error: false,
          valid: false
        },
        estimatedPrice: {
          errorMessage: '',
          error: false,
          valid: false
        }
      }
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    },
    descriptionTextRemaining () {
      return 4000 - this.part.description.length
    },
    privateNoteTextRemaining () {
      return 4000 - this.part.privateNote.length
    },
    canEditBidsOpenAt2 () {
      return this.order.procedureType === 'tste' && this.part.bidsOpenAt && this.serverTime > (new Date(this.part.bidsOpenAt))
    },
    serverTime () {
      return new Date(this.statsStore.currentTime)
    }
  },
  created () {
    this.ShowLoading()
    if (this.$route.params.partId) {
      const part = this.order._parts.filter(part => part.id === this.$route.params.partId)
      Object.assign(this.part, part[0])
      this.CloseLoading()
    } else {
      this.CloseLoading()
    }
  },
  methods: {
    validateBidsOpenAt () {
      let bidsOpenAt = new Date(this.part.bidsOpenAt)
      let bidsLimitTo = new Date(this.order.bidsLimitTo)
      let state = this.form.bidsOpenAt
      if (this.part.divided) {
        this.part.bidsOpenAt = ''
        state.valid = true
        return
      }
      state.error = state.valid = false
      state.errorMessage = ''
      if (bidsOpenAt > bidsLimitTo) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Dátum otvárania ponúk nesmie byť skorší dátum ako koniec lehoty na predkladanie ponúk.'
      }
    },
    validateBidsOpenAt2 () {
      let bidsOpenAt = new Date(this.part.bidsOpenAt)
      let state = this.form.bidsOpenAt2
      state.error = state.valid = false
      state.errorMessage = ''
      if (this.part.divided) {
        this.part.bidsOpenAt2 = ''
        state.valid = true
        return
      }
      // pred otvaranim ponuk ostatne
      if (this.serverTime < bidsOpenAt) {
        this.part.bidsOpenAt2 = ''
        state.valid = true
      // po otvarani ponuk ostatne
      } else {
        if (!this.part.bidsOpenAt2) {
          state.valid = true
          return
        }
        let bidsOpenAt2 = new Date(this.part.bidsOpenAt2)
        if (this.part.bidsOpenAt2 && bidsOpenAt2 > bidsOpenAt) {
          state.valid = true
        } else {
          state.error = true
          state.errorMessage = 'Dátum otvárania ponúk (kritériá) nesmie byť skorší dátum ako dátum a čas otvárania ponúk (ostatné).'
        }
      }
    },
    validatecpvCode () {
      var cpvCode = this.part.cpvCode
      var state = this.form.cpvCode
      state.error = state.valid = false
      state.errorMessage = ''
      if (cpvCode && cpvCode.length > 0) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Hlavný CPV kód musí byť vyplnený.'
      }
    },
    validateDescription () {
      var description = this.part.description
      var state = this.form.description
      state.error = state.valid = false
      state.errorMessage = ''
      if (description && description.length > 0) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Opis predmetu zákazky musí byť vyplnený.'
      }
    },
    validateEstimatedPrice () {
      var state = this.form.estimatedPrice
      state.error = state.valid = false
      state.errorMessage = ''
      if (this.part.divided || this.part.estimatedPrice > 0) {
        state.valid = true
      } else {
        state.error = true
        state.errorMessage = 'Predpokladaná hodnota musí byť zadaná.'
      }
    },
    validateBeforeSave () {
      this.validateEstimatedPrice()
      this.validatecpvCode()
      this.validateDescription()
      this.validateBidsOpenAt()
      this.validateBidsOpenAt2()

      return this.form.estimatedPrice.valid &&
      this.form.cpvCode.valid &&
      this.form.description.valid &&
      this.form.bidsOpenAt.valid &&
      this.form.bidsOpenAt2.valid
    },
    goBack () {
      window.history.go(-1)
    },
    async savePart () {
      if (!this.validateBeforeSave()) {
        return
      }
      this.ShowLoading()
      let data = {
        order: {
          parent: this.order.id,
          name: this.part.name,
          estimatedPrice: this.part.estimatedPrice.toString().replaceAll(' ', '').replaceAll(',', '.'),
          description: this.part.description,
          bidsOpenAt: this.part.bidsOpenAt,
          organization: this.order.organization.id,
          privateNote: this.part.privateNote,
          auction: this.part.auction,
          subjectType: this.part.subjectType,
          cpvCode: this.part.cpvCode,
          cpvCodes: this.part.cpvCodes,
          biddingMethod: this.part.biddingMethod,
          part: this.part.part
        }
      }
      if (this.part.id) {
        data.order.id = this.part.id
      }
      if (this.order.procedureType === 'tste') {
        data.order.bidsOpenAt2 = this.part.bidsOpenAt2
      }
      if (this.part.auction) {
        data.order.offerOpeningType = 'nonpublic'
      } else {
        data.order.offerOpeningType = 'distant'
      }
      let vm = this
      try {
        await this.$http.post('/order/set', data)
        await this.orderStore.getOrder(this.order.id)
        this.CloseLoading()
        this.OkAlert({
          title: vm.part.id ? 'Zmeny boli uložené.' : 'Časť zákazky bola vytvorená.',
          text: 'Nastavenia kritérií pre jednotlivé časti vykonajte v časti „Nastavenia“'
        })
        window.history.go(-1)
      } catch (error) {
        this.CloseLoading()
        this.ErrorAlert({ text: 'Pri spracovaní vašej požiadavky nastala chyba.' }, error)
      }
    }
  }
}
</script>
