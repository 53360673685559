<template>
  <div>
    <order-settings-log-detail :row="row" />
    <div class="row">
      <div class="col-md-4 control-label">
        Názov dokumentu:
      </div>
      <div class="col">
        {{ row.action === 'remove' ? row.data.removed[0].name : row.data.attachments[0].name }}
      </div>
    </div>
    <div class="row no-gutters justify-content-end">
      <button
        class="btn btn-tenderia-grey-blue btn-inverse"
        @click.prevent="close()"
      >
        <i class="icon-close" /> Zavrieť
      </button>
    </div>
  </div>
</template>

<script>
import OrderSettingsLogDetail from './OrderSettingsLogDetail.vue'
export default {
  name: 'OrderSettingsLogOrderDocuments',
  components: { OrderSettingsLogDetail },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  methods: {
    close: function () {
      this.$emit('vuedals:close')
    }
  }
}
</script>

<style>

</style>
