<template>
  <organizations-list
    :organizations="order.interested"
    :order="order"
    title="Zoznam záujemcov"
  />
</template>

<script>
import OrganizationsList from '@/components/order/common/components/OrganizationsList.vue'
import { useOrderStore } from '@/storePinia/orderStore'

export default {
  name: 'AdminInterested',
  components: { OrganizationsList },
  data () {
    return {
      orderStore: useOrderStore()
    }
  },
  computed: {
    order () {
      return this.orderStore.order
    }
  }
}
</script>
