<template>
  <div class="card chart-card">
    <div class="card-heading">
      <h4 class="card-title">
        Žiadanky
      </h4>
    </div>
    <div
      v-if="requestsChartData"
      class="p-2 row"
    >
      <div class="col-md-6">
        <bar-chart-echart
          :data="requestsChartData"
          :series="requestsChartsSeries"
          :minHeight="175"
        />
      </div>
      <div class="col-md-6">
        <bar-chart-echart
          :data="requestsChartValueData"
          :series="requestsChartsSeries"
          :formatLabel="$format.shortCurrencyFixed2"
          :yFormat="$format.shortCurrency"
          :minHeight="175"
        />
      </div>
    </div>

    <div
      v-else
      class="card-body"
    >
      <span>Momentálne neobsahuje žiadne záznamy</span>
    </div>
  </div>
</template>

<script>
import BarChartEchart from '@/components/ui/BarChartEchart.vue'
import { useGlobalStore } from '@/storePinia/globalStore'

export default
{
  name: 'Requests',
  components: { BarChartEchart },
  data () {
    return {
      globals: useGlobalStore(),
      requestsStats: null,
      requestsTotal: 0
    }
  },
  computed: {
    requestsChartsSeries () {
      return {
        planned: {
          color: '#FF9D3B',
          label: 'V príprave',
        },
        approving: {
          color: '#3E97EF',
          label: 'Čakajúce',
        },
        approved: {
          color: '#1FCB7C',
          label: 'Schválené',
        },
        rejected: {
          color: '#EB5757',
          label: 'Odmietnuté',
        }
      }
    },
    requestsChartData () {
      const stats = this.requestsStats
      if (!stats || (stats.estimatedValue === 0 && stats.bestValue === 0)) {
        return null
      }
      // Define a value for the x-axis value
      const xAxis = 'Počet'

      // Prepare the data structure based on the fetched object
      const data = {
        planned: [
          { xAxis, value: stats.planned }
        ],
        approving: [
          { xAxis, value: stats.approving }
        ],
        approved: [
          { xAxis, value: stats.approved }
        ],
        rejected: [
          { xAxis, value: stats.rejected }
        ]
      }

      return data
    },
    requestsChartValueData () {
      const stats = this.requestsStats
      if (!stats || (stats.estimatedValue === 0 && stats.bestValue === 0)) {
        return null
      }
      // Define a value for the x-axis value
      const xAxis = 'Objem (bez DPH)'

      // Prepare the data structure based on the fetched object
      const data = {
        planned: [
          { xAxis, value: stats.plannedValue }
        ],
        approving: [
          { xAxis, value: stats.approvingValue }
        ],
        approved: [
          { xAxis, value: stats.approvedValue }
        ],
        rejected: [
          { xAxis, value: stats.rejectedValue }
        ]
      }

      return data
    }
  },
  methods: {
    async fetchStatistics () {
      const { data: { stats } } = await this.$http.post('/stats/generate', {
        object: 'requests',
        type: 'request-stats'
      })
      this.requestsStats = stats.filter(user => user.userId === this.globals.user.id)[0] || []
    },
  },
  mounted () {
    this.fetchStatistics()
  }
}
</script>
